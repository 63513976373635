import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PATHS } from 'src/app/core/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivateChild, CanActivate {
  private isInMaintenance = environment.inMaintenance
  private router = inject(Router)
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.isInMaintenance){
      return this.router.parseUrl(PATHS.MAINTENANCE)
    }else{
      return true
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(!this.isInMaintenance){
      return this.router.parseUrl(PATHS.LANDING)
    }else{
      return true
    }
  }
  
}
