import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GtmEvent } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {

  private authService = inject(AuthService);
  private gtmService = inject(GtmService)

  @Input()
  email: string;
  @Input()
  questions;

  formQuestion: FormGroup;
  submitted = false;
  questionOption = {
    question: 'firstQuestion',
    answer: 'firstAnswer'
  };
  @Output()
  emitData = new EventEmitter<Data>();

  ngOnInit(): void {
    this.basicForm();
  }

  basicForm(): void {
    this.formQuestion = new FormGroup({
      answer: new FormControl('', [ Validators.required ])
    });
  }

  getMessage(input: string): string {
    const formControl = this.formQuestion.get(input);

    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "Debes ingresar una respuesta";
    }
    return '';
  }

  getError(input: string): string {
    const formControl = this.formQuestion.get(input);
    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    return '';
  }

  sendData() {
    this.submitted = true;
    if (this.formQuestion.invalid) return;
    const answer = this.formQuestion.get('answer').value;
    const data = {
      email: this.email,
      response: true,
      question: {
        text: this.questionOption.question,
        value: this.questions[this.questionOption.question]
      },
      answer: {
        text: this.questionOption.answer,
        value: answer
      }
    }

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: `${this.questionOption.question === 'firstQuestion' ? "Contraseña_2" : "Contraseña_SegundoIntento"}`,
      pantalla: `${this.questionOption.question === 'firstQuestion' ? "Pregunta seguridad1" : "Pregunta seguridad2"}`,
    }

    this.gtmService.sendEvent(gtmEvent)


    this.authService.validateQuestions(data).subscribe({
      next: ({ response }) => {
        this.validateQuestions(data, response);
      }
    });
  }

  validateQuestions(data, response: boolean): void {
    this.formQuestion.get('answer').setValue('');
    this.formQuestion.get('answer').setErrors({'required': null});
    if (response) {
      this.emitData.emit({ step: 2, ...data });
    } else {
      if (this.questionOption.question === 'secondQuestion') {
        this.emitData.emit({ step: 2, response: false });
      }
      this.questionOption = {
        question: 'secondQuestion',
        answer: 'secondAnswer'
      };
    }
  }

}

interface Data {
  step: number;
  email?: string;
  response: boolean;
  question?: {
    text: string;
    value: string;
  };
  answer?: {
    text: string;
    value: string;
  };
}
