import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';

@Component({
  selector: 'app-accessibility-statement',
  templateUrl: './accessibility-statement.component.html',
  styleUrls: ['./accessibility-statement.component.scss']
})
export class AccessibilityStatementComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToLanding() {
    this.router.navigate([PATHS.LANDING])
  }
}
