<div class="header">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<div class="limit">

    <section>
        <img src="/assets/images/group-21.svg" alt="otro estado">
        <article class="d-flex flex-column justify-content-between w-100 h-100">
            <div class="d-flex w-100 flex-column">
                <img width="120" class="d-none d-md-block" src="/assets/images/logo-1-logo-light.svg" alt="Superaste los intentos de inicio de sesión">
                
                <h2 class="main-title mx-auto flexoDemi font-size-20 line-height-28 font-size-md-32 line-height-md-40 text-center text-md-left">Superaste los intentos de inicio de sesión</h2>
                
                <p class="sub-title text-center font-size-14 line-height-20 font-size-md-20 line-height-md-28 text-md-left mb-1">
                    <span class="pr-2">Prueba de nuevo en {{ leftTime }} o</span>
                </p>

                <bcp-button shape="text" tier="primary" (click)="goToRecoverPassword()"><span class="font-size-16">Restablece tu contraseña</span></bcp-button>
            </div>

            <p class="sub-title d-flex align-items-center mb-0 justify-content-md-start justify-content-center"><span class="font-size-14 font-size-md-16 pr-2">¿No tienes un usuario? </span> 
                <bcp-button shape="text" tier="primary" (click)="goToRegister()">Regístrate</bcp-button>
            </p>
        </article>
    </section>
</div>