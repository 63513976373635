import { DecimalPipe } from '@angular/common';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, debounceTime } from 'rxjs';
import { PATHS } from 'src/app/core/constants';
import { CommissionTable, CouponData, DataMandadito, GtmEvent, OPERATIONS, Receiver, UpdateDataService } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { mustBeLessThan } from 'src/app/views/auth/register/custom-validations';
import { CouponComponent } from '../coupon/coupon.component';
import { Data } from '../../new-mandadito.data';
import { Currency } from 'src/app/shared/models/model';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { StatusRegister } from 'src/app/core/constants';
import { LastPageService } from 'src/app/shared/services/last-page.service';

const DEFAULT_COMMISSION_TEXT = 'Comisión por calcular';
const AMOUNT_TYPE = 1;

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss'] 
})
export class AmountComponent implements OnInit, OnDestroy {

  @Input() data: DataMandadito;
  @Input() userData;
  @Input() currentMaxDailyToSend: number;
  @Input() maxAmountPerMandadito: number;
  @Input() promotion = null;
  @Input() presetAmount:string
  @Input() presetReceiver: Receiver
  @Input() activeHome = false;
  @Input() isRepeatingMandadito: boolean;
  @Input() commissionTable: CommissionTable;
  @Output() continue = new EventEmitter<{}>();
  @Output() onSelectedCurrency = new EventEmitter<string>();
  @Input() showAmount : boolean = false;

  maxDailyToSend = 0;

  @ViewChild(CouponComponent) couponComponent: CouponComponent;

  subscriptionAmount: Subscription;
  subscriptionCoupon: Subscription;
  showCards = true;
  coupon: CouponData = null;
  
  couponType = AMOUNT_TYPE;

  modalTariff = false;
  submitted = false;
  previousValue: any;
  amount: number = null;
  commissionText = DEFAULT_COMMISSION_TEXT;
  commission: number = null;
  normalCommission = 0;
  amountForm: FormGroup;
  customError = false;
  isAmountExceeded = false;
  selectedCurrency = ''

  integerAndDecimalAmount = {
    integer: 0,
    decimal: 0, 
  }
  Currency = Currency

  private dataService = inject(DataService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private gtmService = inject(GtmService);
  private localStorageService = inject(LocalStorageService);
  private lastPageService = inject(LastPageService);

  constructor(private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.initializeForm();
    this.checkInputChange();
    this.updateAmount(this.data.operation?.amount);
    this.getData();
    this.setRepeatingMandaditoAccountCurrency()
    this.validateStatusUserToken();
  }

  validateStatusUserToken(): void {
    const token = this.localStorageService.getStorage('token');
    this.showAmount = token.statusRegister.toLowerCase() == StatusRegister.PARTIAL ? false : true;
  }

  ngOnDestroy(): void {
    this.subscriptionAmount?.unsubscribe();
    this.subscriptionCoupon?.unsubscribe();
      
  }

  getData() {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data?.operation  === OPERATIONS.RESET_MANDADITO) {
          this.customError = false;
          this.isAmountExceeded = false;
          this.data = Data;
          this.amount = null;
          this.updateAmount(null);
        }
        if (data?.operation  === OPERATIONS.RESEND_MANDADITO_IN_AMOUNT) {
          this.data = data.data;
        }
      }
    })
  }

  sendData(): void {
    this.submitted = true;
    if (this.maxDailyToSend < this.amount) return;
    if (this.customError) return;

    if(this.couponComponent && (this.coupon === null || this.coupon?.show === false)){
      this.couponComponent.clearCoupon()
    }

    const amount = Number.parseFloat(this.amountForm.controls['amount'].value).toFixed(2);
    this.amountForm.controls['amount'].setValue(amount);
    const operation = {
      amount: this.amount,
      commission: this.commission,
      realCommission: this.getCommission(this.amount),
      promotion: this.promotion,
      total: this.commission + this.amount,
      currency: this.selectedCurrency
    };

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_Envio1_Continuar',
      pantalla: 'EnvioPaso1',
    };
    this.gtmService.sendEvent(gtmEvent);
    this.sendTagCurrencyType();
    this.data.operation = { ...this.data.operation, ...operation };

    this.activeHome = false;
    this.continue.emit(this.data);
  }

  sendTagCurrencyType(){
    let accion = (this.selectedCurrency == Currency.PEN.code) ? 'Moneda_soles': 'Moneda_dolares';
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'EnvioPaso1',
    };
    this.gtmService.sendEvent(gtmEvent);
  }

  updateIndex(event) {
    this.data.indexSelected = event.indexSelected;
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: `clic_Envio1_${event.amount}`,
      pantalla: 'EnvioPaso1',
    };
    this.gtmService.sendEvent(gtmEvent);
    this.updateAmount(event.amount);

  }

  initializeForm(): void {
    this.maxDailyToSend = parseFloat((this.currentMaxDailyToSend >= this.maxAmountPerMandadito ?  this.maxAmountPerMandadito : this.currentMaxDailyToSend).toFixed(2));
    this.amountForm = new FormGroup({
      amount: new FormControl(0, [mustBeLessThan(this.maxDailyToSend), Validators.required, Validators.min(1)])
    });
    this.amountForm.patchValue({
      amount: null
    });
  }

  checkInputChange() {
    this.subscriptionAmount = this.amountForm.get('amount').valueChanges.pipe(debounceTime(750)).subscribe({
      next: data => {
        if (this.data.indexSelected === null) {
          const gtmEvent: GtmEvent = {
            event: 'virtualEvent',
            accion: 'clic_Envio1_monto',
            pantalla: 'EnvioPaso1',
          };
          this.gtmService.sendEvent(gtmEvent);
        }
        if (!isNaN(data)) this.updateAmount(parseFloat(data));
      }
    })
  }

  resetCard(): void {
    this.data.indexSelected = null;
  }

  showModalTariff(): void {
    this.modalTariff = true;
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_tarifario',
      pantalla: 'EnvioPaso1',
    };
    this.gtmService.sendEvent(event);
  }

  updateAmount(amount): void {

    this.previousValue = amount;

    if (amount === null || amount === undefined) {
      const commission = this.getCommission(amount);
      this.getTextCommission(amount, commission);
      return;
    }

    const dedimal = this.decimalPipe.transform(amount, '1.2-2');
    this.amount = parseFloat(dedimal);
    this.getCommission(amount);
    this.getTextCommission(amount, this.commission);
  }

  getTextCommission(amount, commission):void {

    let defaultText = DEFAULT_COMMISSION_TEXT;
    if (amount > 0) {
      defaultText = `Comisión $ ${commission.toFixed(2)}`;
    }

    this.commissionText = defaultText;
  }

  getCommission(amount: number): number {
    let commission = 0;

    Object.entries(this.commissionTable).forEach(([key, value]) => {
      if (amount === 0 || amount === null || amount === undefined) return
      if (amount >= Number(key)) commission = value;
    });

    this.normalCommission = commission;
    this.commission = commission;

    if (this.promotion && this.promotion.discount >= 0 && commission !== null) {
      this.commission = commission - (commission * this.promotion.discount);
    }

    return commission;
  }

  closeSession() {
    this.authService.logout();
    this.router.navigate([PATHS.LANDING]);
  }

  get getTextInputAmount() {
    let text = `Puedes mandar $${ this.maxDailyToSend } como máximo por envío`;
    if (this.submitted && this.amountForm.status === 'INVALID'
    && (this.amountForm.get('amount')?.errors['required'] 
    || this.amountForm.get('amount')?.errors['min'])) {
      text = 'Debes ingresar un monto';
    }

    if (this.customError) {
      text = `Usando este cupón, el límite de este envío es de <strong>$${ this.decimalPipe.transform(this.maxDailyToSend - this.coupon.amount, '1.2-2') }</strong>`;
    }
    return text;
  }

  getInputAmount(type: string, event): void {
    this.customError = false;
    this.isAmountExceeded = false;
    if (type === 'INTEGER') {
      this.integerAndDecimalAmount.integer = event;
    }

    if (type === 'DECIMAL') {
      this.integerAndDecimalAmount.decimal = event;
    }

    const amount = parseFloat((this.integerAndDecimalAmount.integer + this.integerAndDecimalAmount.decimal).toFixed(2));

    if (amount > this.maxDailyToSend) {
      this.isAmountExceeded = true;
      return;
    }

    this.updateAmount(amount);
  }

  maskAccountNumber(accountNumber:string): string {
    let maskAccount = '***************';
    if (accountNumber) {
      maskAccount = `*** ******* ${accountNumber.substring(11,12)} ${accountNumber.substring(12,14)}`;
    }

    return maskAccount;
  }


  goBack(){
    this.presetAmount = null;
    this.activeHome = true;
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.RESET_MANDADITO, data: null });
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  setSelectedCurrency(selectedCurrency:string){
    this.selectedCurrency = selectedCurrency
    this.onSelectedCurrency.emit(selectedCurrency)
  }

  setRepeatingMandaditoAccountCurrency(){
    if(this.isRepeatingMandadito){
      this.setSelectedCurrency(this.data?.receiver?.accountCurrency)
    }
  }


  isModalOpen = false;

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }

  clickUploadPhoto(){
    this.lastPageService.saveLastPage(PATHS.NEW_MANDADITO);
    this.router.navigate([PATHS.COMPLETE_REGISTER_PHOTO]);
  }
}
