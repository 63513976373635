<div class="slide-content" #slider>
    <div class="slider-container" [ngClass]="{'grabbing': isClassGrabbing}" [style.transform]="'translateX('+currentTranslate+'px)'" *ngIf="elements && elements.length > 0 && slideWith !== null">
      <div class="slide" *ngFor="let element of elements; let i = index" [ngStyle]="{'width.px': slideWith}" (touchstart)="touchStart(i, $event)" (touchend)="touchEnd()" (touchmove)="touchMove($event)"
      (mousedown)="touchStart(i, $event)" (mouseup)="touchEnd()" (mouseleave)="touchEnd()" (mousemove)="touchMove($event)">
        <app-element [element]="element" (emitData)="sendData($event)"></app-element>
      </div>
    </div>
    <ul class="dots" *ngIf="elements && elements.length > 1">
      <li *ngFor="let element of elements" [ngClass]="{'active': element.active}"></li>
    </ul>
    <ul class="arrows" *ngIf="elements && elements.length > 1">
      <li (click)="previousElement()" [ngClass]="{'active': currentIndex !== 0}">
        <img src="/assets/images/arrow-left-disabled.svg" alt="" *ngIf="currentIndex === 0">
        <img src="/assets/images/left-arrow.svg" alt="" *ngIf="currentIndex !== 0">
      </li>
      <li (click)="nextElement()" [ngClass]="{'active': currentIndex !== elements.length - 1}">
        <img src="/assets/images/arrow-right-disabled.svg" alt="" *ngIf="currentIndex === elements.length - 1">
        <img src="/assets/images/right-arrow.svg" alt="" *ngIf="currentIndex !== elements.length - 1">
      </li>
    </ul>
  </div>