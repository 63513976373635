import { Component, EventEmitter, inject , Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { ItemsCarouselHead } from 'src/app/views/landing/landing.data';

@Component({
  selector: 'app-headband',
  templateUrl: './headband.html',
  styleUrls: ['./headband.component.scss']

})
export class HeadbandComponent implements OnInit {
  @Input() autoSlide = true;
  @Input() slideInterval = 4000;
  @Output() showModal: EventEmitter<boolean> = new EventEmitter();

  interval: any;
  public currentPosition = 0;

  ngOnInit(): void {
    this.ItemsCarouselHead.forEach( ( i, index ) => {
      i.id = index;
      i.marginLeft = 0;
    });
    this.autoSlideSlides();  
  }


  autoSlideSlides(): void {
    if (this.autoSlide) {
      this.interval = setInterval(() => {
        this.setNext();
      }, this.slideInterval);
    }
  }

  setNext() {
    this.stopSlides();
    let finalPercentage = 0;
    let nextPosition = this.currentPosition + 1;
    if (nextPosition <= this.ItemsCarouselHead.length - 1) {
      finalPercentage = -100 * nextPosition;
    } else {
      nextPosition = 0;
    }
    this.ItemsCarouselHead.find(i => i.id === 0).marginLeft = finalPercentage;
    this.currentPosition = nextPosition;
    this.autoSlideSlides();
  }

  stopSlides(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
 
  private router = inject(Router)
  ItemsCarouselHead = ItemsCarouselHead;

  goToLanding() {
    this.router.navigate([PATHS.REGISTER])
  }

  executeEvent($event: boolean) {
    if ($event) {
      this.showModal.emit($event);
    } else {
      this.goToLanding();
    }
  }
}