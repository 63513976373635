<div class="operations">
    <bcp-button shape="text" tier="primary" (click)="goNewMandadito()">
        <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
        Volver
    </bcp-button>
        <h3>Mis Mandaditos</h3>
        <ng-container *ngIf="hadSendMandadito">
        <p>Selecciona una fecha</p>
        <form [formGroup]="historyPeriodForm">
            <bcp-select id="date-select" (ctrlChange)="onDateSelectionChange($event)" bcpCtrlForm formControlName="period" >
                <bcp-select-header slot="header"></bcp-select-header>
                <bcp-select-option *ngFor="let date of dateList" [value]="date.value">{{date.label}}</bcp-select-option>
            </bcp-select>
        </form>
    </ng-container>

    <div class="disclaimer" *ngIf="hadSendMandadito">
        <bcp-icon name="info-r" size="20" color="primary"></bcp-icon>
        <span>Puedes consultar los Mandaditos hechos hasta 6 meses atrás.</span>
    </div>
    
    <ul *ngIf="operationList.length > 0">
        <li *ngFor="let operation of operationList" (click)="showDetail.emit(operation)">
            <div class="data">
                <span>{{ operation.avatar }}</span>
                <p>
                    <span>{{ operation.autoRemittance ? '(Yo) ' : '' }}{{ operation.receiver | titlecase}}</span>
                    <span>{{ operation.date}}</span>
                </p>
            </div>
            <div class="amount">
                <div class="status">
                    <p>${{ operation.amount | number:'1.2-2' }}</p>
                    <span [ngClass]="mandaditoStatuses[operation.status]">{{operation.status}}</span>
                </div>
                <img src="/assets/images/chevron-right.svg" alt="">
            </div>
        </li>
    </ul>

    <div class="no-data" *ngIf="loading === false && operationList.length === 0 && hadSendMandadito">
        <img src="/assets/images/mdt-personas-menu-historico-mobile@3x.png" alt="Sin mandaditos">
        <h4>No hemos encontrado Mandaditos</h4>
        <p>No hemos encontrado resultados para el mes seleccionado</p>
    </div>

    <div class="no-data" *ngIf="loading === false && operationList.length === 0 && !hadSendMandadito">
        <img src="/assets/images/mdt-personas-menu-historico-mobile@3x.png" alt="Sin mandaditos">
        <h4>Aún no has hecho ningún Mandadito</h4>
        <p>Aquí encontrarás los Mandaditos que hayas solicitado</p>
        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToNewMandadito()">Mandar dinero ahora</bcp-button>
        </div>
    </div>

    <p class="help">
        ¿No encuentras tu Mandadito? <a class="link" href="https://wa.me/51945657689" target="_blank" rel="noopener">Escríbenos</a>
    </p>
</div>
