import { Component, HostListener, OnInit, inject } from '@angular/core';
import { MainService } from 'src/app/core/services/main.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { GtmEvent } from 'src/app/core/interfaces';

@Component({
  selector: 'app-referral-banner',
  templateUrl: './referral-banner.component.html',
  styleUrls: ['./referral-banner.component.scss']
})
export class ReferralBannerComponent implements OnInit {

  showBanner = false;
  private mainService = inject(MainService)
  private gtmService = inject(GtmService)
  showModal = false
  url: string
  customerReferralCode: string
  isDesktop: boolean

  constructor() { }

  ngOnInit(): void {
    this.getCustomer()
    this.onResize()
  }

  closeBanner() {
    this.showBanner = false;
  }

  getCustomer() {
    this.mainService.GetCustomer().subscribe(res => {
      if (res.hasOperationsCompleted) {
        this.showBanner = true
        this.customerReferralCode = res.referralCode
      }
    })
  }

  openModal() {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Ref_Compartir_envio',
      pantalla: 'Envio paso 1',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.showModal = true;
  }

  closeModal(event: boolean): void {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Ref_Compartir_cerrar',
      pantalla: 'Envio paso 1',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.showModal = event;
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 768) {
      this.isDesktop = true
    }
  }
}
