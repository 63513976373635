import { CommonModule, DecimalPipe } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { LandingComponent } from './landing/landing.component';
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { BcpFormmodule } from '@bcp/ng-core-v4/forms';
import { FrequencyQuestionsComponent } from "./frequency-questions/frequency-questions.component";
import { RegisterComponent } from "./auth/register/register.component";
import { Step1Component } from "./auth/register/components/step1/step1.component";
import { SafePipe } from "../core/pipes/safe.pipe";
import { LoginComponent } from './auth/login/login.component';
import { NewMandaditoComponent } from './new-mandadito/new-mandadito.component';
import { AmountComponent } from "./new-mandadito/components/amount/amount.component";
import { FavoriteComponent } from "./new-mandadito/components/favorite/favorite.component";
import { ReceiverComponent } from "./new-mandadito/components/receiver/receiver.component";
import { ResumeComponent } from "./new-mandadito/components/resume/resume.component";
import { PaypalComponent } from "./new-mandadito/components/paypal/paypal.component";
import { PromotionComponent } from './new-mandadito/components/promotion/promotion.component';
import { CouponComponent } from './new-mandadito/components/coupon/coupon.component';
import { OtherStateComponent } from './other-state/other-state.component';
import { RequestSuccessComponent } from './request-success/request-success.component';
import { HistoricalComponent } from "./historical/historical.component";
import { DetailComponent } from "./historical/components/detail/detail.component";
import { ListComponent } from "./historical/components/list/list.component";
import { TermsAndConditionsLayoutComponent } from './terms-and-conditions-layout/terms-and-conditions-layout.component';
import { RouterModule } from "@angular/router";
import { ActiveCampaignsComponent } from './terms-and-conditions-layout/pages/active-campaigns/active-campaigns.component';
import { GeneralTermsComponent } from "./terms-and-conditions-layout/pages/general-terms/general-terms.component";
import { PastCampaignsComponent } from "./terms-and-conditions-layout/pages/past-campaigns/past-campaigns.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions-layout/pages/terms-and-conditions/terms-and-conditions.component";
import { ZeroComissionCampaignComponent } from "./terms-and-conditions-layout/pages/active-campaigns/zero-comission-campaign/zero-comission-campaign.component";
import { PatrioticHolidaysCampaignComponent } from './terms-and-conditions-layout/pages/past-campaigns/patriotic-holidays-campaign/patriotic-holidays-campaign.component';
import { AccessibilityStatementComponent } from './accessibility-statement/accessibility-statement.component';
import { MaintenanceWindowComponent } from './maintenance-window/maintenance-window.component';
import { MyDataComponent } from './my-data/my-data.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { EmailComponent } from './auth/forgot-password/components/email/email.component';
import { AuthenticateComponent } from './auth/forgot-password/components/authenticate/authenticate.component';
import { PasswordComponent } from './auth/forgot-password/components/password/password.component';
import { PasswordUpdatedComponent } from './auth/password-updated/password-updated.component';
import { HelpComponent } from './auth/forgot-password/components/help/help.component';
import { LimitComponent } from './auth/limit/limit.component';
import { ExceededComponent } from './auth/exceeded/exceeded.component';
import { FavoriteStartComponent } from './new-mandadito/components/favorite-start/favorite-start.component';
import { RegisterExpComponent } from './register-exp/register-exp.component';
import { Step1ExpComponent } from './register-exp/components/step1-exp/step1-exp.component';
import { Step2ExpComponent } from './register-exp/components/step2-exp/step2-exp.component';
import { Step3ExpComponent } from './register-exp/components/step3-exp/step3-exp.component';
import { CompletedExpComponent } from './register-exp/components/completed-exp/completed-exp.component';
import { HeaderExpComponent } from './register-exp/components/header-exp/header-exp.component';
import { Step4ExpComponent } from './register-exp/components/step4-exp/step4-exp.component';
import { ChristmasCampaignComponent } from './terms-and-conditions-layout/pages/christmas-campaign/christmas-campaign.component';
import { RedirectComponent } from './redirect/redirect.component';
import { OnboardingComponent } from './auth/register/components/onboarding/onboarding.component';
import { InputAmountComponent } from './new-mandadito/components/input-amount/input-amount.component';
import { UpdateDataComponent } from './auth/update-data/update-data.component';
import { DocumentsComponent } from './auth/update-data/documents/documents.component';
import { CompletedUpdateComponent } from "./auth/update-data/completed/completed-update.component";
import { ReviewingDataComponent } from './auth/reviewing-data/reviewing-data.component';
import { DataComponent } from './auth/update-data/data/data.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReferralsCampaignComponent } from './terms-and-conditions-layout/pages/active-campaigns/referrals-campaign/referrals-campaign.component';
import { HeadbandComponent } from './landing/components/headband/headband.component';
import { ModalScheduleComponent } from './landing/components/popup-landing/popup-landing.component';
import { LayerPopupLandingComponent } from "./landing/components/layer-popup-landing/layer-popup-landing.component";
import { DeploymentWindowComponent } from './deployment-window/deployment-window.component';
import { MothersDayCampaingComponent } from './terms-and-conditions-layout/pages/active-campaigns/mothers-day-campaing/mothers-day-campaing.component';
import { PeruGourmetComponent } from './terms-and-conditions-layout/pages/active-campaigns/peru-gourmet/peru-gourmet.component';
import { SendComponent } from './new-mandadito/send/send.component';
import { HistoryListComponent } from './new-mandadito/components/history-list/history-list.component';
import { HistoryHeaderComponent } from './new-mandadito/components/history-header/history-header.component';
import { ConfirmMessageComponent } from './new-mandadito/components/confirm-message/confirm-message.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { BcpComponent } from "./landing/components/bcp/bcp.component";
import { WhatsappComponent } from './landing/components/whatsapp/whatsapp.component';
import { CurrencyConversionComponent } from './new-mandadito/components/currency-conversion/currency-conversion.component';
import { CurrencyConversionCalculatorComponent } from './landing/components/currency-conversion-calculator/currency-conversion-calculator.component';
import { SearchByDniComponent } from './landing/components/currency-conversion-calculator/search-by-dni/search-by-dni.component';
import { CompletedRegisterComponent } from "./auth/complete-registration/components/completed/completed.component";
import { CompleteRegistrationComponent } from "./auth/complete-registration/complete-registration.component";
import { IncompleteRegisterComponent } from './auth/complete-registration/components/incomplete-register/incomplete-register.component';
import { SecondStepComponent } from "./auth/complete-registration/components/step2/step2.component";
import { ThirdStepComponent } from "./auth/complete-registration/components/step3/step3.component";
import { CompleteRegistrationPhotoComponent } from './auth/complete-registration-photo/complete-registration-photo.component';
import { Step3HomeComponent } from './auth/complete-registration/components/step3-home/step3-home.component';
import { ZeroComissionRaffleComponent } from './terms-and-conditions-layout/pages/active-campaigns/zero-comission-raffle/zero-comission-raffle.component';
import { GiftCardRaffleComponent } from './terms-and-conditions-layout/pages/active-campaigns/gift-card-raffle/gift-card-raffle.component';

const elements = [
  LandingComponent,
  FrequencyQuestionsComponent,
  RegisterComponent,
  CompleteRegistrationComponent,
  Step1Component,
  SecondStepComponent,
  NewMandaditoComponent,
  AmountComponent,
  FavoriteComponent,
  ReceiverComponent,
  ResumeComponent,
  PaypalComponent,
  HistoricalComponent,
  DetailComponent,
  ListComponent,
  TermsAndConditionsLayoutComponent,
  TermsAndConditionsComponent,
  GeneralTermsComponent,
  ActiveCampaignsComponent,
  ZeroComissionCampaignComponent,
  PastCampaignsComponent,
  PatrioticHolidaysCampaignComponent,
  AccessibilityStatementComponent,
  MaintenanceWindowComponent,
  ChristmasCampaignComponent,
  OnboardingComponent
];

const registerExperimentElements = [
  RegisterExpComponent,
  Step1ExpComponent,
  Step2ExpComponent,
  Step3ExpComponent,
  CompletedExpComponent,
  HeaderExpComponent
]

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [...elements, ThirdStepComponent,
    CompletedRegisterComponent,
    LoginComponent,
    PromotionComponent,
    CouponComponent,
    OtherStateComponent,
    RequestSuccessComponent,
    MyDataComponent,
    ForgotPasswordComponent,
    EmailComponent,
    AuthenticateComponent,
    PasswordComponent,
    PasswordUpdatedComponent,
    HelpComponent,
    ...registerExperimentElements,
    Step4ExpComponent,
    LimitComponent,
    ExceededComponent,
    FavoriteStartComponent,
    RedirectComponent,
    InputAmountComponent,
    UpdateDataComponent,
    DocumentsComponent,
    CompletedUpdateComponent,
    ReviewingDataComponent,
    DataComponent,
    ReferralsCampaignComponent,
    HeadbandComponent,
    ModalScheduleComponent,
    LayerPopupLandingComponent,
    DeploymentWindowComponent,
    MothersDayCampaingComponent,
    PeruGourmetComponent,
    SendComponent,
    HistoryListComponent,
    HistoryHeaderComponent,
    ConfirmMessageComponent,
    ReferralsComponent,
    BcpComponent,
    WhatsappComponent
  ,
    CurrencyConversionComponent,
    CurrencyConversionCalculatorComponent,
    SearchByDniComponent,
    IncompleteRegisterComponent,
    CompleteRegistrationPhotoComponent,
    Step3HomeComponent,
    ZeroComissionRaffleComponent,
    GiftCardRaffleComponent],
  exports: [...elements],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    BcpFormmodule,
    RouterModule,
    RecaptchaModule,
  ],
  providers: [SafePipe, DecimalPipe]
})
export class ViewsModule { }
