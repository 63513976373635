import { IOperation } from "./interfaces";

interface DataNewMandadito {
    receiver,
    operation,
    isFavorite,
    checkFavorite,
    paypal,
    location,
    autoRemittance,
    order,
    coupon?,
    extendedSchedule,
}


export class Operation {

    constructor(
        public operation: IOperation
    ) {}

    static fromObject( dataNewMandadito: DataNewMandadito ): Operation {
        const {receiver, operation, order, isFavorite,
            checkFavorite, autoRemittance, location, paypal, coupon, extendedSchedule} = dataNewMandadito;

        return new Operation({
            firstName: receiver.firstName,
            secondName: receiver.secondName,
            firstLastname: receiver.firstLastname,
            secondLastname: receiver.secondLastname,
            name: receiver.name,
            email: receiver.email,
            phonePrefix: receiver.phonePrefix,
            phoneNumber: receiver.phoneNumber,
            receptorNames: receiver.receptorNames,
            documentNumber: receiver.documentNumber,
            accountNumber: receiver.accountNumber,
            commission: operation.commission,
            amount: operation.amount,
            payCode: order.id,
            payAmount: order.purchase_units?.[0]?.payments.captures[0].amount.value,
            message: receiver.message,
            ip: location.ip,
            city: location.city,
            region: location.region,
            country: location.countryCode,
            isFavorite: isFavorite,
            checkFavorite: checkFavorite,
            autoRemittance,
            paymentSource: paypal.paymentSource,
            discount: this.getDiscount( coupon, operation.discount ),
            ipProvider: location.provider,
            extendedSchedule: extendedSchedule,
            origin: operation.origin,
            promotion: this.getPromotion(operation.promotion),
            currency: operation.currency
          })
    }

    static getDiscount(coupon, operationDiscount) {
        let discount = null;
        if ( coupon )  discount = { id: coupon.id, type: 'COUPON' };
        else if ( operationDiscount ) {
            discount = { id: operationDiscount.id, type: operationDiscount.type };
            if (operationDiscount.rewardId) discount.rewardId = operationDiscount.rewardId;
        }

        return discount;
    }

    static getPromotion(operationPromotion) {
        let promotion = null;
        if ( operationPromotion ) {
            const { id, type, rewardId } = operationPromotion;
            promotion = { id, type, rewardId };
        }
        

        return promotion;
    }

}
