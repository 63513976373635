<div class="currency-conversion">
    <h5 class="title" *ngIf="!isRepeatingMandadito">Ellos reciben</h5>
    <div class="currency-switcher" *ngIf="!isRepeatingMandadito">
        <label class="currency-item" [ngClass]="{'active': selectedCurrency === Currency.PEN.code}">
            <input type="radio" [(ngModel)]="selectedCurrency" [value]="Currency.PEN.code"
                (ngModelChange)="emitSelectedCurrency($event)">
            <div class="flag peru"></div>
            Soles
        </label>
        <label class="currency-item" [ngClass]="{'active': selectedCurrency === Currency.USD.code}">
            <input type="radio" [(ngModel)]="selectedCurrency" [value]="Currency.USD.code"
                (ngModelChange)="emitSelectedCurrency($event)">
            <div class="flag usa"></div>
            Dolares
        </label>
    </div> 

    <div class="receiver" *ngIf="isRepeatingMandadito && selectedCurrency === Currency.PEN.code">
        {{presetReceiver?.firstName | titlecase}} recibe
    </div>

    <div class="exchange" *ngIf="selectedCurrency === Currency.PEN.code">
        <span class="total">
            <ng-container>
                S/ {{isAmountExceeded ? 0.00 : amount * latestExchangeRate | number: '1.2-2'}}
            </ng-container>
            
        </span>
        <span class="rate">
            1 USD = {{latestExchangeRate}} PEN
        </span>
    </div>

    <div class="info" *ngIf="isRepeatingMandadito && this.selectedCurrency === Currency.PEN.code">
        <img src="/assets/images/info-blue.svg" alt="">
        <span>Estás enviando a una cuenta en soles</span>
    </div>
</div>
