import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { DataMandadito, Receiver } from 'src/app/core/interfaces';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Currency } from 'src/app/shared/models/model';

@Component({
  selector: 'app-currency-conversion',
  templateUrl: './currency-conversion.component.html',
  styleUrls: ['./currency-conversion.component.scss']
})
export class CurrencyConversionComponent implements OnInit, OnChanges {

  private localStorageService = inject(LocalStorageService)

  @Input()
  amount: any

  @Input()
  isAmountExceeded: boolean

  @Input()
  isRepeatingMandadito: boolean;

  @Input()
  presetReceiver: Receiver;

  @Input()
  data: DataMandadito

  @Output()
  onCurrencyChange = new EventEmitter<string>()

  latestExchangeRate: number;

  selectedCurrency: string;

  Currency = Currency


  constructor() { }

  ngOnInit(): void {
    this.getExchangeRate()
    this.setDefaultCurrency()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['presetReceiver']) {
      this.onPresetReceiverChange(changes['presetReceiver'].currentValue)
    }
  }

  getExchangeRate() {
    let customer = this.localStorageService.getStoredCustomer()
    this.latestExchangeRate = customer.exchangeRate || 0;
  }

  emitSelectedCurrency(value) {
    this.onCurrencyChange.emit(value)
  }

  /**
   * set default value when the component is initialized which is PEN
   */
  setDefaultCurrency() {
    if(this.isRepeatingMandadito) return;
    this.selectedCurrency = Currency.PEN.code;
    this.emitSelectedCurrency(this.selectedCurrency)
  }

  /**
   * This method executes every time the presetReceiver changes when is a repeating Mandadito
   * it checks wether the presetReceiver changes when the user is repeating a Mandadito and sets the selectedCurrency
   * to the accountCurrency of the presetReceiver
   * @param currentPresetReceiver
   */
  onPresetReceiverChange(currentPresetReceiver: Receiver) {
    if (!this.isRepeatingMandadito) return;
    
    if (this.presetReceiver) this.selectedCurrency = this.presetReceiver.accountCurrency
    this.emitSelectedCurrency(this.selectedCurrency)
  }
}
