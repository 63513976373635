import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-stepper',
  templateUrl: './header-stepper.component.html',
  styleUrls: ['./header-stepper.component.scss']
})
export class HeaderStepperComponent implements OnInit {


  @Input()
  stepSelected: number;

  @Input()
  steps: number;

  stepsList: Array<number> = [];
  width: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.setSteps();
  }

  setSteps() {
    this.stepsList = [];
    for(let i = 0; i < this.steps; i++) {
      this.stepsList.push(i+1);
    }
    this.defineWidth();
  }

  defineWidth() {
    this.width = 100/this.steps;
  }
}
