import { Component, Input, OnInit } from '@angular/core';
import { ICarouselItem } from '../../models/model';

@Component({
  selector: 'app-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.scss']
})
export class CarouselSlideComponent implements OnInit {

 @Input() height = 289;
 @Input() autoSlide = true;
 @Input() items: ICarouselItem[] = [];
 @Input() indicators = true;
 @Input() slideInterval = 4000;
 @Input() activeArrows = false;

 public finalHeight: string | number = 0;
 public currentPosition = 0;
 interval: any;

 ngOnInit() {
   this.items.forEach( ( i, index ) => {
     i.id = index;
     i.marginLeft = 0;
   });
   this.autoSlideSlides();
 }

 autoSlideSlides(): void {
   if (this.autoSlide) {
     this.interval = setInterval(() => {
       this.setNext();
     }, this.slideInterval);
   }
 }

 stopSlides(): void {
   if (this.interval) {
     clearInterval(this.interval);
   }
 }

 setCurrentPosition(position: number) {
   this.stopSlides();
   this.currentPosition = position;
   this.items.find(i => i.id === 0).marginLeft = -100 * position;
   this.autoSlideSlides();
 }

 setNext() {
   this.stopSlides();
   let finalPercentage = 0;
   let nextPosition = this.currentPosition + 1;
   if (nextPosition <= this.items.length - 1) {
     finalPercentage = -100 * nextPosition;
   } else {
     nextPosition = 0;
   }
   this.items.find(i => i.id === 0).marginLeft = finalPercentage;
   this.currentPosition = nextPosition;
   this.autoSlideSlides();
 }

 setBack() {
  console.log('Click here')
   this.stopSlides();
   let finalPercentage = 0;
   let backPosition = this.currentPosition  - 1;
   if (backPosition >= 0) {
     finalPercentage = -100 * backPosition;
   } else {
     backPosition = this.items.length - 1;
     finalPercentage = -100 * backPosition;
   }
   this.items.find(i => i.id === 0).marginLeft = finalPercentage;
   this.currentPosition = backPosition;
   this.autoSlideSlides();
 }

}
