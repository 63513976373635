import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { OPERATIONS, GtmEvent } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MainService } from 'src/app/core/services/main.service';
import { StatusRegister } from 'src/app/core/constants';
@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss']
})
export class MenuPanelComponent implements OnInit {

  @Input() isMenuOpened = false
  @Output() toggleFullscreenMenu = new EventEmitter<void>()
  @Input() showOptions : boolean = true;

  private dataService = inject(DataService)
  private router = inject(Router)
  private gtmService = inject(GtmService)
  private authService = inject(AuthService)
  private localStorageService = inject(LocalStorageService)
  private mainService = inject(MainService)

  gtmActionData = {
    'profile': 'clic_misdatos',
    'new': 'clic_Enviar_nuevo_mandadito',
    'historical': 'clic_Mis_Mandaditos',
    'logout': 'clic_Cerrar_sesion',
  };

  showModal = false
  letterMenu = ''
  userName = ''
  PATHS = PATHS

  constructor() { }

  ngOnInit(): void {
    this.GetCustomer();
    this.validateStatusUserToken();
  }

  validateStatusUserToken(): void {
    const token = this.localStorageService.getStorage('token');
    this.showOptions = token.statusRegister.toLowerCase() == StatusRegister.PARTIAL ? false : true;
  }

  goTo(path: string, actionType: string): void {
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.ADD_TIME_LIMIT_SESSION, data: { status: 'enabled', time: 0 } });

    if (this.router.url === path) {
      this.closeMenuPanel()
      return
    }

    this.router.navigate([path]);
    this.sendAnalyticData(actionType);
  }

  openWhatsapp() {
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.ADD_TIME_LIMIT_SESSION, data: { status: 'enabled', time: 0 } });
    const encodedMessage = encodeURIComponent("Hola Mandadito, necesito ayuda con [Escribe tu consulta]")
    const link = `https://wa.me/51945657689?text=${encodedMessage}`
    window.open(link, '_blank').focus()
    const gtmEvent: GtmEvent = {
      accion: 'Menu_contactanos​',
      event: 'virtualEvent',
      pantalla: 'Menu'
    }
    this.gtmService.sendEvent(gtmEvent)
  }

  private sendAnalyticData(actionType: string): void {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      pantalla: 'Cross',
      accion: this.gtmActionData[actionType],
    };
    this.gtmService.sendEvent(gtmEvent);
  }

  modalCloseSession(): void {
    this.showModal = true;
    this.sendAnalyticData('logout');
  }

  closeSession() {
    this.authService.logout();
    this.router.navigate([PATHS.LANDING]);
  }

  closeMenuPanel() {
    this.isMenuOpened = false;
    this.toggleFullscreenMenu.emit()
  }


  GetCustomer() {
    const customer = this.localStorageService.getStoredCustomer()
    if (customer) {
      this.dataService.updateDataWithOperation({ operation: OPERATIONS.USAR_DATA, data: customer });
      this.getLetterByStorage(customer);
    } else {
      this.mainService.GetCustomer().subscribe({
        next: (data) => {
          this.dataService.updateDataWithOperation({ operation: OPERATIONS.USAR_DATA, data: data });
          this.getLetterByStorage(data);
          this.localStorageService.storeCustomer(data)
        },
        error: _ => { },
      });
    }
  }

  getLetterByStorage(data): void {
    this.letterMenu = data?.firstName?.substr(0, 1).toUpperCase();
    this.userName = data ? `${data.firstName} ${data.firstLastname}` : 'Usuario';
  }
}
