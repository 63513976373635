import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { concatMap, from, interval, map, take, tap } from 'rxjs';
import { OPERATIONS, OperationDetail, UpdateDataService } from 'src/app/core/interfaces';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-input-amount',
  templateUrl: './input-amount.component.html',
  styleUrls: ['./input-amount.component.scss']
})
export class InputAmountComponent implements OnInit, AfterViewInit {

  @Output() inputAmountEvent = new EventEmitter<any>();
  @Output() inputDecimalEvent = new EventEmitter<any>();
  @Input() error = false;
  @Input() presetAmount:string

  @ViewChild('decimalInput', { static: false }) decimalInput: ElementRef;
  @ViewChild('amountInput', { static: false }) amountInput: ElementRef;

  formatOne = false;
  formatTwo = false;
  autoCompleteValue = null;
  alignElement = false;
  widthInput = 46;
  alternative = 0;

  amountDecimal = null;

  private dataService = inject(DataService);

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
   this.setPresetAmount()
   this.getData();
  }

  getData(): void {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data?.operation === OPERATIONS.RESEND_MANDADITO) {
          this.resetInput();
          this.presetAmount = data?.data?.toString();
          this.setPresetAmount();
        }
        if (data?.operation === OPERATIONS.RESET_MANDADITO) {
          this.resetInput();
        }
      }
    })
  }

  validateKeyupIntengerAmount(event: KeyboardEvent) {

    setTimeout(() => {

    let value = this.amountInput.nativeElement.value;


    if (!/^[\,\.0-9]*$/.test(value)) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.amountInput.nativeElement.value = value.substring(0, value.length - 1);
      value = value.substring(0, value.length - 1);
    }

    let isZeroValue = false;
    value = value.replace(',', '.');
    if (value.length === 0 || (value.length === 1 && (value.includes('.') || value.includes(','))) || isNaN(value)) isZeroValue = true;

    this.inputAmountEvent.emit(parseInt(isZeroValue ? '0' : this.amountInput.nativeElement.value.replace(',', '.')));

    if (this.amountInput.nativeElement.value.includes('.') || this.amountInput.nativeElement.value.includes(',')) {

      this.alignElement = true;
      this.formatTwo = true;

      setTimeout(() => {
        this.decimalInput.nativeElement.focus();
      }, 1);

    }

    const inputValue = this.amountInput.nativeElement.value;
    this.setIntegerInputWidth(inputValue);
    }, 0);
  }

  private setIntegerInputWidth(value): void {
    let content = value;
    let totalWidth = 0;
    for (let i = 0; i < content.length; i++) {
      const widthFromBumber: number = content.charAt(i) === '1' ? 18 : 26;
      totalWidth = totalWidth + (content.charAt(i) === '.' || content.charAt(i) === ',' ? 14 : widthFromBumber);
    }
    if (totalWidth === 0) {
      this.widthInput = 46
    } else {
      this.widthInput = totalWidth;
    }

    if (content && content.length > 0 && ( content.charAt(content.length - 1) === '.' || content.charAt(content.length - 1) === ',' )) {
      this.alignElement = true;
      setTimeout(() => {
        this.decimalInput.nativeElement.focus();
      }, 0);
    }
  }

  validateKeydownDecimalAmount(event: KeyboardEvent) {
    let value = event.target['value'];

    let permittedCodes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'ArrowLeft', 'ArrowRight'];

    if (!permittedCodes.includes(event.key)) event.preventDefault();

    if (value.length === 0) {
      if ( event.key ===  'Backspace' || event.code === 'Backspace') {
        this.alignElement = false;
        this.amountInput.nativeElement.focus();
        this.formatTwo = false;
        this.formatOne = false;
      }
    }

    if (value && value.length > 1 && !(event.key ===  'Backspace' || event.code === 'Backspace')) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.decimalInput.nativeElement.value = this.amountDecimal;
    }
  }

  validateKeyupDecimalAmount(event: KeyboardEvent) {
    let value = event.target['value'];

    if (value && (this.decimalInput.nativeElement.value.includes('.') || this.decimalInput.nativeElement.value.includes(',') )) {
      this.decimalInput.nativeElement.value = this.decimalInput.nativeElement.value.replace(',', '').replace('.', '');
    }

    if (isNaN(value)) {
      event.stopPropagation();
    }
    this.formatOne = false;
    this.formatTwo = false;

    if (value.length === 0) {
      this.formatTwo = true;
    }

    if (value.length === 1) {
      this.formatOne = true;
    }

    if (value.length > 2) {
      value = this.amountDecimal;
      this.decimalInput.nativeElement.value = this.amountDecimal;
    }


    if (value.length > 0) {
      const newValue = '0.' + value;
      this.inputDecimalEvent.emit(parseFloat(newValue));
      this.amountDecimal = value;
    }

    if (value.length === 0) {
      this.inputDecimalEvent.emit(0);
    }

  }

  setPresetAmount() {
    if (this.presetAmount) {

      const totalCharacters: string[] = new Array<string>()
      let setOnIntegerInput = true

      from(this.presetAmount).pipe(concatMap(digit => interval(70).pipe(map(interval => digit), take(1))), tap(digit => {
        totalCharacters.push(digit)
        if (setOnIntegerInput) {
          let mockEvent = new KeyboardEvent('keyup')
          this.amountInput.nativeElement.value = totalCharacters.reduce((prev, curr) => prev + curr, '')
          this.validateKeyupIntengerAmount(mockEvent)
          if (totalCharacters.includes('.')) setOnIntegerInput = false
        } else {
          let mockEventUp = new KeyboardEvent('keyup', { key: digit })
          let decimalValues = totalCharacters.join('').split('.')[1]
          this.decimalInput.nativeElement.value = decimalValues
          let targetMock = { value: decimalValues }
          Object.defineProperty(mockEventUp, 'target', { value: targetMock })
          this.validateKeyupDecimalAmount(mockEventUp)
        }
      })).subscribe()
    }
  }

  resetInput(): void {
    this.amountInput.nativeElement.value = null;
    if (this.amountDecimal) this.amountDecimal.nativeElement.value = null;
  }

}
