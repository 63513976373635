import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { PATHS } from 'src/app/core/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

interface DateDropdownItem {
  label: string,
  value: string
}
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnChanges {

  @Input()
  operations;

  @Input()
  hadSendMandadito:boolean;

  @Output()
  showDetail = new EventEmitter<any>();
  operationList = [];
  loading = true;
  email: string;

  @Output()
  onDateSelected = new EventEmitter<string>();

  private router = inject(Router);
  localStorageService = inject(LocalStorageService);

  historyPeriodForm = new FormGroup({
    period: new FormControl('')
  })

  mandaditoStatuses = {
    'En proceso': 'pending',
    'Enviado': 'success',
    'Rechazado': 'rejected'
  }

  dateList: DateDropdownItem[];

  ngOnInit(): void {
    this.getEmailByToken();
    this.dateList = this.generateDropdownDateList()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['operations'].firstChange) {
      this.operationList = this.formatOperations(changes['operations'].currentValue)
    }
  }

  getEmailByToken(): void {
    try {
      const { idToken } = this.localStorageService.getStorage('token');
      var decoded: any = jwt_decode(idToken);
      this.email = decoded.name;
      this.operationList = this.formatOperations(this.operations);
    } catch (error) {
      console.log(error);
    }
  }

  formatOperations(operations) {
    const operationList = [];

    operations.map(operation => {
      const { receiverFirstName: firstName, receiverLastname: fatherName } = operation;
      const name = operation.receiverEmail === this.email ? `(Yo) ${firstName} ${fatherName}`
        : `${firstName} ${fatherName}`;
      const data = {
        id: operation.id,
        receiver: name.length > 20 ? name.substr(0,19)+'...': name,
        date: this.formatDate(operation.createdAt),
        amount: operation.amount,
        avatar: firstName.substr(0, 1).toUpperCase(),
        autoRemittance: operation.autoRemittance,
        status: operation.status
      }
      operationList.push(data);
    })
    this.loading = false;
    return operationList;
  }

  formatDate(date): string {
    try {
      const split = date.split('T')[0].split('-');
      return `${split[2]}/${split[1]}/${split[0]}`;
    } catch (error) {
      return '';
    }
  }

  goToNewMandadito(): void {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  goNewMandadito(): void {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  generateDropdownDateList() {
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril",
      "Mayo", "Junio", "Julio", "Agosto",
      "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ]

    const dateList = []
    const date = new Date()
    for (let i = 0; i <= 5; i++) {
      let month = date.getMonth()
      let year = date.getFullYear()

      let label = `${monthNames[month]} ${year}`
      let value = `${year}${(month + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`

      dateList.push({ label, value })

      date.setMonth(month - 1)
    }

    if(this.operationList.length>0){
      const date = this.operationList[0].date.split('/')
      const period = `${date[2]}${date[1]}`
      this.historyPeriodForm.get('period').setValue(period)
    }

   
    return dateList
  }

  onDateSelectionChange(event) {
    this.onDateSelected.emit(event.detail)
  }
}
