<div class="header" [ngClass]="{'steps': isActiveBoth && !steps.validated, 'last': isActiveBoth && steps.data}">
    <img src="/assets/images/logo-mandadito.svg" alt="">
    <div>
        <app-steps-number [current]="steps.data ? 2 : 1" [total]="2" *ngIf="isActiveBoth && !steps.validated"></app-steps-number>
    </div>
</div>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second" [ngClass]="{ 'justify-start' : !steps.validated}">
        <div class="mx-auto step-bar d-md-block d-none" *ngIf="isActiveBoth && !steps.validated">
            <app-steps [elements]="stepElements"></app-steps>
        </div>
        <app-documents [isActiveBoth]="isActiveBoth" (documentsEvent)="getDocuments($event)" [ngClass]="{ 'd-none': !steps.documents }" [documentType]="documentType"></app-documents>
        <app-data [isActiveBoth]="isActiveBoth" (dataEvent)="getData($event)" (comeBackEvent)="comeBack($event)" [ngClass]="{ 'd-none': !steps.data }" [documentType]="documentType" ></app-data>
        <app-completed-update [email]="email" *ngIf="steps.validated"></app-completed-update>
    </div>
</div>
