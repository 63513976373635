import { Injectable, inject } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GtmEvent } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  private tagManagerService = inject(GoogleTagManagerService);

  sendEvent(event: GtmEvent) {
    this.tagManagerService.pushTag(event).then();
    this.tagManagerService.getDataLayer();
  }
}
