import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, finalize, forkJoin, switchMap, tap, throwError, timer } from 'rxjs';
import { ModalTypes, PATHS } from 'src/app/core/constants';
import { IRewards, Modal } from 'src/app/core/interfaces';
import { MainService } from 'src/app/core/services/main.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { ICustomerData } from 'src/app/shared/models/model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit, OnDestroy {

  private mainService = inject(MainService)
  private router = inject(Router)
  private loaderService = inject(LoaderService)

  isMenuOpened = false;
  customer: ICustomerData;
  rewards: IRewards;
  enviosDisponibles: number = 1;
  showClipboardInfo = false;
  modal: Modal

  private clipboardSubject = new Subject<void>()

  PATHS = PATHS

  constructor() { }

  ngOnInit(): void {
    this.fetchData()
    this.setupClipboardSubject()
  }

  setupClipboardSubject() {
    this.clipboardSubject.pipe(
      tap(() => this.openClipboardInfo()),
      switchMap(() => timer(4000).pipe(
        tap(() => this.closeClipboardInfo())
      ))
    ).subscribe()
  }

  toggleFullscreenMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  get hideContent() {
    return this.isMenuOpened && window.innerWidth < 768
  }

  copyToClipboard() {
    const link = this.customerReferralUrl
    navigator.clipboard.writeText(link).then(() => {
      this.clipboardSubject.next()
    })
  }

  closeClipboardInfo() {
    this.showClipboardInfo = false;
  }

  openClipboardInfo() {
    this.showClipboardInfo = true;
  }

  get customerReferralUrl(): string {
    const baseUrl = environment.production ? 'www.mandaditobcp.com.pe' : 'mandadito-dev.innovacxion.com'
    const fullUrl = `${baseUrl}/?referralcode=${this.customer?.referralCode}`
    return this.customer?.referralCode ? fullUrl : ''
  }

  sendToWhatsapp(action: 'contact' | 'referralCode') {
    const messages = {
      contact: 'Hola, tengo una consulta sobre la campaña de referidos [Escribe tu consulta]',
      referralCode: `*¡Obtén 2 envíos GRATIS a Perú!*\n\n*Usa mi enlace de referido para registrarte en Mandadito.*\nEnvía dinero de Florida a Perú sin salir de casa.\n¡Aprovéchalo ahora!\n\nIngresa aquí: `
    }

    const baseURL = action === 'referralCode' ? "https://wa.me/?text=" : 'https://wa.me/51945657689?text='

    let whatsappUrl = ''

    if (action === 'contact') {
      whatsappUrl = baseURL.concat(encodeURIComponent(messages['contact']))
    }

    if (action === 'referralCode') {
      whatsappUrl = baseURL.concat(encodeURIComponent(messages['referralCode'].concat(this.customerReferralUrl)))
    }

    window.open(whatsappUrl, '_blank')

  }

  goToNewMandadito() {
    this.router.navigate([PATHS.NEW_MANDADITO])
  }

  ngOnDestroy(): void {
    this.clipboardSubject.unsubscribe()
  }

  fetchData() {
    this.loaderService.requestStarted()
    const obs = {
      customer$: this.mainService.GetCustomer(),
      rewards$: this.mainService.getRewards()
    }
    forkJoin(obs).pipe(finalize(() => this.loaderService.requestEnded())).subscribe(
      {
        next: (res) => {
          const { customer$: customerRes, rewards$: rewardsRes } = res
          this.customer = customerRes
          this.rewards = rewardsRes
        },
        error: (err) => {
          this.modal = {
            name: ModalTypes.RELOAD
          }
        }
      }
    )
  }
}
