import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS , ModalTypes} from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmEvent, Modal } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  private authService = inject(AuthService);
  private router = inject(Router);
  private gtmService = inject(GtmService);

  stepElements = [
    { value: '1', status: 'current', text: 'Correo' },
    { value: '2', status: '', text: 'Autentícate' },
    { value: '3', status: '', text: 'Contraseña' }
  ];
  modal: Modal = null;
  modalTypes = ModalTypes
  questions = null;
  data: {
    email: string;
    question: {
      text: string;
      value: string;
    };
    answer: {
      text: string;
      value: string;
    };
  } = null;

  steps = {
    EMAIL: true,
    AUTHENTICATE: false,
    PASSWORD: false,
    HELP: false,
  };

  sendData(event: DataEvent): void {
    const { step, email, response, question, answer } = event;
    if (step === 1) {
      this.data = {
        email: event.email,
        question: null,
        answer: null
      };
      this.sendEmail(email);
    }

    if (step === 2) {
      console.log("response")
      console.log(response)
      if (!response) {
        this.steps = {
          ...this.steps,
          AUTHENTICATE: false,
          HELP: true
        }
        return;
      }
      this.data.question = question;
      this.data.answer = answer;
      this.stepElements = this.completeStep(2);
      this.steps = {
        ...this.steps,
        AUTHENTICATE: false,
        PASSWORD: true
      }
    }

    if (event.step === 3) {
      this.router.navigate([PATHS.PASSWOR_UPDATED]);
    }
  }

  private completeStep(step: number) {
    return this.stepElements.map((element, index) => {
      element.status = '';
      if (index < step) element.status = 'completed';
      if (step === index) element.status = 'current';
      return element;
    });
  }

  sendEmail(email: string) {
    this.authService.recoverPassword(email).subscribe({
      next: (resp: any) => {
        if (resp?.questions) {
          this.steps = {
            ...this.steps,
            EMAIL: false,
            AUTHENTICATE: true
          }
          this.questions = resp.questions;
          this.stepElements = this.completeStep(1);
        }
        else{
          if (resp?.hasIncompleteRegister){
            this.modal = { name: this.modalTypes.PASSWORD };
            const event: GtmEvent = {
              event: 'Modal_contraseña50​',
              accion: '-',
              pantalla: 'Recuperar contraseña',
            };
            this.gtmService.sendEvent(event);
            return;
          }
          else{
            this.steps = {
              ...this.steps,
              EMAIL: false,
              HELP: true
            }
          }
        }
      },
      error: (err) => {
        console.log('====>ERROR', err);
        this.steps = {
          ...this.steps,
          EMAIL: false,
          HELP: true
        }
      }
    });
  }
  
  closeModal() {
    this.modal = { name: '' };
  }
}

interface DataEvent {
  step: number;
  email?: string;
  response?: boolean;
  question?: {
    text: string;
    value: string;
  };
  answer?: {
    text: string;
    value: string;
  };
}