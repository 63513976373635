import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ErrorImageType } from 'src/app/core/constants';
import { DocumentTypes } from '../../models/model';

const MAX_FILE_SIZE_MEGABYTES = 25;

@Component({
  selector: 'app-upload-dni',
  templateUrl: './upload-dni.component.html',
  styleUrls: ['./upload-dni.component.scss']
})
export class UploadDniComponent implements OnChanges{
  
  @Input() selectedDocument: string
  @Input() showDocumentRequirements = false

  @ViewChild('front', { static: true }) front: ElementRef;
  @ViewChild('back', { static: true }) back: ElementRef;

  @Output() documentFront = new EventEmitter<any>();
  @Output() documentBack = new EventEmitter<any>();

  previewImage: string = null;
  errorImageTypeFront: ErrorImageType;
  errorImageTypeBack: ErrorImageType;
  ErrorImageType = ErrorImageType;
  maxFileSize = MAX_FILE_SIZE_MEGABYTES;
  documentTypes = DocumentTypes

  images = {
    front: {
      number: 1,
      title: 'Foto de tu DNI de frente*',
      permitFormats: {
        title: 'Formato JPG o PNG. Peso máximo',
        formats: ['image/png', 'image/jpeg', 'image/jpg'],
      },
      maxSize: MAX_FILE_SIZE_MEGABYTES,
      preview: true,
    },
    back: {
      number: 2,
      title: 'Foto de tu DNI del reverso*',
      permitFormats: {
        title: 'Formato JPG o PNG. Peso máximo',
        formats: ['image/png', 'image/jpeg', 'image/jpg'],
      },
      maxSize: MAX_FILE_SIZE_MEGABYTES,
      preview: false,
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDocument']) {
      const selectedDocument = changes['selectedDocument'].currentValue
      if (selectedDocument === DocumentTypes.DriverLicense) {
        this.images.front.title = "Foto de tu Driver's License de frente*"
        this.images.back.title = "Foto de tu Driver's License de reverso*"
      }
      if (selectedDocument === DocumentTypes.DNI) {
        this.images.front.title = "Foto de tu DNI de frente*"
        this.images.back.title = "Foto de tu DNI de reverso*"
      }
    }
  }

  setImageDocument(type: string, event): void {
    type === 'front' ? this.documentFront.emit(event) : this.documentBack.emit(event);
  }

}
