import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { IBreadcrumbItem } from 'src/app/shared/models/model';
import { GeneralTermsBreadcrumb, TermsAndConditionsBreadcrumb } from '../breadcrumbData';

@Component({
  selector: 'app-general-terms',
  templateUrl: './general-terms.component.html',
  styleUrls: ['./general-terms.component.scss']
})
export class GeneralTermsComponent implements OnInit {
  items = [
    { label: 'Less than $100.00',  value: '$3.99'  },
    { label: '$100.00 to $199.99', value: '$5.50' },
    { label: '$200.00 to $500.99', value: '$7.50' },
    { label: '$501.00 to $970.00', value: '$9.00' }
  ];

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }


  setBreadcrumb() {
    const breadcrumbData: IBreadcrumbItem[] = [
      TermsAndConditionsBreadcrumb,
      GeneralTermsBreadcrumb
    ]

    this.breadcrumbService.setBreadcrumb(breadcrumbData)
  }

}
