import { Component, OnInit, inject } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActiveCampaingsBreadcrumb, ReferralsCampaignBreadcrumb, TermsAndConditionsBreadcrumb } from '../../breadcrumbData';

@Component({
  selector: 'app-referrals-campaign',
  templateUrl: './referrals-campaign.component.html',
  styleUrls: ['./referrals-campaign.component.scss']
})
export class ReferralsCampaignComponent implements OnInit {

  private breadcrumbService = inject(BreadcrumbService)

  constructor() { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb(){
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      ActiveCampaingsBreadcrumb,
      ReferralsCampaignBreadcrumb
    ])
  }

}
