import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ASSETS, PATHS, StatusRegister, TEXT } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MainService } from 'src/app/core/services/main.service';
import { LastPageService } from 'src/app/shared/services/last-page.service';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedRegisterComponent implements OnInit, OnDestroy {

  data: { email: string, password: string };
  title: string = '';
  btnDescription: string = '';
  subscription: Subscription;
  showModalAccessTime = false;
  hasCompleteRegister: boolean = false;
  spotImgRegister: string = ASSETS.SPOT_REGISTER;
  imgIconInfo: string = ASSETS.IMG_ICON_INFO;
  imgLogo: string = ASSETS.IMG_LOGO_CHIP;

  private router = inject(Router);
  private gtmService = inject(GtmService);
  private authService = inject(AuthService);
  private mainService = inject(MainService);
  private lastPageService = inject(LastPageService);
  private localStorageService = inject(LocalStorageService);

  ngOnInit(): void {
    this.getSessionData();
    this.getStatusRegister();
    this.getTitle();
    this.getBtnDescription();
    this.lastPageService.saveLastPage(PATHS.SUCCESS_REGISTER);
  }

  getSessionData(): void {
    const registerData = JSON.parse(sessionStorage.getItem('registerData') ?? '{}');
    if (!!registerData?.firstValue === false) {
      this.router.navigate([PATHS.LOGIN]);
    }

    this.data = {
      email: registerData.firstValue,
      password: registerData.secondValue,
    };

    setTimeout(() => {
      sessionStorage.removeItem('registerData');
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  goToHome(): void {
    this.authService.logout();
    this.router.navigate([PATHS.LANDING]);
  }

  goToNewMandadito(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_Registro3_Mandar',
      pantalla: 'registroExitoso'
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  isWithinAccessTime() {
    this.mainService.isWithinAccessTime().subscribe({
      next: response => {
        this.responseAccessTime(response);
      }
    });
  }

  responseAccessTime(response: { isWithinAccessTime: boolean }): void {
    this.showModalAccessTime = true //!response?.isWithinAccessTime;
  }

  getTitle() {
    this.title = this.hasCompleteRegister ? TEXT.COMPLETE_REGISTER_SUCCESS : TEXT.REGISTER_SUCCESS;
  }

  getStatusRegister() {
    const { statusRegister } = this.localStorageService.getStorage('token');
    this.hasCompleteRegister = statusRegister.toLowerCase() === StatusRegister.COMPLETE;
  }

  getBtnDescription() {
    this.btnDescription = this.hasCompleteRegister ? TEXT.COMPLETE_REGISTER_BTN : TEXT.REGISTER_BTN;
  }
}
