import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-layer-popup-landing',
  templateUrl: './layer-popup-landing.component.html',
  styleUrls: ['./layer-popup-landing.component.scss']
})
export class LayerPopupLandingComponent {

  @Input() isOpen: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  hideModal() {
    this.closeModal.emit(false);
  }
}
