import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './views/landing/landing.component';
import { FrequencyQuestionsComponent } from './views/frequency-questions/frequency-questions.component';
import { RegisterComponent } from './views/auth/register/register.component';
import { LoginComponent } from './views/auth/login/login.component';
import { NewMandaditoComponent } from './views/new-mandadito/new-mandadito.component';
import { isAuthenticatedGuard } from './shared/guards/is-authenticated.guard';
import { OtherStateComponent } from './views/other-state/other-state.component';
import { RequestSuccessComponent } from './views/request-success/request-success.component';
import { HistoricalComponent } from './views/historical/historical.component';
import { TermsAndConditionsLayoutComponent } from './views/terms-and-conditions-layout/terms-and-conditions-layout.component';
import { ActiveCampaignsComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/active-campaigns.component';
import { GeneralTermsComponent } from './views/terms-and-conditions-layout/pages/general-terms/general-terms.component';
import { PastCampaignsComponent } from './views/terms-and-conditions-layout/pages/past-campaigns/past-campaigns.component';
import { TermsAndConditionsComponent } from './views/terms-and-conditions-layout/pages/terms-and-conditions/terms-and-conditions.component';
import { ZeroComissionCampaignComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/zero-comission-campaign/zero-comission-campaign.component';
import { PatrioticHolidaysCampaignComponent } from './views/terms-and-conditions-layout/pages/past-campaigns/patriotic-holidays-campaign/patriotic-holidays-campaign.component';
import { AccessibilityStatementComponent } from './views/accessibility-statement/accessibility-statement.component';
import { MaintenanceWindowComponent } from './views/maintenance-window/maintenance-window.component';
import { MaintenanceGuard } from './shared/guards/maintenance.guard';
import { MyDataComponent } from './views/my-data/my-data.component';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { PasswordUpdatedComponent } from './views/auth/password-updated/password-updated.component';
import { LimitComponent } from './views/auth/limit/limit.component';
import { ExceededComponent } from './views/auth/exceeded/exceeded.component';
import { RegisterExpComponent } from './views/register-exp/register-exp.component';
import { ChristmasCampaignComponent } from './views/terms-and-conditions-layout/pages/christmas-campaign/christmas-campaign.component';
import { RedirectComponent } from './views/redirect/redirect.component';
import { ReloadComponent } from './shared/components/reload/reload.component';
import { UpdateDataComponent } from './views/auth/update-data/update-data.component';
import { IsRecoverGuard } from './shared/guards/is-recover.guard';
import { ReviewingDataComponent } from './views/auth/reviewing-data/reviewing-data.component';
import { IsReviewingGuard } from './shared/guards/is-reviewing.guard';
import { ReferralsCampaignComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/referrals-campaign/referrals-campaign.component';
import { DeploymentWindowComponent } from './views/deployment-window/deployment-window.component';
import { DeploymentWindowGuard } from './shared/guards/deployment-window.guard';
import { MothersDayCampaingComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/mothers-day-campaing/mothers-day-campaing.component';
import { PeruGourmetComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/peru-gourmet/peru-gourmet.component';
import { ReferralsComponent } from './views/referrals/referrals.component';
import { CompleteRegistrationComponent } from './views/auth/complete-registration/complete-registration.component';
import { CompletedRegisterComponent } from './views/auth/complete-registration/components/completed/completed.component';
import { returnPageGuard } from './shared/guards/return-page.guard';
import { CompleteRegistrationPhotoComponent } from './views/auth/complete-registration-photo/complete-registration-photo.component';
import { returnPageGuardPhoto } from './shared/guards/return-page-photo.guard';
import { ZeroComissionRaffleComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/zero-comission-raffle/zero-comission-raffle.component';
import { GiftCardRaffleComponent } from './views/terms-and-conditions-layout/pages/active-campaigns/gift-card-raffle/gift-card-raffle.component';

const routes: Routes = [
  {
    path: '', canActivateChild: [MaintenanceGuard], children: [
      { path: '', component: LandingComponent, pathMatch: 'full' },
      { path: 'preguntas-frecuentes', component: FrequencyQuestionsComponent, pathMatch: 'full' },
      { path: 'registro', component: RegisterComponent, pathMatch: 'full' },
      { path: 'completar-registro',
        canActivate: [isAuthenticatedGuard],
        component: CompleteRegistrationComponent,
        pathMatch: 'full' },
      { path: 'completar-registro-foto',
        canActivate: [isAuthenticatedGuard, returnPageGuardPhoto],
        component: CompleteRegistrationPhotoComponent,
        pathMatch: 'full' },
      { path: 'registro-exp', component: RegisterExpComponent, pathMatch: 'full' },
      { path: 'iniciar-sesion', component: LoginComponent, pathMatch: 'full' },
      { path: 'otro-estado', component: OtherStateComponent, pathMatch: 'full' },
      {
        path: 'nuevo-mandadito',
        canActivate: [isAuthenticatedGuard, DeploymentWindowGuard],
        component: NewMandaditoComponent,
        pathMatch: 'full'
      },
      {
        path: 'solicitud-enviada',
        canActivate: [isAuthenticatedGuard],
        component: RequestSuccessComponent,
        pathMatch: 'full'
      },
      {
        path: 'mis-mandaditos',
        canActivate: [isAuthenticatedGuard],
        component: HistoricalComponent,
        pathMatch: 'full'
      },
      {
        path: 'mis-datos',
        canActivate: [isAuthenticatedGuard],
        component: MyDataComponent,
        pathMatch: 'full'
      },
      {
        path: 'mandadito',
        canActivate: [isAuthenticatedGuard],
        component: ReloadComponent,
        pathMatch: 'full'
      },
      {
        path: 'actualizar-datos',
        canActivate: [IsRecoverGuard],
        component: UpdateDataComponent,
        pathMatch: 'full'
      },
      {
        path: 'revisando-datos',
        canActivate: [IsReviewingGuard],
        component: ReviewingDataComponent,
        pathMatch: 'full'
      },
      {
        path: 'terminos-y-condiciones',
        component: TermsAndConditionsLayoutComponent,
        children: [
          {
            path: '',
            component: TermsAndConditionsComponent
          },
          {
            path: 'terminos-generales',
            component: GeneralTermsComponent,
          },
          {
            path: 'campanias-activas',
            children: [
              {
                path: '',
                component: ActiveCampaignsComponent,
              },
              {
                path: 'comision-cero',
                component: ZeroComissionCampaignComponent
              },
              {
                path: 'campania-referidos',
                component: ReferralsCampaignComponent
              },
              {
                path: 'campania-dia-de-la-madre',
                component: MothersDayCampaingComponent
              },
              {
                path: 'sorteo-peru-gourmet',
                component: PeruGourmetComponent
              },
              {
                path: 'sorteo-cero-costo-envio',
                component: ZeroComissionRaffleComponent
              },
              {
                path: 'sorteo-gift-card',
                component: GiftCardRaffleComponent
              },
            ]
          },
          {
            path: 'campanias-anteriores',
            children: [
              {
                path: '',
                component: PastCampaignsComponent,
              },
              {
                path: 'fiestas-patrias',
                component: PatrioticHolidaysCampaignComponent
              },
              {
                path: 'campanias-navidenas',
                component: ChristmasCampaignComponent
              },
            ]
          }
        ]
      },
      {
        path: 'declaracion-accesibilidad',
        component: AccessibilityStatementComponent
      },
    ]
  },
  {
    path: 'mantenimiento',
    component: MaintenanceWindowComponent,
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'recuperar-clave',
    component: ForgotPasswordComponent
  },
  {
    path: 'clave-actualizada',
    component: PasswordUpdatedComponent
  },
  {
    path: 'limite',
    component: LimitComponent
  },
  {
    path: 'excedido',
    component: ExceededComponent
  },
  {
    path: 'privacypolicy',
    component: RedirectComponent
  },
  {
    path: 'registro-exitoso',
    component: CompletedRegisterComponent
  },
  {
    path: 'despliegue',
    component: DeploymentWindowComponent,
    canActivate: [DeploymentWindowGuard]
  },
  {
    path:'referidos',
    component: ReferralsComponent,
    canActivate: [isAuthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
