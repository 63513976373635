import { Component, Input } from '@angular/core';
import { ASSETS } from 'src/app/core/constants';

@Component({
  selector: 'app-header-exp',
  templateUrl: './header-exp.component.html',
  styleUrls: ['./header-exp.component.scss']
})
export class HeaderExpComponent {

  imgLogo: string = ASSETS.IMG_LOGO_CHIP;

  @Input()
  step = 1
  
  @Input()
  completed = false

  @Input()
  total = 4
}
