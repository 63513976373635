import { Component, Input } from '@angular/core';
import { Promotion } from 'src/app/core/interfaces';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {

  @Input() promotion: Promotion;

}
