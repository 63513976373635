import { Component } from '@angular/core';
import { IBreadcrumbItem } from 'src/app/shared/models/model';

@Component({
  selector: 'app-terms-and-conditions-layout',
  templateUrl: './terms-and-conditions-layout.component.html',
  styleUrls: ['./terms-and-conditions-layout.component.scss']
})
export class TermsAndConditionsLayoutComponent {

  breadcrumbData = new Array<IBreadcrumbItem>()

  setBreadcrumbData() {

  }

  handleClickEvent(event: any) {

  }
}
