import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthRecoverType, AuthStatus, PATHS } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

export const isAuthenticatedGuard: CanActivateFn = async () => {

      const authService = inject(AuthService);
      const localStorageService = inject(LocalStorageService);
      const router = inject(Router);

      if ( !authService.checkAuthStatus() ) {
        let token = localStorageService.getStorage('token');
        authService.status = token?.loginStatus;
        authService.recoverType = token?.recoverType;
      }

      if ( authService.checkAuthStatus() === AuthStatus.authenticated ) {
        return true;
      }

      if ( authService.checkAuthStatus() === AuthStatus.Recovering.toString() && authService.checkAuthRecoveryType() === AuthRecoverType.justDni.toString() ) {
        router.navigate([PATHS.UPDATE_DATA]);
        return false;
      }

      if ( !authService.checkAuthStatus() ) {
        router.navigate([PATHS.LOGIN]);
        return false;
      }

      const response = await authService.refreshToken();
      if ( !response ) {
        router.navigate([PATHS.LOGIN]);
        return false;
      } else return true;

  }
