<app-referral-banner></app-referral-banner>
<app-menu-panel (toggleFullscreenMenu)="toggleFullscreenMenu()" [isMenuOpened]="isMenuOpened" class="mobile"></app-menu-panel>
<div class="content">
    <div class="app-menu">
        <app-menu (toggleFullscreenMenu)="toggleFullscreenMenu()"></app-menu>
    </div>
    <div class="background"></div>
    <div class="panel">
        <div class="box">
            <bcp-button shape="text" tier="primary" (click)="goToNewMandadito()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r"></bcp-icon>
                Volver
            </bcp-button>
            <div class="d-flex justify-content-center flex-column text-center">
                <img class="img" src="/assets/images/spot-heroes-s-sh-scan-dni-woman.svg" alt="Mis Datos">
                <h3 class="title">Cambia tus datos personales</h3>
                <p class="subtitle mx-auto">Escríbenos por correo para revisar o cambiar tus datos personales</p>

                <div class="contact d-flex align-items-center justify-content-center">
                    <img src="/assets/images/icon-letter-orange.svg" alt="letter">
                    <span>contacto@mandaditobcp.com.pe</span>
                </div>
            </div>
            
        </div>
    </div>
</div>
