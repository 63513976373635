import { Component, Input, OnInit, Output, EventEmitter, inject } from '@angular/core';
import { message } from '../referral-banner/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss']
})
export class ReferralModalComponent implements OnInit {

  @Input() showModal = false;
  @Input() customerReferralCode: string;
  @Output() closeModalEvent = new EventEmitter<boolean>();

  private gtmService = inject(GtmService)

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closeModalEvent.emit(false);
  }


  shareReferralCode() {
    const encodeURIMessage = encodeURIComponent(message.concat(this.customerReferralCode));
    const pathLinkGenerated = `https://wa.me/?text=${encodeURIMessage}`;
    const link = document.createElement('a');
    link.href = pathLinkGenerated;
    link.target = '_blank';
    link.click();
    this.sendGTMEvent()
  }
  

  sendGTMEvent(){
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Ref_Compartir_popup',
      pantalla: 'Envio paso 1',
    }

    this.gtmService.sendEvent(gtmEvent)
  }

}
