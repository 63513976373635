<div class="login">
    <h2 class="main-title flexo">Ingresa tu correo</h2>
    
    <form class="form-main" [formGroup]="formForgot" (ngSubmit)="sendData()">
        <div class="inputElement">
            <bcp-input type="email" label="Correo electrónico *" required="true" bcpCtrlForm placeholder="Correo electrónico"
            [message]="getMessage('email')" [state]="getError('email')" formControlName="email"></bcp-input>
        </div>
        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit">Continuar</bcp-button>
        </div>
        <!-- <div class="error text-center mt-3" *ngIf="error.show" [innerHTML]="error.message"></div> -->
    </form>
</div>