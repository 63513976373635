<section *ngIf="historyElements && historyElements.length > 0">
    <app-history-header></app-history-header>
</section>

<section class="history-list" *ngIf="historyElements && historyElements.length > 0">
    <p class="mb-3 color-onsurface-700 font-size-12 font-size-md-14 d-none d-md-block">
        Aquí encontrarás tus Mandaditos enviados con éxito
    </p>
    <article class="mb-2 d-block d-md-none">
        <div class="tutorial-element" *ngIf="historyElements.length > 0 && showTutorial && currentStep === 1"><app-element [element]="historyElements[0]" (emitData)="validateOperation($event)" class="pointer-events-none"></app-element></div>
        <p class="font-size-12 color-onsurface-700">Aquí encontrarás tus Mandaditos enviados con éxito</p>
        <app-slide-touch [elements]="historyElements"  (emitData)="validateOperation($event)"></app-slide-touch>
    </article>
    <article class="d-none d-md-block position-relative">
        <div class="tutorial-element" *ngIf="historyElements.length > 0 && showTutorial && currentStep === 1"><app-element [element]="historyElements[0]" (emitData)="validateOperation($event)" class="pointer-events-none"></app-element></div>
        <ng-container *ngFor="let element of historyElements;">
            <div class="mb-2"><app-element [element]="element" (emitData)="validateOperation($event)"></app-element></div></ng-container>
    </article>
</section>

<section class="d-none d-md-block" *ngIf="historyElements && historyElements.length === 0">
    <article>
        <app-history-header></app-history-header>
        <app-empty-history></app-empty-history>
    </article>
</section>