<div class="password-updated">
    <app-img [src]="'/assets/images/group-21.svg'" [sizes]="[147.5 ,147.5]" [alt]="'te ayudaremos a actualizar tu contraseña'"></app-img>
    <h1 class="main-title max-380 mx-auto text-center">
        Te ayudaremos a actualizar tu contraseña
    </h1>
    <h2 class="sub-title">Envíanos un correo con los siguientes datos:</h2>

    <ul class="mx-auto text-center">
        <li>Foto de tu DNI de frente y de reverso</li>
        <li>Nombre completo y número de teléfono</li>
    </ul>

    <div class="mt-4 mx-auto w-100">
        <a href="mailto:contacto@mandaditobcp.com.pe" rel="noopener" target="_blank">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="sendGtmEvent()">contacto&#64;mandaditobcp.com.pe</bcp-button>
        </a>
    </div>
    <div class="d-flex mt-3 justify-content-center text-center">
        <bcp-button shape="text" tier="primary" (click)="goToHome()">Volver a Madaditobcp.com.pe</bcp-button>
    </div>

</div>
