<h3 class="main-title">
    Sorteo Perú Gourmet
</h3>

<p>
    Promoción válida para peruanos residiendo en Florida, USA. Vigente del 12 al 19 de abril del 2024. Esta Promoción es
    desarrollada por el Banco de Crédito del Perú (BCP)
</p>

<p>
    Participan las personas naturales que durante la vigencia de la Promoción: (i) se registren exitosamente en
    <a href="http://www.mandaditobcp.com.pe">http://www.mandaditobcp.com.pe</a> (ii) realicen su primer envío exitoso
    sin monto mínimo; y (iii) coloquen en la descripción del envío "Perú
    Gourmet”.
</p>

<p>
    En adelante, los “Clientes”.
</p>

<p>Premio: Una (01) entrada doble para el evento “Perú Gourmet 2024” a llevarse a cabo el 23 de abril de 2024. </p>

<p>El acreedor del Premio será el Cliente sorteado entre los que hayan cumplido con todos los requisitos. </p>
<p>
    El ganador será contactado a través del correo electrónico o número de teléfono celular que tenga registrado en el
    Banco para coordinar la entrega del Premio. Toda coordinación se realizará por estos medios. Es responsabilidad del
    Cliente asegurarse que los datos proporcionados en el registro sean correctos y estén actualizados.
</p>

<p>
    El Sorteo se realizará el 20 de abril del 2024. La entrega del Premio se realizará entre el 20 y 22 de abril del
    2024, posterior al contacto con el Cliente ganador.
</p>

<p>
    En caso el BCP no obtuviera respuesta del ganador tras 2 días calendario desde el primer contacto, o si por algún
    otro hecho imputable al ganador no pudiera realizarse la entrega del Premio, perderá su derecho a recibirlo sin
    opción a reclamo, y el BCP decidirá libremente el destino del Premio.
</p>

<p>
    El ganador autoriza al BCP a publicar sus datos en la página web <a href="http://www.viabcp.com">www.viabcp.com</a>
    y/o a través de otros canales
    oficiales del BCP y Mandadito como redes sociales, con la finalidad de hacer de conocimiento público los resultados
    del Sorteo. Los datos que se publicarán son los utilizados por el Participante para la Promoción.
</p>

<p>
    El BCP no se responsabiliza por la idoneidad de los productos o servicios que componen el Premio, ni por cualquier
    daño o perjuicio que pudiera derivarse del uso de estos.
</p>

<p>
    El BCP podrá, previa comunicación a los Clientes y sin responsabilidad alguna, modificar alguno de los términos
    establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o sea
    producto de alguna obligación legal.
</p>

<p>
    Para más información sobre la promoción y/o restricciones, puede llamar gratuitamente a nuestra Banca por teléfono
    al (01) 311-9898 o ingresar a <a href="http://www.viabcp.com.">www.viabcp.com.</a>
</p>
