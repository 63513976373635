import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/core/services/util.service';
import { GtmEvent, UpdateRegisterRequest, UserInterface } from 'src/app/core/interfaces';
import { mustNotBeEqualToValue } from '../../custom-validations';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LastPageService } from 'src/app/shared/services/last-page.service';
import { PATHS } from 'src/app/core/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class SecondStepComponent implements OnInit {

  @Output()
  setData = new EventEmitter<UpdateRegisterRequest>();

  questions: string[] = [
    '¿Cuál era tu apodo de la infancia?',
    '¿Cómo se llama tu mejor amigo de la infancia?',
    '¿En qué calle vivías en tercer grado?',
    '¿En qué mes y año nació tu hermano mayor? ',
    '¿A qué colegio fuiste en sexto grado?',
    '¿Cómo se llama tu primo mayor?',
    '¿En qué ciudad se conocieron tus padres?',
    '¿En dónde vive tu hermano más cercano?',
    '¿En qué mes y año nació tu hermano menor? ',
    '¿En qué ciudad tuviste tu primer trabajo?',

  ];

  private utilService = inject(UtilService);
  private gtmService = inject(GtmService);
  private lastPageService = inject(LastPageService);
  private localStorageService = inject(LocalStorageService);

  successMessage = '';
  errorMessage = '';
  stepNumber: number = 3;

  formRegister: FormGroup;
  submitted = false;

  ngOnInit(): void {
    this.initializeForm();
    this.getNextStep();
  }

  initializeForm(): void {
    this.formRegister = new FormGroup({
      firstQuestion: new FormControl(null, [ Validators.required ]),
      firstAnswer: new FormControl(null, [ Validators.required ]),
      secondQuestion: new FormControl(null, [ Validators.required ]),
      secondAnswer: new FormControl(null, [ Validators.required ]),
    }, [mustNotBeEqualToValue('firstQuestion', 'secondQuestion')]);
  }

  getMessage(input: string) {
    const data = { submitted: this.submitted, form: this.formRegister, input };
    return this.utilService.getMessage(data);
  }

  getError(input: string) {
    const data = { submitted: this.submitted, form: this.formRegister, input };
    return this.utilService.getError(data);
  }

  sendData(): void {
    this.submitted = true;
    if (this.formRegister.invalid) {
      return;
    }

    this.sendAnalytic();
    this.setData.emit(this.formRegister.value);
  }

  getNextStep() {
    const fromLogin = this.lastPageService.getLastPage() === PATHS.LOGIN;
    this.stepNumber = fromLogin ? 2 : 3;
  }

  sendAnalytic(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_Registro2_continuar',
      pantalla: 'Registro2',
      email: this.localStorageService.getStorage('registerData')?.firstValue || ''
    };
    this.gtmService.sendEvent(event);
  }
}
