<div class="full-screen">
    <div class="background">
        <div class="mant-card">
            <img src="assets/images/logo-1-logo-light.svg" alt="" class="bcp-logo">
            <div class="mant-card-content">
                <div class="text-display">
                    <p class="title">Estamos trabajando para mejorar tu experiencia</p>
                    <p class="subtitle">
                        Regresamos desde las 6:00 a.m de lunes a viernes
                    </p>
                    <a class="link" (click)="goToLanding()">
                        Conoce más sobre Mandadito
                        <bcp-icon name="arrow-right-r" size="24"></bcp-icon>
                    </a>
                </div>
                <img src="assets/images/piggy-mantenimiento.svg" alt="piggy-maintenance" class="piggy-maintenance">
            </div>
        </div>
    </div>
</div>

<div class="mobile">
    <div class="mobile-content">
        <app-header-exp [completed]="true"></app-header-exp>
        <div class="main-content">
            <img src="assets/images/piggy-mantenimiento.svg" alt="piggy-maintenance" class="piggy-maintenance">
            <p class="title">Estamos trabajando para mejorar tu experiencia</p>
            <p class="subtitle">
                Regresamos desde las 6:00 a.m de lunes a viernes
            </p>
            <a class="link" (click)="goToLanding()">
                Conoce más sobre Mandadito
                <bcp-icon name="arrow-right-r" size="24"></bcp-icon>
            </a>
        </div>
    </div>
</div>
