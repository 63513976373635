<div class="step2">
    <h3>Ingresa los datos del destinatario</h3>
    <form [formGroup]="formReceiver" (ngSubmit)="validateForm()">

        <div class="elements-container">
            <div class="inputAccount">
    
                <bcp-input type="text" [label]="data?.operation?.currency === Currency.PEN.code ? 'Cuenta soles BCP *' : 'Cuenta dólares BCP *'"
                [placeholder]="data?.operation?.currency === Currency.PEN.code ? 'Cuenta soles BCP *' : 'Cuenta dólares BCP *'"
                message="Ingresa 14 dígitos sin espacios ni guiones"
                formControlName="accountNumber"
                bcpCtrlForm
                mask="accountSavings" [message]="getMessage('accountNumber')" [state]="getError('accountNumber')"></bcp-input>
    
            </div>
            <div class="inputForMe">
                <bcp-checkbox tier="secondary" size="lg" value="1" (ctrlChange)="triggerForMe($event)">
                    <span class="inputForMe__text">La cuenta de destino est&aacute; a mi nombre</span>
                </bcp-checkbox>
            </div>

            <app-collapse [collapse]="collapse">
                <ng-container body>
                    <div class="inputElement">
                        <bcp-input type="text" label="Primer nombre *" required="true" placeholder="Ej. Juan" bcpCtrlForm
                        [message]="getMessage('firstName')" [state]="getError('firstName')" formControlName="firstName"></bcp-input>
                    </div>
            
                    <div class="inputElement">
                        <bcp-input type="text" label="Segundo nombre (opcional)" placeholder="Ej. Ricardo" bcpCtrlForm
                        [message]="getMessage('secondName')" [state]="getError('secondName')" formControlName="secondName"></bcp-input>
                    </div>
                    <div class="inputElement">
                        <bcp-input type="text" label="Primer apellido *" required="true" placeholder="Ej. Perez" bcpCtrlForm 
                        [message]="getMessage('firstLastname')" [state]="getError('firstLastname')" formControlName="firstLastname"></bcp-input>
                    </div>
                    <div class="inputElement">
                        <bcp-input type="text" label="Segundo apellido *" required="true" placeholder="Ej. Vega" bcpCtrlForm
                        [message]="getMessage('secondLastname')" [state]="getError('secondLastname')" formControlName="secondLastname"></bcp-input>
                    </div>
                    <div class="inputElement">
                        <bcp-input type="text" label="DNI Peruano *" required="true" placeholder="Documento de identidad peruano" maxlength="8"
                            [message]="getMessage('documentNumber')" [state]="getError('documentNumber')" bcpCtrlForm formControlName="documentNumber">
                        </bcp-input>
                    </div>
            
                    <ul class="inputPhone inputElement">
                        <li>
                            <bcp-select-input placeholder="Nro de celular" [selectOptions]="selectOptions" 
                            bcpCtrlForm formControlName="phoneNumber" [message]="messagePhoneNumber" [state]="!formReceiver.get('phoneNumber').pristine && statusPhoneNumber" inputmode="numeric" maxlength="15">
                            </bcp-select-input>
                        </li>
                    </ul>
                    <div class="inputElement">
                        <bcp-input type="email" label="Correo electrónico *" required="true" bcpCtrlForm placeholder="Ej. ejemplo@correo.com"
                        [message]="getMessage('email')" [state]="getError('email')" formControlName="email"></bcp-input>
                    </div>
                </ng-container>
            </app-collapse>  
        </div>
        <div class="button">
            <bcp-button class="mt-2" shape="rectangle" tier="secondary"  size="lg" full-width="true" (click)="backToPrevious()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
                Volver
            </bcp-button>
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit" [disabled]="formReceiver.invalid">Confirmar</bcp-button>
        </div>
      </form>
    
</div>
