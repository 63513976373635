<app-header-exp [step]="stepNumber" class="mobile" [completed]="this.steps.completed"></app-header-exp>
<div class="content" [ngClass]="{'completed': steps.completed}">
    <div class="sidebar-exp">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second">
        <div class="steps full-screen">
            <app-steps [elements]="stepElements" *ngIf="!this.steps.completed"></app-steps>
        </div>
        <div class="second-content">
            <app-step1-exp [hidden]="!steps.first" (setData)="nextStep($event)"></app-step1-exp>
            <app-step2-exp [hidden]="!steps.second" [data]="data" (setData)="nextStep($event)" (backStep)="backStep($event)"></app-step2-exp>
            <app-step3-exp [hidden]="!steps.third" [data]="data" (setData)="nextStep($event)" (backStep)="backStep($event)"></app-step3-exp>
            <app-step4-exp [hidden]="!steps.fourth" [data]="data" (setData)="sendData($event)" (backStep)="backStep($event)"></app-step4-exp>
            <div class="error text-center mt-3" *ngIf="error.show" [innerHTML]="error.message"></div>
            <app-completed [data]="data" *ngIf="steps.completed"></app-completed>
        </div>
    </div>
</div>

<app-modal [size]="'sm'" *ngIf="showModalAccessTime">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="/assets/images/sleeping-piggy.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700">En este horario no puedes<br />enviar Mandaditos</h3>
            <p class="mt-3 flexo-regular font-size-md-16 font-size-14 line-height-24 text-center">Nuestro horario de envíos es de <span class="flexo-demi">lunes a viernes</span></p>
            <p class="mt-1 flexo-regular font-size-md-16 font-size-14 line-height-24 text-center">Puedes continuar y registrarte, pero no podrás enviar un mandadito por ahora.</p>
            
            <div class="extra-button-size mx-auto mt-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="showModalAccessTime = false">Continuar</bcp-button>
            </div>
        </div>
    </section>
</app-modal>
