import { Directive, Injector, OnInit, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, Validators } from '@angular/forms';
import { Subject, distinctUntilChanged, startWith, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[appControlValueAccessor]'
})
export class ControlValueAccessorDirective<T> implements ControlValueAccessor, OnInit {

  control: FormControl | undefined;
  isRequired = false;
  value: string | T = '';
  private _isDisabled = false;
  private _destroy$ = new Subject<void>();
  public onTouched: () => void;
  public onChange: (value: boolean) => void;

  injector = inject(Injector);

  ngOnInit() {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);
      switch(formControl.constructor) {
        case FormControlName:
          this.control = this.injector.get(FormGroupDirective).getControl(formControl as FormControlName);
        break;
        default:
          this.control = (formControl as FormControlDirective).form as FormControl;
        }

    } catch (error) {
      this.control = new FormControl();
    }
  }

  writeValue(value: T): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => T): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

}
