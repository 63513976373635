import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MANDADITO_STEPS, PATHS } from 'src/app/core/constants';
import { DataMandadito, GtmEvent, OPERATIONS, UpdateDataService } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { SELECT_OPTIONS } from './receiver.component.data';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Router } from '@angular/router';
import { Currency } from 'src/app/shared/models/model';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-receiver',
  templateUrl: './receiver.component.html',
  styleUrls: ['./receiver.component.scss']
})
export class ReceiverComponent implements OnInit {

  @Input() activeHome = false;
  isAutoremittance = false;
  checkFavorite = false;
  autoRemittanceData: any = null;
  selectOptions = SELECT_OPTIONS;

  @ViewChild('bcpCheckbox') checkboxBcp!: ElementRef<any>;
  accessibleConfig = {
    inputAriaLabel: 'Primera jerarquía',
    inputAriaDescribedby: 'checkboxSpanSrOnlyAria2'
  };
  submitted = false;
  collapse = true;

  formReceiver: FormGroup;
  step = MANDADITO_STEPS.RESUME;
  lengthCharacters = "00/100";

  @Input()
  data: DataMandadito;

  @Input()
  user: any;

  @Output()
  backStep = new EventEmitter<string>();

  @Output()
  nextStep = new EventEmitter<Object>();

  private gtmService = inject(GtmService);
  private localStorageService = inject(LocalStorageService);
  private router = inject(Router);
  private dataService = inject(DataService);


  statusPhoneNumber = '';
  messagePhoneNumber = 'Ej. +51 999 555 444';

  Currency = Currency

  ngOnInit(): void {
    this.initializeForm();
    this.getData();
  }

  initializeForm() {
    this.formReceiver = new FormGroup({
      accountNumber: new FormControl('', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]),
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      secondName: new FormControl('', [Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      firstLastname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      secondLastname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      documentNumber: new FormControl('', [Validators.required, 
        Validators.pattern(/^[0-9]{8,8}$/), Validators.minLength(8), Validators.maxLength(8)]),
      phoneNumber: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    });
    
    const accountNumberControl = this.formReceiver.get('accountNumber')

    this.data?.operation?.currency=== Currency.PEN.code ?
      accountNumberControl.addValidators([Validators.pattern(/[0-9]{11}[0][0-9]{2}$/)]) :
      accountNumberControl.addValidators([Validators.pattern(/[0-9]{11}[1][0-9]{2}$/)])


    if(this.data.receiver){
      this.formReceiver.patchValue({
        ...this.data.receiver,
        phoneNumber: { inputValue: this.data?.receiver?.phoneNumber, selectValue: this.data?.receiver?.phonePrefix }
      })
    }


    this.getValidationPhoneNumber();

  }

  getData(): void {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data?.operation  === OPERATIONS.VERIFY_IF_PHONE_EMPTY) {
          if (this.data?.receiver?.phoneNumber?.length > 0) {
            this.formReceiver.get('phoneNumber').patchValue({
              selectValue: this.data?.receiver?.phonePrefix,
              inputValue: this.data?.receiver?.phoneNumber
            })
          }
        }
      }
    })
  }

  getValidationPhoneNumber() {
    this.formReceiver.get('phoneNumber').valueChanges.subscribe({
      next: (value: any) => {
        this.statusPhoneNumber = '';
        this.messagePhoneNumber = '';
        if (!value) return;
        let validate = value.inputValue.match(/^-?(0|[1-9]\d*)?$/);
        if (value.inputValue.length === 0) {
          this.statusPhoneNumber = 'error';
          this.messagePhoneNumber = 'Ingrese el número';
        }

        if (value.inputValue.length > 0 && value.inputValue.length < 8) {
          this.statusPhoneNumber = 'error';
          this.messagePhoneNumber = 'Ingrese al menos 8 dígitos';
        }

        if (validate === null) {
          this.statusPhoneNumber = 'error';
          this.messagePhoneNumber = 'Solo números';
        }
      }
    })
  }
  

  triggerForMe(event) {
    const inputs = ['firstName', 'secondName', 'firstLastname', 'secondLastname',
    'email', 'documentNumber', 'phoneNumber'];

    this.isAutoremittance = false;
    if (event.detail !== null) {
      this.isAutoremittance = true;
      const event: GtmEvent = {
        event: 'virtualEvent',
        accion: 'clic_autoremesa',
        pantalla: 'EnvioPaso2',
      };
      this.gtmService.sendEvent(event);
    }

    this.collapse = !this.collapse;

    inputs.forEach(input => {
      if (this.isAutoremittance) {
        setTimeout(() => {
          if (input === 'phoneNumber') {
            this.formReceiver.get(input).setValue({
              selectValue: this.user['phonePrefix'], inputValue: this.user['phoneNumber']
            });
          }
          else {
            this.formReceiver.get(input).setValue(this.user[input]);
          }

          this.formReceiver.get(input).disable();
        }, 0);
      } else {
        this.formReceiver.get(input).reset();

        if (input === 'phoneNumber') {
          this.formReceiver.get(input).setValue({
            selectValue: '+51', inputValue: ''
          });
        }
        this.formReceiver.get(input).enable();
      }
    });
  }

  validateForm() {
    this.submitted = true;
    if (this.formReceiver.invalid || this.statusPhoneNumber === 'error') {
      return;
    }

    const form = { ...this.formReceiver.value };
    delete form.phoneNumber;
    form.phonePrefix = this.formReceiver.value.phoneNumber?.selectValue;
    form.phoneNumber = this.formReceiver.value.phoneNumber?.inputValue;
    const operation = {
      ...this.data.operation,
      autoremittance: this.isAutoremittance,
    };

    this.data = {
      ...this.data,
      operation,
      checkFavorite: false,
      receiver: this.isAutoremittance ? 
        {
          ...form,
          ...this.user
        } : 
        form
    };
    
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_Envio2_Continuar',
      pantalla: 'EnvioPaso2',
    };
    this.gtmService.sendEvent(event);

    this.nextStep.emit({ step: MANDADITO_STEPS.RESUME, data: this.data })
  }

  getMessage(input: string) {
    const formControl = this.formReceiver.get(input);

    if (input === 'documentNumber') {
      return 'Ingresa los 8 dígitos del DNI Peruano';
    }

    if (formControl && formControl.errors && !formControl.pristine && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (formControl && formControl.errors && !formControl.pristine && !formControl.errors.hasOwnProperty('required')) {
      return this.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }

    return '';
  }

  getError(input: string) {
    const formControl = this.formReceiver.get(input);

    if (formControl && formControl.errors && !formControl.pristine && formControl.errors['required']) {
      return "error";
    }

    if (formControl && formControl.errors && !formControl.pristine && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    return '';
  }

  getMessageErrorByErrorType(input: string, error: string): string {
    const JUST_LETTERS = 'Ingresa solo letras';
    const JUST_NUMBERS = 'Ingresa solo números';
    const REQUIRED = 'Ingrese el dato';
    const CANTITY_DIGITS_DNI = 'Ingresa 8 caracteres';
    const CANTITY_DIGITS_ACCOUNT = 'Ingresa 14 dígitos sin espacios ni guiones';
    const BCP_DOLLARS_ACCOUNT_TYPE = 'Debes ingresar una cuenta dólares BCP';
    const BCP_SOLES_ACCOUNT_TYPE = 'Debes ingresar una cuenta soles BCP';
    const BCP_ACCOUNT_TYPE = this.data?.operation?.currency === Currency.PEN.code ? BCP_SOLES_ACCOUNT_TYPE : BCP_DOLLARS_ACCOUNT_TYPE;
    const VALID_EMAIL = 'Ingrese un email válido';

    const inputs = {
      accountNumber: {
        required: REQUIRED,
        pattern:   BCP_ACCOUNT_TYPE,
        minlength: CANTITY_DIGITS_ACCOUNT,
        maxlength: CANTITY_DIGITS_ACCOUNT
      },
      firstName: {
        required: REQUIRED,
        pattern: JUST_LETTERS
      },
      secondName: {
        pattern: JUST_LETTERS
      },
      firstLastname: {
        required: REQUIRED,
        pattern: JUST_LETTERS
      },
      secondLastname: {
        required: REQUIRED,
        pattern: JUST_LETTERS
      },
      email: {
        required: REQUIRED,
        pattern: VALID_EMAIL
      },
      documentNumber: {
        required: REQUIRED,
        pattern: JUST_NUMBERS,
        minlength: CANTITY_DIGITS_DNI,
        maxlength: CANTITY_DIGITS_DNI,
      },
      phoneNumber: {
        required: REQUIRED,
        pattern: JUST_NUMBERS
      }
    } 

    return inputs[input][error];
  }

  backToPrevious() {
    if (this.activeHome) {
      this.router.navigate([PATHS.NEW_MANDADITO]);
    }else{
      this.backStep.emit('AMOUNT');
    }
  }
}
