<div class="header">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second">
        <div class="d-flex mx-auto justify-content-center flex-adapt">
            <bcp-button shape="text" tier="primary" (ctrlClick)="goBack()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
                Volver
            </bcp-button>
            <div class="login">
                <h2 class="main-title flexo">Inicia sesión</h2>
                <p class="sub-title d-flex align-items-center mx-auto"><span class="pr-2">¿No tienes un usuario? </span> 
                    <bcp-button shape="text" tier="primary" (click)="goToRegister()">Regístrate</bcp-button>
                </p>
                <form class="form-main" [formGroup]="formLogin" (ngSubmit)="sendData()">
                    <div class="inputElement">
                        <bcp-input type="email" label="Correo electrónico *" required="true" bcpCtrlForm placeholder="ejemplo@correo.com"
                        [message]="getMessage('email')" [state]="getError('email')" formControlName="email" (input)="onInputSessionChange($event)"></bcp-input>
                    </div>
                    <div class="inputElement">
                        <bcp-input [type]="inputType" label="Contraseña" required="true" bcpCtrlForm placeholder="Ingresa tu contraseña"
                        [message]="getMessage('password')" [state]="getError('password')" formControlName="password" icon-position="right" (input)="onInputSessionChange($event)">
                        <bcp-icon [name]="eyeType" class="cursor-pointer" slot="icon" (click)="eventTriggered($event)"></bcp-icon></bcp-input>
                    </div>
                    <bcp-button shape="text" tier="primary" (click)="showForgotPasswordModal()">¿Olvidaste tu contraseña?</bcp-button>
                    <div class="button">
                        <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit">Iniciar sesión</bcp-button>
                    </div>
                    <div class="error text-center mt-3" *ngIf="error.show" [innerHTML]="error.message"></div>
                </form>
            </div>
        </div>
    </div>
</div>


<app-modal [size]="'sm'" *ngIf="loginModals.maxMonth && !(loginModals.recovery || loginModals.rejected)">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
        <h3 class="error-modal-header">Has alcanzado el máximo de<br>envíos este mes</h3>
        <p class="error-modal-subheader">Solo puedes enviar <span class="flexo-demi">4 Mandaditos por mes</span>, regresa el primer día del próximo mes.</p>
        
        <div class="mt-3 text-center font-size-16">
            <bcp-button *ngIf="userHasCompletedOperations" shape="rectangle" size="lg" (click)="redirectToHistorical()">Ver mi historial de Mandaditos</bcp-button>
        </div>
        <div class="mt-3 text-center font-size-16">
            <!-- Due to a weird bug in this component we had to render diferent buttons based on the user operations conditions instead of passing the corresponding props using same condition-->
            <bcp-button *ngIf="userHasCompletedOperations" size="sm" shape="text" (click)="goBack()">Volver a Mandaditobcp.com.pe</bcp-button>
            <bcp-button *ngIf="!userHasCompletedOperations" size="lg" shape="rectangle" (click)="goBack()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="loginModals.maxDay && !loginModals.maxMonth && !(loginModals.recovery || loginModals.rejected)">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-24 line-height-28 text-center color-primary_700">Has alcanzado el monto<br />máximo por hoy</h3>
            <p class="mt-3 flexo-regular font-weight-100 font-size-16 line-height-25 text-center pl-22 pr-22">Solo puedes enviar <span class="flexo-demi">999 dólares por día</span>, regresa mañana para enviar otro Mandadito.</p>
            
            <div class="mt-3 text-center font-size-16">
                <bcp-button *ngIf="userHasCompletedOperations" shape="rectangle" size="lg" (click)="redirectToHistorical()">Ver mi historial de Mandaditos</bcp-button>
            </div>
            <div class="mt-3 text-center font-size-16">
                <bcp-button [size]="userHasCompletedOperations ? 'sm': 'lg'" [shape]="userHasCompletedOperations ? 'text':'rectangle'" (click)="goBack()">Volver a Mandaditobcp.com.pe</bcp-button>
            </div>
        </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="loginModals.personalData">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="172" class="mx-auto" src="/assets/images/spot-heroes-s-sh-scan-dni-woman-l.svg" alt="girl" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">Te falta agregar algunos<br />datos personales</h3>
        <p class="mt-3 flexo-regular font-size-14 line-height-20 text-center">Completa tu registro y empieza a enviar<br />dinero a quienes más quieres.</p>
        
        <div class="standard-button-size mx-auto mt-3">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="loginModals.maxMonth = false">Completar mi registro</bcp-button>
        </div>
    </div>
    </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="loginModals.hour && !(loginModals.recovery || loginModals.rejected || loginModals.maxDay || loginModals.maxMonth)">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="assets/images/sleeping-piggy.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-20 font-size-md-24 line-height-28 line-height-md-32 text-center color-primary_700 break-word pl-24 pr-24">En este horario no puedes<br />enviar Mandaditos</h3>
            <p class="mt-1 flexo font-size-md-16 font-size-14 line-height-24 text-center mb-2">Nuestro horario de envíos es:</p>
            <ul class="modal-extended-hours-req">
                <li>Lunes a viernes durante todo el día</li>
                <li>Sábados hasta la 1:00 p.m. EST</li>
            </ul>
            <div class="mt-3 text-center font-size-14 font-size-md-16">
                <bcp-button *ngIf="userHasCompletedOperations" shape="rectangle" size="lg" (click)="redirectToHistorical()">Ver mi historial de Mandaditos</bcp-button>
            </div>
            <div class="mt-3 text-center font-size-14 font-size-md-16">
                <bcp-button [size]="userHasCompletedOperations ? 'sm': 'lg'" [shape]="userHasCompletedOperations ? 'text':'rectangle'" (click)="goBack()">Volver a Mandaditobcp.com.pe</bcp-button>
            </div>
        </div>
        </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="loginModals.recoverPassword">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img src="assets/images/close.svg" class="close" (click)="goBack()" alt="cerrar" />
            <img width="147" class="mx-auto" src="/assets/images/spot-heroes-s-sh-lock.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-24 line-height-32 text-center color-primary_700 break-word pl-24 pr-24">Recupera tu contraseña</h3>
            <p class="mt-3 font-size-16 line-height-16 text-center break-word pl-24 pr-20 line-height-20">Escríbenos por correo y te ayudaremos a acceder a tu cuenta</p>
            <div class="mt-3 font-size-16 line-height-16 text-center break-word pl-24 pr-20 line-height-20 email">
                <bcp-icon name="mail-r" color="secondary-500" size="24" class="email-icon"></bcp-icon>
                <p class="email-text">contacto@mandaditobcp.com.pe</p>
            </div>
        </div>
        </section>
</app-modal>

<app-shared-modals [modal]="modal"></app-shared-modals>
