import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';

@Component({
  selector: 'app-deployment-window',
  templateUrl: './deployment-window.component.html',
  styleUrls: ['./deployment-window.component.scss']
})
export class DeploymentWindowComponent implements OnInit {

  private router = inject(Router)
  constructor() { }

  ngOnInit(): void {
  }

  goToLanding(){
    this.router.navigate([PATHS.LANDING])
  }

}
