import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { MANDADITO_STEPS, PATHS ,TEXT} from 'src/app/core/constants';
import { DataMandadito, GtmEvent, OPERATIONS, Operation, UpdateDataService } from 'src/app/core/interfaces';
import { DataService } from 'src/app/core/services/data.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MainService } from 'src/app/core/services/main.service';
import { Data, TutorialSteps } from './new-mandadito.data';
import { SendComponent } from './send/send.component';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { StatusRegister } from 'src/app/core/constants';
import { LastPageService } from 'src/app/shared/services/last-page.service';

@Component({
  selector: 'app-new-mandadito',
  templateUrl: './new-mandadito.component.html',
  styleUrls: ['./new-mandadito.component.scss']
})
export class NewMandaditoComponent implements OnInit {
  activeHome = true;
  activeNextStep = false;
  data: DataMandadito;
  historyElements: Operation[] = [];
  isMenuOpened = false;
  isConfirmMessage = false;
  showTutorial = false;
  tutorialSteps = TutorialSteps;
  currentStep = 0;
  statusRegister : StatusRegister;
  showAlert: boolean = false;
  msgInvalidForm: string = TEXT.COMPLETE_REGISTER_SUCCESS_PHOTO;

  private lastPageService = inject(LastPageService);
  @ViewChild(SendComponent) sendComponent: SendComponent;

  private localStorageService = inject(LocalStorageService);
  private mainService = inject(MainService);
  private dataService = inject(DataService);
  private router = inject(Router);
  private gtmService = inject(GtmService);
  private loaderService = inject(LoaderService)
  private cdr = inject(ChangeDetectorRef)

  isExtendedHours = false;
  showExtendedHoursModal = false;


  ngOnInit(): void {
    this.getData();
    this.getCustomer();
    //this.getHistory();
    this.validateConfirmMessage();
    this.getUserOperationValidation();
    this.validateCustomer();
  }

  validateCustomer(): void {
    const lastPage = this.lastPageService.getLastPage();
    if(lastPage == PATHS.COMPLETE_REGISTER_PHOTO ){
      this.mainService.GetCustomer().subscribe({
        next: (data) => {
          this.localStorageService.deletedStoredCustomer();
          this.localStorageService.storeCustomer(data);
          this.showAlert = true;
        },
        error: _ => { },
      });
    }
    this.lastPageService.saveLastPage(PATHS.NEW_MANDADITO);
  }

  validateConfirmMessage(): void {
    const activeHome = this.localStorageService.getStorage('goToHome');
    activeHome && this.showConfirmMessage();
    setTimeout(() => {
      this.localStorageService.removeStorage('goToHome');
    }, 1000);
  }

  private showConfirmMessage(): void {
    this.isConfirmMessage = true;
  }

  getData(): void {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data?.operation === OPERATIONS.RESET_MANDADITO) {
          this.activeHome = true;
          this.activeNextStep = false;
          this.data = Data;
        }
      }
    });
  }

  getCustomer(): void {
    const customer = this.localStorageService.getStoredCustomer();
    if (customer) {
      this.showTutorial = customer.hasOperationsCompleted && !customer.viewNewHome;
    }
    this.validateCustomer();
  }

  async getHistory() {
    let historyElements = this.localStorageService.getStorage('historyElements');
    if (!historyElements) {
      historyElements = await lastValueFrom(this.mainService.getOperations(4, 'Completado'));
      historyElements = historyElements.data.map((element, index) => {
        element.active = index === 0;
        return element;
      });
      this.localStorageService.setStorage('historyElements', historyElements);
    }
    this.historyElements = historyElements;
    this.loaderService.requestEnded()
  }

  goToHistory(): void {    
    this.router.navigate([PATHS.HISTORICAL]);
  }

  toggleFullscreenMenu() {
    this.isMenuOpened = !this.isMenuOpened
  }

  get hideContent(){
    return this.isMenuOpened && window.innerWidth < 768
  }

  nextStep(): void {
    if (this.currentStep === 0) {
      this.currentStep = 1;
    } else {
      const customer = this.localStorageService.getStoredCustomer();
      this.localStorageService.deletedStoredCustomer();
      this.localStorageService.storeCustomer({ ...customer, viewNewHome: true });      
      this.showTutorial = false;
    }
  }

  triggerEvent(event) {
    this.isConfirmMessage = false;
    this.activeHome = event;

    if (this.isExtendedHours && !this.activeHome) {
      this.showExtendedHoursModal = true
      const event: GtmEvent = {
        event: 'virtualEvent',
        accion: 'Modal_Sabado',
        pantalla: 'Horario sabado',
      };

      this.gtmService.sendEvent(event)
    }

    this.cdr.detectChanges()

  }

  goToStep1(gtmEventName: string) {
    if (this.sendComponent) {
      this.sendComponent.goToStep(MANDADITO_STEPS.AMOUNT)
      this.closeModalAndContinue()

      const event: GtmEvent = {
        event: 'virtualEvent',
        accion: `${gtmEventName}_Sabado`,
        pantalla: 'Horario sabado',
      };

      this.gtmService.sendEvent(event)
    }
  }

  closeModalAndContinue(sendGtmEvent = false) {
    this.showExtendedHoursModal = false
    if (sendGtmEvent) {
      const event: GtmEvent = {
        event: 'virtualEvent',
        accion: 'Continuar_Sabado',
        pantalla: 'Horario sabado',
      };
      this.gtmService.sendEvent(event)
    }
  }

  getUserOperationValidation() {
    this.mainService.getValidationUserOperations(0).subscribe({
      next: (res) => {
        this.isExtendedHours = res.extendedSchedule
      },
      error: (err) => {

      }
    })
  }

  handleEvent(value: boolean) {
    this.showAlert = value;
  }
  chooseReceiver(data): void{
    if(data){
      this.showAlert = false;
    }
  }
}
