import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-frequency-questions',
  templateUrl: './frequency-questions.component.html',
  styleUrls: ['./frequency-questions.component.scss']
})
export class FrequencyQuestionsComponent implements OnInit {
  
  private gtmService = inject(GtmService)
  
  whatsappUrl = "https://wa.me/51945657689?text=Hola%20Mandadito%2C%20quiero%20saber%20informaci%C3%B3n%20sobre%E2%80%A6"
  isDesktop = false;

  tabs = [
        { status: true, text: 'General', textResponsive: 'General' },
        { status: false, text: 'Requisitos', textResponsive: 'Requisitos' },
        { status: false, text: 'Envío de dinero', textResponsive: 'Envío y recepción' }
      ];

  general = [
    { title: '¿Qué es Mandadito BCP y cómo funciona?', text: `
      <span class="accordion">
       Mandadito es una web del BCP para enviar dinero 100% digital desde Florida a Perú. ¡Tu primer envío es GRATIS! 
      </span><br>
      <span class="accordion">¡Es super simple!</span>
      <ul class="accordion">
        <li>Regístrate</li>
        <li>Selecciona el monto a enviar</li>
        <li>Coloca los datos de tu familiar o ser querido</li>
        <li>Paga con tu tarjeta de débito o crédito, o cuenta PayPal de USA.</li>
      </ul>
      <span class="accordion">
      ¡Y listo! Tu destinatario recibirá el dinero directo en su cuenta BCP en dólares o soles, según lo que elijas.
      </span>` 
    },
    { 
      title: '¿Solo está disponible en Florida? ', 
      text: `
      <span class="accordion">
        Sí, por ahora solo procesamos envíos de Florida a Perú, pero esperamos poder expandir nuestro servicio pronto. 
      </span>`
     },
     { 
      title: '¿En cuánto tiempo llegará mi dinero?', 
      text: `
      <span class="accordion">
        Contamos con same day delivery para Mandaditos solicitados antes de las 3 p.m. 
        EST de lunes a viernes y los sábados hasta la 1 p.m. EST. 
      </span>`
     },
     { 
      title: '¿Necesito ser cliente BCP para usar Mandadito? ', 
      text: `
      <span class="accordion">
        No, sólo el destinatario del Mandadito debe ser cliente del BCP. 
        Para enviar dinero, debes pagar con tu tarjeta de débito o crédito, o cuenta PayPal de USA. 
      </span>`
     },
     { 
      title: '¿Puedo enviarme dinero a mí mismo/a?', 
      text: `
      <span class="accordion">
        ¡Sí! Puedes enviar dinero a tu propia cuenta en dólares o soles activa del BCP.
      </span>`
     },
     { 
      title: '¿Cuánto cobra Mandadito por comisión?', 
      text: `
      <span class="accordion">
        ¡El primer envío es GRATIS!
        <br>
        Desde el segundo envío, Mandadito cobra una comisión variable según el monto solicitado. 
        Siempre se te cobrará en dólares.  
      </span>
      <div class="accordiontable">
        <table class="accordion">
          <tr>
            <th>Monto</th>
            <th>Comisión</th>
          </tr>
          <tr>
            <td>Menos de 100 USD</td>
            <td>3.99 USD</td>
          </tr>
          <tr>
            <td>100 - 199.99 USD</td>
            <td>5.50 USD</td>
          </tr>
          <tr>
            <td>200 - 500.99 USD</td>
            <td>7.50 USD</td>
          </tr>
            <tr>
            <td>501 - 970 USD</td>
            <td>9.00 USD</td>
          </tr>
        </table>  
      </div>
      <span class="accordion italic">Tu banco americano podría aplicar cargos 
      adicionales.</span>
      `      
     },
     { 
      title: '¿Necesito tener mi DNI Vigente? ', 
      text: `
      <span class="accordion">
        ¡No necesariamente! Puedes registrarte con tu Driver’s License americano vigente o con tu DNI peruano vigente. 
        Recuerda que para el registro tendrás que subir una imagen de la parte de adelante y atrás del documento.  
      </span>`
     },
     { 
      title: '¿En qué monedas envían? ', 
      text: `
      <span class="accordion">
        Hacemos depósitos en soles y dólares directo a cuentas activas del BCP. 
        Siempre se te cobrará en dólares.    
      </span>`
     },
     { 
      title: '¿Cuándo y a qué hora puedo registrarme? ', 
      text: `
      <span class="accordion">
        ¡Puedes registrarte en cualquier momento!    
      </span>`
     },
     { 
      title: '¿Cuándo y a qué hora puedo solicitar mi envío? ', 
      text: `
      <span class="accordion">
        Puedes solicitar tu envío de lunes a viernes durante las 24hrs y los sábados hasta la 1p.m. EST.  
      </span>`
     },
    { 
      title: '¿Cómo está BCP relacionado con Mandadito?', 
      text: `
      <span>Mandadito es un producto del Centro de Innovación del BCP en 
      colaboración con BCP Miami.</span>
      <span>
      Para más información, ingresa a 
       <a href="https://www.viabcp.com/mandadito" class="accordion" target="_blank">
           viabcp.com/mandadito
       </a>
      . 
      También nos podrás encontrar en 
         <a href="https://www.viabcp.com/cix-bcp" class="accordion" target="_blank">
           viabcp.com/cix-bcp
       </a>
      
      </span>
    ` },
    { 
      title: '¿Por qué debería usar Mandadito para enviar mi dinero?', 
      text: `
      <span class="accordion">
        Mandadito es rápido, seguro y 100% digital. Puedes solicitar tu envío sin salir de casa 
        y el destinatario lo recibe directamente en su cuenta así que no tiene que ir a la agencia 
        a retirar el dinero. Además, contamos con el respaldo del BCP.  
        <br><br>
        ¡Recuerda que el primer envío es GRATIS! 
      </span>
    ` },
    {
      title: '¿Cuáles son los canales oficiales de Mandadito?', 
      text: `
      <span class="accordion">Puedes comunicarte con nosotros en:</span>
      <ul class="accordion">
        <li>Toll free number: 1(888) 528-7886</li>
        <li>Correo electrónico: contacto@mandaditobcp.com.pe</li>
        <li>Instagram: 
           <a href="https://www.instagram.com/mandaditobcp" class="accordion" target="_blank">
           instagram.com/mandaditobcp
          </a>
        </li>
        <li>Facebook: 
          <a href="https://www.facebook.com/mandaditobcp" class="accordion" target="_blank">
           facebook.com/mandaditobcp
          </a>
        </li>
        <li>WhatsApp consultas: +51 945 657 689</li>
        <li>Usamos +51 962909910 para mantenerte actualizado sobre tu envío.</li>
      </ul>
    ` },
    {
      title: '¿Cuál es el horario de atención para dudas y consultas?', 
      text: `
      <span class="accordion">
        Nuestro horario de atención es de lunes a viernes, de 9 A.M a 6 P.M EST 
        (Eastern Standard Time) 
      </span>
    ` },
  ];




  requirements = [
    { title: '¿Qué requisitos necesito cumplir para enviar dinero a Perú?', 
      text: `
        <span class="accordion">
          Hay ciertos requisitos que deben cumplir tanto la persona que envía el dinero, 
          así como la persona que recibe el dinero: 
        </span><br><br>
        <span class="accordion bold">Emisor:</span><br>
        <ul class="accordion">
          <li>Ser mayor de 18 años</li>
          <li>Ser peruano/a con DNI (no tiene que estar vigente)</li>
          <li>Tener un DNI peruano vigente o un Driver’s License americano vigente, y subir una foto de adelante y detrás.</li>
          <li>Pagar con tu tarjeta de débito o crédito, o cuenta PayPal de USA. En ambos casos el medio de pago debe estar a tu nombre.</li>
          <li>Estar ubicado en el estado de Florida al momento de registrarte y realizar la transacción.</li>
          </ul><br>
        <span class="accordion bold">Receptor:</span><br>
        <ul class="accordion">
          <li>Ser mayor de 18 años</li> 
          <li>Ser peruano/a </li>   
          <li>Tener una cuenta bancaria activa en dólares o soles en el BCP (según la moneda seleccionada para el envío). Si no tiene una cuenta, puede abrirla en 
          <a href="https://www.abretucuenta.viabcp.com" class="accordion" target="_blank">
           www.abretucuenta.viabcp.com
          </a>
          </li>
        </ul>
        <br>
        <span class="accordion"> Además, tanto el emisor como el receptor serán evaluados siguiendo las políticas del BCP. </span>`
    },
    { title: '¿Necesito ser peruano/a? ', 
      text: `
        <span class="accordion">
          Sí, por ahora solo peruanos/as pueden enviar y recibir dinero con Mandadito. 
        </span>`
    },
    { title: '¿Necesito tener mi DNI vigente?', 
      text: `
        <span class="accordion">
          ¡No necesariamente! Puedes registrarte con tu Driver’s license americano vigente o con tu DNI peruano vigente. 
          Recuerda que para el registro tendrás que subir una imagen de la parte de adelante y atrás del documento. 
        </span>`
    },
    { title: '¿Necesito ser cliente BCP para usar Mandadito? ', 
      text: `
        <span class="accordion">
          No, sólo el destinatario del Mandadito debe ser cliente del BCP. 
          Para enviar dinero, debes pagar con tu tarjeta de débito o crédito o cuenta PayPal de USA. 
        </span>`
    },
    { title: '¿Qué me pedirán para registrarme?', 
      text: `
        <span class="accordion">
          En el registro te pediremos: 
        </span>
        <ul class="accordion">
          <li>Nombres</li>
          <li>Apellidos</li>
          <li>Número de DNI </li>
          <li>Fotos de tu DNI vigente o de tu Driver’s License americano vigente</li>
          <li>Celular </li>
          <li>Correo Electrónico </li>
          <li>Responder las preguntas de seguridad </li>  
          <li>Generar usuario y contraseña  </li>
        </ul>`
    },
    { title: '¿Qué datos necesito de mi destinatario?', 
      text: `
        <span class="accordion">
          Para poder enviar dinero necesitaras los siguientes datos de tu destinatario:
        </span><br>
        <ul class="accordion">
          <li>Nombres</li>
          <li>Apellidos</li>
          <li>DNI</li>
          <li>Celular</li>
          <li>Correo </li>
          <li>Número de cuenta BCP en dólares o soles (según la moneda seleccionada para el envío) </li>
          <li>Mensaje (Opcional)</li>
        </ul>`
    },

    { title: '¿Desde qué dispositivos puedo usar Mandadito?',
      text: `
      <span class="accordion">
        Mandadito es 100% digital, te recomendamos utilizar una red Wifi para 
        una conexión más estable.</span><br><br>
      <span class="accordion">
        Puedes ingresar a través de tu celular, tablet o computadora en el siguiente 
      enlace: 
       <a href="https://www.mandaditobcp.com.pe" class="accordion" target="_blank">
           www.mandaditobcp.com.pe
          </a>
      </span>`
    },

  ]

  shippingReceiving = [
    { title: '¿Cómo envío dinero con Mandadito?', text: `
      <span class="accordion">¡Es simple!</span>
      <ul class="accordion">
        <li>Regístrate</li>
        <li>Ingresa el monto que deseas enviar en dólares o soles. </li>
        <li>Ingresa los datos de tu destinatario y su cuenta BCP en dólares o soles (según la moneda seleccionada para el envío).</li>
        <li>Realiza tu pago con tu tarjeta de débito o crédito o cuenta PayPal de USA.</li>
      </ul>
      <span class="accordion">¡Y listo! Revisaremos tu solicitud y te mantendremos 
      informado de todo el proceso por WhatsApp y correo.</span>
    ` },
    { title: '¿Cuándo y a qué hora puedo solicitar mi envío? ', 
      text: `
      <span class="accordion">Puedes solicitar tu envío de lunes a viernes durante las 24hrs y los sábados hasta la 1p.m. EST. 
      ¡Puedes registrarte en cualquier momento! </span>
    ` },
    { title: '¿En cuánto tiempo llegará mi dinero?  ', 
      text: `
      <span class="accordion">
      Contamos con same day delivery para Mandaditos solicitados antes de las 3 p.m. EST de lunes a viernes y los sábados hasta la 1p.m. EST. 
      </span>
    ` },
    { 
      title: '¿Cuánto cobra Mandadito por comisión?', 
      text: `
      <span class="accordion">
        ¡El primer envío es GRATIS!
        <br>
        Desde el segundo envío, Mandadito cobra una comisión variable según el monto solicitado. 
        Siempre se te cobrará en dólares.  
      </span>
      <div class="accordiontable">
        <table class="accordion">
          <tr>
            <th>Monto</th>
            <th>Comisión</th>
          </tr>
          <tr>
            <td>Menos de 100 USD</td>
            <td>3.99 USD</td>
          </tr>
          <tr>
            <td>100 - 199.99 USD</td>
            <td>5.50 USD</td>
          </tr>
          <tr>
            <td>200 - 500.99 USD</td>
            <td>7.50 USD</td>
          </tr>
            <tr>
            <td>501 - 970 USD</td>
            <td>9.00 USD</td>
          </tr>
        </table>
      </div>
      <span class="accordion italic">Tu banco americano podría aplicar cargos 
      adicionales.</span>
      `      
     },
    { title: '¿Solo puedo enviar dinero desde Florida?  ', 
      text: `
      <span class="accordion">
        Sí, solo pueden enviar dinero los peruanos que se encuentren en Florida, 
        pero estamos trabajando constantemente para poder ofrecer esta solución a otros estados en EE. UU.       
      </span>
    ` },
    { title: '¿A qué países y bancos puedo enviar? ', 
      text: `
      <span class="accordion">
        Por el momento el único país al que se puede enviar dinero es a Perú. 
        Hacemos depósitos en soles y dólares directo a cuentas activas del BCP.       
      </span>
    ` },
    { title: '¿En qué monedas envían? ', 
      text: `
      <span class="accordion">
        Hacemos depósitos en soles y dólares directo a cuentas activas del BCP. 
        Siempre se te cobrará en dólares.      
      </span>
    ` },
    { title: '¿Qué métodos de pago acepta Mandadito?  ', 
      text: `
      <span class="accordion">
        Debes pagar con tu tarjeta de débito o crédito o cuenta PayPal de USA. 
        El medio de pago debe estar a tu nombre.        
      </span>
    ` },
    { title: '¿Puedo enviarme dinero a mí mismo/a? ', 
      text: `
      <span class="accordion">
        ¡Sí! Puedes enviar dinero a tu propia cuenta en dólares o soles activa del BCP.         
      </span>
    ` },
    { title: '¿Cuál es el límite de transferencias en el mes?', text: `
      <span class="accordion">Podrás enviar como máximo 4 Mandaditos al mes.</span><br>
      <span class="accordion">El límite de envíos es:</span>
      <ul class="accordion">
          <li>970 USD por transacción</li>
          <li>970 USD al día</li>
      </ul>
    ` },
    { title: '¿Tienen que ir a recoger el dinero a una agencia? ', 
      text: `
      <span class="accordion">
        ¡No! Mandadito es 100% digital. El dinero se recibe directamente en una cuenta en dólares o soles del BCP, según lo que elijas.           
      </span>
    ` },
    { title: '¿Se le realiza algún cobro a mi destinatario? ', 
      text: `
      <span class="accordion">
        No, recibirá el dinero completo directamente en su cuenta, libre de intereses y comisiones.         
      </span>
    ` },
    { title: '¿Cómo sabré si mi dinero llegó? ', 
      text: `
      <span class="accordion">
        Enviaremos un mensaje por WhatsApp y un correo electrónico, 
        tanto al emisor como el receptor cuando se haya hecho el depósito. 
        Asegúrate de revisar tu carpeta de no deseados o Spam.        
      </span>
    ` },
    { title: '¿Qué pasa si quiero cancelar mi solicitud y quiero mi dinero devuelto? ', 
      text: `
      <span class="accordion">
        Tienes un máximo de 30 minutos una vez que la solicitud haya sido creada para pedir la devolución. 
        Si el reclamo procede, el dinero será devuelto a tu cuenta en máximo 5 días.
      </span>
    ` },
    { title: '¿Mi solicitud fue denegada? ¿Por qué?', 
      text: `
      <span class="accordion">
        En caso tu solicitud haya sido denegada, revisa tu correo electrónico o tu WhatsApp para más detalles. 
      </span>
    ` },
    { title: '¿Cómo está relacionado PayPal con ustedes?', 
      text: `
      <span class="accordion">
        PayPal es la pasarela de pago que utiliza Mandadito.  
      </span>
    ` },
    { title: '¿Puedo empezar con mi Mandadito desde un dispositivo y continuar en otro? ', 
      text: `
      <span class="accordion">
        Por seguridad, te recomendamos realizar tu Mandadito desde un dispositivo a la vez.   
      </span> 
    ` },
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.onResize()
  }

  activeTab(index: number) {
    this.tabs = this.tabs.map((tab, i) => {
      tab.status = false;
      if (index === i) {
        tab.status = true;
      }
      return tab;
    });
  }

  goToLanding(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  toggleAccordion(closed: boolean, index: number) {
    if (closed) return

    const FAQCategories = [
      {
        name: 'FAQ_General',
        range: [1, 8]
      },
      {
        name: 'FAQ_Requisitos',
        range: [9, 13]
      },
      {
        name: 'FAQ_Envios',
        range: [14, 25]
      },
    ]

    let screen = "Unknown";

    for (let category of FAQCategories) {
      if (index>=category.range[0] && index <= category.range[1]) {
        screen = category.name
        break
      }
    }

    const tag = {
      event: 'virtualEvent',
      accion: `FAQ_${index}`,
      pantalla: screen
    }

    this.gtmService.sendEvent(tag)

  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isDesktop = window.innerWidth >= 768
  }

}
