<div class="general-content">
    <h1 class="text-center flexo-bold font-size-24 line-height-32 font-size-md-32 line-height-md-56">Conoce sus experiencias</h1>
    <p class="subtitle text-center font-size-16 line-height-24 font-size-md-24 line-height-md-32">Clientes felices, historias reales</p>
   
    <div class="main-content" [ngClass]="{'responsive': responsive}">
        <div class="slide-content" *ngIf="DISTANCE > 0">
                <div *ngFor="let element of elements; let i = index"
                class="slide text-center" [ngStyle]="{'left.px': element.left, 'width.px': !responsive && DISTANCE }">
                    <div class="position-relative"
                        [ngStyle]="{'opacity': currentIndex === element.id ? 1 : 0.75 }" 
                        [ngClass]="{'shadow-element': responsive ? element.left === 0 : element.left === DISTANCE/2}">
                        <img class="cuotation-mark" src="/assets/images/double-cuotation-mark.svg" alt="Quotation mark" width="38" height="22" loading="lazy">
                        <p class="text font-size-14 line-height-20 font-size-md-16 line-height-md-24 flexo" [innerHTML]="element.text"></p>
                        <div class="customer">
                            <img *ngIf="responsive" [src]="element.img" width="52" height="52" [alt]="element.name" loading="lazy"/>
                            <img *ngIf="!responsive" [src]="element.img" width="96" height="96" [alt]="element.name" loading="lazy"/>
                            
                            <div>
                                <p class="name flexo-demi font-size-14 line-height-18 font-size-md-24 line-height-md-32">{{ element.name }}</p>
                                <span class="font-size-12 line-height-16 font-size-md-16 line-height-md-24">{{ element.info }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            
        </div>
        <ul class="arrows">
            <li (click)="setBack()" [ngClass]="{ 'pointer-events-none': backEvent }"><img src="/assets/images/left-arrow.svg" alt="Next arrow" width="17" height="11.33"></li>
            <li (click)="setNext()" [ngClass]="{ 'pointer-events-none': nextEvent }"><img src="/assets/images/right-arrow.svg" alt="Previous arrow" width="17" height="11.33"></li>
        </ul>
        
    </div>

    <ul class="dots">
        <li *ngFor="let element of cloneElements" [ngClass]="{'active': currentIndex === element.id }"></li>
    </ul>
</div>
