<div class="content-accordion">
    <!-- <p class="mb-0 accordion-line" *ngIf="index > 0"></p> -->
    <div class="accordion">
        <div class="acc-header" class="{{color}}" (click)="onClick($event)">
            <div class="headerTitle" [innerHTML]="title">
            </div>
            <div [@rotatedState]="!active? 'default': 'rotated'"><img class="chevron" src="/assets/images/chevron-right.svg" alt="Chevron right" width="15" height="15"></div>
        </div>
        <div [@smoothCollapse]="!active? 'initial': 'final'">
            <div class="acc-body">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
