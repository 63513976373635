import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { atLeastEightCharacters, atLeastOneNumber, atLeastOneUppercase, atLeastOneSpecialCharacter, emailsMatch } from '../../custom-validations';
import { UserInterface, GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-step4-exp',
  templateUrl: './step4-exp.component.html',
  styleUrls: ['./step4-exp.component.scss']
})
export class Step4ExpComponent implements OnInit {

  @Input()
  data: UserInterface;
  
  @Output()
  setData = new EventEmitter<UserInterface>();
  
  @Output()
  backStep = new EventEmitter<string>();

  submitted = false
  inputType = 'password';
  eyeType = 'eye-b';

  formRegister: FormGroup


  private gtmService = inject(GtmService)
  private utilService = inject(UtilService)

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.formRegister = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      repeatEmail: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, atLeastEightCharacters,
        atLeastOneNumber, atLeastOneUppercase, atLeastOneSpecialCharacter]),
    }, [emailsMatch('email', 'repeatEmail')])
  }

  sendData(): void {
    this.submitted = true;
    if (this.formRegister.invalid) {
      return;
    }

    const data: UserInterface = {
      ...this.data,
      fields: {
        ...this.data.fields,
        ...this.formRegister.value
      }
    };
    this.sendAnalytic();
    this.setData.emit(data);
  }

  sendAnalytic(): void {

    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_Registro2_registrarme',
      pantalla: 'Registro2'
    };
    this.gtmService.sendEvent(event);

  }

  getMessage(input: string) {
    const emailControl = this.formRegister.get('email')
    const repeatEmailControl = this.formRegister.get('repeatEmail')
    if((emailControl && repeatEmailControl) && (!emailControl.pristine || !repeatEmailControl.pristine)){
      
    }
    const data = { submitted: this.submitted, form: this.formRegister, input };
    return this.utilService.getMessage(data);
  }

  getError(input: string): string {
    const formControl = this.formRegister.get(input);
    if (!!formControl && !formControl.pristine && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (input === 'repeatEmail' && formControl && !formControl.pristine && this.formRegister.errors?.['emailMatchError']) {
      return "error";
    }

    if (!!formControl && !formControl.pristine && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    if(input==='combinedPhone' && formControl && !formControl.pristine){
      return this.formRegister.get('phonePrefix').invalid && this.formRegister.get('phoneNumber').invalid ? 'error' : ''
    }

    return '';
  }

  
  eventTriggered(_) {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.eyeType = 'eye-slash-b';
    } else {
      this.inputType = 'password';
      this.eyeType = 'eye-b';
    }
  }

  getImageByCondition(condition: boolean) {
    const isDirty = this.formRegister.get('password').dirty;
    if (!condition && isDirty) {
      return 'round-valid';
    } else if (isDirty) {
      return 'round-invalid';
    }
    return 'round';
  }

  goBackStep(): void {
    this.backStep.emit('third');
  }
}
