import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActiveCampaingsBreadcrumb, TermsAndConditionsBreadcrumb, ZeroComissionCampaignBreadcrumb } from '../../breadcrumbData';

@Component({
  selector: 'app-zero-comission-campaign',
  templateUrl: './zero-comission-campaign.component.html',
  styleUrls: ['./zero-comission-campaign.component.scss']
})
export class ZeroComissionCampaignComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      ActiveCampaingsBreadcrumb,
      ZeroComissionCampaignBreadcrumb
    ])
  }
}
