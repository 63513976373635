<img class="d-block d-md-none mx-auto" width="120" src="/assets/images/spot-heroes-small-s-shs-hand-success-l.svg" alt="Completaste tus datos" />
<img class="d-none d-md-block mx-auto" width="160" src="/assets/images/spot-heroes-small-s-shs-hand-success-l.svg" alt="Completaste tus datos" />

<h2 class="main-title">
    ¡Completaste tus datos!
</h2>
<p class="sub-title mw-360 mx-auto">
    Validaremos tu información.<br>Te notificaremos a tu correo:
</p>
<p class="sub-title d-flex flex-column">
    <strong> {{ email }} </strong>
</p>

<p class="sub-title d-flex align-items-center justify-content-center mx-auto mt-4 mb-0"><span class="pr-2">¿Tienes dudas? </span> 
    <a href="https://wa.link/ny1jx2" rel="noopener" target="_blank">
        <bcp-button shape="text" tier="primary" size="lg">
            Escríbenos
        </bcp-button>
    </a>
</p>

<div class="informative">
        <img src="assets/images/clock-blue.svg" width="16" alt="Info">
        <p>Atenderemos tus consultas de lunes a viernes de 9:00 a.m. a 6:00 p.m. EST</p>
</div>



<div class="button extra-button-size">
    <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="button" (click)="goToLanding()">
        Volver a Mandaditobcp.com.pe
    </bcp-button>
</div>
