import { Component, Input, OnInit, inject } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { inputs } from 'src/app/core/constants';
// import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-md-validation-errors',
  templateUrl: './md-validation-errors.component.html',
  styleUrls: ['./md-validation-errors.component.scss']
})
export class MdValidationErrorsComponent implements OnInit {

  @Input() errors: Record<string, ValidationErrors> | null = {}; 
  @Input() inputName: string;
  @Input() customErrorMessage = '';

  errorMessages = inputs;

  ngOnInit(): void {
  }

}
