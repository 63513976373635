import { Component, HostListener, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

const RESPONSIVE_WITH = 348;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input() autoSlide = true;
  @Input() slideInterval = 60000;
  @Input() responsive = false;

  router = inject(Router);
  private gtmService = inject(GtmService)

  backEvent = false;
  nextEvent = false;
  DISTANCE = 0; 
  
  elements: Element[] = [
    {
      id: 1,
      text: 'Tenía que mandar plata a Perú para unas cosas y con <span class="flexo-demi">Mandadito fue super rápido, super fácil y se demoró menos de 24 horas!</span> Encima la primera vez ni tenía tarifa. En verdad si la gente quiere usarlo es super conveniente',
      img: '/assets/images/carousel/isabella-farro.webp',
      name: 'Isabella Farro',
      info: '22 años, Florida USA',
      left: null,
    },
    {
      id: 2,
      text: 'Con Mandadito <span class="flexo-demi">ya no tengo que hacer cola</span>, es más rápido, digital y sobre todo <span class="flexo-demi">es seguro para mí y para mi mamá</span> que recibe la plata en Perú.',
      img: '/assets/images/carousel/ricardo-bolanios.webp',
      name: 'Ricardo Bolaños',
      info: '61 años, Florida USA',
      left: null,
    },
    {
      id: 3,
      text: 'Lo uso para enviar plata a Perú, <span class="flexo-demi">llega rápido y me facilita mucho.</span> También <span class="flexo-demi">es muy seguro para quién recibe la plata</span>, de frente a su cuenta sin hacer cola.',
      img: '/assets/images/carousel/ernesto-cardoso.webp',
      name: 'Ernesto Cardoso',
      info: '46 años, Florida USA',
      left: null,
    },
    {
      id: 4,
      text: 'Mandadito me parece <span class="flexo-demi">una super herramienta, me permite enviar remesas a mis seres queridos en Perú.</span> La verdad es que es fácil de usar, super económica y muy divertida. La verdad que la recomiendo.',
      img: '/assets/images/carousel/boris-fonseca.webp',
      name: 'Boris Fonseca',
      info: '41 años, Florida USA',
      left: null,
    }
  ];

  halfPosition = 0;
  interval;
  currentIndex = 2;
  cloneElements: Element[];

  ngOnInit(): void {
    this.cloneElements = JSON.parse(JSON.stringify(this.elements));
    this.autoSlideSlides();
    this.initCarousel(this.responsive);

  }

  autoSlideSlides(): void {
    if (this.autoSlide) {
      this.interval = setInterval(() => {
        this.setNext();
      }, this.slideInterval);
    }
  }

  initCarousel(responsive: boolean) {
    this.DISTANCE = Math.ceil(window.innerWidth) / 2;
    let interval = responsive ? RESPONSIVE_WITH : Math.ceil(window.innerWidth) / 2;
    this.elements = this.elements.map((element, index) => {
      element.left = responsive ? (index - 1) * interval : index * interval - this.DISTANCE / 2;
      return element;
    });
  }

  stopSlides(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setNext(id: number = 1) {
    if (this.backEvent) return;
    this.nextEvent = true;
    this.stopSlides();
    this.elements = this.elements.map((element, index) => {
      element.left = element.left - (this.responsive ? RESPONSIVE_WITH : this.DISTANCE) * id;
      return element;
    });

    const elements = JSON.parse(JSON.stringify(this.elements));
    setTimeout(() => {
      let firstElement = elements[0];
      elements.shift();
      firstElement = {
        ...firstElement,
        left: this.responsive ? RESPONSIVE_WITH * (this.elements.length - 2) : this.DISTANCE * (this.elements.length - 1) - this.DISTANCE/2
      }

      elements.push(firstElement);
      this.elements = JSON.parse(JSON.stringify(elements));

      this.currentIndex = this.getCurrentIndex(this.elements);
      this.autoSlideSlides();
      this.nextEvent = false;
    }, 250);
  }

  getCurrentIndex(elements): number {
    const position = this.responsive ? 0 : this.DISTANCE/2;
    const element = elements.find(element => element.left === position);
    return element.id;
  }

  setBack(id: number = 1) {
    if (this.nextEvent) return;
    this.backEvent = true;
    this.stopSlides();
    this.elements =  this.elements.map(element => {
        element.left = element.left + (this.responsive ? RESPONSIVE_WITH : this.DISTANCE) * id;
        return element;
    });

    const elements = JSON.parse(JSON.stringify(this.elements));
    setTimeout(() => {
      let lastElement = elements[this.elements.length - 1];
      elements.pop();
      lastElement = {
        ...lastElement ,
        left: this.responsive ? - RESPONSIVE_WITH : - this.DISTANCE/2
      };
      elements.unshift(lastElement);
      this.elements = JSON.parse(JSON.stringify(elements));

      this.currentIndex = this.getCurrentIndex(this.elements);
      this.autoSlideSlides();
      this.backEvent = false;
    }, 250);
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(_): void {
   this.initCarousel(this.responsive);
  }

}

interface Element {
  id: number,
  text: string;
  img: string;
  name: string;
  info: string;
  left: number;
}
