import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { UserInterface, GtmEvent } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { MainService } from 'src/app/core/services/main.service';
import { UtilService } from 'src/app/core/services/util.service';
import { atLeastEightCharacters, atLeastOneNumber, atLeastOneUppercase, atLeastOneSpecialCharacter, emailsMatch } from '../../custom-validations';

@Component({
  selector: 'app-step1-exp',
  templateUrl: './step1-exp.component.html',
  styleUrls: ['./step1-exp.component.scss']
})
export class Step1ExpComponent implements OnInit {

  @Output()
  setData = new EventEmitter<UserInterface>();

  private authService = inject(AuthService);
  private mainService = inject(MainService);
  private gtmService = inject(GtmService);

  formRegister: FormGroup;
  submitted = false;
  terms = false;
  data = false;
  user: UserInterface;
  previousDocumentNumber = '';
  dniError = null
  showModalEmailExists = false;

  selectOptions= [{
    value: '+1',
    name:'+1 USA',
    shortName: '+1'
  },
  {
    value: '+51',
    name:'+51 Perú',
    shortName: '+51'
  }]

  constructor(private router: Router, private utilService: UtilService) { }


  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.formRegister = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      secondName: new FormControl('', [Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      firstSurname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      secondSurname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      combinedPhone: new FormControl(''),
      dni: new FormControl(null, [Validators.required,
        Validators.pattern(/^[0-9]{8,8}$/), Validators.minLength(8), Validators.maxLength(8)]),
      phonePrefix: new FormControl('+1', [ Validators.required]),
      phoneNumber: new FormControl('', [ Validators.minLength(8) ,Validators.pattern(/^[0-9]+$/) ]),
    });
  }

  goBack(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  goToLogin(): void {
    this.router.navigate([PATHS.LOGIN]);
  }

  getMessage(input: string): string {
    if(!input) return ''

    const formControl = this.formRegister.get(input);
    if (formControl && !formControl.pristine && formControl.errors && formControl.errors['required']) {
      if (input === 'terms' || input === 'clauses') {
        return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
      }
      return "Campo requerido";
    }
  
    if (formControl && !formControl.pristine && formControl.errors && !formControl.errors.hasOwnProperty('required')) {  
      return this.utilService.getMessageErrorByErrorType(input, input === 'password' ? 'invalid' : Object.keys(formControl.errors)[0]);
    }

    if(input === 'phoneNumber' && formControl && formControl.errors){
      if(formControl.errors['pattern']) return this.utilService.getMessageErrorByErrorType(input, 'pattern');
      if(formControl.errors['minlength']) return this.utilService.getMessageErrorByErrorType(input, 'minlength');
    }

    return '';
  }

  getError(input: string): string {
    const formControl = this.formRegister.get(input);
    if (!!formControl && !formControl.pristine && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (input === 'repeatEmail' && formControl && !formControl.pristine && this.formRegister.errors?.['emailMatchError']) {
      return "error";
    }

    if (!!formControl && !formControl.pristine && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    if(input==='combinedPhone' && formControl && !formControl.pristine){
      return this.formRegister.get('phonePrefix').invalid && this.formRegister.get('phoneNumber').invalid ? 'error' : ''
    }

    return '';
  }

  acceptTerms(event) {
    this.terms = event.detail === null ? false : true;
  }

  acceptClauses(event) {
    this.data = event.detail !== null;
  }

  async sendData() {
    this.submitted = true;
    if (this.formRegister.invalid) {
      return;
    }

    if (!this.terms) {
      return;
    }

    this.user = {
      fields: {
        ...this.formRegister.value,
        terms: this.terms,
        data: this.data,
        country: null,
        state: null,
        address: null,
        combinedPhone: null
      },
      document_front: null,
      document_back: null
    };

    this.mainService.saveUserLog(this.user.fields).subscribe();

    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_Registro1_Continuar',
      pantalla: 'Registro1'
    };
    this.gtmService.sendEvent(event);

    this.setData.emit(this.user);
  }

  async findByEmail(email: string) {
    return new Promise((resolve, reject) => {
      this.mainService.existsUserByEmail(email).subscribe({
        next: ({ existsUser }) => {
          resolve(existsUser);
        },
        error: (err) => {
          reject(err);
        }
      });
    })
  }

  handleValueChange(event){
    const {selectValue, inputValue} = event.detail
    const phonePrefixCtrl = this.formRegister.get('phonePrefix')
    const phoneNumberCtrl = this.formRegister.get('phoneNumber')
       
    phonePrefixCtrl.setValue(selectValue)
    phoneNumberCtrl.setValue(inputValue)
    
    phonePrefixCtrl.updateValueAndValidity()
    phoneNumberCtrl.updateValueAndValidity()
  }

  validateUser(_): void {
    const documentNumber = this.formRegister.get('dni').value;
    this.dniError = null;
    if (documentNumber && documentNumber.length === 8 && this.previousDocumentNumber !== documentNumber) {

      this.previousDocumentNumber = documentNumber;
      this.authService.validateUser(documentNumber).subscribe({
        next: (res) => {
          if (res.existsUser) {
            this.dniError = 'error';
            this.formRegister.get('dni').setErrors({ 'userExists': true });
          }
        }
      });
    }
  }

  excludeLetters(event: KeyboardEvent){
    return !!event.key.match(/^[0-9]+$/)
  }
}
