<div class="register step1">
  <h2 class="main-title mb-0">Reg&iacute;strate en Mandadito</h2>
  <p class="to-login">
    <span class="pr-2">¿Ya te registraste?</span>
    <bcp-button shape="text" tier="primary" (ctrlClick)="goToLogin()">Inicia sesi&oacute;n</bcp-button>
  </p>
  <form class="form-main" [formGroup]="formRegister" (ngSubmit)="sendData()">
    <div class="inputElement">
      <bcp-input type="text" label="N&deg; de DNI peruano *" maxlength="8" required="true"
        placeholder="N&deg; de DNI peruano" [message]="getDniErrorMsg()"
        [state]="dniError === null ? getError('dni') : 'error'" bcpCtrlForm formControlName="dni"
        (keypress)="excludeLetters($event)">
      </bcp-input>
    </div>
    <div class="inputElement">
      <bcp-input type="text" label="Primer nombre *" required="true" placeholder="Ej. Juan" bcpCtrlForm
        [message]="getMessage('firstName')" [state]="getError('firstName')" formControlName="firstName"></bcp-input>
    </div>
    <div class="inputElement">
      <bcp-input type="text" label="Segundo nombre (solo si aplica)" placeholder="Ej. Ricardo" bcpCtrlForm
        [message]="getMessage('secondName')" [state]="getError('secondName')" formControlName="secondName"></bcp-input>
    </div>
    <div class="inputElement">
      <bcp-input type="text" label="Primer apellido *" required="true" placeholder="Ej. Perez" bcpCtrlForm
        [message]="getMessage('firstSurname')" [state]="getError('firstSurname')"
        formControlName="firstSurname"></bcp-input>
    </div>
    <div class="inputElement">
      <bcp-input type="text" label="Segundo apellido *" required="true" placeholder="Ej. Vega" bcpCtrlForm
        [message]="getMessage('secondSurname')" [state]="getError('secondSurname')"
        formControlName="secondSurname"></bcp-input>
    </div>
    <div class="inputElement">
      <bcp-input type="text" label="Dirección actual *" required="true" placeholder="Ej. 123 Spring Street" bcpCtrlForm
       [message]="getMessage('address')" [state]="getError('address')" formControlName="address"></bcp-input>
    </div>
    <div class="inputElement">
      <bcp-select-input
        [message]="formRegister.get('phoneNumber').valid ? 'Ingresa tu teléfono sin espacios ni guiones' : getMessage('phoneNumber')"
        [state]="getError('phoneNumber')" bcpCtrlForm [selectOptions]="selectOptions" label="Nº de teléfono"
        inputmode="tel" (ctrlChange)="handleValueChange($event)" placeholder="Nro de teléfono"></bcp-select-input>
    </div>

    <div class="inputElement">
      <bcp-input type="email" label="Correo electrónico *" required="true" bcpCtrlForm placeholder="ejemplo@correo.com"
        [message]="getMessage('email')" [state]="getError('email')" formControlName="email"></bcp-input>
    </div>

    <div class="inputElement">
      <bcp-input type="email" label="Repite tu correo electrónico *" required="true" bcpCtrlForm
        placeholder="ejemplo@correo.com"
        [message]="!formRegister.errors?.['emailMatchError'] ? 'Los correos deben coincidir' : getMessage('repeatEmail')"
        [state]="getError('repeatEmail')" formControlName="repeatEmail"></bcp-input>
      <span class="error-mismatch" *ngIf="formRegister.errors?.['emailMatchError'] && formRegister.get('repeatEmail').value
            && formRegister.get('repeatEmail').value.length > 0">
        Los correos no coinciden
      </span>
    </div>
    <div class="inputElement">
      <bcp-input [type]="inputType" label="Contraseña" required="true" bcpCtrlForm
        placeholder="Ingresa una contraseña segura" [message]="getMessage('password')" [state]="getError('password')"
        formControlName="password" icon-position="right">
        <bcp-icon [name]="eyeType" class="cursor-pointer" slot="icon" (click)="eventTriggered($event)"></bcp-icon>
      </bcp-input>

      <ul class="password-validators">
        <li><img
            src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastEightCharacters'])}}.svg"
            alt=""> <span>Mínimo 8 caracteres</span></li>
        <li><img
            src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneNumber'])}}.svg"
            alt=""> <span>Por lo menos un número</span></li>
        <li><img
            src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneUppercase'])}}.svg"
            alt=""> <span>Por lo menos una mayúscula</span></li>
        <li><img
            src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneSpecialCharacter'])}}.svg"
            alt=""> <span>Por lo menos un caracter especial #S/ %-.!</span></li>
      </ul>
    </div>

    <div class="terms">
      <div>
        <bcp-checkbox tier="secondary" [value]="terms" (ctrlChange)="acceptTerms($event)" size="lg">Estoy de acuerdo con
          los <a [href]="urlTermsConditionsPrivacyPolicy" target="_blank">Términos y condiciones y Política de
            privacidad*</a></bcp-checkbox>
        <span *ngIf="!terms && submitted" class="pl-3 d-inline-block error">Debe aceptar los términos y
          condiciones</span>
      </div>
      <div>
        <bcp-checkbox tier="secondary" [value]="data" (ctrlChange)="acceptClauses($event)" size="lg">Estoy de acuerdo
          con la <a
            href="https://staceu2mandp01.blob.core.windows.net/public-documents/clausulas-de-usos-adicionales-mandadito.pdf"
            rel="noopener" target="_blank">Cláusula de usos adicionales</a></bcp-checkbox>
      </div>
    </div>

    <div class="recaptcha">
      <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
    </div>

    <app-alert-message
      type="error"
      [msg]="msgInvalidForm"
      *ngIf="showAlert">
    </app-alert-message>

    <div class="button">
      <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (ctrlClick)="goBack()">
        <bcp-icon name="arrow-left-r"></bcp-icon>
        Volver
      </bcp-button>
      <bcp-button shape="rectangle"
        tier="primary"
        size="lg"
        full-width="true"
        type="submit">
        Continuar al paso 2
      </bcp-button>
    </div>
  </form>
</div>

<app-modal [size]="'sm'" *ngIf="showModalUserExists">
  <section class="error-modal">
    <div class="d-flex flex-column">
      <img width="147" class="mx-auto" src="/assets/images/girl-phone.svg" alt="registro usuario existe" />
      <h3 class="register__modal-title flexo-demi font-size-20 font-size-md-24 line-height-28 text-center color-primary_700">Ya estás registrado en
        <br/>Mandadito</h3>
      <p class="register__text flexo-regular font-size-14 font-size-md-16 line-height-20 text-center">Inicia sesi&oacute;n con tu correo electr&oacute;nico y
        <br/>contraseña</p>
      <div class="extra-button-size mx-auto">
        <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true"
          (ctrlClick)="goToLogin('Correo registrado​', 'Modal_correoregistrado_iniciar​')">Inicia
          sesión</bcp-button>
      </div>
    </div>
  </section>
</app-modal>

<app-modal [size]="'sm'" *ngIf="showModalRegisterInProcess">
  <section class="error-modal">
    <div class="d-flex flex-column">
      <img width="96" class="mx-auto" [src]="spotAlertHand" alt="registro usuario en proceso" />
      <h3 class="register__modal-title flexo-demi font-size-20 font-size-md-24 line-height-28 text-center color-primary_700">
        Ya tienes un registro en proceso
      </h3>
      <p class="register__text flexo-regular font-size-14 font-size-md-16 line-height-20 text-center">
        <span class="flexo-demi">Inicia sesi&oacute;n</span> con tu correo electr&oacute;nico y <br/>contraseña <span
          class="flexo-demi">para completar tu registro</span>
      </p>
      <div class="extra-button-size mx-auto">
        <bcp-button shape="rectangle" tier="primary" size="lg" class="register-incomplete__btn-action" full-width="true"
          (ctrlClick)="goToLogin('DNI registro incompleto', 'Modal_DNI_registro_iniciar​')">
          Iniciar sesi&oacute;n
        </bcp-button>
      </div>
    </div>
  </section>
</app-modal>

<app-modal *ngIf="showError">
  <section>
    <div class="d-flex flex-column">
      <img width="170" class="mx-auto" [src]="spotAlertGroup" alt="error">
      <h3 class="title mt-4">Ocurri&oacute; un problema</h3>
      <p class="subtitle">Por favor, intenta nuevamente.</p>
      <div class="button mt-4">
        <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (ctrlClick)="closeErrorModal()">De
          acuerdo</bcp-button>
      </div>
    </div>
  </section>
</app-modal>