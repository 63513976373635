<section class="bcp" id="relacion-bcp">
    <p class="hash-tag">
        #PeruanosTrabajandoParaPeruanos
    </p>
    <h2 class="text-center font-size-24 line-height-32 font-size-32 line-height-40">Con el respaldo del <img class="ml-1" src="/assets/images/landing/bcp_light.svg" alt="BCP logo light" width="92" height="24" /></h2>
    <p class="flexo-demi mx-auto mb-0 text-center font-size-16 font-size-md-24 line-height-24 line-height-md-32">asegurando que cada envío llegue a su destino</p>

    <ul>
        <li>
            <h4><img src="/assets/images/landing/c_ic_check_circle.svg" class="mr-1" alt="success" width="37" height="37"> Útil y sencillo</h4>
            <p class="m-0">Mucho más fácil que una compra en internet.</p>
        </li>
        <li>
            <h4><img src="/assets/images/landing/c_ic_clock_circle.svg" class="mr-1" alt="heart" width="37" height="37"> Envío 100% digital</h4>
            <p class="m-0">El dinero llega directamente a una cuenta dólares BCP.</p>
        </li>
        <li>
            <h4><img src="/assets/images/landing/c_ic_check_bell.svg" alt="clock" width="37" height="37"> Te mantenemos informado</h4>
            <p class="m-0">Te avisaremos sobre tu envío por WhatsApp y correo.</p>
        </li>
    </ul>
    <a class="d-flex align-items-center justify-content-center gap-2 mx-auto mt-4 mt-md-5" href="https://www.viabcp.com/mandadito" target="_blank" rel="noopener">
        <u class="mb-0 color-primary_700 font-size-14 font-size-md-16 flexo-demi">Encuéntranos en Vía</u>
        <img class="ml-2" src="/assets/images/landing/bcp-lg-container-dark.svg" width="78" height="31.65" alt="BCP">
    </a>
</section>
