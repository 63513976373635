import { ASSETS } from "src/app/core/constants";
import { ICarouselItem, ICarouselHeadItem } from "src/app/shared/models/model";

export const ItemsCarouselHead: ICarouselHeadItem[] = [
  {
    id: 1,
    imageLeft: ASSETS.SPOT_BANNER_GIFT,
    imageRight: '/assets/images/landing/chevron-right-blue.svg',
    title: '¡SORTEO por aniversario del 20/08 al 20/09!',
    hasModal: true,
  },
  {
    id: 2,
    imageLeft: '/assets/images/landing/spot_circle_b_spc_bills_dollars_d_l.svg',
    imageRight: '/assets/images/landing/chevron-right-orange.svg',
    title: '¡Manda hasta $970 en un solo envío!',
    hasModal: false,
  },
];

export const ItemsCarousel: ICarouselItem[] = [
    {
      image: '/assets/images/landing/carousel/step1.webp 480w, /assets/images/landing/carousel/step1-x2.webp 800w',
      images: {
        responsive: '/assets/images/landing/carousel/step1.webp',
        desktop: '/assets/images/landing/carousel/step1-x2.webp'
      },
      title: '1. Regístrate',
      text: '<p class="mb-1">Con tu Driver’s License americano o DNI peruano</p><p class="mb-2 color-primary-200">No es necesario ser cliente BCP</p>'
    },
    {
      image: '/assets/images/landing/carousel/step2.webp 480w, /assets/images/landing/carousel/step2-x2.webp 800w',
      images: {
        responsive: '/assets/images/landing/carousel/step2.webp',
        desktop: '/assets/images/landing/carousel/step2-x2.webp'
      },
      title: '2. Selecciona la moneda',
      text: '<p>Tu destinatario puede recibir su <span class="flexo-demi">Mandadito en soles o dólares</span></p>'
    },
    {
      image: '/assets/images/landing/carousel/step3.webp 480w, /assets/images/landing/carousel/step3-x2.webp 800w',
      images: {
        responsive: '/assets/images/landing/carousel/step3.webp',
        desktop: '/assets/images/landing/carousel/step3-x2.webp'
      },
      title: '3. Envía a una cuenta BCP',
      text: '<p>Ingresa la <span class="flexo-demi">cuenta BCP soles o dólares</span> del destintatario</p>'
    },
    {
      image: '/assets/images/landing/carousel/step4.webp 480w, /assets/images/landing/carousel/step4-x2.webp 800w',
      images: {
        responsive: '/assets/images/landing/carousel/step4.webp',
        desktop: '/assets/images/landing/carousel/step4-x2.webp'
      },
      title: '4. Paga tu Mandadito',
      text: '<p>Paga con tu <span class="flexo-demi">tarjeta de débito o crédito de USA</span> o cuenta PayPal de USA</p>'
    }
  ];

  export const TariffCards = [
    {
      title: '<div><span class="flexo-demi"> hasta 99.99</span></div>',
      tariff: '$ 3.99'
    },
    {
      title: '<div><span class="flexo-demi">100 a 199.99</span></div>',
      tariff: '$ 5.50'
    },
    {
      title: '<div><span class="flexo-demi">200 a 500.99</span></div>',
      tariff: '$ 7.50'
    },
    {
      title: '<div><span class="flexo-demi">501 a 970</span></div>',
      tariff: '$ 9.00'
    }
  ]

export const AnswerQuestions = [
  { title: '¿Solo está disponible en Florida?', text: 'Sí, por ahora solo procesamos envíos de Florida a Perú, pero esperamos poder expandir nuestro servicio pronto.'},
  
  { title: '¿Cuánto cobra Mandadito por envío?', text: `
    <span class="accordion">
    <p>¡El primer envío es GRATIS!</p>
    <p>Desde el segundo envío, Mandadito cobra una comisión variable según el monto solicitado. Siempre se te cobrará en dólares.</p>
    <div class="accordiontable">
      <table class="accordion">
          <tr>
            <th>Monto</th>
            <th>Comisión</th>
          </tr>
        <tr>
              <td>Menos de 100 USD</td>
              <td>3.99 USD</td>
            </tr>
            <tr>
              <td>100 - 199.99 USD</td>
              <td>5.50 USD</td>
            </tr>
            <tr>
              <td>200 - 500.99 USD</td>
              <td>7.50 USD</td>
            </tr>
              <tr>
              <td>501 - 970 USD</td>
              <td>9.00 USD</td>
          </tr>
        </table>
      <div>
    </span>
  ` },
    { title: '¿En cuánto tiempo llegará mi dinero?', text: `
    <span class="accordion">
    Contamos con same day delivery para Mandaditos solicitados antes de las 3 p.m. EST de lunes a viernes y los sábados hasta la 1 p.m. EST.
    </span>
  ` },
    { title: '¿Necesito ser cliente BCP para usar Mandadito?', text: `
      <span class="accordion">
      No, sólo el destinatario del Mandadito debe ser cliente del BCP. Para enviar dinero, debes pagar con tu tarjeta de débito o crédito, o cuenta PayPal de USA.
      </span>
  ` },
  ];