<div class="position-fixed logo">
    <img src="/assets/images/logo.svg" alt="">
</div>
<div class="center">
    <p>
        Hacemos que tu dinero llegue<br>
        <strong>rápido y seguro</strong> desde Florida<br>
        <strong>a todo el Perú</strong>
    </p>
    <img src="/assets/images/landing/illust-main-v-1.webp" alt="">
    <img class="bcp" src="/assets/images/logo-2-logo-dark.svg" alt="">
</div>
