import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRecoverType, AuthStatus, PATHS, UPDATE_DATA_STORAGE } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MainService } from 'src/app/core/services/main.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';

@Component({
  selector: 'app-update-data',
  templateUrl: './update-data.component.html',
  styleUrls: ['./update-data.component.scss']
})
export class UpdateDataComponent implements OnInit {

  steps = {
    data: false,
    documents: false,
    validated: false,
  };
  isActiveBoth = false;
  stepElements = [
    { value: '1', status: 'current', text: '' },
    { value: '2', status: '', text: '' },
  ];
  email: string;
  data: any;
  documentType:string
  authService = inject(AuthService);
  localStorageService = inject(LocalStorageService);
  router = inject(Router);
  formData = new FormData();
  mainService = inject(MainService)
  loaderService = inject(LoaderService)

  ngOnInit(): void {
    this.goToLoginIfNotStorage();
    this.getScreenByToken();
    this.email = this.localStorageService.getStoredCustomer().email
    this.getCustomerDocumentType()
  }

  completeStep(step: number) {
    return this.stepElements.map((element, index) => {
      element.status = '';
      if (index < step) element.status = 'completed';
      if (step === index) element.status = 'current';
      return element;
    });
  }

  private getScreenByToken(): void {
    const { recoverType } = this.localStorageService.getStorage('token');
    if (AuthRecoverType.justDni.toString() === recoverType) this.steps.documents = true;
    if (AuthRecoverType.justPersonalData.toString() === recoverType) this.steps.data = true;
    if (AuthRecoverType.dniAndPersonalData.toString() === recoverType) {
      this.isActiveBoth = true;
      this.steps.documents = true;
    }
    this.formData.append('updateType', recoverType);
  }

  private goToLoginIfNotStorage(): void {
    const updateData = this.localStorageService.getStorage(UPDATE_DATA_STORAGE);
    if (!updateData) this.router.navigate([PATHS.LOGIN]);
  }

  getDocuments(event): void {
    this.formData.append("document_front", event.document_front, 'document_front');
    this.formData.append("document_back", event.document_back, 'document_back');
    this.formData.append('docTypeloaded', event.docTypeloaded);
    if (this.isActiveBoth) {
      this.steps.documents = false;
      this.steps.data = true;
      this.stepElements = [
        { value: '1', status: 'completed', text: '' },
        { value: '2', status: 'current', text: '' },
      ];
      return;
    }
    this.sendData(this.formData);
  }

  getData(event) {
    this.formData.append("firstName", event.firstName);
    this.formData.append("secondName", event.secondName);
    this.formData.append("firstLastname", event.firstLastname);
    this.formData.append("secondLastname", event.secondLastname);
    this.formData.append("documentNumber", event.documentNumber);
    this.sendData(this.formData);
  }

  sendData(data): void {
    this.authService.update(data).subscribe({
      next: (_) => {
        this.steps = {
          data: false,
          documents: false,
          validated: true,
        };

        let token = this.localStorageService.getStorage('token');
        if (token) {
          this.authService.status = AuthStatus.Reviewing;
          token.loginStatus = AuthStatus.Reviewing;
          this.localStorageService.setStorage('token', token);
          this.localStorageService.removeStorage(UPDATE_DATA_STORAGE);
          this.localStorageService.removeStorage('customer');
        }
      }
    });

  }

  comeBack(_): void {
    this.steps.data = false;
    this.steps.documents = true;
    this.stepElements = [
      { value: '1', status: 'current', text: '' },
      { value: '2', status: '', text: '' },
    ];
  }

  getCustomerDocumentType() {
    this.loaderService.requestStarted()
    this.mainService.GetCustomer().subscribe({
      next: (res) => {
        this.documentType = res.docTypeloaded
        this.loaderService.requestEnded()
      },
      error: (err) => {
        this.loaderService.requestEnded()
      }
    })
  }

}
