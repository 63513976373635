import { Component, Input, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { PATHS } from 'src/app/core/constants';
import { OPERATIONS, UpdateDataService } from 'src/app/core/interfaces';
import { DataService } from 'src/app/core/services/data.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MainService } from 'src/app/core/services/main.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() activeBackground = false;
  isMenuOpened = false;
  showModal = false;
  PATHS = PATHS;
  letterMenu = '';
  userName = '';
  private dataService = inject(DataService);
  private mainService = inject(MainService);
  private localStorageService = inject(LocalStorageService)

  @Output()
  toggleFullscreenMenu = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    this.getUserData();
    this.GetCustomer();
  }

  getUserData(): void {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data && data.operation && data.operation === OPERATIONS.USAR_DATA) {
          this.getLetterByStorage(data.data);
        }
      }
    });
  }

  GetCustomer() {
    const customer = this.localStorageService.getStoredCustomer()
    if(customer){
      this.dataService.updateDataWithOperation({ operation: OPERATIONS.USAR_DATA, data: customer});
      this.getLetterByStorage(customer);
    }else{
      this.mainService.GetCustomer().subscribe({
        next: (data) => {
          this.dataService.updateDataWithOperation({ operation: OPERATIONS.USAR_DATA, data: data});
          this.getLetterByStorage(data);
          this.localStorageService.storeCustomer(data)
        },
        error: _ => {},
      });
    }
  }

  getLetterByStorage(data): void {
    this.letterMenu = data?.firstName?.substr(0,1).toUpperCase();
    this.userName = data ? `${data.firstName} ${data.firstLastname}` : 'Usuario';
  }

  toggleMenu(): void {
    if (window.innerWidth < 768) {
      this.toggleFullscreenMenu.emit()
      return
    }
    this.isMenuOpened = !this.isMenuOpened;
  }

  clickedOutside(): void {
    this.isMenuOpened = false;
  }

}
