import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, finalize } from 'rxjs';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { Router } from '@angular/router';
import { PATHS } from '../constants';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {

  private loaderInterceptor = inject(LoaderService);
  private urlExceptions = ['users/Authenticate', 'users/ValidateUser', 'users/RegisterEmail'];
  private urlNoLoader = ['people/GetCustomer', 'people/GetReceivers',
  'operations/ValidateUserOperations', 'operations/LogOperation', 'users/IsWithinAccessTime'];

  private router = inject(Router)
  private authService = inject(AuthService);
  private localStorageService = inject(LocalStorageService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let currentRoute = this.router.url

    let withHeader = true;
    this.urlExceptions.forEach(url => {
      if (request.url.includes(url)) {
        withHeader = false;
      }
    });

    let loader = true;
    this.urlNoLoader.forEach(url => {
      if (request.url.includes(url)) {
        loader = false;
      }
    });

    // We allow the loader in all pages except the login and landing
    loader = loader && ![PATHS.LOGIN, PATHS.LANDING].includes(currentRoute)

    if (loader) this.loaderInterceptor.requestStarted();

    const token = this.localStorageService.getStorage('token') || JSON.parse('{}');
    const modifiedReq = request.clone(withHeader ? {
      headers: request.headers.set('Authorization', `Bearer ${token.idToken}`),
    } : {});

    return next.handle(modifiedReq).pipe(
      catchError(async(err) => {
        if (err.error?.code === 401) {
          await this.reAuthenticate();
        }
        throw err;
      }),
      finalize(() => {
        if (loader) this.loaderInterceptor.requestEnded();
      })
    );
  }

  async reAuthenticate() {
      return await this.authService.refreshToken();
  }
}
