import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Favorite } from 'src/app/core/interfaces';
import { Currency, ICustomerData } from '../../models/model';

@Component({
  selector: 'app-favorite-card',
  templateUrl: './favorite-card.component.html',
  styleUrls: ['./favorite-card.component.scss']
})
export class FavoriteCardComponent implements OnInit{

  @Input()
  elements: Favorite[] = null;

  @Input()
  customer: ICustomerData

  @Input()
  currency: string

  @Output()
  elementEmitter = new EventEmitter();

  CurrencyTypes = Currency;

  filteredFavorites = new Array<Favorite>();

  ngOnInit(): void {
    this.filterAccountsByCurrency(this.currency)
  }

  selectFavorite(favorite: Favorite) {
    this.elementEmitter.emit(favorite)
  }

  maskAccountNumber(accountNumber:string): string {
    let maskAccount = '***************';
    if (accountNumber) {
      maskAccount = `*** ******* ${accountNumber.substring(11,12)} ${accountNumber.substring(12,14)}`;
    }

    return maskAccount;
  }

  filterAccountsByCurrency(currency: string) {
    this.filteredFavorites = this.elements.filter(favorite => favorite.accountCurrency === currency)
  }
}
