import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { UserInterface } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { DocumentTypes } from 'src/app/shared/models/model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  private changeDetectorRef = inject(ChangeDetectorRef)

  @Input() isActiveBoth = false;
  @Input() documentType:string 
  @Output() documentsEvent = new EventEmitter();

  selectedDocumentType = '';

  documents = {
    front: null,
    back:  null
  };
  showGeneralError = false;
  documentTypes = DocumentTypes

  authService = inject(AuthService);

  ngOnInit(): void {
  }

  setDocumentByType(type: string, event): void {
    this.documents[type] = event;
    this.changeDetectorRef.detectChanges();
  }

  sendData(): void {
    this.showGeneralError = false;
    if (this.documents.front?.status !== 'success' || this.documents.back?.status !== 'success') {
      return;
    }

    if (this.documents.front === null || this.documents.back === null) {
      this.showGeneralError = true;
      return;
    }

    const data: UserInterface = {
      fields: null,
      document_front: this.documents.front,
      document_back: this.documents.back,
      docTypeloaded: this.selectedDocumentType
    };

    this.documentsEvent.emit(data);

  }

  get canContinue() {
    return this.documents.front?.status !== 'success' || this.documents.back?.status !== 'success'
  }
}
