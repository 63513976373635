import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';

import { API_CODES, MandaditoStatus, ModalTypes, PATHS } from 'src/app/core/constants';
import { GtmEvent, MandaditoQueryParams, Modal } from 'src/app/core/interfaces';
import { MainService } from 'src/app/core/services/main.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { HostListener } from '@angular/core';
import { GtmService } from 'src/app/core/services/gtm.service';
import { Currency } from 'src/app/shared/models/model';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  @Input()
  operation;
  @Output()
  backEvent = new EventEmitter();

  isDesktop:boolean;

  private mainService = inject(MainService)
  private loaderService = inject(LoaderService)
  private router = inject(Router)
  private gtmService = inject(GtmService)

  mandaditoStatus = MandaditoStatus
  currencyTypes = Currency

  customerEmail: string
  modal: Modal

  ngOnInit(): void {
    this.getCustomerEmail()
    this.onResize()
  }

  formatDate(date, isDesktop): string | string[] {
    if (!date) return ''

    const utcDate = new Date(date)
    utcDate.setHours(utcDate.getHours() - (this.isDST(utcDate) ? 4 : 5));
    const estDateString = utcDate.toISOString()

    try {
      const split = estDateString.split('T')[0].split('-');
      const time = estDateString.split('T')[1]
      const formatedTime = this.formatTime(time)
      return isDesktop ? `${split[2]}/${split[1]}/${split[0]} - ${formatedTime} EST`: [`${split[2]}/${split[1]}/${split[0]}`, `${formatedTime} EST`];
    } catch (error) {
      return '';
    }


  }
  private formatTime(time: string): string {
    time = time.slice(0, time.length - 1)
    let split = time.split(':')
    let hour = parseInt(split[0])
    let minutes = split[1]

    return `${hour > 12 ? (hour - 12) : hour}:${minutes} ${hour >= 12 && hour < 24 ? 'p.m.' : 'a.m.'}`
  }

  private isDST(date: Date) {
    const year = date.getUTCFullYear();
    const march = new Date(Date.UTC(year, 2, 8));
    const november = new Date(Date.UTC(year, 10, 1));
    march.setUTCDate(14 - (march.getUTCDay() + 13) % 7);
    november.setUTCDate(7 - (november.getUTCDay() + 6) % 7);
    return date >= march && date < november;
  }

  getCustomerEmail() {
    this.mainService.GetCustomer().subscribe(customer => this.customerEmail = customer.email)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isDesktop = window.innerWidth >= 768
  }

  repeatOperation() {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Repetir_operacion',
      pantalla: 'Detalle mandadito',
    }
    this.gtmService.sendEvent(gtmEvent)
    this.validateOperation()
  }

  validateOperation() {

    const mandaditoQueryParams: MandaditoQueryParams = {
      receiverId: this.operation.receiverId,
      amount: this.operation.requestedAmount,
      origin: 'Historico'
    }

    this.loaderService.requestStarted()
    this.mainService.getValidationUserOperations(this.operation.amount).subscribe({
      next: (res) => {
        this.router.navigate([PATHS.NEW_MANDADITO], { queryParams: mandaditoQueryParams })
      },
      error: (err) => {
        this.loaderService.requestEnded()
        const code = err.error?.errors[0]?.code;
        if (code === API_CODES.API_OPER_ERROR_MAX_QUANTITY_MONTH) {
          this.modal = {
            name: ModalTypes.MAX_MONTH,
            isRepeatingMandadito: true,
          };
        } else if (code === API_CODES.API_OPER_ERROR_MAX_AMOUNT_DAY) {
          this.router.navigate([PATHS.NEW_MANDADITO], { queryParams: mandaditoQueryParams })
        } else if (code === API_CODES.API_OPER_ERROR_ACCESS_TIME) {
          this.modal = {
            name: ModalTypes.ACCESS_TIME,
            isRepeatingMandadito: true
          }
        }
      }
    })
  }

  closeModal() {
    this.modal = { name: '' };
  }
}
