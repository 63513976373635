<div class="register">
    <h2 class="main-title">Registra tus datos personales</h2>
    <form class="form-main" [formGroup]="formRegister" (ngSubmit)="sendData()">        
        <div class="inputElement">
            <bcp-input type="text" label="Primer nombre *" required="true" placeholder="Ej. Juan" bcpCtrlForm
            [message]="getMessage('firstName')" [state]="getError('firstName')" formControlName="firstName"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Segundo nombre (solo si aplica)" placeholder="Ej. Ricardo" bcpCtrlForm
            [message]="getMessage('secondName')" [state]="getError('secondName')" formControlName="secondName"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Primer apellido *" required="true" placeholder="Ej. Perez" bcpCtrlForm 
            [message]="getMessage('firstSurname')" [state]="getError('firstSurname')" formControlName="firstSurname"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Segundo apellido *" required="true" placeholder="Ej. Vega" bcpCtrlForm
            [message]="getMessage('secondSurname')" [state]="getError('secondSurname')" formControlName="secondSurname"></bcp-input>
        </div>

        <div class="inputElement">
            <bcp-input type="text" label="Nº de DNI peruano *" (keyup)="validateUser($event)" maxlength="8" required="true" placeholder="Nº de DNI peruano vigente"
                [message]="this.formRegister.get('dni').errors?.['userExists'] ? '': 'Debes ingresar 8 dígitos'" [state]="dniError === null ? getError('dni') : 'error'" bcpCtrlForm formControlName="dni" (keypress)="excludeLetters($event)">
            </bcp-input>
            <span class="error-mismatch" *ngIf="formRegister.get('dni').value 
            && formRegister.get('dni').value.length === 8 && 
            formRegister.get('dni').errors?.['userExists']">
                El DNI ya se encuentra registrado
            </span>
        </div>
        
            <div class="inputElement">
                <bcp-select-input 
                [message]="formRegister.get('phoneNumber').valid ? 'Ej. +1 567 890 3302' : getMessage('phoneNumber')" 
                [state]="formRegister.get('phoneNumber').invalid ? 'error' : ''" 
                bcpCtrlForm 
                [selectOptions]="selectOptions" 
                label="Nº de teléfono" 
                inputmode="tel"
                (ctrlChange)="handleValueChange($event)"
                placeholder="Nro de telefono"
                ></bcp-select-input>
            </div>
        <!-- <div class="inputElement">
            <bcp-input type="email" label="Correo electrónico *" required="true" bcpCtrlForm placeholder="ejemplo@correo.com"
            [message]="getMessage('email')" [state]="getError('email')" formControlName="email"></bcp-input>
        </div>

        <div class="inputElement">
            <bcp-input type="email" label="Repite tu correo electrónico *" required="true" bcpCtrlForm placeholder="ejemplo@correo.com"
            [message]="!formRegister.errors?.['emailMatchError'] ? 'Los correos deben coincidir' : getMessage('repeatEmail')" [state]="getError('repeatEmail')" formControlName="repeatEmail"></bcp-input>
            <span class="error-mismatch" *ngIf="formRegister.errors?.['emailMatchError'] && formRegister.get('repeatEmail').value 
            && formRegister.get('repeatEmail').value.length > 0">
                Los correos no coinciden
            </span>
        </div>
        <div class="inputElement">
            <bcp-input [type]="inputType" label="Contraseña" required="true" bcpCtrlForm placeholder="Ingresa una contraseña segura"
            [message]="getMessage('password')" [state]="getError('password')" formControlName="password" icon-position="right">
                <bcp-icon [name]="eyeType" class="cursor-pointer" slot="icon" (click)="eventTriggered($event)"></bcp-icon>
            </bcp-input>

            <ul class="password-validators">
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastEightCharacters'])}}.svg" alt=""> <span>Mínimo 8 caracteres</span></li>
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneNumber'])}}.svg" alt=""> <span>Por lo menos un número</span></li>
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneUppercase'])}}.svg" alt=""> <span>Por lo menos una mayúscula</span></li>
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneSpecialCharacter'])}}.svg" alt=""> <span>Por lo menos un caracter especial #S/ %-.!</span></li>
            </ul>
        </div>
 -->
        <div class="terms">
            <div>
                <bcp-checkbox tier="secondary" [value]="terms"
                (ctrlChange)="acceptTerms($event)" size="lg">Estoy de acuerdo con los <a href="https://staceu2mandp01.blob.core.windows.net/public-documents/11.09BCPMandadito-TOS(00367517-16)+PP.pdf" rel="noopener" target="_blank">Términos y condiciones y Política de privacidad*</a></bcp-checkbox>
                <span *ngIf="!terms && submitted" class="pl-3 d-inline-block error">Debe aceptar los términos y condiciones</span>
            </div>
            <div>
                <bcp-checkbox tier="secondary" [value]="data"
                (ctrlChange)="acceptClauses($event)" size="lg">Estoy de acuerdo con la <a href="https://staceu2mandp01.blob.core.windows.net/public-documents/clausulas-de-usos-adicionales-mandadito.pdf" rel="noopener" target="_blank">Cláusula de usos adicionales</a></bcp-checkbox>
            </div>
        </div>

        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit" [disabled]="formRegister.invalid || !this.terms">Continuar</bcp-button>
        </div>
        <p class="to-login d-flex justify-content-center align-items-center">
            <span class="pr-2">¿Ya te registraste?</span>
            <bcp-button shape="text" tier="primary" (click)="goToLogin()">Inicia sesión</bcp-button>
        </p>
    </form>
</div>

<app-modal [size]="'sm'" *ngIf="showModalEmailExists">
    <section class="error-modal">
    <div class="d-flex flex-column">
        <img width="147" class="mx-auto" src="/assets/images/girl-phone.svg" alt="pig" />
        <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">Ya estás registrado en Mandadito</h3>
        <p class="mt-3 flexo-regular font-size-14 line-height-20 text-center">Ingresa a tu cuenta y sigue enviando dinero a tus seres queridos</p>
        
        <div class="extra-button-size mx-auto mt-3">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToLogin()">Inicia sesión</bcp-button>
        </div>
    </div>
    </section>
</app-modal>
