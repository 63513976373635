import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ILocationApi } from '../interfaces';
import { LocationApi } from 'src/app/views/new-mandadito/new-mandadito.data';
import { IP_PROVIDER } from '../constants';

const LOCATIONS_ALLOWED = [
  {
    country: 'US',
    regions: ['FL', 'Florida']
  },
  /*{
    country: 'CO',
    regions: ['*']
  },
  {
    country: 'PE',
    regions: ['*']
  },*/
];

const WHITE_LIST_IPS = environment.trustedIps;

@Injectable({
  providedIn: 'root'
})
export class LocationApiService {

  async getLocationApi(provider: string): Promise<ILocationApi> {
    
    // If environment is not production dont fetch ip APIs and return as if its an allowed location
    if (!environment.production){
      let locationResponse = LocationApi
      locationResponse.isLocationAllowed = true
      locationResponse.regionCode = 'dev'
      locationResponse.countryCode = 'dev'
      locationResponse.ip = 'dev'
      locationResponse.city = 'dev'
      locationResponse.region = 'dev'
      locationResponse.countryCodeIso = 'dev'
      locationResponse.provider = provider
      return locationResponse
    }

    try {
      let ipProviderUrl = provider === 'ipApi' ? environment.ipApi : environment.ipStack

      const response = await fetch(ipProviderUrl);
      if (response?.ok) {
        const location = await response.json();
        const regionCode = location?.region_code;
        const countryCode = location?.country_code;
        const ip = location?.ip;
        const city = location?.city;
        const region = provider === IP_PROVIDER.IP_STACK ? location?.region_name : location?.region;
        const countryCodeIso = location?.country_code_iso3;
        let isLocationAllowed = false;

        if (WHITE_LIST_IPS.includes(ip)) {
          isLocationAllowed = true;
        } else {
          isLocationAllowed = !!LOCATIONS_ALLOWED.find(x => x.country === countryCode && (x.regions.includes(regionCode) || x.regions.includes('*')));
        }

        return {
          isLocationAllowed,
          regionCode,
          countryCode,
          ip,
          city,
          region,
          countryCodeIso,
          provider
        };
      }

      return null;
    } catch (error) {
      console.log({ error })
      return null;
    }

  }
}
