<div class="d-flex" [ngClass]="{'content-payment': !activeHome, 'position-relative': activeHome, 'reset-top': activeHome, 'd-none': !showPanel}">
    <div class="box" [ngClass]="{'border-shadow': activeHome}">
        <div class="mx-auto mb-4 pb-3 pb-md-4 mb-md-5 step-bar" *ngIf="!activeHome">
            <app-steps [elements]="stepElements"></app-steps>
        </div>

        <!-- Using display:none to hide components isn't good practice, many issues come with doing that (performance, losing the ability to initialize data on component render, too many changes to detect, etc.), use *ngIf directive to handle component rendering -->
        <app-amount [activeHome]="activeHome" [ngClass]="{'d-none': !steps.AMOUNT}" [data]="data" [userData]="userData" [promotion]="promotion" [currentMaxDailyToSend]="maxDailyToSend" [maxAmountPerMandadito]="maxAmountPerMandadito" (continue)="chooseReceiver($event)" *ngIf="!loading && userData" [presetAmount]="presetAmount" [presetReceiver]="this.data?.receiver" [isRepeatingMandadito]="isRepeatingMandadito" (onSelectedCurrency)="resetReceiver()" [commissionTable]="commissionTable"></app-amount>

        <app-favorite [activeHome]="activeHome" [data]="data" [userData]="userData" [favorites]="favorites" (backStep)="goToStep($event)" (nextStep)="goToResume($event)" [ngClass]="{'d-none': !steps.FAVORITE}" *ngIf="favorites && userData && steps.FAVORITE"></app-favorite>
        <app-receiver [activeHome]="activeHome" [data]="data" [user]="userData" (backStep)="goToStep($event)" (nextStep)="goToResume($event)" [ngClass]="{'d-none': !steps.RECEIVER}" *ngIf="steps.RECEIVER"></app-receiver>

        <app-resume (backStep)="goToStep($event)" [step]="getResumeBackStep" [userData]="userData" [currentMaxDailyToSend]="maxDailyToSend"
            (nextStep)="goToPaypal($event)" [data]="data" [ngClass]="{'d-none': !steps.RESUME}" [maxAmountPerMandadito]="maxAmountPerMandadito" [maxAmountPerDay]="maxAmountDay"></app-resume>
        <app-paypal (backStep)="goToStep($event)" (completed)="registerNewMandadito($event)" [data]="data" *ngIf="steps.PAYPAL" [maxAmountPerDay]="maxAmountDay"></app-paypal>

</div>

<app-shared-modals [customer]="userData" [modal]="modal"></app-shared-modals>
