import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';

type InputyType = 'text' | 'number' | 'email' | 'password';

@Component({
  selector: 'app-md-input',
  templateUrl: './md-input.component.html',
  styleUrls: ['./md-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdInputComponent),
      multi: true,
    }
  ]
})
export class MdInputComponent<T> extends ControlValueAccessorDirective<T> {

  @Input() properties: Properties;
  @Input() submitted: boolean;

}

interface Properties {
  inputId: string;
  label: string;
  type: InputyType;
  maxlength?: string;
  placeholder?: string;
  customErrorMessage?: string;
}
