<h2>Campaña cero comisión</h2>
<div>
    <p>
        Promoción vigente del 15 de agosto del 2023 al 31 de diciembre del 2024. Esta Promoción es desarrollada por Mandadito, una iniciativa del Banco de Crédito del Perú (“BCP”) habilitada por BCP Miami.
    </p>

    <p>
        Participan las personas naturales de nacionalidad peruana que, durante la vigencia de la Promoción: (i) se encuentren físicamente en el estado de Florida en Estados Unidos de América y (ii) soliciten su primer envío, en adelante, los “Usuarios”.
    </p>

    <p>
        Beneficio: Descuento del 100% de la comisión a los Usuarios que cumplan con todos los requisitos del segundo párrafo. Máximo un (1) Beneficio por Usuario.
    </p>

    <p>
        Si el Usuario ya tiene una operación pendiente, aprobada o rechazada en la plataforma de Mandadito, previo a la vigencia de la Promoción, no podrá ser acreedor del Beneficio.
    </p>

    <p>
        El Beneficio se aplicará directamente al momento de usar la plataforma y se reflejará en las pantallas de resumen antes de hacer el pago.
    </p>

    <p>
        El BCP podrá, previa comunicación a los Usuarios y sin responsabilidad alguna, modificar alguno de los términos establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o el cambio sea producto de una obligación legal.
    </p>

    <p>
        Para más información sobre la Promoción y/o restricciones, puede llamar a +51 945 657 689 o ingresar a www. mandaditobcp.com.pe
    </p>
</div>
