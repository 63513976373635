<div class="register">
    <h2 class="main-title">Crea tu nueva contraseña</h2>
    <form class="form-main" [formGroup]="formPassword" (ngSubmit)="sendData()">
        <div class="inputElement">
            <bcp-input [type]="eyeType.password.type" label="Contraseña" required="true" bcpCtrlForm placeholder="Ingresa una contraseña segura"
            [message]="getMessage('password')" [state]="getError('password')" formControlName="password" icon-position="right">
                <bcp-icon [name]="eyeType.password.icon" class="cursor-pointer" slot="icon" (click)="eventTriggered('password')"></bcp-icon>
            </bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input [type]="eyeType.repeatPassword.type" label="Contraseña" required="true" bcpCtrlForm placeholder="Ingresa una contraseña segura"
            [message]="getMessage('repeatPassword')" [state]="getError('repeatPassword')" formControlName="repeatPassword" icon-position="right">
                <bcp-icon [name]="eyeType.repeatPassword.icon" class="cursor-pointer" slot="icon" (click)="eventTriggered('repeatPassword')"></bcp-icon>
            </bcp-input>
            <span class="error-mismatch" *ngIf="formPassword.errors?.['passwordMatchError'] && formPassword.get('repeatPassword').value 
            && formPassword.get('repeatPassword').value.length > 0">
                Las contraseñas no coinciden
            </span>
        </div>

        <ul class="password-validators">
            <li><img src="/assets/images/check-{{getImageByCondition(formPassword.get('password').errors?.['atLeastEightCharacters'])}}.svg" alt=""> <span>Mínimo 8 caracteres</span></li>
            <li><img src="/assets/images/check-{{getImageByCondition(formPassword.get('password').errors?.['atLeastOneNumber'])}}.svg" alt=""> <span>Por lo menos un número</span></li>
            <li><img src="/assets/images/check-{{getImageByCondition(formPassword.get('password').errors?.['atLeastOneUppercase'])}}.svg" alt=""> <span>Por lo menos una mayúscula</span></li>
            <li><img src="/assets/images/check-{{getImageByCondition(formPassword.get('password').errors?.['atLeastOneSpecialCharacter'])}}.svg" alt=""> <span>Por lo menos un caracter especial #S/ %-.!</span></li>
        </ul>


        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit">Continuar</bcp-button>
        </div>
        
    </form>
</div>