<div class="files mt-4">
    <app-upload-file [data]="images.front" (documentEvent)="setImageDocument('front', $event)" [selectedDocument]="selectedDocument"></app-upload-file>
    <app-upload-file [data]="images.back" (documentEvent)="setImageDocument('back', $event)" [selectedDocument]="selectedDocument"></app-upload-file>
</div>

<div class="informative" *ngIf="showDocumentRequirements">
    <div class="header">
        <img src="assets/images/info-blue.svg" alt="Info">
        <div class="text">Las fotos deben cumplir lo siguiente</div>
    </div>
    <ul>
        <li>
            <ng-container *ngIf="selectedDocument === documentTypes.DNI"> DNI con <strong>fecha vigente</strong></ng-container>
            <ng-container *ngIf="selectedDocument === documentTypes.DriverLicense"><strong> Driver's License emitida en USA</strong></ng-container>
        </li>
        <li>
            Las fotos <strong>no deben estar borrosas</strong>
        </li>
        <li>
            <ng-container *ngIf="selectedDocument === documentTypes.DNI"> En la foto <strong> el DNI debe estar completo</strong>, no cortado</ng-container>
            <ng-container *ngIf="selectedDocument === documentTypes.DriverLicense"> En la foto tu <strong>Driver's License debe estar completo, no cortada.</strong></ng-container>
        </li>
    </ul>
</div>
