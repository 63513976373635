<div class="menu" *ngIf="isMenuOpened">
    <div class="close-button    ">
        <img src="assets/images/close.svg" (click)="closeMenuPanel()">
    </div>
    <div class="header">
        <div class="name">{{ letterMenu }}</div>
        <p class="flexo-demi font-size-16">{{ userName }}</p>
    </div>
    <div *ngIf="showOptions" class="items">
        <div  class="menu-item" (click)="goTo(PATHS.NEW_MANDADITO, 'new')">
            <div class="flex-center">
                <bcp-icon name="bill-r" size="24" color="primary-300"></bcp-icon>
                <span class="flexo-demi">Nuevo mandadito</span>
            </div>
            <bcp-icon name="angle-right-r" size="16" color="onsurface-600"></bcp-icon>
        </div>
        <div class="menu-item" (click)="goTo(PATHS.MY_DATA, 'profile')">
            <div class="flex-center">
                <bcp-icon name="man-circle-r" size="24" color="primary-300"></bcp-icon>
                <span class="flexo-demi">Mis datos</span>
            </div>
            <bcp-icon name="angle-right-r" size="16" color="onsurface-600"></bcp-icon>
        </div>
        <div class="menu-item" (click)="goTo(PATHS.HISTORICAL, 'historical')">
            <div class="flex-center">
                <bcp-icon name="list-r" size="24" color="primary-300"></bcp-icon>
                <span class="flexo-demi">Historial</span>
            </div>
            <bcp-icon name="angle-right-r" size="16" color="onsurface-600"></bcp-icon>
        </div>
        <div class="menu-item" (click)="goTo(PATHS.REFERRALS, 'referrals')">
            <div class="flex-center">
                <img src="/assets/images/m_ic_megaphone.svg" alt="referrals" loading="eager" width="24">
                <span class="flexo-demi">Refiere y gana</span>
            </div>
            <bcp-icon name="angle-right-r" size="16" color="onsurface-600"></bcp-icon>
        </div>
        <div>
            <p class="item-title">¿Tienes consultas?</p>
            <div class="menu-item" (click)="openWhatsapp()">
                <div class="flex-center">
                    <img src="assets/images/ic_social_whatsapp-blue.svg">
                    <span class="flexo-demi">Contáctanos</span>
                </div>
                <bcp-icon name="angle-right-r" size="16" color="onsurface-600"></bcp-icon>
            </div>
        </div>
    </div>
    <div class="footer">
        <bcp-button shape="text" tier="primary" size="lg" (click)="modalCloseSession()">
            <bcp-icon name="sign-out-r" size="24" color="secondary-500"></bcp-icon>
            Cerrar sesión
        </bcp-button>
    </div>
</div>

<app-modal [size]="'sm'" *ngIf="showModal">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="/assets/images/group-21.svg" alt="pig" />
            <h3 class="mt-4 flexo-bold font-size-20 line-height-28 text-center color-primary_700">¿Estás seguro que
                quieres<br />cerrar tu sesión?</h3>
            <p class="mt-3 flexo-regular font-size-14 line-height-20 text-center">No se guardarán los datos de tu
                Mandadito y se cerrará tu sesión.</p>

            <div class="extra-button-size mx-auto mt-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="closeSession()">Cerrar
                    sesión</bcp-button>
            </div>
            <div class="mt-3 text-center">
                <bcp-button shape="text" tier="primary" (click)="showModal = false">Volver</bcp-button>
            </div>
        </div>
    </section>
</app-modal>
