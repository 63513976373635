<div class="paypal">

    <h3>Paga con PayPal o tu tarjeta de USA</h3>
    <div class="paypal-content">
        <h4 class="amount-to-pay">$ {{ data.operation?.total | number:'1.2-2' }}</h4>
        <p class="amount-to-pay-label">Total a pagar</p>
        <div class="charges">
            <img src="/assets/images/info-blue.svg" alt="info">
            <p>Recuerda que la tarjeta o la cuenta de PayPal debe estar con el nombre que te registraste en Mandadito</p>
        </div>
        <div class="paypal" [ngClass]="{'d-none': !showPaypal}">
            <div #paypal></div>
            <div class="error text-center mt-3" *ngIf="error.show" [innerHTML]="error.message"></div>
        </div>
        <div class="text-center mx-auto button" [ngClass]="{'d-none': showPaypal}">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="validateUser()">Continuar el pago</bcp-button>
        </div>
        <p class="disclaimer">Tu banco podría aplicar cargos adicionales cuando pagues con tu tarjeta</p>

        <div class="button mt-4">
            <bcp-button class="mt-2" shape="rectangle" tier="secondary"  size="lg" full-width="true" (click)="backToResume()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
                Volver
            </bcp-button>
        </div>

        <p class="help">¿Necesitas ayuda? <a [href]="whatsappURL" target="_blank">Contáctanos</a></p>

    </div>
</div>

<app-shared-modals [modal]="modal" [screen]="'Envío paso 4'"></app-shared-modals>
