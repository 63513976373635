import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-history-header',
  template: `
  <ul class="align-items-center mt-1 mt-md-0 mb-2 position-relative">
        <li class="flexo-demi font-size-20 line-height-28 font-size-24 line-height-3">Historial de envíos</li>
        <li class="font-size-12">
            <bcp-button shape="text" tier="primary" (click)="goToHistory()">
                Ver todos
            </bcp-button>
        </li>
    </ul>`,
  styles: [`
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    li {
        &:first-child {
            color: #002a8d;
        }
    }
}`]
})
export class HistoryHeaderComponent {

  private router = inject(Router);
  private gtmService = inject(GtmService)

  goToHistory(): void {

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Home_VerTodos',
      pantalla: 'Home'
    } 

    this.gtmService.sendEvent(gtmEvent);
    this.router.navigate([PATHS.HISTORICAL]);
  }

}
