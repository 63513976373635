import { Component, OnInit, inject } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActiveCampaingsBreadcrumb, PeruGourmetBreadcrumb, TermsAndConditionsBreadcrumb } from '../../breadcrumbData';

@Component({
  selector: 'app-peru-gourmet',
  templateUrl: './peru-gourmet.component.html',
  styleUrls: ['./peru-gourmet.component.scss']
})
export class PeruGourmetComponent implements OnInit {

  private breadcrumbService = inject(BreadcrumbService)

  constructor() { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      ActiveCampaingsBreadcrumb,
      PeruGourmetBreadcrumb
    ])
  }

}
