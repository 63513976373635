import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-limit',
  templateUrl: './limit.component.html',
  styleUrls: ['./limit.component.scss']
})
export class LimitComponent implements OnInit {

  private gtmService = inject(GtmService);
  private router = inject(Router);
  private utilService = inject(UtilService);
  private localStorageService = inject(LocalStorageService);
  leftTime: string|boolean = '';

  ngOnInit(): void {
    this.getTimeLimit();

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Login_3intentos',
      pantalla: '3 intentos​',
    }

    this.gtmService.sendEvent(gtmEvent)
  }

getTimeLimit(): void {
    const currentDate = new Date().getTime();
    const limitTime = +(this.localStorageService.getStorage('limitLoginTime')) || 0;

    let distance = ((limitTime + 1800000) - currentDate );
    if (distance <= 1000 || limitTime === 0) {
      this.localStorageService.removeStorage('limitLoginTime');
      this.router.navigate([PATHS.LOGIN]);
    }

    let timerInterval = setInterval(() => {
      this.leftTime = this.utilService.getTimer(distance);
      distance = distance - 1000;
      if (this.leftTime === false) {
        clearInterval(timerInterval);
        this.router.navigate([PATHS.LOGIN]);
        return;
      }

    }, 1000);

  }

  goToRecoverPassword(): void {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Login_3intentos_restablece',
      pantalla: '3 intentos',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.router.navigate([PATHS.RECOVER]);
  }

  goToRegister(): void {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Login_3intentos_registrate',
      pantalla: '3 intentos',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.router.navigate([PATHS.REGISTER]);
  }


}
