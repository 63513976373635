<app-loader></app-loader>
<router-outlet></router-outlet>
<app-modal *ngIf="showModalTimeSession">
    <section>
        <div>
            <img src="/assets/images/sleeping-piggy.svg" width="138" class="d-block mx-auto" alt="sesión vencida">
            <h3 class="mt-4 flexo-demi font-size-20 font-size-md-24 line-height-28 line-height-md-32 text-center color-primary_700 break-word pl-24 pr-24">Se ha cerrado la sesión</h3>
            <p class="mt-3 font-size-14 text-center font-size-16 line-height-20 line-height-24 text-center break-word pl-24 pr-20 line-height-20">Para seguir usando Mandadito inicia sesión nuevamente</p>
            <div class="mt-3 px-3 text-center d-flex flex-column font-size-16">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToLogin()">Iniciar sesión de nuevo</bcp-button>
            </div>
        </div>
    </section>
</app-modal>
<app-modal *ngIf="showModalTimerSession === true && leftTime !== null">
    <section>
        <div>
            <img src="/assets/images/man-cellphone.svg" class="d-block mx-auto" alt="Sesión abierta">
            <h3 class="mt-4 flexo-demi font-size-20 font-size-md-24 line-height-28 line-height-md-32 text-center color-primary_700 break-word pl-24 pr-24">Tienes una sesión abierta en otra ventana</h3>
            <p class="mt-3 font-size-14 font-size-16 line-height-20 line-height-24 text-center break-word pl-24 pr-20 line-height-20"><span class="flexo-demi">Cierra sesión y actualiza esta ventana</span> o espera para continuar aquí.</p>
            <div class="mt-3 px-3 text-center d-flex flex-column font-size-16">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" [disabled]="true">Continuar aquí en {{ leftTime }}</bcp-button>
                <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goBack()">Conoce más sobre Mandadito</bcp-button>
            </div>
        </div>
    </section>
</app-modal>