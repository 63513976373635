import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit{

  private router = inject(Router);
  private gtmService = inject(GtmService)

  ngOnInit(): void {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Respuestas incorrectas',
      pantalla: 'Respuestas incorrectas',
    }

    this.gtmService.sendEvent(gtmEvent)
  }

  goToLogin() {
    this.router.navigate([PATHS.LOGIN]);
  }

  goToHome() {
    this.router.navigate([PATHS.LANDING]);
  }

  sendGtmEvent(){
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Contraseña_contacto',
      pantalla: 'Respuestas incorrectas',
    }

    this.gtmService.sendEvent(gtmEvent)
  }

}
