import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-exceeded',
  templateUrl: './exceeded.component.html',
  styleUrls: ['./exceeded.component.scss']
})
export class ExceededComponent {

  private router = inject(Router);
  private gtmService = inject(GtmService);

  goToRegister(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Login_4intentos_registrate',
      pantalla: '4intentos',
    };
    this.gtmService.sendEvent(event);

    this.router.navigate([PATHS.REGISTER]);
  }

  sendGtmEvent(){
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Login_4correo',
      pantalla: '4intentos',
    };
    this.gtmService.sendEvent(event);
  }
}
