import { ModalTypes } from "src/app/core/constants";
import { DataMandadito, OperationMandadito } from "src/app/core/interfaces";

export const  ModalMaxToday = {
    name: ModalTypes.MAX_TODAY,
    hasOperationsCompleted: false,
    gtm: {
      event: 'virtualEvent',
      accion: 'Unhappy_MaxDiario',
      pantalla: 'envioPaso1'
    },
    maxAmountMandadito: 0
  };

export const StepElements = [
    { value: '1', status: 'current', text: 'Monto' },
    { value: '2', status: '', text: 'Receptor' },
    { value: '3', status: '', text: 'Revisión' },
    { value: '4', status: '', text: 'Pago' }];

export const StepElementsVariation = [
  { value: '1', status: 'current', text: 'Monto' },
  { value: '2', status: '', text: 'Revisión' },
  { value: '3', status: '', text: 'Pago' }]

export const Steps = {
    AMOUNT: true,
    RECEIVER: false,
    FAVORITE: false,
    RESUME: false,
    PAYPAL: false
  };

export const  Data: DataMandadito = {
    receiver: null,
    operation: null,
    indexSelected: null,
    isFavorite: false,
    checkFavorite: false,
    autoremittance: false,
    extendedSchedule: false,
  };


export const OperationData: OperationMandadito = {
  amount: null,
  commission: null,
  realCommission: null,
  discount: null,
  promotion: null,
  total: null,
  autoremittance: false,
  isFavorite: false,
  origin: '',
  currency:'',
}

export const LocationApi = {
    isLocationAllowed: null,
    regionCode: null,
    countryCode: null,
    ip: null,
    city: null,
    region: null,
    countryCodeIso: null,
    provider:null
  };

  export const TutorialSteps = [{
    step: 1, title: 'Historial de envíos',
    message: `Visualiza todos tus Mandaditos pasados haciendo click en “ver todos”. También podrás consultar el estado y detalle de cada envío.`,
    button: 'Siguiente'
  },{
    step: 2, title: 'Editar y enviar',
    message: `Vuelve a enviar rápidamente haciendo click en “Editar y enviar”. Puedes mandar el mismo monto o editarlo.`,
    button: 'Entendido'
  }];
