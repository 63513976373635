import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { API_CODES, CustomerStatus, ModalTypes, PATHS } from 'src/app/core/constants';
import { DataMandadito, GtmEvent, Modal, OPERATIONS } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { MainService } from 'src/app/core/services/main.service';
import { whatsappURL } from './paypal.component-extras';
import { ScriptService } from 'src/app/core/services/script.service';
import { DataService } from 'src/app/core/services/data.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';

declare var paypal;

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements AfterViewInit, OnDestroy {

  whatsappURL = whatsappURL

  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  @Input() data: DataMandadito;
  @Input() maxAmountPerDay: number;

  @Output()
  backStep = new EventEmitter<string>();

  @Output()
  completed = new EventEmitter<Object>();

  paidFor = false;
  error = {
    show: false,
    message: 'Hubo un problema con tu pago'
  };
  showPaypal = true;

  timeoutButtonId = null;
  keepTimeSession;
  modal: Modal;

  modalTypes = ModalTypes
  paypalTries = 0;

  private mainService = inject(MainService);
  private router = inject(Router);
  private gtmService = inject(GtmService);
  private scriptService = inject(ScriptService);
  private dataService = inject(DataService);
  private loaderService = inject(LoaderService);

  ngAfterViewInit(): void {
    this.initializePaypal();
    this.initializeTimer();
    this.keepSessionActive('disabled', 0)
  }

  ngOnDestroy(): void {
    clearInterval(this.keepTimeSession);
  }

  keepSessionActive(status: string, time) {
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.ADD_TIME_LIMIT_SESSION, data: { status, time } });
  }

  initializePaypal(): void {
    this.loaderService.requestStarted();
    try {
      paypal
      .Buttons({
        createOrder: (data, actions) => {
          const { paymentSource } = data;
          clearTimeout(this.timeoutButtonId);
          this.initializeTimer(300000);
          const gtmEvent: GtmEvent = {
            event: 'virtualEvent',
            accion: `clic_Envio4_PayPal_${ paymentSource === 'paypal' ? 'PayPal' : 'Tarjetas' }`,
            pantalla: 'EnvioPaso4',
          };
          this.gtmService.sendEvent(gtmEvent);
          return actions.order.create({
            purchase_units: [
              {
                description: 'Mandadito',
                amount: {
                  'currency_code': 'USD',
                  value: (Math.round(this.data.operation?.total * 100) / 100).toFixed(2)
                }
              }
            ]
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          this.paidFor = true;
          
          !this.data.testTransaction && this.validatePaypalOrigin(order);
          this.completed.emit({data, order});
        },
        onError: err => {
          this.showErrorPaypal();
        }
      }).render(this.paypalElement.nativeElement)
      this.loaderService.requestEnded();
    } catch (error) {
      this.paypalTries++;
      if (this.paypalTries > 3) {
        this.modal = { name: ModalTypes.RELOAD };
        this.loaderService.requestEnded();
      } else {
        this.scriptService.load('paypal').then(_ => {
          setTimeout(() => {
            this.initializePaypal();
          }, 1000);
        }).catch(error => console.log(error));
      }
    }
  }

  private showErrorPaypal(): void {
    this.error.show = true;
    setTimeout(() => {
      this.error.show = false;
    }, 5000);
  }

  private validatePaypalOrigin(order): boolean {
    if (order.links[0]?.href.includes('sandbox')) {
        this.showErrorPaypal();
        this.modal = { name: this.modalTypes.PROBLEM };
        throw new Error('Paypal sandbox');
    }
    return true;
  }

  initializeTimer(time = 20000): void {
    this.timeoutButtonId = setTimeout(() => {
      this.showPaypal = false;
    }, time);
  }

  triggerLoadPaypalScript(): void {
    this.scriptService.load('paypal').then(_ => {
      this.initializePaypal();
    }).catch(error => console.log(error));
  }

  async validateUser(){
      this.loaderService.requestStarted();
      const { code } = await this.getValidationUserOperations();
      const customerData = await lastValueFrom(this.mainService.GetCustomer())
      this.loaderService.requestEnded();

      if (code === API_CODES.API_OPER_ERROR_EXPIRED_SESSION) { //AGREGANDO TIEMPO DE SESION PARA EL BUG
        this.showPaypal=false
        this.modal = { name:this.modalTypes.TIMESESSION };
        return;
      }

      if (code === API_CODES.API_OPER_ERROR_MAX_QUANTITY_MONTH) {
        this.showPaypal=false
        this.modal = { name: this.modalTypes.MAX_MONTH, hasOperationsCompleted: customerData.hasOperationsCompleted };
        return;
      }

      if(code === API_CODES.API_OPER_DEPLOY_MAINTENANCE){
        this.router.navigate([PATHS.DEPLOYMENT])
        return
      }

      if (code === API_CODES.API_OPER_ERROR_MAX_AMOUNT_DAY) {
        this.showPaypal=false
        this.modal = { name: this.modalTypes.MAX_TODAY, hasOperationsCompleted: customerData.hasOperationsCompleted, maxAmountMandadito: this.maxAmountPerDay};
        return;
      }

      if (code === API_CODES.API_OPER_ERROR_ACCESS_TIME) {
        this.showPaypal=false
        this.modal = { name:this.modalTypes.ACCESS_TIME };
        return;
      }

      if(customerData.customerState === CustomerStatus.REJECTED.toString()){
        this.showPaypal=false
        this.modal = {name:this.modalTypes.REJECTED, userEmail: customerData.email}
        return
      }

      if(customerData.customerState === CustomerStatus.RECOVERY.toString()){
        this.showPaypal=false
        this.modal = {name:this.modalTypes.RECOVERY, userEmail: customerData.email}
        return
      }

      this.showPaypal = true;
      this.keepSessionActive('disabled', 0);
      this.initializeTimer();
  }

  goToHistory(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  closeSession(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  getValidationUserOperations(): Promise<any> {
    return new Promise((resolve, _) => {
      this.mainService.getValidationUserOperations(this.data.operation.amount).subscribe({
        next: () => {
          resolve({code: API_CODES.OK});
        },
        error: err => {
          console.log(err.error);
          resolve({ code: err.error.errors[0].code });
        }
      });
    });

  }

  backToResume(): void {
    this.keepSessionActive('enabled', 0);
    this.backStep.emit('RESUME');
  }

}
