<h2 class="main-title">
    {{ isActiveBoth ? 'Datos Personales' : 'Necesitamos verificar tu identidad' }}
</h2>
<p class="sub-title" *ngIf="!isActiveBoth">
    Los datos que ingresaste no coincidían con tu DNI. Por tu seguridad vuelve a ingresarlos.
</p>
<form class="form-main" [formGroup]="formData" (ngSubmit)="sendData()">

    <div class="d-flex mx-auto justify-content-center flex-column">

        <div class="inputElement">
            <bcp-input type="text" label="Primer nombre *" required="true" placeholder="Ej. Juan" bcpCtrlForm
                [message]="getMessage('firstName')" [state]="getError('firstName')"
                formControlName="firstName"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Segundo nombre (solo si aplica)" placeholder="Ej. Ricardo" bcpCtrlForm
                [message]="getMessage('secondName')" [state]="getError('secondName')"
                formControlName="secondName"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Primer apellido *" required="true" placeholder="Ej. Perez" bcpCtrlForm
                [message]="getMessage('firstLastname')" [state]="getError('firstLastname')"
                formControlName="firstLastname"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Segundo apellido *" required="true" placeholder="Ej. Vega" bcpCtrlForm
                [message]="getMessage('secondLastname')" [state]="getError('secondLastname')"
                formControlName="secondLastname"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Nº de DNI peruano *" required="true" placeholder="N° de DNI peruano vigente"
                maxlength="8"
                [message]="documentNumberMessage"
                [state]="dniError === null ? getError('documentNumber') : 'error'" bcpCtrlForm
                (keypress)="excludeLetters($event)" inputmode="numeric" formControlName="documentNumber">
            </bcp-input>
        </div>
    </div>

    <div class="button-group mt-4">
        <bcp-button shape="rectangle" tier="secondary" size="lg" type="button" full-width="true" (click)="comeBack()"
            *ngIf="isActiveBoth" class="mt-2">
            <bcp-icon name="arrow-left-r" slot="start"></bcp-icon>
            Volver
        </bcp-button>
        <bcp-button shape="rectangle" tier="primary" size="lg" type="button" full-width="true" (click)="sendData()"
            [disabled]="formData.invalid">
            Continuar
        </bcp-button>
    </div>
</form>

<p class="sub-title d-flex align-items-center justify-content-center mx-auto mt-4 mb-0"><span class="pr-2">¿Tienes
        dudas? </span>
    <a href="https://wa.link/ny1jx2" rel="noopener" target="_blank">
        <bcp-button shape="text" tier="primary" size="lg">
            Escríbenos
        </bcp-button>
    </a>
</p>
