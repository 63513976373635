import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-completed-update',
  templateUrl: './completed-update.component.html',
  styleUrls: ['./completed-update.component.scss']
})
export class CompletedUpdateComponent {

  @Input() email: string;

  private router = inject(Router);
  private authService = inject(AuthService);

  goToLanding(): void {
    this.authService.logout()
    this.router.navigate([PATHS.LANDING]);
  }

}
