import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-password-updated',
  templateUrl: './password-updated.component.html',
  styleUrls: ['./password-updated.component.scss']
})
export class PasswordUpdatedComponent {

  private router = inject(Router);
  private gtmService = inject(GtmService)

  goToLogin() {

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Mandar_post_contraseña',
      pantalla: 'Contraseña actualizada',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.router.navigate([PATHS.LOGIN]);
  }

  goToHome() {
    this.router.navigate([PATHS.LANDING]);
  }

}
