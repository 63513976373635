import { AfterContentInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import jwt_decode from "jwt-decode";
import { DataService } from 'src/app/core/services/data.service';
import { GtmEvent, OPERATIONS, UpdateDataService } from 'src/app/core/interfaces';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import * as confetti from 'canvas-confetti';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Currency, ICustomerData } from 'src/app/shared/models/model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-success',
  templateUrl: './request-success.component.html',
  styleUrls: ['./request-success.component.scss']
})
export class RequestSuccessComponent implements OnInit, AfterContentInit, OnDestroy {

  private router = inject(Router);
  private dataService = inject(DataService);
  private authService = inject(AuthService);
  private gtmService = inject(GtmService);
  private storageService = inject(LocalStorageService);

  subscriptionDataService: Subscription;
  email: string;
  info = {
    requestedAmount: '',
    operationCode: '',
    extendedSchedule: false,
    currency: '',
    commission:'' ,
    coupon: ''
  };
  showBannerShareLink = false;
  showBannerReferred = false;
  isModalShareLink = false;
  customer: ICustomerData;
  exchangeRate: number;
  isMenuOpened = false;

  Currency = Currency

  renderer2 = inject(Renderer2);
  elementRef = inject(ElementRef);
  localStorageService = inject(LocalStorageService);

  urlBlob = environment.urlBaseBlob;

  ngOnInit(): void {
    this.getEmailByToken();
    this.initializeData();
    this.getCustomerDataFromStorage();
    this.localStorageService.setStorage('goToHome', true);
    this.getExchangeRate(); 
  }

  ngAfterContentInit(): void {
    this.triggerConfetti();
  }

  triggerConfetti(): void {
    const canvas = this.renderer2.createElement('canvas');

    this.renderer2.appendChild(this.elementRef.nativeElement, canvas);

    const myConfetti = confetti.create(canvas, {
      resize: true
    });

    myConfetti();

    setTimeout(() => {
      this.renderer2.removeChild(this.elementRef.nativeElement, canvas);
    }, 4000);
  }

  ngOnDestroy(): void {
    const data = this.storageService.getStoredCustomer();
    delete data.exchangeRateFinal;
    this.storageService.deletedStoredCustomer();
    this.storageService.storeCustomer({...data});
    this.subscriptionDataService?.unsubscribe();
  }

  getEmailByToken(): void {
    try {
      const { idToken } = this.localStorageService.getStorage('token');
      const decoded: any = jwt_decode(idToken);
      this.email = decoded.name;
    } catch (error) {
      this.closeSession();
    }
  }

  initializeData(): void {
    this.subscriptionDataService = this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        this.processOperation(data);
      },
    });
  }

  processOperation(data: UpdateDataService): void {
    if (data && data.operation === OPERATIONS.MANDADITO_SUCCESS) {
      if (data.success) {
        const couponAmount = (data.data.coupon && data.data.coupon.couponType === 'Monto' ? data.data.coupon.amount : 0);
        this.info = {
          requestedAmount: (data.data.amount).toFixed(2),
          operationCode: data.data.operationCode,
          extendedSchedule: data.extendedSchedule,
          currency: data.data.currency,
          commission: data.data.commission.toFixed(2),
          coupon: couponAmount.toFixed(2),
        };

        const gtmEvent: GtmEvent = {
          event: 'virtualEvent',
          accion: 'Solicitud_exitosa',
          pantalla: 'SolicitudExitosa',
        };
        this.gtmService.sendEvent(gtmEvent);
      }
    }
  }

  closeSession() {
    this.authService.logout();
    this.router.navigate([PATHS.LANDING]);
  }

  goToNewMandadito() {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  getCustomerDataFromStorage(): void {
    this.customer = this.storageService.getStorage('customer') || {};
    let { hasOperationsValid, referralCodeUsed } = this.customer;
    if (referralCodeUsed === undefined) referralCodeUsed = '';
    this.showBannerShareLink = !hasOperationsValid && referralCodeUsed.length === 0;
    this.showBannerReferred = !hasOperationsValid && referralCodeUsed.length > 0;
    this.updateCustomerStorage();
  }

  updateCustomerStorage(): void {
    const customer: ICustomerData = this.storageService.getStoredCustomer();
    customer.hasOperationsValid = true;
    this.storageService.setStorage('customer', customer);
  }

  showModalShareLink(): void {

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Ref_Compartir_solicitud',
      pantalla: 'Solicitud exitosa',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.isModalShareLink = true;
  }

  closeModalShareLink(event: boolean): void {
    this.isModalShareLink = event;
  }

  getExchangeRate() {
    if (this.storageService.getStoredCustomer().exchangeRateFinal) {
      this.exchangeRate = this.storageService.getStoredCustomer().exchangeRateFinal
    } else {
      this.exchangeRate = this.storageService.getStoredCustomer().exchangeRate
    }
  }

  toFloat(number:string): number {
    return parseFloat(number)
  }

  toggleFullscreenMenu(){
    this.isMenuOpened = !this.isMenuOpened;
  }
}
