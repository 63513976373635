import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0',
        visibility: 'hidden'
      })),
      state('final', style({
        overflow: 'hidden'
      })),
      transition('initial<=>final', animate('250ms'))
    ]),
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(90deg)'})),
      state('rotated', style({ transform: 'rotate(-90deg)'})),
      transition('default <=> rotated', animate('250ms'))
    ])
  ]
})
export class AccordionItemComponent {

  @Input() title: string;
  @Input() active = false;
  @Input() index: number;
  @Input() color = 'primary_300';
  @Output() toggleAccordion: EventEmitter<boolean> = new EventEmitter();

  onClick(event) {
    event.preventDefault();
    this.toggleAccordion.emit(this.active);
  }

}
