<div class="register">
    <h2 class="main-title">Crea tus preguntas de seguridad</h2>
    <p class="description">Usaremos estas preguntas para validar tu identidad en caso olvides tu contraseña</p>
    <form class="form-main questions" [formGroup]="formRegister" (ngSubmit)="sendData()">

        <p class="paragraph-primary">Primera pregunta</p>

        <div class="inputElement mt-3">
            <bcp-select [message]="getMessage('firstQuestion')" [state]="getError('firstQuestion')" bcpCtrlForm formControlName="firstQuestion">
                <bcp-select-header slot="header" label="Primera pregunta">
                </bcp-select-header>
                <bcp-select-option *ngFor="let question of questions" [value]="question">{{ question }}</bcp-select-option>
                
            </bcp-select>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Respuesta *" required="true" placeholder="Ej. Azul" bcpCtrlForm
            message="Recuerda que la usarás en caso olvides tu contraseña" [state]="getError('firstAnswer')" formControlName="firstAnswer"></bcp-input>
        </div>

        <p class="paragraph-primary mt-4">Segunda pregunta</p>
        <div class="inputElement">
            <bcp-select [message]="getMessage('secondQuestion')" [state]="getError('secondQuestion')" bcpCtrlForm formControlName="secondQuestion">
                <bcp-select-header slot="header" label="Segunda pregunta">
                </bcp-select-header>
                <bcp-select-option *ngFor="let question of questions" [value]="question">{{ question }}</bcp-select-option>
            </bcp-select>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Respuesta *" required="true" placeholder="Ej. Azul" bcpCtrlForm
            message="Recuerda que la usarás en caso olvides tu contraseña" [state]="getError('secondAnswer')" formControlName="secondAnswer"></bcp-input>
        </div>
        <span class="error d-block text-center" *ngIf="formRegister.errors?.['inputNotEqualError'] && formRegister.get('firstQuestion').value?.length > 0
            && formRegister.get('secondQuestion').value?.length > 0">
            Las preguntas no pueden ser iguales
        </span>
        <div class="button">
            <bcp-button id="button-customer-register" shape="rectangle" tier="primary" size="lg" full-width="true" type="submit" [disabled]="formRegister.invalid">Continuar al paso {{ stepNumber }}</bcp-button>
        </div>
    </form>
</div>
