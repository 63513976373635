<bcp-button shape="text" tier="primary" (click)="goToLanding()">
    <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
    Volver
</bcp-button>


<div class="register">
    <ng-container *ngIf="step===1">
        <img src="/assets/images/piggy-gratis.svg" class="piggy-gratis">
        <h2 class="title">Bienvenido a Mandadito</h2>
        <p class="description">Regístrate y haz tu primer envío GRATIS</p>
        
    </ng-container>
    <ng-container *ngIf="step===2">
        <img src="/assets/images/illust_scan-document.svg" class="scan-dni">
        <h3 class="title mb-0 mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700">Toma una foto de tu Driver’s license o DNI peruano vigente</h3>
        <p class="description">Aseg&uacute;rate de tomar una foto por cada lado del documento</p>
    </ng-container>

    <div>
        <bcp-dots [disabledClick]="true" length="2" id="dots"></bcp-dots>
        <div class="button">
            <bcp-button size="lg" full-width="true" shape="rectangle" (click)="step=== 1 ? nextStep(): goToStep1()">{{step === 1 ? 'Continuar':'Regístrate'}}</bcp-button>
        </div>
        <p class="signup">¿Ya te registraste? <span class="link" (click)="goToLogin()">Inicia sesión</span></p>
    </div>

</div>
