<bcp-button shape="text" tier="primary" (click)="backEvent.emit()">
    <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
    Volver
</bcp-button>

<div class="detail">
    <h3>Detalle de Mandadito</h3>
    <h4>$ {{ operation?.amount | number:'1.2-2' }}</h4>
    <p>Total</p>

    <div class="button" *ngIf="operation?.status === mandaditoStatus.Sent">
        <bcp-button size="lg" full-width="true" (click)="repeatOperation()">
            <bcp-icon name="turn-r" size="20"></bcp-icon>
            Repetir operación
        </bcp-button>
    </div>

    <div class="status-message" *ngIf="operation?.status !== mandaditoStatus.Sent" [ngClass]=" {'in-progress': operation?.status === 'En proceso','rejected': operation?.status === 'Rechazado'
      }">
        <ng-container *ngIf="operation?.status === mandaditoStatus.InProgress" >
            <bcp-icon name="info-r" size="20" color="primary"></bcp-icon>
            <span><span class="flexo-demi">Tu mandadito está en proceso.</span> Les avisaremos cuando el dinero llegue a la cuenta del destinatario, máximo en 24 horas.</span>
        </ng-container>
        <ng-container *ngIf="operation?.status === mandaditoStatus.Rejected" >
            <bcp-icon name="exclamation-triangle-r" size="20" color="error"></bcp-icon>
            <span>Tu Mandadito fue rechazado. Te enviamos un correo a <strong>{{customerEmail}}</strong> con el motivo.</span>
        </ng-container>
    </div>
    <ul>
        <li>
            <p>Fecha de solicitud</p>
            <div>
                <p *ngIf="isDesktop">{{formatDate(operation?.createdAt, isDesktop)}}</p>
                <p *ngIf="!isDesktop">{{formatDate(operation?.createdAt, isDesktop)[0]}}<br>{{formatDate(operation?.createdAt, isDesktop)[1]}}</p>
            </div>
        </li>
        <li class="receiver">
            <p>Destinatario</p>
            <div>
                <p>{{ operation?.receiverFirstName }} {{ operation?.receiverSecondName }}  {{ operation?.receiverLastname }} {{ operation?.receiverSecondLastname }}</p>
                <p>Cuenta {{ operation?.currency === 'PEN' ? 'soles': 'dólares' }} BCP</p>
                <p>{{ operation?.receiverAccount }}</p>
            </div>
        </li>
        <li *ngIf="operation?.currency === 'PEN'">
            <p>Monto solicitado</p>
            <div>
                <p>$ {{ (operation?.amount - operation?.commission) | number:'1.2-2' }}</p>
            </div>
        </li>

        <li class="receiver">
            <p>Monto enviado</p>
            <div>
                <p>{{currencyTypes[operation?.currency]?.symbol}} {{((operation?.amount - operation?.commission) * (operation?.currency === currencyTypes.PEN.code ? operation?.exchangeRate : 1)) | number:'1.2-2'}}</p>
                <p *ngIf="operation?.currency === currencyTypes.PEN.code">1 USD = {{ operation?.exchangeRate }} PEN</p>
            </div>
        </li>
        <li>
            <p>Comisión</p>
            <div>
                <p>$ {{ operation?.commission | number:'1.2-2' }}</p>
            </div>
        </li>
        <li>
            <p>Número de operación</p>
            <div>
                <p>{{ operation?.codeOperation }}</p>
            </div>
        </li>

        <li *ngIf="operation?.status === mandaditoStatus.Sent">
            <p>Recibido</p>
            <div>
                <p *ngIf="isDesktop">{{formatDate(operation?.sendToReceiverAt, isDesktop)}}</p>
                <p *ngIf="!isDesktop">{{formatDate(operation?.sendToReceiverAt, isDesktop)[0]}}<br>{{formatDate(operation?.sendToReceiverAt, isDesktop)[1]}}</p>
            </div>
        </li>
    </ul>
</div>


<app-shared-modals [modal]="modal" (closeModalEvent)="closeModal()"></app-shared-modals>
