<h3 class="main-title">
    Sorteo Cero Costo Envío
</h3>

<p>
Promoción vigente del 20 agosto al 20 de setiembre del 2024. Esta Promoción es desarrollada por Mandadito, una iniciativa del Banco de Crédito del Perú (“BCP”) habilitada por BCP Miami.
</p>

<p>
Participan las personas naturales de nacionalidad peruana que se hayan registrado en Mandaditos con su DNI y/o licencia de conducir emitida por Estados Unidos de América (“U.S.A.”), y durante la vigencia de la Promoción: (i) residan físicamente en el estado de Florida en U.S.A.; y, (ii) realicen, como mínimo, un (1) envío exitoso en Mandadito de US$50.00 (cincuenta con 00/100 dólares americanos) o más (en adelante, el “Participante”).
</p>

<p>
Un “Envío exitoso” es aquel que es aprobado por Mandadito y cumple con las especificaciones descritas en este párrafo.
</p>

<p>
Premio: un (01) envío gratis mensual (sin cobro de comisión) hasta el 31 de diciembre de 2024. El Premio no es reembolsable, ni transferible. Stock: tres (03) Premio.
</p>

<p>
Por cada envío que el Participante realice en el periodo de vigencia de la Promoción, acumulará una opción para participar en el Sorteo. El Participante podrá hacer un máximo de cuatro (4) envíos al mes siguiendo los parámetros de Mandadito y; por ende, tendrá como máximo ocho (8) opciones para participar en el sorteo.
</p>

<p>
Si previo al periodo de vigencia de la Promoción, el usuario ya ha hecho un envío exitoso en Mandadito, este envío no podrá ser considerado para el Sorteo.
</p>

<p>
El Sorteo se realizará el 25 de setiembre del 2024. Y se informará el 27 de setiembre de 2024, a través de las Redes Sociales, Whatsapp y Correo de Mandadito, los tres (03) Participantes ganadores.
</p>

<p>
La entrega del Premio se realizará, vía correo electrónico, entre el 27 y  30 de setiembre del 2024, posterior al contacto y confirmación del Participante ganador.
</p>

<p>
En caso el BCP no obtuviera respuesta del Participante ganador hasta el segundo día calendario contados desde el primer contacto, o si por algún otro hecho imputable al ganador no pudiera realizarse la entrega del Premio, perderá su derecho a recibirlo sin opción a reclamo. En tales casos, el BCP se reserva el derecho de decidir libremente el destino del Premio, incluyendo la posibilidad de realizar un nuevo sorteo.
</p>

<p>
El Participante ganador autoriza a Mandadito a publicar sus datos en la página web <a href="http://www.mandaditobcp.com.pe">http://www.mandaditobcp.com.pe</a> y/o a través de otros canales oficiales de Mandadito como redes sociales, con la finalidad de hacer de conocimiento público los resultados del Sorteo. Los datos que se publicarán son los utilizados por el Participante para la Promoción.
</p>

<p>
El BCP no se responsabiliza por la idoneidad de los productos o servicios que componen el Premio, ni por cualquier daño o perjuicio que pudiera derivarse del uso de estos.
</p>

<p>
El BCP podrá, previa comunicación a los Participantes y sin responsabilidad alguna, modificar alguno de los términos establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o sea producto de alguna obligación legal.
</p>

<p>
Para más información sobre la promoción y/o restricciones, puede comunicarse con Mandadidto por Whatsapp al +51 945 657 689 ingresar a <a href="http://www.mandaditobcp.com.pe">http://www.mandaditobcp.com.pe</a>.
</p>