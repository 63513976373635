<div class="register">
    <ng-container>
        <img [src]="spotImgScanDocument" class="register__spot-img">
        <h3 class="title mb-0 mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700">Completa tu registro para empezar a usar Mandadito</h3>
        <p class="register__description flexo-regular font-size-14 font-size-md-16 line-height-20 text-center color-primary">Aprovecha tu primer env&iacute;o GRATIS</p>
    </ng-container>
    <div class="text-center">
        <div class="register__btn">
            <bcp-button size="lg" full-width="true" shape="rectangle"
                (ctrlClick)="completeRegister()">Completar mi registro</bcp-button>
        </div>
        <div class="register__btn register__btn-back">
            <bcp-button size="lg" full-width="true" shape="text"
                (ctrlClick)="logout()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>
    </div>
</div>
