import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-slide-touch',
  templateUrl: './slide-touch.component.html',
  styleUrls: ['./slide-touch.component.scss']
})
export class SlideTouchComponent implements AfterViewChecked {

  @ViewChild('slider', { static: false }) slider: ElementRef;
  @Input() elements;
  @Output() emitData = new EventEmitter();

  private gtmService = inject(GtmService);

  isDragging = false;
  startPos = 0;
  currentTranslate = 0;
  prevTranslate = 0;
  animationID = 0;
  currentIndex = 0;
  slideWith = null;

  isClassGrabbing = false;

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.slideWith = this.slider.nativeElement.clientWidth;
    }, 0);
  }

  touchStart(index, event) {
    this.currentIndex = index;
    this.startPos = this.getPositionX(event);
    this.isDragging = true;

    // https://css-tricks.com/using-requestanimationframe/
    this.animationID = requestAnimationFrame(this.animation);
    this.isClassGrabbing = true;

  }

  touchEnd() {
    this.isDragging = false;
    cancelAnimationFrame(this.animationID);
    const movedBy = this.currentTranslate - this.prevTranslate;

    if (movedBy < -100 && this.currentIndex < this.elements.length - 1) this.currentIndex += 1;

    if (movedBy > 100 && this.currentIndex > 0) this.currentIndex -= 1;

    this.setPositionByIndex();
    this.isClassGrabbing = false;
  }

  touchMove(event) {
    if (this.isDragging) {
      const currentPosition = this.getPositionX(event);
      this.currentTranslate = this.prevTranslate + currentPosition - this.startPos;
    }
  }

  getPositionX(event) {
    const position = event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
    return position;
  }

  animation = () => {
    if (this.isDragging) requestAnimationFrame(this.animation);
  }

  setPositionByIndex() {
    this.currentTranslate = this.currentIndex * -this.slideWith;
    this.elements = this.elements.map((element, index) => {
      element.active = false;
      if (index === this.currentIndex) element.active = true;
      return element;
    });
    this.prevTranslate = this.currentTranslate;
  }

  preventDefault(e) {
    e.preventDefault();
  }

  previousElement() {
    if (this.currentIndex === 0) return;
    this.currentIndex--;
    this.touchEnd();
  }

  nextElement() {
    if (this.currentIndex === this.elements.length - 1) return;
    this.currentIndex++;
    this.touchEnd();

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Home_Flecha',
      pantalla: 'Home',
    }

    this.gtmService.sendEvent(gtmEvent)
  }

  sendData(element) {
    this.emitData.emit(element);
  }

  getElementPosition(event) {
    
  }

}
