<div class="header">
    <img src="/assets/images/logo-mandadito.svg" alt="">
</div>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second">
        <img class="d-block d-md-none mx-auto" width="125" src="/assets/images/mdt-personas-menu-historico-mobile.svg" alt="Revisando tus datos" />
        <img class="d-none d-md-block mx-auto" width="160" src="/assets/images/mdt-personas-menu-historico-mobile.svg" alt="Revisando tus datos" />
        
        <h2 class="main-title">
            Estamos validando tus datos
        </h2>
        <p class="sub-title mx-auto">
            Te notificaremos a tu correo el estado de tu registro
        </p>
        <p class="sub-title d-flex flex-column">
            <strong> {{ email }} </strong>
        </p>
        
        <p class="sub-title d-flex align-items-center justify-content-center mx-auto mt-4 mb-0"><span class="pr-2">¿Tienes dudas? </span> 
            <a href="https://wa.link/ny1jx2" rel="noopener" target="_blank">
                <bcp-button shape="text" tier="primary" size="lg">
                    Escríbenos
                </bcp-button>
            </a>
        </p>

        <div class="informative">
            <img src="assets/images/clock-blue.svg" width="16" alt="Info">
            <p>Atenderemos tus consultas de lunes a viernes de 9:00 a.m. a 6:00 p.m. EST</p>
        </div>
        
        <div class="button extra-button-size">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="button" (click)="goToLanding()">
                Volver a Mandaditobcp.com.pe
            </bcp-button>
        </div>
    </div>
</div>
