<header [ngClass]="{'background-base': activeBackground}">
    <div class="content" [ngClass]="{'reset-size': activeBackground}">
        <img class="logo" src="/assets/images/logo-header-white-responsive.svg" alt="menu">
        <div class="menu-container" clickOutisde (clickOutside)="clickedOutside()">
            <div class="trigger" (click)="toggleMenu()">
                <div class="name flexo-demi">{{ letterMenu }}</div>
                <div class="arrow">
                    <bcp-icon class="d-none d-md-block" name="angle-down-r" size="20" color="white"></bcp-icon>
                    <bcp-icon class="d-block d-md-none" name="angle-down-r" size="14" color="white"></bcp-icon>
                </div>
            </div>
            <app-menu-panel [isMenuOpened]="isMenuOpened" class="desktop"></app-menu-panel>
        </div>
    </div>
</header>
