import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FooterComponent } from "./templates/footer/footer.component";
import { HeaderComponent } from './templates/header/header.component';
import { SharedModule } from "../shared/shared.module";
import { HttpClientModule } from "@angular/common/http";
import { SafePipe } from './pipes/safe.pipe';
import { SidebarComponent } from './templates/sidebar/sidebar.component';


const elements = [
  FooterComponent,
  HeaderComponent,
  SafePipe,
  SidebarComponent
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [ ...elements ],
  exports: [ ...elements ],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule
  ],
})
export class CoreModule { }
