import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  animations: [
    trigger('toggle', [
      state('expand', style({
        height: AUTO_STYLE
      })),
      state('collapse', style({
        height: '0'
      })),
      transition('expand <=> collapse', [
        animate('0.3s')
      ])
    ])
  ]
})
export class CollapseComponent {

  @Input() collapse = false;

}
