import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent {

  @Output() emitter = new EventEmitter<boolean>();

  triggerEvent(): void {
    this.emitter.emit(false);
  }

}
