<div class="register">
    <h2 class="main-title mb-1 centerdesktp">Toma una foto de tu documento</h2>
    <div class="flex-container">
       <span class="pr-2 flexo-bold centerdesktp">Te pedimos esto para validar tu identidad.</span>
       <span class="pr-2 centerdesktp">Puedes usar tu Driver's license o DNI peruano</span>
    </div>

    <form class="form-main" [formGroup]="formRegister" (ngSubmit)="sendData()">
        <br>
        <h2 class="main-title text-left" style="font-size: 17px !important;">Elige el documento que vas a subir</h2>
        <div class="radio-group">
            <label class="radio-card">
                <input type="radio" [value]="documentTypes.DNI" formControlName="docTypeloaded" class="radio">
                <div class="radio-content">
                    <img src="/assets/images/illust_DNI.svg" alt="DNI">
                    <span> DNI Peruano </span>
                </div>
            </label>
            <label class="radio-card">
                <input type="radio" [value]="documentTypes.DriverLicense" formControlName="docTypeloaded" class="radio">
                <div class="radio-content">
                    <img src="/assets/images/illust_Drivers.svg" alt="Drivers's license">
                    <span> Driver's license</span>
                </div>
            </label>
        </div>

        <div class="info-box" *ngIf="selectedDocument">
            <img src="/assets/images/e_ic_exclamation_triangle.svg" alt="Exclamation">
            <span>Recuerda que tu documento debe estar vigente</span>
        </div>

        <app-upload-dni *ngIf="selectedDocument" [selectedDocument]="selectedDocument"
            (documentFront)="setDocumentByType('front', $event)"
            (documentBack)="setDocumentByType('back', $event)"></app-upload-dni>

        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit"
                [disabled]="isContinueButtonDisabled">Subir fotos</bcp-button>
        </div>
    </form>
</div>
