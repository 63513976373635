<app-referral-banner></app-referral-banner>
<app-menu-panel [isMenuOpened]="isMenuOpened" (toggleFullscreenMenu)="toggleFullscreenMenu()" class="mobile"></app-menu-panel>
   <div class="content" [ngClass]="{'d-none': hideContent}">
        <div class="app-menu">
            <app-menu (toggleFullscreenMenu)="toggleFullscreenMenu()"></app-menu>
        </div>
        <div class="background"></div>
        <div class="panel">
            <div class="box" *ngIf="operations">
                <app-list [operations]="operations" [hadSendMandadito]="hadSendMandadito" (showDetail)="showDetail($event)" [ngClass]="{'d-none': !steps.list}" (onDateSelected)="onDateSelected($event)"></app-list>
                <app-detail [operation]="operation" (backEvent)="showList()" [ngClass]="{'d-none': !steps.detail }"></app-detail>
            </div>
        </div>
    </div>


<app-modal *ngIf="showError">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img src="/assets/images/group-21.svg" alt="error">
            <h3 class="title">Tuvimos un error</h3>
            <p class="subtitle">No pudimos cargar la información.<br>Por favor vuelve a intentar en un momento.</p>
            
            <div class="button">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToNewMandadito()">Volver</bcp-button>
            </div>
        </div>
    </section>
</app-modal>
