import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MainService } from 'src/app/core/services/main.service';
import { UtilService } from 'src/app/core/services/util.service';
import { DocumentTypes } from 'src/app/shared/models/model';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit, OnDestroy {

  @Input() isActiveBoth = false;
  @Input() documentType : string;
  @Output() dataEvent = new EventEmitter();
  @Output() comeBackEvent = new EventEmitter();

  private utilService = inject(UtilService);
  private mainService = inject(MainService);
  private localStorageService = inject(LocalStorageService);
  dniSubscription$: Subscription;
  formData: FormGroup;
  submitted = false;
  dniError = null;

  documentTypes = DocumentTypes

  constructor() { }

  ngOnInit(): void {
    this.initializeForm();
    this.checkIfDocumentExist();
  }

  ngOnDestroy() {
    this.dniSubscription$?.unsubscribe();
  }

  initializeForm(): void {

    this.formData = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      secondName: new FormControl('', [Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      firstLastname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      secondLastname: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      documentNumber: new FormControl(null, [Validators.required,
        Validators.pattern(/^[0-9]{8,8}$/), Validators.minLength(8), Validators.maxLength(8)]),
    });
  }

  getMessage(input: string) {
    const formControl = this.formData.get(input);

    if (formControl && formControl.errors && !formControl.pristine && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (formControl && formControl.errors && !formControl.pristine && !formControl.errors.hasOwnProperty('required')) {
      return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }

    return '';
  }

  getError(input: string) {
    const formControl = this.formData.get(input);

    if (!!formControl && !formControl.pristine && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (formControl && formControl.errors && !formControl.pristine && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    return '';
  }

  checkIfDocumentExist() {
    this.dniSubscription$ = this.formData.get('documentNumber').valueChanges.subscribe(value => {
      this.dniError = null;
      if (value && value.length === 8 && this.formData.get('documentNumber').valid) {
        this.mainService.validateIfDocumentNumberAlreadyRegistered(value).subscribe({
          next: (res) => {
            if (res) {
              this.dniError = 'error';
              this.formData.get('documentNumber').setErrors({ 'userExists': true });
            }
          }
        });
      }
    })
  }

  sendData(): void {
    this.submitted = true;
    if ( this.formData.invalid || this.dniError ) return;
    const data = this.formData.value;
   
    this.dataEvent.emit(data);
  }

  comeBack(): void {
    this.comeBackEvent.emit(true);
  }
  
  excludeLetters(event: KeyboardEvent){
    return !!event.key.match(/^[0-9]+$/)
  }

  get documentNumberMessage(){
    return this.formData.get('documentNumber').errors?.['userExists'] ? 'El DNI ya se encuentra registrado' : this.formData.get('documentNumber').pristine ? '' : 'Debes ingresar 8 dígitos'
  }
}
