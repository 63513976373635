import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActiveCampaingsBreadcrumb, TermsAndConditionsBreadcrumb, ZeroComissionRaffleBreadcrumb } from '../../breadcrumbData';

@Component({
  selector: 'app-zero-comission-raffle',
  templateUrl: './zero-comission-raffle.component.html',
  styleUrls: ['./zero-comission-raffle.component.scss']
})
export class ZeroComissionRaffleComponent implements OnInit {

  private breadcrumbService = inject(BreadcrumbService)

  constructor() { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      ActiveCampaingsBreadcrumb,
      ZeroComissionRaffleBreadcrumb
    ])
  }

}
