import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { UpdateDataService } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  behaviorSubject = new BehaviorSubject({});
  public data = this.behaviorSubject.asObservable();

  public updateData(data: any) {
    this.behaviorSubject.next(data);
  }

  public updateDataWithOperation(data: UpdateDataService) {
    this.behaviorSubject.next(data);
  }

  public cleanSubject() {
    this.behaviorSubject.next(EMPTY);
  }
}

