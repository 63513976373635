import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, catchError, concatMap, forkJoin, of, switchMap, tap, throwError } from 'rxjs';
import { API_CODES, AuthRecoverType, AuthStatus, CustomerStatus, IP_PROVIDER, LOGIN_ERROR, PATHS, StatusRegister, UPDATE_DATA_STORAGE } from 'src/app/core/constants';
import { GtmEvent, OPERATIONS } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LocationApiService } from 'src/app/core/services/location-api.service';
import { MainService } from 'src/app/core/services/main.service';
import { UtilService } from 'src/app/core/services/util.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { ICustomerData } from 'src/app/shared/models/model';
import { LastPageService } from 'src/app/shared/services/last-page.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  formLogin: FormGroup;
  submitted = false;
  error = {
    show: false,
    message: null
  };
  loginModals = {
    maxMonth: false,
    maxDay: false,
    hour: false,
    personalData: false,
    recoverPassword: false,
    rejected: false,
    recovery: false,
  };
  inputType = 'password';
  eyeType = 'eye-b';

  userMail: string
  userHasCompletedOperations: boolean

  canLogin: boolean = false
  hasValidCredentials = false
  canSendMandaditos: boolean;
  hasIncompleteRegister: boolean = false;
  hasPartialRegister: boolean = false;

  modal = {
    name: '',
    userEmail: ''
  };

  recoverTypes: string[] = [ AuthRecoverType.justDni.toString(),
    AuthRecoverType.justPersonalData.toString(),
    AuthRecoverType.dniAndPersonalData.toString()];

  private router = inject(Router);
  private authService = inject(AuthService);
  private utilService = inject(UtilService);
  private mainService = inject(MainService)
  private gtmService = inject(GtmService);
  private loaderService = inject(LoaderService);
  private locationApiService = inject(LocationApiService);
  private localStorageService = inject(LocalStorageService);
  private dataService = inject(DataService);
  private lastPageService = inject(LastPageService);

  captchaResponse: string|undefined;

  get form() {
    return this.formLogin;
  }

  ngOnInit(): void {
    this.getApiData();
    this.basicForm();
    this.validateSession();
  }

  basicForm(): void {
    this.formLogin = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      password: new FormControl('', new FormControl(null, [Validators.required])),
    });
  }

  validateSession(): void {
    let token = this.localStorageService.getStorage('token');
    const status = token?.statusRegister || '';

    if (this.authService.checkAuthStatus() === AuthStatus.authenticated || status.toLowerCase() === StatusRegister.INCOMPLETE) {
      this.authService.logout();
    }
  }

  async getApiData() {
    const [ipApi, ipInfo] = await Promise.all([
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_API),
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_STACK)
    ]);

    let goAway = true;
    if (ipApi?.isLocationAllowed || ipInfo?.isLocationAllowed) {
          goAway = false;
    }

    if ( goAway ) {
      this.router.navigate([PATHS.OTHER_STATE]);
    }
  }

  getMessage(input: string): string {
    const formControl = this.formLogin.get(input);

    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (this.submitted && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      if (input === 'password') return '';
      return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }

    return '';
  }

  getError(input: string): string {
    const formControl = this.formLogin.get(input);
    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (this.submitted && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      if (input === 'password') return '';
      return "error";
    }

    return '';
  }

  goBack(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  goToRegister(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Click_modal_regístrate',
      pantalla: 'Inicia sesión',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.REGISTER]);
  }

  showForgotPasswordModal(): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_olvida_contraseña',
      pantalla: 'Inicia sesión',
    };
    this.gtmService.sendEvent(event);
    this.router.navigate([PATHS.RECOVER]);
  }

  sendData() {
    this.loaderService.requestStarted()
    this.submitted = true;

    if (this.formLogin.invalid) {
      this.loaderService.requestEnded()
      return;
    }

    let data = {
      type: 'autenticate',
      ...this.formLogin.value
    };

    if (this.localStorageService.getStorage('sessionId')) {
      data.sessionId = this.localStorageService.getStorage('sessionId');
    }

    const loginObs = this.authService.login(data).pipe(
      catchError(err => {
      this.hasValidCredentials = false;
      const code = err.error.errors[0].code;
      this.goToByCode(err.error.errors[0]);
      if (LOGIN_ERROR.ERROR_SIGNIN_SESSION === code) {
        this.validateTimerSession(err.error.errors[0]?.data);
      } else {
        this.error = {
          show: true,
          message: code === LOGIN_ERROR.USER_PASSWORD_INCORRECT ? 'Usuario o contraseña incorrectos' : 'Por favor vuelva a intentar'
        }
      }
      // We throwError so the stream ends if error is triggered when authenticating
      return throwError(() => ({ source: 'login', error: err }))
    }))

    const userDataObs = this.mainService.GetCustomer().pipe(catchError(err => {
      return throwError(() => ({ source: 'GetCustomer', error: err }))
    })) as Observable<ICustomerData>

    const accessTimeObs = this.mainService.isWithinAccessTime(data.email).pipe(catchError(err => {
      return throwError(() => ({ source: 'isWithinAccessTime', error: err }))
    }))

    const userOperationsObs = this.mainService.getValidationUserOperations().pipe(catchError(err => {
      // For the operations of the User, we get the needed codes as Http errors when we make the request thats why we handle the logic here in the catchError
      const code = err.status === HttpStatusCode.UnprocessableEntity ? err.error.errors[0].code : 'Código de respuesta desconocido'
      // this.loginModals.maxDay = code === API_CODES.API_OPER_ERROR_MAX_AMOUNT_DAY.toString() couldnt use this to validate max amount operations per day
      this.loginModals.maxMonth = code === API_CODES.API_OPER_ERROR_MAX_QUANTITY_MONTH.toString()
      return of(null)
    }))

    const observables$ = forkJoin({
      userDataResponse: userDataObs,
      accessTimeResponse: accessTimeObs,
      userOperationsResponse: userOperationsObs
    })

    loginObs.pipe(
      switchMap(loginResponse => {
        const { statusRegister, loginStatus, recoverType } = loginResponse
        this.setAuthStatus(loginStatus);
        this.setRecoverType(recoverType);
        this.localStorageService.removeStorage('sessionId');
        this.localStorageService.setStorage('token', loginResponse);
        const status = statusRegister.toLowerCase();
        const hasIncompleteRegister = status === StatusRegister.INCOMPLETE;
        const hasPartialRegister = status === StatusRegister.PARTIAL;
        if (hasIncompleteRegister) {
          const { email, password } = this.formLogin.value;
          sessionStorage.setItem('registerData',
            JSON.stringify({ firstValue: email, secondValue: password }));
          this.hasIncompleteRegister = true;
          this.lastPageService.saveLastPage(PATHS.LOGIN);

          return throwError(() => new Error('usuario con registro incompleto') );
        } else if (hasPartialRegister) {
          this.hasPartialRegister = true;
          return throwError(() => new Error('usuario con registro sin foto') );
        } else {
          return observables$
        }
      })).subscribe({
        next: (response) => {
          const event: GtmEvent = {
            event: 'virtualEvent',
            accion: 'clic_envio_signIn',
            pantalla: 'SignIn',
          };
          this.gtmService.sendEvent(event);
          this.onSuccess(response)
        },
        error: (err) => {
          this.loaderService.requestEnded()
          if (this.hasIncompleteRegister){
            this.router.navigate([PATHS.COMPLETE_REGISTER]);
          } else if (this.hasPartialRegister) {
            this.router.navigate([PATHS.NEW_MANDADITO])
          }
        },
      })
  }

  private setAuthStatus(status): void {
    if (!status || status?.length === 0) status = AuthStatus.authenticated;
    this.authService.status = status;
  }

  private setRecoverType(recoverType: string) {
    this.authService.recoverType = recoverType;
  }

  goToByCode({ code, message }) {

    const navigateLink = {
      'USER_SIGNIN_TRIES_LIMIT_ERROR': PATHS.LIMIT,
      'USER_SIGNIN_TRIES_EXCEED_ERROR': PATHS.EXCEEDED
    };

    const PATH: string = navigateLink[code];
    if (!!PATH === false) return;
    this.localStorageService.setStorage('limitLoginTime', +message);

    setTimeout(() => {
      this.router.navigate([PATH]);
    }, 300);
  }

  onSuccess(response) {
    const { userDataResponse, accessTimeResponse, userOperationsResponse } = response

    this.loaderService.requestEnded();

    if (userDataResponse) {
      this.userMail = userDataResponse.email
      this.userHasCompletedOperations = userDataResponse.hasOperationsCompleted
      this.hasValidCredentials = true

      this.localStorageService.storeCustomer(userDataResponse)

      if( !this.validateUserStatus(userDataResponse) ) return;

      this.modal.userEmail = this.userMail;

      this.loginModals.maxDay = userDataResponse.totalAmount >= 999
      if (userDataResponse.customerState === CustomerStatus.REJECTED.toString()) {
        this.modal = { name: 'showModalReject', userEmail: this.userMail };
        this.loginModals.rejected = true;
      }
      if (userDataResponse.customerState === CustomerStatus.RECOVERY.toString()) {
        this.modal = { name: 'showModalRecovery', userEmail: this.userMail };
        this.loginModals.rejected = true;
      }
    }
    if (accessTimeResponse) {
      this.loginModals.hour = !accessTimeResponse.isWithinAccessTime
    }

    this.canLogin = !this.loginModals.recovery && !this.loginModals.rejected && this.hasValidCredentials
    this.canSendMandaditos = !(this.loginModals.hour || this.loginModals.maxMonth || this.loginModals.maxDay)

    this.loaderService.requestEnded();
    this.triggerTimeLimit();

    // If we can login and send mandaditos we redirect to new mandadito
    if (this.canLogin && this.canSendMandaditos) {
      this.router.navigate([PATHS.NEW_MANDADITO]);
    }
    // If we cannot login we clear the token
    if (!this.canLogin) {
      this.authService.logout()
    }
  }

  redirectToHistorical() {
    // When pressing the "Ver mi historia de mandaditos" button if we can login and we cannot send mandaditos we redirect to historical
    if (this.canLogin && !this.canSendMandaditos) {
      this.router.navigate([PATHS.HISTORICAL])
    }
  }

  eventTriggered(_) {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.eyeType = 'eye-slash-b';
    } else {
      this.inputType = 'password';
      this.eyeType = 'eye-b';
    }
  }

  validateUserStatus(userDataResponse): boolean {
    if (this.authService.status === AuthStatus.Recovering.toString() &&  this.recoverTypes.includes(this.authService.recoverType)) {
      this.authService.email = userDataResponse?.email;
      this.localStorageService.setStorage(UPDATE_DATA_STORAGE, '{ temporal: true }');
      this.router.navigate([PATHS.UPDATE_DATA]);
      return false;
    }

    if (this.authService.status === AuthStatus.Reviewing.toString()) {
      this.authService.email = userDataResponse?.email;
      this.localStorageService.setStorage(UPDATE_DATA_STORAGE, '{ temporal: true }');
      this.router.navigate([PATHS.REVIEWING_DATA]);
      return false;
    }

    return true;
  }

  validateTimerSession({ expire, startDate }): void {
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.SESSION_VALIDATE_TIMER, data: { expire, startDate } });

  }

  triggerTimeLimit(): void {
    const token = this.localStorageService.getStorage('token');
    const { session } = token;
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.SESSION_TIME_LIMIT, data: session.expire});
  }

  onInputSessionChange(event:any){
    /*deleting error message*/
    this.error = {
      show: false,
      message: null
    };
  }
}
