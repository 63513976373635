<h2 class="main-title">
    Campaña Navideña 2023
</h2>

<h3>Sorteo</h3>
<p>
    Este sorteo es desarrollado por Mandadito, una iniciativa del BCP habilitada por BCP Miami. Sorteo válido para
    participar del 1 al 31 de diciembre del 2023. Podrán participar los peruanos que se encuentren en el estado de
    Florida en Estados Unidos de América y que hasta el 29 de noviembre inclusive no hayan hecho un envío válido en
    Mandadito, en adelante “Los Usuarios”.
</p>

<p>
    Los Usuarios deberán registrarse exitosamente en Mandadito (si todavía no lo han hecho) y deberán hacer por lo menos
    un envío exitoso en Mandadito durante la vigencia del sorteo. Se considera un envío exitoso aquel que es aprobado
    por Mandadito. El primer envío es gratis y se reflejará automáticamente en el proceso de envío. Por cada envío que
    haga el Usuario en el periodo de vigencia, tendrá una oportunidad para ganar en el sorteo. El usuario podrá hacer un
    máximo de 4 envíos siguiendo los
    parámetros de Mandadito y por ende tendrá máximo 4 oportunidades para ganar. Si previo al periodo de vigencia del
    sorteo, el usuario ya ha hecho un envío exitoso en Mandadito, no podrá ser considerado para el sorteo.
</p>

<p>
    Beneficio: Habrá 4 ganadores en el sorteo. El primer ganador se llevará una gift card virtual de Amazon válida por
    200 dólares americanos. El segundo ganador se llevará una gift card virtual de Amazon válida por 100 dólares
    americanos. El tercer y cuarto ganador se llevará una gift card virtual de Amazon válida por 50 dólares americanos
    cada uno.
</p>

<p>
    El anuncio de los ganadores se hará en nuestras historias de Instagram el 4 de enero del 2024 y los ganadores
    recibirán una comunicación a su correo y WhatsApp, considerando la información registrada en Mandadito, en la que se
    hará la entrega del beneficio de manera virtual. El correo saldrá del buzón contacto@mandaditobcp.com.pe y el
    WhatsApp del número +51 945 657 689, y solo lo recibirá el ganador del sorteo.
</p>

<p>
    Mandadito podrá, previa comunicación a los participantes, modificar alguno de los términos establecidos en el
    presente texto, únicamente cuando dicho cambio no afecte la naturaleza del sorteo.
    Para más información sobre la promoción y/o restricciones, puede llamar a +51 945 657 689 o ver las publicaciones en
    nuestro Instagram @mandaditobcp.
</p>

<h3 style="margin-top: 50px;">Promoción Navideña</h3>
<p>
    Esta promoción es desarrollada por Mandadito, una iniciativa del BCP habilitada por BCP Miami. Promoción válida para
    participar del 15 al 29 de diciembre del 2023. Podrán participar los peruanos que se encuentren en el estado de
    Florida en Estados Unidos de América y que no hayan hecho un envío válido en Mandadito hasta la fecha de vigencia de
    la promoción, en adelante “Los Usuarios”.
</p>

<p>Los Usuarios deberán registrarse exitosamente en Mandadito (si todavía no lo han hecho) y deberán hacer por lo menos
    un (1) envío exitoso en Mandadito durante la vigencia de la promoción. Se considera un registro exitoso aquel que es
    aprobado por Mandadito. Se considera un envío exitoso aquel que es aprobado por Mandadito. El primer envío es gratis
    y se reflejará automáticamente en el proceso de envío. Si previo al periodo de vigencia de la promoción, el usuario
    ya ha hecho un envío exitoso en Mandadito, no podrá ser considerado para la promoción.
</p>

<p>
    Beneficio: 1 gift card virtual de Amazon de $10 para los que cumplan los requisitos de los párrafos anteriores.
    Máximo 1 beneficio por Usuario. Stock: Cincuenta (50) beneficios. El beneficio se entregará de manera virtual al
    correo del Usuario, considerando la información registrada en Mandadito. El correo se enviará del buzón
    contacto@mandaditobcp.com.pe máximo 48hrs hábiles luego de que el primer Mandadito exitoso del Usuario haya sido
    procesado.
</p>

<p>
    Mandadito podrá, previa comunicación a los participantes, modificar alguno de los términos establecidos en el
    presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la promoción.
</p>

<p>
    Para más información sobre la promoción y/o restricciones, puede llamar o escribir por Whatsapp a +51 945 657 689 o
    enviarnos un correo a <a href="mailto:contacto@mandaditobcp.com.pe">contacto@mandaditobcp.com.pe</a>
</p>
