import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { IBreadcrumbItem } from 'src/app/shared/models/model';
import { PastCampaignsBreadcrumb, TermsAndConditionsBreadcrumb } from '../breadcrumbData';

@Component({
  selector: 'app-past-campaigns',
  templateUrl: './past-campaigns.component.html',
  styleUrls: ['./past-campaigns.component.scss']
})
export class PastCampaignsComponent implements OnInit {

  pastCampaings = [
    {
      text: 'Campaña fiestas patrias 2023',
      to: 'fiestas-patrias'
    },
    {
      text:'Campaña navideña 2023',
      to: 'campanias-navidenas'
    },
  ]
  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    const breadcrumbData: IBreadcrumbItem[] = [
      TermsAndConditionsBreadcrumb,
      PastCampaignsBreadcrumb
    ]

    this.breadcrumbService.setBreadcrumb(breadcrumbData)
  }
}
