import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { LastPageService } from '../services/last-page.service';
import { AuthService } from 'src/app/core/services/auth.service';

export const returnPageGuardPhoto: CanActivateFn = async () => {
  const lastPageService = inject(LastPageService);
  const router = inject(Router);
  const authService = inject(AuthService);
  
  const lastPage = lastPageService.getLastPage();


  if (lastPage == PATHS.NEW_MANDADITO ) {
    return true
  } else {
    authService.logout();
    router.navigate([PATHS.LOGIN]);

    return false;
  }
}
