import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

import { LoginInterface, UpdateRegisterRequest, UserInterface } from '../interfaces';
import { AuthStatus } from '../constants';
import { LocalStorageService } from './local-storage.service';

const CODE_LOGIN = 'unbdFkFQfZhZnDvwouwjzJkxSQJonsufuyKpj5fH1I_1AzFuX3RDrQ==';
const CODE_DNI = 'h1D-NkFuAtqdgoD9XeB-75c_2gIo49hrM-sb0410T6WvAzFuTiKcCw==';

const headers = {
  headers: { 'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey }
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly baseUrl: string = environment.urlBase;
  private http = inject( HttpClient );
  private localstorageService = inject(LocalStorageService)

  private _status;
  private _recoverType;
  private _email;

  public set status(val: string) {
    this._status = val;
  }

  public get status(): string {
    return this._status;
  }

  public set recoverType(val: string) {
    this._recoverType = val;
  }

  public get recoverType(): string {
    return this._recoverType;
  }

  public set email(val: string) {
    this._email = val;
  }

  public get email(): string {
    return this._email;
  }

  public register(data: UserInterface): Observable<any> {
    const formData = new FormData();
    Object.entries(data.fields).forEach(([key, value]) => {
      formData.append(key, value)
    });
    formData.append("document_front", data.document_front, 'document_front');
    formData.append("document_back", data.document_back, 'document_back');

    return this.http.post(`${environment.urlBase}people/CreateCustomer`, formData, headers);
  }


  public update(formData: FormData): Observable<any> {
    return this.http.post(`${environment.urlBase}people/UpdateUser`, formData, headers);
  }

  public login(data: LoginInterface): Observable<any> {
    const url = `${ this.baseUrl }users/Authenticate?code=${CODE_LOGIN}`;
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    if (data.sessionId) {
      formData.append("sessionId", data.sessionId);
    }
    return this.http.post<any>(url, formData, headers);
  }

  validateUserExists(dni: string, email: string): Observable<any> {
    return this.http.get(`${ this.baseUrl }users/ValidateUser?code=${CODE_DNI}&dni=${dni}&email=${email}`, headers);
  }

  updateUserRegister(request: UpdateRegisterRequest) {
    return this.http.post(`${environment.urlBase}people/UpdateRegister50`, request, headers)
  }

  validateUser(documentNumber: string): Observable<any> {
    return this.http.get(`${ this.baseUrl }users/ValidateUser?code=${ CODE_DNI }&dni=${ documentNumber }`, headers);
  }

  checkAuthStatus() {
    return this.status;
  }

  checkAuthRecoveryType() {
    return this.recoverType;
  }

  async refreshToken() {

    const token = this.localstorageService.getStorage('token');

    if (!token) {
      return false;
    }

    const {refreshToken, loginStatus, recoverType } = token;

    try {
      const response = await fetch(`${ this.baseUrl }users/GetToken?refreshToken=${refreshToken}`, headers);
      const data = await response.json();
      if (data.accessToken) {
        this.status = loginStatus;
        this.recoverType = recoverType;
        this.localstorageService.setStorage('token', data);
        return true;
      }
      this.localstorageService.removeStorage('token');
      return false;
    } catch (err) {
      this.localstorageService.removeStorage('token');
      throwError( () => err.errors[0].message);
      return false;
    }

  }

  logout() {
    this.closeSession().subscribe({
      next: (_) => {
        this.localstorageService.removeAllStorage();
        this.status = AuthStatus.nonAuthenticated;
      }
    })

  }

  closeSession() {
    return this.http.post(`${environment.urlBase}people/CloseSession`, {}, headers);
  }

  recoverPassword(email: string) {
    return this.http.get(`${ this.baseUrl }users/getQuestion?email=${email}`, headers);
  }

  validateQuestions(data): Observable<{ response: boolean }> {
    return this.http.post<{ response: boolean }>(`${ this.baseUrl }users/validateQuestionPassword`, data, headers);
  }

  registerRestricterUserLocation(data: { email: string, ip: string, country: string, state: string }) {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("ip", data.ip);
    formData.append("country", data.country);
    formData.append("state", data.state);
    return this.http.post(`${environment.urlBase}users/RegisterEmail`, formData, headers);
  }

}
