import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnChanges {

  @Input()
  elements = [];
  lineWith = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['elements'] && changes['elements'].firstChange === false) {
      this.lineWith = calculateLineWith(changes['elements'].currentValue);
    }
  }

}
function calculateLineWith(elements: [{text: string; value: string; status: string }]): number {
  const segment = (100/(elements.length - 1));
  let count = 0;
  elements.forEach(element => {
    if(element.status === 'completed') {
      count++;
    }
  });
  return segment * count;
}

