import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActiveCampaingsBreadcrumb, GiftCardRaffleBreadcrumb, TermsAndConditionsBreadcrumb } from '../../breadcrumbData';

@Component({
  selector: 'app-gift-card-raffle',
  templateUrl: './gift-card-raffle.component.html',
  styleUrls: ['./gift-card-raffle.component.scss']
})
export class GiftCardRaffleComponent implements OnInit {

  private breadcrumbService = inject(BreadcrumbService)

  constructor() { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      ActiveCampaingsBreadcrumb,
      GiftCardRaffleBreadcrumb
    ])
  }

}
