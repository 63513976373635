import { Component } from '@angular/core';

@Component({
  selector: 'app-maintenance-window',
  templateUrl: './maintenance-window.component.html',
  styleUrls: ['./maintenance-window.component.scss']
})
export class MaintenanceWindowComponent {

}
