<div class="coupon">
    <div class="promotional-code cursor-pointer" *ngIf="steps.promotionalCode" (click)="steps.promotionalCode = false; steps.modalInput = true">
        
        <div class="d-flex">
            <img src="/assets/images/percent.svg" width="24" alt="percent">
            <span class="ml-2 flexo-demi font-size-16">Agrega un código promocional</span>
        </div>
        <img src="/assets/images/a_ic_angle_right.svg" width="16" alt="angle">
    </div>
    <app-modal [size]="'sm'" [ngClass]="{'d-none': !steps.modalInput }">
        <section class="error-modal">
            <div class="d-flex flex-column">
                <img src="assets/images/close.svg" class="close cursor-pointer" (click)="resetCoupon()" alt="cerrar" width="48" />
                <form class="d-flex flex-column" [formGroup]="couponForm" (ngSubmit)="sendCouponCode()" [ngClass]="{'d-none': isValidCoupon}" *ngIf="couponForm">
                    <p class="color-primary_700 w-100 text-left flexo-demi mt-4 mb-0 px-3 px-md-0">Agrega un código promocional</p>
                    <div class="inputElement position-relative">
                        <app-md-input (keypress)="valideCharacters($event)" (keyup)="removeSpecialCharacter($event)" [submitted]="submitted"
                        [properties]="{inputId: 'couponCode', label: 'Ingresa tu código', type: 'text', maxlength: '10', placeholder: '', customErrorMessage: couponError}" formControlName="couponCode"></app-md-input>
                        
                    </div>
                    <div class="button">
                        <bcp-button shape="rectangle" tier="primary" size="lg" type="submit"
                        [disabled]="!(couponForm.get('couponCode')?.value && couponForm.get('couponCode')?.value.length > 2)"
                        full-width="true">Agregar</bcp-button>
                    </div>
                </form>
            </div>
            </section>
    </app-modal>
    
    <div class="data position-relative" *ngIf="steps.coupon">
        <img src="/assets/images/discount-v2.svg" alt="discount">
        <div class="information">
            <div class="w-100 position-relative justify-content-between">
                <p class="flexo-demi">{{ couponForm.get('couponCode')?.value?.toUpperCase() }}</p>
                <span class="position-absolute flexo description">{{ coupon.description }}</span>
                <a class="flexo-demi" [href]="coupon.link" target="_blank">Ver términos y condiciones</a>
            </div>
            <div class="images">
                <img class="separator" src="/assets/images/line-vertical.svg" alt="vertical">
                <img class="trash" src="/assets/images/icon-trashcan-delete.svg" (click)="clearCoupon()" alt="delete">
            </div>
        </div>
    </div>
</div>