import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IP_PROVIDER, PATHS } from 'src/app/core/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LocationApiService } from 'src/app/core/services/location-api.service';
import { environment } from 'src/environments/environment';
import { LocationApi } from '../new-mandadito/new-mandadito.data';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-other-state',
  templateUrl: './other-state.component.html',
  styleUrls: ['./other-state.component.scss']
})
export class OtherStateComponent implements OnInit {

  private router = inject(Router);
  private localStorageService = inject(LocalStorageService);
  private locationApiService = inject(LocationApiService);
  private authService = inject(AuthService);
  formRegister: FormGroup;
  isLetMeKnow = false;
  submitted = false;
  locationApi = LocationApi;

  ngOnInit(): void {
    this.validateStorageData();
    this.getApiData();
    this.basicForm();
  }

  validateStorageData(): void {
    this.isLetMeKnow = this.localStorageService.getStorage('letMeKnow');
  }

  basicForm(): void {
    this.formRegister = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
    });
  }

  getMessage(input: string): string {
    const formControl = this.formRegister.get(input);
    let message = 'Ej. micorreo@mail.com';
    if (!!formControl && this.submitted && formControl.errors && formControl.errors['required']) message = "Campo requerido";
    if (!!formControl && this.submitted && formControl.errors && formControl.errors['pattern']) message = "Ingrese un correo válido";

    return message;
  }

  getError(input: string): string {
    const formControl = this.formRegister.get(input);
    if (!!formControl && this.submitted && formControl.errors && formControl.errors['required']) return "error";
    if (!!formControl && this.submitted && formControl.errors && !formControl.errors.hasOwnProperty('required')) return "error";

    return '';
  }

  goBack(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  async getApiData() {
    const [ipApi, ipInfo] = await Promise.all([
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_API),
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_STACK)
    ]);

    this.locationApi = ipApi?.isLocationAllowed ? ipApi : ipInfo;
    console.log(this.locationApi);
  }

  sendData() {
    this.submitted =  true;
    if (this.formRegister.invalid) return;

    const data = {
      email: this.formRegister.get('email').value,
      ip: this.locationApi.ip,
      country: this.locationApi.countryCode,
      state: this.locationApi.region
    };
    console.log('========>SendData======>', data, this.authService, this.authService.registerRestricterUserLocation(data))
    this.authService.registerRestricterUserLocation(data).subscribe({
      next: (res) => {
        console.log(res);
        this.isLetMeKnow = true;
        this.localStorageService.setStorage('letMeKnow', 'true');
      }
    });
    
  }

}
