<div class="login">
    <h2 class="main-title flexo">Responde tu pregunta de seguridad</h2>
    
    <form class="form-main" [formGroup]="formQuestion" (ngSubmit)="sendData()">
        <p class="mx-auto text-center flexo-demi">{{ questions[questionOption.question] }}</p>
        <div class="inputElement">
            <bcp-input type="text" label="Ingresa tu respuesta" required="true" bcpCtrlForm placeholder="Ej. Azul"
            [message]="getMessage('answer')" [state]="getError('answer')" formControlName="answer"></bcp-input>
        </div>
        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" [disabled]="submitted && formQuestion.status === 'INVALID'" type="submit">Continuar</bcp-button>
        </div>
        <!-- <div class="error text-center mt-3" *ngIf="error.show" [innerHTML]="error.message"></div> -->
    </form>
</div>