<app-menu-panel [isMenuOpened]="isMenuOpened" (toggleFullscreenMenu)="toggleFullscreenMenu()" class="mobile"></app-menu-panel>

<div class="header" *ngIf="hasIncompleteRegister">
    <img [src]="imgLogo" alt="">
</div>

<div class="content" [ngClass]="{'completed': steps.completed}">
    <div class="app-menu">
        <app-menu [activeBackground]="activeHome" (toggleFullscreenMenu)="toggleFullscreenMenu()"></app-menu>
    </div>
    <div class="background"> </div>
    
    <div class="home">
        <div class="second headsecond content-payment">
            <div class="d-flex mx-auto justify-content-center flex-column2">
                <bcp-button shape="text" tier="primary" (click)="skipStep()">
                   <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r"></bcp-icon>
                Volver
                </bcp-button>
            <app-step3-home class="mt-4" [hidden]="!steps.third" [data]="data" (setData)="sendData($event)" (skipStep)="skipStep()"></app-step3-home>
            </div>
        </div>
    </div>
</div>

<app-modal [size]="'sm'" *ngIf="showModalAccessTime">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="/assets/images/sleeping-piggy.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700">En
                este horario no puedes<br />enviar Mandaditos</h3>
            <p class="mt-3 flexo-regular font-size-md-16 font-size-14 line-height-24 text-center mb-2">Nuestro horario
                de envíos es:</p>
            <ul class="modal-extended-hours-req">
                <li>Lunes a viernes durante todo el día</li>
                <li>Sábados hasta la 1:00 p.m. EST</li>
            </ul>
            <p class="mt-1 mb-3 flexo-demi font-size-md-16 font-size-14 line-height-24 text-center">Puedes registrarte y
                te avisaremos cuando puedas enviar un Mandadito</p>

            <div class="extra-button-size mx-auto mt-1 mt-md-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true"
                    (click)="showModalAccessTime = false">Registrarme</bcp-button>
            </div>
            <div class="mt-3 text-center">
                <bcp-button shape="text" size="lg" tier="primary" (click)="goToLanding()">Volver a
                    Mandaditobcp.com.pe</bcp-button>
            </div>
        </div>
    </section>
</app-modal>

<app-modal *ngIf="showModalError">
    <section>
        <div class="d-flex flex-column">
            <img width="170" class="mx-auto" [src]="spotAlertGroup" alt="error">
            <h3 class="title mt-4">Ocurri&oacute; un problema</h3>
            <p class="subtitle">Por favor, intenta nuevamente.</p>
            <div class="button mt-4">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true"
                    (ctrlClick)="closeModal('showModalError')">De acuerdo</bcp-button>
            </div>
        </div>
    </section>
</app-modal>

<app-modal *ngIf="showModalSkipPhoto">
    <section>
        <div class="d-flex flex-column">
            <img width="96" class="mx-auto" [src]="spotAlertHand">
            <h3 class="text-center font-size-20 font-size-md-24 flexo-demi mt-4">¿Estás seguro de volver?</h3>
            <p class="subtitle">Recuerda que para tu primer envío es necesario que subas la foto de tu documento</p>
            <div class="button complete-registration__btn-save-photo">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true"
                    (ctrlClick)="closeModal('showModalSkipPhoto')">Subir foto ahora</bcp-button>
            </div>
            <div class="button mt-3">
                <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true"
                    (ctrlClick)="goBack()">Hacerlo en otro momento</bcp-button>
            </div>
        </div>
    </section>
</app-modal>