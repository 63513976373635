import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ASSETS, LOGIN_NEXT_STEP } from 'src/app/core/constants';

@Component({
  selector: 'app-incomplete-register',
  templateUrl: './incomplete-register.component.html',
  styleUrls: ['./incomplete-register.component.scss']
})
export class IncompleteRegisterComponent {
  
  @Output()
  sendData = new EventEmitter<string>();
  
  spotImgScanDocument: string = ASSETS.SPOT_SCAN_DOCUMENT;

  constructor() { }

  completeRegister() {
    this.sendData.emit(LOGIN_NEXT_STEP.CONTINUE);
  }

  logout() {
    this.sendData.emit(LOGIN_NEXT_STEP.LOGOUT);
  }
}
