<app-header></app-header>
<div class="content flex-column">
    <bcp-button shape="text" tier="primary" (click)="goToLanding()">
        <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r"></bcp-icon>
        Volver
    </bcp-button>
    <h2>Declaración de accesibilidad</h2>
    <p>
        Mandadito se compromete a proporcionar un sitio web accesible para una audiencia lo más amplia posible,
        independientemente de las circunstancias y la capacidad. Nuestro objetivo es cumplir de la manera más cercana
        posible a las Pautas de Accesibilidad para el Contenido Web (WCAG 2.0, Nivel AA), publicadas por el Consorcio WWW, o
        según su nombre en inglés, World Wide Web Consortuium (W3C). Estas pautas explican cómo lograr que el contenido web
        sea más accesible para las personas con discapacidad. El cumplimiento de estas pautas ayudará a que el sitio sea más
        fácil de usar y amigable para todos los usuarios.
    </p>
    
    <p>
        Si bien Mandadito se esfuerza por cumplir con las pautas y estándares de accesibilidad, no siempre es posible
        hacerlo
        en todas sus áreas y actualmente estamos trabajando para lograrlo. Ten en cuenta que debido a la naturaleza dinámica
        del sitio web, pueden surgir inconvenientes menores, ya que se actualiza con regularidad. Continuamente buscamos
        soluciones que lleven todas las áreas del sitio al mismo nivel de accesibilidad web general.
    </p>
    
    <p>
        Si tienes algún comentario o sugerencia relacionados con la mejora de la accesibilidad de nuestro sitio, por favor
        no
        dudes en contactarnos a <b>contacto@mandaditobcp.com.pe.</b> Tus comentarios nos ayudarán a hacer las mejoras.
    </p>
</div>
<app-footer></app-footer>
