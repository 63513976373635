<div class="header">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="mt-0 second">

        <div class="mb-2 w-100 mx-auto px-3 px-md-0" *ngIf="!steps.HELP">
            <app-steps [elements]="stepElements"></app-steps>
        </div>

        <app-email *ngIf="steps.EMAIL" (emitData)="sendData($event)"></app-email>   
        <app-authenticate [email]="data.email" [questions]="questions" (emitData)="sendData($event)" *ngIf="steps.AUTHENTICATE"></app-authenticate>
        <app-password [data]="data" *ngIf="steps.PASSWORD"></app-password>
        <app-help *ngIf="steps.HELP"></app-help>
    </div>
</div>

<app-shared-modals [modal]="modal" (closeModalEvent)="closeModal()"></app-shared-modals>
