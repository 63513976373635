import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MANDADITO_STEPS, PATHS } from 'src/app/core/constants';
import { DataMandadito, Favorite, GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { Currency, ICustomerData } from 'src/app/shared/models/model';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {

  @Input() data: DataMandadito;
  @Input() userData: ICustomerData;
  @Input() favorites: Favorite[];
  @Input() activeHome = false;

  @Output()
  backStep = new EventEmitter<string>();

  @Output()
  nextStep = new EventEmitter<Object>();

  favorite: Favorite;
  step = MANDADITO_STEPS.RESUME;
  favoriteForm: FormGroup;
  lengthCharacters = '00/100';

  Currency = Currency
  
  private gtmService = inject(GtmService);
  private router = inject(Router);


  ngOnInit(): void {
    this.initializeForm();

    if (this.data.receiver) {
      this.favorite = this.data.receiver;
    }
  }

  initializeForm() {
    this.favoriteForm = new FormGroup({
       message: new FormControl(null)
    });

    this.favoriteForm.controls['message'].valueChanges.subscribe({
      next: (data) => {
        this.lengthCharacters = `${data.length}/100`;
      }
    });
  }

  sendData(alternative: boolean): void {
    let emit = null;

    if (!alternative) {
      emit = {step: this.step, data: {
        ...this.data,
        receiver: this.favorite,
        isFavorite: true,
        autoremittance:
        this.favorite.myself
      }};
    } 
    else {
      this.data.isFavorite = false;
      this.data.receiver = null;
      emit = {step: MANDADITO_STEPS.RECEIVER, data: this.data };
    }

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: `clic_Envio2_${ alternative ? 'OtroReceptor' : 'favorito'}`,
      pantalla: 'EnvioPaso2',
    };
    this.gtmService.sendEvent(gtmEvent);

    this.nextStep.emit(emit);

  }

  selectFavorite(favorite: Favorite){
    this.favorite = favorite
    this.sendData(false)
  }

  backToPrevious() {
    if (this.activeHome) {
      this.router.navigate([PATHS.NEW_MANDADITO]);
    }
    else this.backStep.emit('AMOUNT');
  }

}
