<div class="register">
    <h2 class="main-title">Confirma tu dirección e identidad</h2>
    <form class="form-main" [formGroup]="formRegister" (ngSubmit)="sendData()">
        <div class="inputElement">
            <bcp-select message="Ej. +1 567 890 3301"
            message="En Mandadito solo puedes enviar dinero desde USA" [state]="getError('country')" bcpCtrlForm formControlName="country">
                <bcp-select-header slot="header" label="¿En qué País vives actualmente?">
                </bcp-select-header>
                <bcp-select-option value="Estados Unidos">Estados Unidos</bcp-select-option>
                <bcp-select-option value="Otro">Otro</bcp-select-option>
            </bcp-select>

        </div>
        <div class="inputElement">
            <bcp-select message="Por ahora solo puedes usar Mandadito desde Florida" [state]="getError('state')" bcpCtrlForm formControlName="state">
                <bcp-select-header slot="header" label="¿En qué estado de USA vives actualmente?">
                </bcp-select-header>
                <bcp-select-option value="Florida">Florida</bcp-select-option>
                <bcp-select-option value="Otros">Otros</bcp-select-option>
            </bcp-select>
        </div>
        <div class="inputElement">
            <bcp-input type="text" label="Dirección actual *" required="true" placeholder="Ej. 123 Spring Street" bcpCtrlForm
            [message]="getMessage('address')" [state]="getError('address')" formControlName="address"></bcp-input>
        </div>

        <div class="inputElement">
            <bcp-select [message]="getMessage('howtoFind')" [state]="getError('howtoFind')" bcpCtrlForm formControlName="howtoFind"> 
                <bcp-select-header slot="header" label="¿Cómo te enteraste de Mandadito?"></bcp-select-header>
                <bcp-select-option value="Redes sociales">Redes sociales</bcp-select-option>
                <bcp-select-option value="Amigos / Familiares">Amigos / Familiares</bcp-select-option>
                <bcp-select-option value="Feria">Feria</bcp-select-option>
                <bcp-select-option value="Restaurante">Restaurante</bcp-select-option>
                <bcp-select-option value="Instagram @soyperuana.pe">Instagram &#64;soyperuana.pe</bcp-select-option>
                <bcp-select-option value="referralCode">Tengo un código de referido</bcp-select-option>
                <bcp-select-option value="other">Otros</bcp-select-option>
            </bcp-select>
        </div>
        
        <div class="inputElement" [ngClass]="{'d-none': formRegister.get('howtoFind').value !== 'referralCode'}">
            <bcp-input type="text" label="Código de referido*" required="true" placeholder="Ingresa tu código de referido" bcpCtrlForm
            [message]="getMessage('referralCode')" [state]="getError('referralCode')" formControlName="referralCode"></bcp-input>
        </div>
        <div class="inputElement" [ngClass]="{'d-none': formRegister.get('howtoFind').value !== 'other'}">
            <bcp-input type="text" label="¿Quién te contó?*" required="true" placeholder="¿Quién te contó?" bcpCtrlForm
            [message]="getMessage('other')" [state]="getError('other')" formControlName="other"></bcp-input>
        </div>

        <div class="files mt-4">

            <div class="mt-2">
                <p class="flexo-demi">Foto de tu DNI de frente*</p>
                <div class="custom-fileupload">
                    <div (click)="front.click()">
                        <div class="image">
                            <img [ngClass]="{'d-block': images.front.status === null }" src="/assets/images/cards-add-file-1-upload.svg" alt="cargar">
                            <img [ngClass]="{'d-block': images.front.status === 'success' }" src="/assets/images/avatar-square-icon-success.svg" alt="éxito">
                            <img [ngClass]="{'d-block': images.front.status === 'error' }" src="/assets/images/avatar-square-icon-error.svg" alt="error">
                        </div>
                        <div class="text">
                            <p class="flexo-demi">{{ images.front.status === null ? 'Subir foto aquí' : images.front.name }}</p>
                            <span *ngIf="images.front.status === null">Formato JPG o PNG. Peso máximo 25MB</span>
                            <span class="success flexo-demi" *ngIf="images.front.status === 'success'">¡Éxito!</span>
                            <span class="error flexo-demi" *ngIf="images.front.status === 'error'">{{ errorImageTypeFront === ErrorImageType.FILE_TYPE ? 'Sube una imagen JPG o PNG' : 'La imagen sobrepasa los 25MB' }}</span>
                            <span></span>
                        </div>
                    </div>
                    <img class="delete" src="/assets/images/icon-trashcan-delete.svg" (click)="removeImage('front')" *ngIf="images.front.status !== null" alt="">
                    <input type="file" accept="image/jpg, image/png, image/jpeg" (change)="onFileChange('front', $event)" #front>
                </div>
            </div>

            <div class="mt-4">
                <p>Foto de tu DNI del reverso*</p>
                <div class="custom-fileupload">
                    <div (click)="back.click()">
                        <div class="image">
                            <img [ngClass]="{'d-block': images.back.status === null }" src="/assets/images/cards-add-file-1-upload.svg" alt="cargar">
                            <img [ngClass]="{'d-block': images.back.status === 'success' }" src="/assets/images/avatar-square-icon-success.svg" alt="éxito">
                            <img [ngClass]="{'d-block': images.back.status === 'error' }" src="/assets/images/avatar-square-icon-error.svg" alt="error">
                        </div>
                        <div class="text">
                            <p>{{ images.back.status === null ? 'Subir foto aquí' : images.back.name }}</p>
                            <span *ngIf="images.back.status === null">Formato JPG o PNG. Peso máximo 25MB</span>
                            <span class="success" *ngIf="images.back.status === 'success'">¡Éxito!</span>
                            <span class="error" *ngIf="images.back.status === 'error'">{{ errorImageTypeBack === ErrorImageType.FILE_TYPE ? 'Sube una imagen JPG o PNG' : 'La imagen sobrepasa los 25MB' }}</span>
                        </div>
                    </div>
                    <img class="delete" src="/assets/images/icon-trashcan-delete.svg" (click)="removeImage('back')" *ngIf="images.back.status !== null" alt="">
                    <input type="file" accept="image/jpg, image/png, image/jpeg" (change)="onFileChange('back', $event)" #back>
                </div>
            </div>
        </div>

        <div class="dni-requirements">
            <div class="header">
                <img src="assets/images/info-blue.svg" alt="Info">
                <div class="text">Las fotos deben cumplir lo siguiente</div>
            </div>
            <ul>
                <li>
                    DNI con <strong>fecha vigente</strong>
                </li>
                <li>
                    Las fotos <strong>no deben estar borrosas</strong>
                </li>
                <li>
                    En la foto <strong>el DNI debe estar completo</strong>, no cortado
                </li>
            </ul>
        </div>

        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit"
            [disabled]="isContinueButtonDisabled">Continuar</bcp-button>
        </div>

        <div class="button">
            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goBackStep()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
                Volver
            </bcp-button>
        </div>
        <p class="error mx-auto mt-2 text-center" *ngIf="showGeneralError">
            Todos los campos son obligatorios 
        </p>
    </form>
</div>



<app-modal [size]="'sm'" *ngIf="showDocument">
    <section class="error-modal">
    <div class="contents">
        <h3 class="title original-size flexo-regular">Revisa la foto que subiste</h3>
        <div class="preview-image">
            <img [src]="images.front.url" alt="">
        </div>
        <p class="dni-text">Asegúrate que cumpla los requisitos:</p>
        <ul class="checks-document">
            <li>DNI con <strong>fecha vigente</strong></li>
            <li>Las fotos <strong>no deben estar borrosas</strong></li>
            <li>En la foto <strong>el DNI debe estar completo</strong>, no cortado</li>
        </ul>

        <div class="d-flex flex-column document-buttons mx-auto mt-3">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="photoIsOk()">La foto cumple los requisitos</bcp-button>

            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="removeImage('front')">Subir otra foto</bcp-button>
        </div>
    </div>
    </section>
</app-modal>
