<div class="referral-banner" *ngIf="showBanner">
    <div class="main-content" (click)="isDesktop ? '': openModal()">
        <img src="assets/images/gift@3x.png" class="gift">
        <div class="text-container" (click)="isDesktop ? openModal(): ''">
            <span class="text">Refiere y gana ENVÍOS GRATIS</span>
            <div>
                <span class="link">Comparte tu enlace</span>
                <img src="assets/images/a_ic_angle_down_circle.svg" class="angle-right-circle">
            </div>
        </div>
    </div>
    <div (click)="closeBanner()">
        <img src="assets/images/c_ic_close.svg" class="close-b">
    </div>
</div>


<app-referral-modal [showModal]="showModal" [customerReferralCode]="customerReferralCode" (closeModalEvent)="closeModal($event)"></app-referral-modal>
