import { Injectable } from '@angular/core';
import { commitHash } from 'src/environments/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor() { }

  /**
   * When app is build wether is production or not, the commit hash is stored in the version.ts file, this method returns the commit hash stored in the version.ts file
   * @returns the version commit hash of latest build (production or testing) or 'unknown' if the hash is not found
   */
  getVersionHash(): string {
    return commitHash || 'unknown';
  }
}
