import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { ActiveCampaingsBreadcrumb, TermsAndConditionsBreadcrumb } from '../breadcrumbData';

@Component({
  selector: 'app-active-campaigns',
  templateUrl: './active-campaigns.component.html',
  styleUrls: ['./active-campaigns.component.scss']
})
export class ActiveCampaignsComponent implements OnInit {

  activeCampaigns = [
    {
      text: 'Campaña cero comisión',
      to: 'comision-cero'
    },
    {
      text:'Campaña de Referidos',
      to: 'campania-referidos'
    },
    {
      text:'Campaña dia de la madre 2024',
      to:'campania-dia-de-la-madre'
    },
    {
      text:'Sorteo Perú Gourmet',
      to:'sorteo-peru-gourmet'
    },
    {
      text: 'Sorteo cero costo envío',
      to: 'sorteo-cero-costo-envio'
    },
    {
      text: 'Sorteo Gift Card',
      to: 'sorteo-gift-card'
    }
  ]
  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([TermsAndConditionsBreadcrumb, ActiveCampaingsBreadcrumb])
  }

}
