<bcp-button shape="text" tier="primary" (click)="goToLanding()">
    <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r"></bcp-icon>
    Volver
</bcp-button>
<h2>Términos y condiciones</h2>
<p>Te dejamos los acuerdos para el uso de Mandadito y campañas promocionales:</p>
<ul>
    <li *ngFor="let item of terminosCondicionesList; let i = index" class="link" (click)="goToItem(i)">
        <p [routerLink]="[item.to]">{{item.label}}</p>
    </li>
</ul>
