<div class="favorite">
    <h3>Elige el destinatario</h3>
    <div class="new-receiver" (click)="sendData(true)">
        <div>
            <bcp-icon accessible-name="" accessible-title="" name="plus-circle-r" size="26.7" color="secondary-500"></bcp-icon>
            <p>Nuevo destinatario</p>
        </div>
        <bcp-icon accessible-name="" accessible-title="" name="angle-right-r" slot="start"></bcp-icon>
    </div>
    <div class="subtitle">Favoritos con cuenta en {{data?.operation?.currency === Currency.PEN.code ? 'soles': 'dólares'}}</div>
    <app-favorite-card [customer]="userData" [elements]="favorites" (elementEmitter)="selectFavorite($event)" [currency]="data?.operation?.currency"></app-favorite-card>

    <div class="button">
        <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="backToPrevious()">
        <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
            Volver
        </bcp-button>
    </div>
</div>
