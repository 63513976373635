import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-favorite-start',
  templateUrl: './favorite-start.component.html',
  styleUrls: ['./favorite-start.component.scss']
})
export class FavoriteStartComponent {

  @Input() disabled = false;
  @Input() active = false;
  @Output() emitFavorite = new EventEmitter<boolean>();

  activeEvent(): void {
    if (this.disabled) return;
    this.active = !this.active;
    this.emitFavorite.emit(this.active);
  }
  

}
