import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { API_CODES, PATHS } from 'src/app/core/constants';
import { MainService } from 'src/app/core/services/main.service';

@Injectable({
  providedIn: 'root'
})
export class DeploymentWindowGuard implements CanActivate {
  private router = inject(Router)
  private mainService = inject(MainService)

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.mainService.getValidationUserOperations().pipe(catchError(err => {
      let errorResponse = err.error.errors[0]
      return of(errorResponse.code) 
    }), map(res => {
      if (state.url.includes(PATHS.DEPLOYMENT)) {
        return res === API_CODES.API_OPER_DEPLOY_MAINTENANCE ? true : this.router.parseUrl(PATHS.LANDING)
      }
      return res === API_CODES.API_OPER_DEPLOY_MAINTENANCE ? this.router.parseUrl(PATHS.DEPLOYMENT) : true
    }))
  }
}
