import { Injectable, inject } from '@angular/core';
import { API_CODES, IP_PROVIDER, ModalTypes, PATHS, inputs } from '../constants';
import { LocationApiService } from './location-api.service';

import currentDevice from 'current-device';
import { detect } from 'detect-browser';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private locationApiService = inject(LocationApiService)

  getMessageErrorByErrorType(input: string, error: string): string {
    return inputs[input][error];
  }

  getMessage({submitted, form, input}): string {
    const formControl = form.get(input);
    if (submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (submitted && !!formControl && formControl.errors &&
      !formControl.errors.hasOwnProperty('required')) {
      return this.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }

    return '';
  }

  getError({submitted, form, input}): string {
    const formControl = form.get(input);

    if (submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (submitted && !!formControl && formControl.errors &&
      !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    return '';
  }

  getTimer(distance: number): string | boolean {

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 1000) return false;
    // distance -= 1000;

    return `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${
      minutes ? minutes + 'm' : ''
    } ${seconds ? seconds + 's' : ''}`;

  }

  getSimpleTimer(distance: number): string | boolean {
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 1000) return false;

    const minutesText = minutes > 9 ? `${minutes}` : `0${minutes}`;
    const secondsText = seconds > 9 ? `${seconds}` : `0${seconds}`;

    let result = `${ minutesText }:${secondsText} min`;
    if (!minutesText) result = `${secondsText} min`;
    return result;
  }

  sessionTimeoutLimitExpired(time): boolean {
    let response = false;
      const currentTime = new Date().getTime();
      if (currentTime > time) {
        response = true;
      }
      return response;
  }

  validateOperation({ loaderService, mainService, router, operation, from }): Promise<{name: string, isRepeatingMandadito: boolean} | boolean> {
    return new Promise((resolve, _) => {
      loaderService.requestStarted()
      mainService.getValidationUserOperations(operation.amount).subscribe({
        next: (_) => {
          const total = operation.requestedAmount;
          router.navigate([PATHS.NEW_MANDADITO], { queryParams: { receiverId: operation.receiverId, amount: total } })
          resolve(true);

        },
        error: (err) => {
          loaderService.requestEnded()
          const code = err.error?.errors[0]?.code;
          if (code === API_CODES.API_OPER_ERROR_MAX_QUANTITY_MONTH) {
            resolve({
              name: ModalTypes.MAX_MONTH,
              isRepeatingMandadito: true,
            });
          } else if (code === API_CODES.API_OPER_ERROR_MAX_AMOUNT_DAY) {
            const total = operation.requestedAmount
            router.navigate([PATHS.NEW_MANDADITO], { queryParams: { receiverId: operation.receiverId, amount: total } })
          } else if (code === API_CODES.API_OPER_ERROR_ACCESS_TIME) {
            resolve({
              name: ModalTypes.ACCESS_TIME,
              isRepeatingMandadito: true
            })
          }
        }
      })
    });
  }

  getBrowserByUserAgent(): string {
    const browsers = { crios: 'chrome', ios: 'safari' };
    const browser = detect();
    return browsers[browser.name] || browser.name;
  }

  getDeviceByUserAgent(): string {
    return currentDevice.type;
  }


  getOperationSystemByUserAgent(): string {
    return currentDevice.os;
  }

  async getApiData() {
    let locationApi = null;
    const [ipApi, ipInfo] = await Promise.all([
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_API),
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_STACK)
    ]);

    let goAway = true;
    if (ipApi?.isLocationAllowed || ipInfo?.isLocationAllowed) {
          goAway = false;
          locationApi = ipApi?.isLocationAllowed ? ipApi : ipInfo;
    }

    return { goAway, locationApi };
  }

}
