import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { IBreadcrumbItem } from 'src/app/shared/models/model';
import { PastCampaignsBreadcrumb, PatrioticHolidayCampaignBreadcrumb, TermsAndConditionsBreadcrumb } from '../../breadcrumbData';

@Component({
  selector: 'app-patriotic-holidays-campaign',
  templateUrl: './patriotic-holidays-campaign.component.html',
  styleUrls: ['./patriotic-holidays-campaign.component.scss']
})
export class PatrioticHolidaysCampaignComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }


  setBreadcrumb() {
    const breadcrumbData: IBreadcrumbItem[] = [
      TermsAndConditionsBreadcrumb,
      PastCampaignsBreadcrumb,
      PatrioticHolidayCampaignBreadcrumb
    ]
    this.breadcrumbService.setBreadcrumb(breadcrumbData)
  }
}
