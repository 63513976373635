<div *ngFor="let filteredFavorite of filteredFavorites; let i = index" class="favorite cursor-pointer" (click)="selectFavorite(filteredFavorite)" >
    <div class="favorite-info">
        <div class="favorite-letter">{{ CurrencyTypes[filteredFavorite.accountCurrency].symbol }}</div>
        <div class="favorite-data">
            <span class="name">{{ filteredFavorite.myself || (!filteredFavorite.myself && filteredFavorite.documentNumber === customer.documentNumber)? '(Yo) ' : '' }}{{ filteredFavorite.name }}</span>
            <span class="account">{{maskAccountNumber(filteredFavorite.accountNumber)}}</span>
        </div>
    </div>
    <bcp-icon name="angle-right-r" ></bcp-icon>
</div>
