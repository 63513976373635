import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ASSETS, AlertTypes } from 'src/app/core/constants';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  @Input() type: string = AlertTypes.SUCCESS;
  @Input() msg: string = '';
  @Input() hasCloseAlert: boolean = false;
  @Output() emitter = new EventEmitter<boolean>();

  alertImg: string = ASSETS.IMG_CHECK_VALID;
  alertCloseImg: string = ASSETS.IMG_ICON_CLOSE_GREEN;

  constructor() { }

  ngOnInit(): void {
    this.setStyleByType();
  }

  setStyleByType() {
    if (this.type === AlertTypes.ERROR) {
      this.alertImg = ASSETS.IMG_CHECK_ERROR;
    }
    else{
      this.alertImg = ASSETS.IMG_CHECK_SUCCESS;
    }
  }

  triggerEvent(): void {
    this.emitter.emit(false);
  }
}
