<app-header></app-header>
<div class="content flex-column">
    <bcp-button shape="text" tier="primary" (click)="goToLanding()">
        <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r"></bcp-icon>
        Volver
    </bcp-button>

    <h2>Preguntas frecuentes</h2>
    
    <ul class="tab">
        
        <li *ngFor="let tab of tabs; let i = index" 
        [ngClass]="{'active': tab.status}"
        (click)="activeTab(i)">
            <span class="full-screen">
                {{ tab.text }}
            </span>
            <span class="mobile">
                {{ tab.textResponsive }}
            </span>
        </li>
    </ul>
    <div class="text" [ngClass]="{'d-block': tabs[0].status}">
        <app-accordion [activeFirst]="false">
            <app-accordion-item *ngFor="let element of general; let e = index" [title]="element.title" [index]="e" (toggleAccordion)="toggleAccordion($event, e+1)">
                <p [innerHTML]="element.text"></p>
            </app-accordion-item>
        </app-accordion>
    </div>
    <div class="text" [ngClass]="{'d-block': tabs[1].status}">
        <app-accordion [activeFirst]="false">
            <app-accordion-item *ngFor="let element of requirements; let e = index" [color]="'secondary_300'" [title]="element.title" [index]="e" (toggleAccordion)="toggleAccordion($event, e+general.length+1)">
                <p [innerHTML]="element.text"></p>
            </app-accordion-item>
        </app-accordion>
    </div>
    <div class="text" [ngClass]="{'d-block': tabs[2].status}">
        <app-accordion [activeFirst]="false">
            <app-accordion-item *ngFor="let element of shippingReceiving; let e = index" [color]="'analogous_01_300'" [title]="element.title" [index]="e" (toggleAccordion)="toggleAccordion($event, e+general.length+requirements.length+1)">
                <p [innerHTML]="element.text"></p>
            </app-accordion-item>
        </app-accordion>
    </div>
    
    <article class="wp-banner">
        <img src="assets/images/landing/spot-heroes-p-sh-puff-laptop-dog-man-l.svg" class="wp-banner-piggy" alt="Piggy Cellphone" *ngIf="isDesktop">
        <img src="assets/images/landing/spot-heroes-p-sh-puff-laptop-dog-man-l-full.svg" class="wp-banner-piggy" alt="Piggy Cellphone" *ngIf="!isDesktop">
        <div class="wp-banner-content">
            <p>No te quedes con dudas, <strong>escríbenos por WhatsApp</strong></p>
            <div class="wp-banner-number">
                <img src="assets/images/landing/whatsapp-icon.svg" alt="Whatsapp">
                <a [href]="whatsappUrl" target="_blank"><span>+51 945 657 689</span></a>
            </div>
        </div>
    </article>

</div>
<app-footer></app-footer>
