import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  terminosCondicionesList: Array<any> = [
    {
      label: 'Términos generales',
      to: 'terminos-generales'
    },
    {
      label: 'Campañas activas',
      to: 'campanias-activas'
    },
    {
      label: 'Campañas anteriores',
      to: 'campanias-anteriores'
    },
  ]
  constructor(private router: Router, private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  goToLanding() {
    this.router.navigate([PATHS.LANDING])
  }

  goToItem(index: number) {
    this.router.navigate([this.terminosCondicionesList[index].to])
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([])
  }
}
