<div class="favorite">

    <h3 class="flexo-demi">Confirma los datos de tu envío</h3>

    <p class="total font-size-14 flexo-demi font-size-md-16">Enviar a</p>
    <ul>
        <li class="vertical-align-top">
            <span>Destinatario</span>
            <p class="flexo-demi receiver-name">{{ getFullName(data?.receiver) }}</p>
        </li>
        <li>
            <span>&nbsp;</span>
            <span class="flex justify-content-end" [ngClass]="{'cursor-pointer': !data?.isFavorite}">
                <app-favorite-start [disabled]="data?.isFavorite" (emitFavorite)="addFavorite($event)" [active]="data?.isFavorite ? true : data?.checkFavorite"></app-favorite-start>
            </span>
        </li>
        <li>
            <span>&nbsp;</span>
            <p class="d-flex flex-column gap-1 color-gray-100 font-size-12 text-right">
                <span class="flexo">DNI {{ data?.receiver?.documentNumber }}</span>
                <span class="flexo">{{ data?.receiver?.email }}</span>
                <span class="flexo mb-2" *ngIf="data?.receiver">{{ formatPhoneNumber(data?.receiver) }}</span>
            </p>
        </li>
        <li>
            <span>Cuenta {{data?.operation?.currency === Currency.PEN.code ? 'soles': 'dólares'}} BCP</span>
            <p class="flexo-demi">{{maskAccountNumber(data?.receiver?.accountNumber)}}</p>
        </li>
    </ul>
    <br>
    <p class="total font-size-14 flexo-demi font-size-md-16">Código promocional</p>
    <app-coupon (emitCoupon)="getCouponData($event)"></app-coupon>

    <br>
    <p class="total font-size-14 flexo-demi font-size-md-16">Detalle del envío</p>
    <ul>
        <li class="position-relative">
            <span>Tú envías</span>
            <p class="flexo-demi">$ {{ data?.operation?.amount | number:'1.2-2' }}</p>
        </li>

        <li class="position-relative" [ngClass]="{'add-height': data?.coupon && data?.coupon.couponType !== 'Comisión'}">
            <div class="detail-item">
                <span class="detail-item__subtitle">{{ data?.receiver?.firstName }} recibe</span>
                <span class="detail-item__tag bg-orange flexo-demi font-size-10"
                    *ngIf="coupon && coupon?.couponType !== 'Comisión'">+
                    <span> {{ coupon?.couponType === 'Tipo de cambio' || data?.operation?.currency === Currency.PEN.code ? 'S/' : '$' }}</span>
                    {{ getAdditionalAmount() | number:'1.2-2' }}</span>
                <span class="position-absolute font-size-12" *ngIf="coupon && coupon?.couponType !== 'Comisión'">Por código promocional</span>
            </div>
            <p class="detail-item__amount flexo-demi position-relative">{{data?.operation?.currency === Currency.PEN.code ? 'S/' : '$'}} {{ getReceivedAmount() | number:'1.2-2' }}
                <span class="detail-item__previous flexo font-size-12 font-size-md-14"
                    *ngIf="coupon && coupon?.couponType !== 'Comisión'"> {{ coupon?.couponType === 'Tipo de cambio' ? 'S/' : '$' }} {{ getInitialRecievedAmount() | number:'1.2-2' }}</span>
            </p>
        </li>
        <li class="position-relative"
            [ngClass]="{'add-height': data?.coupon && data?.coupon.couponType !== 'Comisión'}"
            *ngIf="data?.operation?.currency === Currency.PEN.code">
            <div class="detail-item">
                <span class="detail-item__subtitle">Tipo de cambio</span>
                <span class="detail-item__tag bg-orange flexo-demi font-size-10"
                    *ngIf="!!coupon?.profitExchangeRate">+{{ coupon?.profitExchangeRate }}</span>
                <span class="position-absolute font-size-12" *ngIf="!!coupon?.profitExchangeRate">Por código promocional</span>
            </div>
            <p class="position-relative flexo-demi detail-item__amount">1 USD = {{ getExchangeRate() | number:'1.2-2' }} PEN
                <span class="detail-item__previous flexo font-size-12 font-size-md-14"
                    *ngIf="!!coupon?.profitExchangeRate">{{userData.exchangeRate}} PEN</span>
            </p>
        </li>
        <li class="position-relative" [ngClass]="{'active-referred': data?.operation?.promotion?.promotionType && ['REFERIDO', 'REFERIDOR'].includes(data?.operation?.promotion?.promotionType), 'add-height': data?.operation?.promotion?.discount > 0}">
            <div class="detail-item">
                <span class="detail-item__subtitle">Comisión</span>
                <span class="detail-item__tag bg-orange flexo-demi font-size-10" *ngIf="data?.operation?.promotion">{{ data?.operation?.promotion?.labelTwo }}</span>
                <span class="position-absolute font-size-12" *ngIf="data?.operation?.promotion">{{ data?.operation?.promotion?.promotionType === 'REFERIDO' ? 'Por usar un enlace de referido' : 'Usaron tu enlace de referido' }}</span>
            </div>
            <p class="detail-item__amount position-relative flexo-demi"><span class="original amount text-right" *ngIf="data?.operation?.promotion?.discount > 0">$ {{ +data?.operation.realCommission | number:'1.2-2' }}</span> 
                $ {{ ( data?.operation?.promotion?.discount > 0 ? data?.operation?.commission : data?.operation?.realCommission) | number:'1.2-2' }}</p>
        </li>
        <li *ngIf="coupon && data?.coupon?.couponType === 'Comisión'">
            <span class="position-relative">Descuento por código</span>
            <p class="position-relative flexo-demi coupon-discount">-$ {{ data?.operation?.realCommission * coupon.discount | number:'1.2-2' }}</p>
        </li>
        <li class="total-pay">
            <span class="font-size-16 font-weight-bold">Total a pagar</span>
            <p class="font-size-16 flexo-demi font-weight-bold">$ {{ data?.operation?.total | number:'1.2-2' }}</p>
        </li>
    </ul>

    <div class="mt-4">
        <form action="" class="text-area">
            <bcp-textarea rows="5" [message]="lengthCharacters"
            placeholder="Mensaje (Opcional)" (ctrlChange)="textareaChange($event)"
            maxlength="100"></bcp-textarea>
        </form>
    </div>

    <div class="inputAccept">
        <bcp-checkbox tier="secondary" size="lg" value="1" (ctrlChange)="triggerAccept($event)">Estoy de acuerdo con los <a [href]="urlTermsConditions" target="_blank"><u>Términos y condiciones</u>*</a></bcp-checkbox>
    </div>
    <div class="button">
        <bcp-button class="mt-2" shape="rectangle" tier="secondary"  size="lg" full-width="true" (click)="goBack(step)">
            <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
            Volver
        </bcp-button>
        <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="sendData()" [disabled]="acceptConditions !== true">Confirmar</bcp-button>
    </div>
    <div class="error text-center mt-3" *ngIf="error.show" [innerHTML]="error.message"></div>
</div>

<app-shared-modals [modal]="modal" [screen]="'Envío paso 3'"></app-shared-modals>
