<div class="modal-background" *ngIf="showModal">
    <div class="referral-modal">
        <div class="referral-modal-header">
            <img src="assets/images/close-white.svg" alt="cerrar" class="close-btn" (click)="closeModal()" />
            <img src="assets/images/gift@3x.png" alt="regalo" class="gift" />
            <div class="text">
                <span>Refiere y gana</span>
                <span>ENVÍOS GRATIS</span>
            </div>
        </div>
        <div class="referral-modal-content">
            <h2 class="main-title">¿Como funciona?</h2>
            <div class="list">
                <div class="list-item">
                    <div class="number">1</div>
                    <span>Comparte tu enlace</span>
                </div>
                <div class="list-item">
                    <div class="number">2</div>
                    <span>Tu referido hace su primer envío</span>
                </div>
                <div class="list-item">
                    <div class="number">3</div>
                    <span>¡Y listo! <strong>Ganarás 1 envío gratis por referido</strong></span>
                </div>
            </div>
            <div class="info">
                <img src="assets/images/gift-blue.svg" alt="">
                <span class="info-text">Tus referidos ganan un segundo envío gratis al usar tu enlace</span>
            </div>
            <div class="wp-btn">
                <bcp-button color="primary" full-width="true" size="lg" (click)="shareReferralCode()">
                    <img src="assets/images/ic_social_whatsapp.svg" alt="">
                    Compartir mi enlace
                </bcp-button>
            </div>
        </div>
    </div>
</div>