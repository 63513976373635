import { IBreadcrumbItem } from "src/app/shared/models/model";

export const TermsAndConditionsBreadcrumb: IBreadcrumbItem = {
    text: 'Términos y condiciones',
    textBack: '',
    route: 'terminos-y-condiciones'
}

export const ActiveCampaingsBreadcrumb: IBreadcrumbItem = {
    text: 'Campañas activas',
    textBack: 'Términos y condiciones',
    route: 'terminos-y-condiciones/campanias-activas'
}

export const ZeroComissionCampaignBreadcrumb: IBreadcrumbItem = {
    text: 'Campaña cero comisión',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/comision-cero'
}

export const GeneralTermsBreadcrumb: IBreadcrumbItem = {
    text: 'Términos generales',
    textBack: 'Términos y condiciones',
    route: 'terminos-y-condiciones/terminos-generales'
}

export const PastCampaignsBreadcrumb: IBreadcrumbItem = {
    text: 'Campañas anteriores',
    textBack: 'Términos y condiciones',
    route: 'terminos-y-condiciones/campanias-anteriores'
}

export const PatrioticHolidayCampaignBreadcrumb: IBreadcrumbItem = {
    text: 'Campaña fiestas patrias',
    textBack: 'Campañas anteriores',
    route: 'terminos-y-condiciones/campanias-anteriores/fiestas-patrias'
}

export const ChristmasCampaingBreadcrumb: IBreadcrumbItem = {
    text: 'Campaña Navideña 2023',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/campania-navidena'
}

export const ReferralsCampaignBreadcrumb: IBreadcrumbItem = {
    text: 'Campaña de Referidos',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/campania-referidos'
}


export const MothersDayCampaignBreadcrumb: IBreadcrumbItem = {
    text: 'Dia de la madre 2024',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/campania-dia-de-la-madre'
}

export const PeruGourmetBreadcrumb: IBreadcrumbItem = {
    text: 'Sorteo Perú Gourmet',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/sorteo-peru-gourmet'
}

export const ZeroComissionRaffleBreadcrumb: IBreadcrumbItem = {
    text: 'Sorteo cero costo envío',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/sorteo-cero-costo-envio'
}

export const GiftCardRaffleBreadcrumb: IBreadcrumbItem = {
    text: 'Sorteo Gift Card',
    textBack: 'Campañas activas',
    route: 'terminos-y-condiciones/campanias-activas/sorteo-gift-card'
}
