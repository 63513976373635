<div class="header">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second">
        <div class="password-updated">
            <img src="/assets/images/spot-heroes-p-sh-puff-laptop-dog-man-l.svg" alt="contraseña actualizada">
            <h1 class="main-title text-center">¡Contraseña actualizada!</h1>
    
            <div class="button mt-2">
                <bcp-button shape="rectangle" tier="primary" size="lg" (click)="goToLogin()" full-width="true">Mandar dinero ahora</bcp-button>
            </div>
    
            <bcp-button shape="text" tier="primary" (click)="goToHome()">Volver a Mandaditobcp.com.pe</bcp-button>
        </div>
        
    </div>
</div>