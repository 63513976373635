import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CarouselSlideComponent } from "./components/carousel-slide/carousel-slide.component";
import { AccordionComponent } from "./components/accordion/accordion/accordion.component";
import { AccordionItemComponent } from "./components/accordion/accordion-item/accordion-item.component";
import { ModalComponent } from './components/modal/modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FavoriteCardComponent } from "./components/favorite-card/favorite-card.component";
import { MenuComponent } from "./components/menu/menu.component";
import { StepsComponent } from "./components/steps/steps.component";
import { AmountCardComponent } from "./components/amount-card/amount-card.component";
import { ClickOutisdeDirective } from './directives/click-outisde.directive';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ShortenBreadcrumbPipe } from "../core/pipes/shorten-breadcrumb.pipe";
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';
import { SharedModalsComponent } from './components/shared-modals/shared-modals.component';
import { ImgComponent } from './components/img/img.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ReloadComponent } from './components/reload/reload.component';
import { UploadDniComponent } from './components/upload-dni/upload-dni.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { StepsNumberComponent } from './components/steps-number/steps-number.component';
import { ReferralBannerComponent } from './components/referral-banner/referral-banner.component';
import { ReferralModalComponent } from './components/referral-modal/referral-modal.component';
import { SlideTouchComponent } from './components/slide-touch/slide-touch.component';
import { ElementComponent } from "./components/slide-touch/element/element.component";
import { EmptyHistoryComponent } from './components/empty-history/empty-history.component';
import { MenuPanelComponent } from './components/menu-panel/menu-panel.component';
import { MdInputComponent } from './components/md-input/md-input.component';
import { ControlValueAccessorDirective } from './directives/control-value-accessor.directive';
import { MdValidationErrorsComponent } from './components/md-validation-errors/md-validation-errors.component';
import { LastPageService } from "./services/last-page.service";
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { HeaderStepperComponent } from './components/header-stepper/header-stepper.component';


const elements = [
  CarouselSlideComponent,
  AccordionComponent,
  AccordionItemComponent,
  ModalComponent,
  LoaderComponent,
  FavoriteCardComponent,
  MenuComponent,
  StepsComponent,
  AmountCardComponent,
  BreadcrumbComponent,
  TwoDigitDecimalNumberDirective,
  SharedModalsComponent,
  ImgComponent,
  CollapseComponent,
  CarouselComponent,
  ReloadComponent,
  UploadDniComponent, 
  UploadFileComponent,
  StepsNumberComponent,
  ReferralBannerComponent,
  ReferralModalComponent,
  SlideTouchComponent,
  ElementComponent,
  EmptyHistoryComponent,
  MenuPanelComponent,
  MdInputComponent,
  MdValidationErrorsComponent,
  AlertMessageComponent,
  HeaderStepperComponent,
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [...elements, ClickOutisdeDirective, ShortenBreadcrumbPipe, ControlValueAccessorDirective ],
  exports: [...elements, FormsModule, ReactiveFormsModule],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    LastPageService
  ]
})
export class SharedModule { }
