import { Component, OnInit, OnDestroy, Output, EventEmitter, inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GtmEvent, UserInterface } from 'src/app/core/interfaces';
import { Subscription, distinctUntilChanged, tap } from 'rxjs';
import { GtmService } from 'src/app/core/services/gtm.service';
import { DocumentTypes } from 'src/app/shared/models/model';
import { LastPageService } from 'src/app/shared/services/last-page.service';
import { PATHS } from 'src/app/core/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class ThirdStepComponent implements OnInit, OnDestroy {

  backgroundIcon = null;
  @Output()
  setData = new EventEmitter<UserInterface>();
  @Output()
  skipStep = new EventEmitter<boolean>();
  @Input()
  data: UserInterface;
  showGeneralError = false;
  howToFindSubscription: Subscription;
  documentTypeSubscription: Subscription;
  selectedDocument: '' | 'DNI' | 'DL'
  documentTypes = DocumentTypes
  title: string = '';
  subtitle: string = '';
  showDescription: boolean = false;

  documents = {
    front: null,
    back:  null
  };

  formRegister: FormGroup;
  submitted = false;

  previousDocumentNumber = '';
  btnDescription: string = '';
  private gtmService = inject(GtmService);
  private lastPageService = inject(LastPageService);
  private localStorageService = inject(LocalStorageService);

  ngOnInit(): void {
    this.initializeForm();
    this.subscribeToDocumentTypeChanges();
    this.getBtnDescription();
  }

  ngOnDestroy(): void {
    this.howToFindSubscription?.unsubscribe();
    this.documentTypeSubscription.unsubscribe();
  }

  initializeForm(): void {
    this.formRegister = new FormGroup({
      docTypeloaded: new FormControl(''),
    });
  }

  skipSavePhoto() {
    this.skipStep.emit(true);
  }

  sendData(): void {
    this.submitted = true;
    if (this.formRegister.invalid) {
      return;
    }

    if (this.documents.front === null || this.documents.back === null) {
      this.showGeneralError = true;
      return;
    }

    const data: UserInterface = {
      fields: {
        ...this.formRegister.value,
        ...this.data.fields
      },
      document_front: this.documents.front,
      document_back: this.documents.back
    };

    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_registro2_registrarme',
      pantalla: 'Registro2',
      email: this.localStorageService.getStorage('registerData')?.firstValue || ''
    };
    this.gtmService.sendEvent(event);
    this.setData.emit(data);
  }

  get isContinueButtonDisabled():boolean{
    return this.documents.front === null 
    || this.documents.back === null 
    || this.formRegister.invalid 
    || this.documents.front?.status !== 'success'
    || this.documents.back?.status !== 'success'
  }

  setDocumentByType(type: string, event): void {
    this.documents[type] = event;
  }

  subscribeToDocumentTypeChanges(){
   this.documentTypeSubscription = this.formRegister.get('docTypeloaded').valueChanges.pipe(distinctUntilChanged(), tap(docType=>{
      this.selectedDocument = docType
    })).subscribe()
  }

  getBtnDescription() {
    const fromLogin = this.lastPageService.getLastPage() === PATHS.LOGIN;
    this.btnDescription = fromLogin ? 'Subir foto' : 'Registrarme';
  }
}
