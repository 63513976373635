import { Component, OnInit ,inject ,EventEmitter,Output, ViewChild , ElementRef,Renderer2 } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { FormControl, FormGroup , Validators} from '@angular/forms';
import { MainService } from 'src/app/core/services/main.service';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-by-dni',
  templateUrl: './search-by-dni.component.html',
  styleUrls: ['./search-by-dni.component.scss']
})
export class SearchByDniComponent implements OnInit {

  constructor(
    private renderer: Renderer2
  ) { }
  formReceiver: FormGroup;
  showSpinner: boolean = false;
  showcomplete : boolean = false
  //Variables para captcha
  isInvalidCaptcha = true;
  captchaResponse: string;
  recaptchaSiteKey = environment.recaptcha.siteKey;
  showCaptcha: boolean = false;
  uuidValue: string = '';
  
  attempts: number = 0;

  private mainService = inject(MainService)
  @Output() sendExchangeRate= new EventEmitter();
  @ViewChild('dniInput') dniInput: ElementRef;

  ngOnInit(): void {
    this.initializeForm();
    this.generateUuid();
  }

  initializeForm() {
    this.formReceiver = new FormGroup({
      documentNumber: new FormControl('', [Validators.required, 
        Validators.pattern(/^[0-9]{8,8}$/), Validators.minLength(8), Validators.maxLength(8)]),
    });
  }

  getMessage(input: string) {
    if (input === 'documentNumber') {
      return 'Ingresa los 8 dígitos';
    }
    return '';
  }

  getError(input: string) {
    const formControl = this.formReceiver.get(input);

    if (formControl && formControl.errors && !formControl.pristine && formControl.errors['required']) {
      return "error";
    }

    if (formControl && formControl.errors && !formControl.pristine && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    return '';
  }

  searchByDNI(){
    if (this.formReceiver.invalid ) {
      return;
    }

    if (this.attempts >= 3 ) {
      this.showCaptcha  = true;
      return;
    }

    if(!this.showSpinner){
      this.showSpinner = true;
      this.getExchangeRate();
    }
  }

  getExchangeRate(captcha?: string) {
    const documentNumber = this.formReceiver.get('documentNumber');
    this.mainService.getPublicExchangeRateDni(documentNumber.value, this.uuidValue, captcha).pipe(tap(res => {
      this.showcomplete = true;
      this.showSpinner = false;
      this.attempts++;
      this.sendExchangeRate.emit({'res': res, 'show': true});
    })).subscribe()
  }

  keyEnter($event) { 
    const { detail } = $event ;
    if(detail.keyCode == 13){
      this.searchByDNI();
    } 
  } 
 

  excludeLetters(event: KeyboardEvent){
    return !!event.key.match(/^[0-9]+$/)
  }

  onChangeInput(){
    this.sendExchangeRate.emit({'res': null, 'show': false});
    this.showcomplete = false;
  }
  
  resolved(captchaResponse: any) {
    this.isInvalidCaptcha = true;
    if (captchaResponse && captchaResponse.length > 10) {
      this.captchaResponse = captchaResponse;
      this.getExchangeRate(captchaResponse);
      this.restartCaptcha();
    }
  }

  restartCaptcha(){
    this.isInvalidCaptcha = false;
    this.showCaptcha = false;
    //this.attempts = 0
  }

  generateUuid() {
    this.uuidValue = uuid();
  }
}
