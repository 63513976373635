import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { IBreadcrumbItem } from '../../models/model';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  isDesktop = true;
  data: IBreadcrumbItem[]

  breadcrumbSubscription$: Subscription

  constructor(private router: Router, private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit(): void {
    this.checkPlatform()
    this.breadcrumbSubscription$ = this.breadcrumbService.asObservable().subscribe(res => this.data = res)
  }

  goToRoute(route: string) {
    this.router.navigate([route])
  }

  /**
   * We listen to any windows resize event so we can track the viewport size
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResizeEvent(event: any) {
    this.checkPlatform()
  }

  private checkPlatform() {
    // Using 768px as threshold for mobile devices
    this.isDesktop = window.innerWidth >= 786;
  }

  ngOnDestroy(): void {
    this.breadcrumbSubscription$.unsubscribe()
  }

}
