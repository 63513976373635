<div class="full-screen">
    <div class="background">
        <div class="mant-card">
            <img src="assets/images/logo-1-logo-light.svg" alt="" class="bcp-logo">
            <div class="mant-card-content">
                <div class="text-display">
                    <p class="title">Estamos trabajando para mejorar tu experiencia</p>
                    <p class="subtitle">
                        Volveremos pronto para que puedas seguir enviado dinero de Florida a Perú.
                    </p>
                </div>
                <img src="assets/images/piggy-mantenimiento.svg" alt="piggy-maintenance" class="piggy-maintenance">
            </div>
        </div>
    </div>
</div>

<div class="mobile">
    <div class="mobile-content">
        <app-header-exp [completed]="true"></app-header-exp>
        <div class="main-content">
            <img src="assets/images/piggy-mantenimiento.svg" alt="piggy-maintenance" class="piggy-maintenance">
            <p class="title">Estamos trabajando para mejorar tu experiencia</p>
            <p class="subtitle">
                Volveremos pronto para que puedas seguir enviado dinero de Florida a Perú.
            </p>
        </div>
    </div>
</div>
