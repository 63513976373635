<app-referral-banner></app-referral-banner>
<app-menu-panel [isMenuOpened]="isMenuOpened" (toggleFullscreenMenu)="toggleFullscreenMenu()" class="mobile"></app-menu-panel>
<div class="content flex-column" [ngClass]="{'d-none': hideContent}">
    <div class="app-menu">
        <app-menu [activeBackground]="activeHome" (toggleFullscreenMenu)="toggleFullscreenMenu()"></app-menu>
    </div>
    <div class="background" *ngIf="!activeHome"></div>

    <ng-container *ngIf="isConfirmMessage">
        <app-confirm-message (emitter)="isConfirmMessage = $event"></app-confirm-message>
    </ng-container>

    <div class="home">

        <div class="history-list mt-0 mb-3 position-relative" [ngClass]="{'d-none': !activeHome}">

            <div class="tutorial-element d-flex flex-column pointer-events-none" *ngIf="showTutorial && currentStep === 0">
                <ul class="d-flex align-items-center justify-content-between mb-0 position-relative">
                    <li class="flexo-demi font-size-20 font-size-md-24 mb-0">Historial de envíos</li>
                    <li class="font-size-12">
                        <bcp-button shape="text" tier="primary">
                            Ver todos
                        </bcp-button>
                    </li>
                </ul>
                <p class="font-size-12 font-size-md-14 color-onsurface-700 mb-0">Aquí encontrarás tus Mandaditos enviados con éxito</p>
            </div>

            <app-history-list [historyElements]="historyElements" [showTutorial]="showTutorial" [currentStep]="currentStep"></app-history-list>
        </div>
        <div class="payment" style="min-height: 110px;" [ngClass]="{'position-relative':  showTutorial}">

            <div class="popup" [ngClass]="{'second-step': currentStep === 1}" *ngIf="showTutorial">
                <h4 class="color-white font-size-20 font-size-md-24 mb-0">{{ tutorialSteps[currentStep].title }}</h4>
                <p class="color-white font-size-14 font-size-md-16 mb-0">{{ tutorialSteps[currentStep].message }}</p>
                <ul class="dots">
                    <li *ngFor="let step of tutorialSteps; let i = index" [ngClass]="{'active': currentStep === i}"></li>
                </ul>
                <a href="javascript:;" (click)="nextStep()"><span class="font-size-16">{{ tutorialSteps[currentStep].button }}</span> <img class="ml-2" src="/assets/images/right-arrow.svg" *ngIf="currentStep === 0" width="20" alt="->" /></a>
            </div>

            <app-alert-message  (emitter)="handleEvent($event)" type="success" [hasCloseAlert]="true" [msg]="msgInvalidForm" *ngIf="showAlert"></app-alert-message>
        
            <ul class="mb-3">
                <li class="primary-700 flexo-demi font-size-20 line-height-28 font-size-24 line-height-32">Envía un Mandadito</li>
            </ul>
            <app-send [activeHome]="activeHome" (emitFlow)="triggerEvent($event)" [showSteps]="!activeHome" [activeNextStep]="activeNextStep" [initialData]="data" (triggerRemittanceHistoryLoad)="getHistory()" (continue)="chooseReceiver($event)"></app-send>
        </div>
        <section class="d-block d-md-none mt-4 pt-3 position-relative" [ngStyle]="{'z-index': 1}" *ngIf="historyElements.length === 0"  [ngClass]="{'d-none': !activeHome}">
            <ul class="d-flex align-items-center justify-content-between">
                <li class="flexo-demi font-size-20 line-height-28 font-size-24 line-height-3 mb-0 primary-700">Historial de envíos</li>
                <li class="font-size-12">
                    <bcp-button shape="text" tier="primary" (click)="goToHistory()">
                        Ver todos
                    </bcp-button>
                </li>
            </ul>
            <app-empty-history></app-empty-history>
        </section>
    </div>
</div>


<div class="tutorial" *ngIf="showTutorial">
    <div class="background"></div>
</div>

<div *ngIf="showExtendedHoursModal" class="modal-background">
    <div class="modal-container">
        <div class="extended-hours-modal">
            <bcp-icon name="close-r" size="24" (click)="goToStep1('Cerrar')" color="onsurface-600"></bcp-icon>
            <img src="assets/images/spc_clock_hand_d_l@3x.svg" alt="clock" />
            <p class="title">Por ser sábado, atenderemos tu solicitud el lunes</p>
            <p class="subtitle">Te notificaremos por correo y WhatsApp sobre el estado de tu solicitud</p>
            <bcp-button size="lg" color="primary" (click)="closeModalAndContinue(true)">De acuerdo, quiero continuar</bcp-button>
            <p class="back-button" (click)="goToStep1('Volver')">Volver</p>
        </div>
    </div>
</div>
