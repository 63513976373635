import { AbstractControl, ValidationErrors } from "@angular/forms";

export function atLeastEightCharacters(control: AbstractControl): ValidationErrors | null  {

    if (control.value && control.value.length >= 8) {
        return null;
    }

    return { atLeastEightCharacters: true };
}

export function atLeastOneNumber(control: AbstractControl): ValidationErrors | null  {
    if (control.value && control.value.match(/(?=.*\d)/)) {
        return null;
    }

    return { atLeastOneNumber: true };
}

export function atLeastOneUppercase(control: AbstractControl): ValidationErrors | null  {
    if (control.value && control.value.match(/(?=.*[A-Z])/)) {
        return null;
    }

    return { atLeastOneUppercase: true };
}

export function atLeastOneSpecialCharacter(control: AbstractControl): ValidationErrors | null  {
    if (control.value && control.value.match(/(?=.*[-+_!@#/$%^&*.,?])/)) {
        return null;
    }

    return { atLeastOneSpecialCharacter: true };
}

export function emailsMatch(email: string, repeatEmail: string) {
    return function(form: AbstractControl) {
        const emailValue = form.get(email)?.value;
        const repeatEmailValue = form.get(repeatEmail)?.value;
        if (emailValue === repeatEmailValue) {
            return null;
        }
        return { emailMatchError: true };
    }

}

export function passwordsMatch(password: string, repeatPassword: string) {
    return function(form: AbstractControl) {
        const passwordValue = form.get(password)?.value;
        const repeatPasswordValue = form.get(repeatPassword)?.value;
        if (passwordValue === repeatPasswordValue) {
            return null;
        }
        return { passwordMatchError: true };
    }

}

export function mustBeEqualToValue(value: string) {
    return function(control: AbstractControl): ValidationErrors | null  {
        if (control.value === value) {
            return null;
        }
        return { mustBeEqualToValue: true };
    }

}

export function mustNotBeEqualToValue(firstInput: string, secondInput: string) {
    return function(form: AbstractControl) {
        const firstValue = form.get(firstInput)?.value;
        const secondValue = form.get(secondInput)?.value;
        if (firstValue !== secondValue) {
            return null;
        }
        return { inputNotEqualError: true };
    }

}

export function mustBeLessThan(amount: number) {
    return function(control: AbstractControl): ValidationErrors | null  {
        if (control.value && control.value.length > 0 && control.value*1 <= amount) {
            return null;
        }
        return { mustBeLessThan: true };
    }

}

export function validateCouponError(error: string) {
    return function(control: AbstractControl): ValidationErrors | null  {
        if (control.value && control.value.length > 0 && error?.length === 0) {
            return null;
        }
        return { couponError: true };
    }

}