<div class="header">
  <img [src]="imgLogo" alt="">
</div>
<div class="content completed">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="second d-flex align-items-center">
    <div class="completed d-flex flex-column align-items-center">
      <img class="image" [src]="spotImgRegister" alt="completado">
      <h2 class="main-title mt-4 mt-md-3">{{ title }}</h2>
      <p class="subtitle" *ngIf="hasCompleteRegister">Ahora podrás enviar dinero desde Florida a todo el Perú</p>
      <div class="completed__alert-info" *ngIf="!hasCompleteRegister">
        <img width="20" [src]="imgIconInfo">
        <p class="font-size-12 font-size-md-14">Recuerda que te pediremos subir la foto de tu documento para empezar a enviar dinero en Mandadito</p>
      </div>
      <div class="button mt-3 mt-md-2">
        <bcp-button id="button-register-go-new-mandadito" shape="rectangle" tier="primary" size="lg" full-width="true"
          type="button" (ctrlClick)="goToNewMandadito()">{{ btnDescription }}</bcp-button>
      </div>
      <div class="completed__banner" *ngIf="hasCompleteRegister">
        <img src="/assets/images/piggy-laptop.svg" alt="Primera comisión gratis">
        <div class="completed__banner-content">
          <p class="completed__banner-title">Tu primer envío es <span class="title-style">GRATIS</span></p>
          <p class="completed__banner-description">No te cobraremos comisión en tu primer Mandadito</p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal [size]="'sm'" *ngIf="showModalAccessTime">
  <section class="error-modal">
    <div class="d-flex flex-column">
      <img width="147" class="mx-auto" src="/assets/images/sleeping-piggy.svg" alt="pig" />
      <h3 class="mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700">Por ahora no
        podemos<br />atenderte</h3>
      <p class="mt-3 flexo-regular font-size-md-16 font-size-14 line-height-24 text-center">Nuestro horario de envíos es
        de lunes a viernes entre 6 a.m. a 9 p.m EST</p>
      <div class="extra-button-size mx-auto mt-3">
        <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="goToHome()">Volver a
          Mandaditobcp.com.pe</bcp-button>
      </div>
    </div>
  </section>
</app-modal>