import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-popup-landing',
  templateUrl: './popup-landing.component.html',
  styleUrls: ['./popup-landing.component.scss'],
  animations: [
    trigger('closeOpen', [

      state('close', style({
        opacity: '0',
        top: -320
      })),
      state('open', style({
        opacity: '1',
        top: 0
      })),
      transition('* => open', [
        animate('.6s .6s ease-in')
      ]),
    ]),
  ],
})
export class ModalScheduleComponent implements OnInit {

  @Output() closeEmitter = new EventEmitter();

  private router = inject(Router);
  private gtmService = inject(GtmService)

  show = false;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.show = true;
    }, 100);
  }

  goToSignup() {
    this.sendGtmEvent();
    this.router.navigate([PATHS.REGISTER]);
  }

  sendGtmEvent() {
    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Registrate_madre',
      pantalla: 'Pop up dia de la madre'
    }

    this.gtmService.sendEvent(gtmEvent)
  }

  blockModal() {
    event.stopPropagation();
  }
}
