<footer>
    <ul class="links">
        <li>
            <img src="/assets/images/logo-2-logo-dark.svg" alt="BCP logo dark" width="164" height="57.59" loading="lazy">
        </li>
        <li>
            <h4 class="mt-4 mt-md-0">Políticas</h4>
            <ul class="options">
                <li class="tiny"><a href="/terminos-y-condiciones">Términos y condiciones</a></li>
                <li class="tiny"><a href="https://e3d80977-d332-4e8d-808d-68358c261840.usrfiles.com/ugd/e3d809_ebedfaa899aa4337923fcde3ea01364e.pdf" rel="noopener" target="_blank">Política de privacidad</a></li>
                <li class="tiny"><a href="/declaracion-accesibilidad">Declaración de accesibilidad</a></li>
            </ul>
        </li>
        <li>
            <h4 class="mt-4 mt-md-0">Contáctanos</h4>
            <ul class="options">
                <li><img src="/assets/images/m-ic-mail-b.svg" alt="Letter" width="24" height="24" loading="lazy"><a href="mailto:contacto@mandaditobcp.com.pe">contacto@mandaditobcp.com.pe</a></li>
                <li><img src="/assets/images/landing/whatsapp-icon.svg" alt="WhatsApp" width="24" height="24" loading="lazy"><span>+51 945 657 689</span></li>
            </ul>
        </li>
        <li class="mt-4 mt-md-0 social">
            <h4>Síguenos</h4>
            <ul>
                <li><a href="https://www.facebook.com/mandaditobcp" rel="noopener" (click)="sendEvent('Clic_Landing_Fb')" target="_blank"><img src="/assets/images/s-ic-social-facebook-b.svg" alt="Facebook logo" width="24" height="24"></a></li>
                <li><a href="https://www.instagram.com/mandaditobcp/" rel="noopener" (click)="sendEvent('Clic_Landing_Insta')" target="_blank"><img src="/assets/images/s-ic-social-linkedin-b.svg" alt="LinkedIn logo" width="24" height="24"></a></li>
            </ul>
        </li>
    </ul>
    <ul class="copyright">
        <img src="/assets/images/logo.svg" alt="BCP Logo" width="80" height="21" loading="lazy">
        <p class="mobile">
            <span>© {{ date.getFullYear() }} BCP</span>
            <span>Habilitado por BCP Miami</span>
            <span>Todos los derechos reservados.</span>
        </p>
        <p class="full-screen">© {{ date.getFullYear() }} BCP | Habilitado por BCP Miami | Todos los derechos reservados.</p>
    </ul>
</footer>
