import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { GtmEvent, Operation } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { Currency } from 'src/app/shared/models/model';

@Component({
  selector: 'app-element',
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss']
})
export class ElementComponent implements OnInit {

  @Input() element: Operation;
  @Output() emitData = new EventEmitter();

  private gtmService = inject(GtmService)

  Currency = Currency

  ngOnInit(): void {
  }

  sendData(element: Operation): void {
    this.emitData.emit(element);

    const gtmEvent: GtmEvent = { 
      event: 'virtuaEvent',
      accion: 'Home_EditarEnviar',
      pantalla: 'Home'
    }

    this.gtmService.sendEvent(gtmEvent);
  }

  formatDate(date): string {
    try {
      const split = date.split('T')[0].split('-');
      return `${split[2]}/${split[1]}/${split[0]}`;
    } catch (error) {
      return '';
    }
  }

  getReceiverName(receiverFirstName: string, receiverLastname: string): string {
    let name = `${ receiverFirstName } ${ receiverLastname }`;
    if ((receiverFirstName.length + receiverLastname.length) > 23) {
      name = `${name.substring(0, 22)}...`;
    }
    return name;
  }

  maskAccountNumber(accountNumber:string): string {

    // The API for recent mandaditos is returning obfuscated account numbers with 15 characters, so we have to remove the first character to get the correct account number length (14 characters)
    accountNumber = accountNumber ? accountNumber.substring(1) : '';

    let maskAccount = '***************'; 
    if (accountNumber) {
      maskAccount = `*** ******* ${accountNumber.substring(11,12)} ${accountNumber.substring(12,14)}`;
    }

    return maskAccount;
  }

}
