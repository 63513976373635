<div class="register">
    <h2 class="main-title">Sube una foto de tu documento para validar tu identidad</h2>
    <form class="form-main" [formGroup]="formRegister" (ngSubmit)="sendData()">
        <p class="description">Elige entre tu DNI peruano o tu Driver's license de USA</p>
        <div class="radio-group">
            <label class="radio-card">
                <input type="radio" [value]="documentTypes.DNI" formControlName="docTypeloaded" class="radio">
                <div class="radio-content">
                    <img src="/assets/images/illust_DNI.svg" alt="DNI">
                    <span> DNI Peruano </span>
                </div>
            </label>
            <label class="radio-card">
                <input type="radio" [value]="documentTypes.DriverLicense" formControlName="docTypeloaded" class="radio">
                <div class="radio-content">
                    <img src="/assets/images/illust_Drivers.svg" alt="Drivers's license">
                    <span> Driver's license</span>
                </div>
            </label>
        </div>

        <div class="info-box" *ngIf="selectedDocument">
            <img src="/assets/images/e_ic_exclamation_triangle.svg" alt="Exclamation">
            <span>Recuerda que tu documento debe estar vigente</span>
        </div>

        <app-upload-dni *ngIf="selectedDocument" [selectedDocument]="selectedDocument"
            (documentFront)="setDocumentByType('front', $event)"
            (documentBack)="setDocumentByType('back', $event)"></app-upload-dni>

        <div>
            <bcp-button shape="text" tier="primary" size="md"
                (ctrlClick)="skipSavePhoto()">
                Omitir este paso por ahora
                <bcp-icon name="arrow-right-r"></bcp-icon>
            </bcp-button>
        </div>

        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit"
                [disabled]="isContinueButtonDisabled">{{ btnDescription }}</bcp-button>
        </div>
        <p class="error mx-auto mt-2 text-center" *ngIf="showGeneralError">
            Todos los campos son obligatorios
        </p>
    </form>
</div>
