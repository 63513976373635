import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorImageType } from 'src/app/core/constants';
import { UserInterface, GtmEvent } from 'src/app/core/interfaces';
import { SafePipe } from 'src/app/core/pipes/safe.pipe';
import { GtmService } from 'src/app/core/services/gtm.service';
import { UtilService } from 'src/app/core/services/util.service';
import { mustBeEqualToValue } from '../../custom-validations';

const TWENTY_FIVE_MEGABYTES = 25000000;
const DNI_FRONT = 'front'
const DNI_BACK = 'back'


@Component({
  selector: 'app-step2-exp',
  templateUrl: './step2-exp.component.html',
  styleUrls: ['./step2-exp.component.scss']
})
export class Step2ExpComponent implements OnInit, OnDestroy {

  backgroundIcon = null;
  @Input() 
  data: UserInterface;

  @Output()
  setData = new EventEmitter<UserInterface>();

  @Output()
  backStep = new EventEmitter<string>();

  showGeneralError = false;
  howToFindSubscription: Subscription;
  previewImage: string = null;
  errorImageTypeFront: ErrorImageType;
  errorImageTypeBack: ErrorImageType;
  ErrorImageType = ErrorImageType;


  images = {
    front: {
      file: null,
      url: null,
      name: null,
      status: null
    },
    back: {
      file: null,
      url: null,
      name: null,
      status: null
    }
  };

  documents = {
    front: null,
    back:  null
  };

  @ViewChild('front', { static: true }) front: ElementRef;
  @ViewChild('back', { static: true }) back: ElementRef;

  fileExtensions = ['image/png', 'image/jpeg', 'image/jpg'];
  maxFileSize = TWENTY_FIVE_MEGABYTES;

  showDocument = false;

  formRegister: FormGroup;
  submitted = false;

  previousDocumentNumber = '';

  private utilService = inject(UtilService);
  private safePipe = inject(SafePipe);
  private gtmService = inject(GtmService);

  ngOnInit(): void {
    this.initializeForm();
    this.onInputChange();
  }

  ngOnDestroy(): void {
    this.howToFindSubscription?.unsubscribe();
  }

  initializeForm(): void {
    this.formRegister = new FormGroup({
      country: new FormControl('Estados Unidos', [ Validators.required, mustBeEqualToValue('Estados Unidos') ]),
      state: new FormControl(null, [ Validators.required, mustBeEqualToValue('Florida') ]),
      address: new FormControl(null, [ Validators.required ]),
      howtoFind: new FormControl('', [Validators.required ]),
      referralCode: new FormControl(null, []),
      other: new FormControl(null, []),
    });

  }

  onInputChange() {
    this.howToFindSubscription = this.formRegister.get('howtoFind').valueChanges.subscribe({
      next: data => {
        let referralCtrl = this.formRegister.get('referralCode')
        let otherCtrl = this.formRegister.get('other')

        referralCtrl.clearValidators()
        otherCtrl.clearValidators()

        if (data === 'referralCode')
          referralCtrl.addValidators([Validators.required]);
          otherCtrl.reset()

        if (data === 'other')
          otherCtrl.addValidators([Validators.required]);
          referralCtrl.reset()

        referralCtrl.updateValueAndValidity();
        otherCtrl.updateValueAndValidity();
      }
    })
  }

  photoIsOk(): void {
    this.showDocument = false;
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Clic_foto_cumple',
      pantalla: 'Registro2'
    };
    this.gtmService.sendEvent(event);
  }

  removeImage(image): void {
    this.imageToRemove(image);
    this.showDocument = false;
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_subir_otra_foto',
      pantalla: 'Registro2'
    };
    this.gtmService.sendEvent(event);
  }

  imageToRemove(image): void {
    const element = image === 'front' ? this.front : this.back;
    if (image === 'front') this.previewImage = null;
    element.nativeElement.value = '';
    this.images[image] = {
      file: null,
      url: null,
      name: null,
      status: null
    };
    this.documents[image] = null;
  }

  onFileChange(image, event: any) {

    const [file] =event.target.files;
    if (file) {
      this.imageToRemove(image);
      setTimeout(() => {
        this.images[image].url = this.safePipe.transform(URL.createObjectURL(file), 'resourceUrl');
        this.images[image].file = file;
        this.images[image].name = file.name;
        this.images[image].status = this.validateFile(file, image);
        this.documents[image] = file;

        if (image === 'front') {
          this.previewImage = `url('${this.images[image].url}')`;
          this.showDocument = true;
        }
        const event: GtmEvent = {
          event: 'virtualEvent',
          accion: `clic_Registro2_DNI${ image === 'front' ? 1 : 2 }`,
          pantalla: 'Registro2'
        };
        this.gtmService.sendEvent(event);
      }, 0);
    }
  }

  validateFile(file, imageSide:string) {
    if (!this.fileExtensions.includes(file.type)) {
     if(imageSide === DNI_FRONT)  this.errorImageTypeFront = ErrorImageType.FILE_TYPE;
     if(imageSide === DNI_BACK)  this.errorImageTypeBack = ErrorImageType.FILE_TYPE
      return 'error';
    } else if (file.size > this.maxFileSize) {
      if(imageSide === DNI_FRONT)  this.errorImageTypeFront = ErrorImageType.FILE_SIZE;
     if(imageSide === DNI_BACK)  this.errorImageTypeBack = ErrorImageType.FILE_SIZE
      return 'error';
    }

    this.errorImageTypeFront = null;
    this.errorImageTypeBack = null
    return 'success';
  }

  getMessage(input: string) {
    const formControl = this.formRegister.get(input);
    if (!formControl.pristine && !!formControl && formControl.errors && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (!formControl.pristine && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }

    return '';
  }

  getError(input: string) {
    const formControl = this.formRegister.get(input);

    if (!formControl.pristine && !!formControl && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (!formControl.pristine && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    return '';
  }

  sendData(): void {
    this.submitted = true;
    this.showGeneralError = false;
    if (this.formRegister.invalid) {
      return;
    }

    if (this.documents.front === null || this.documents.back === null) {
      this.showGeneralError = true;
      return;
    }

    if ([this.images.front.status, this.images.back.status].includes('error')) {
      return;
    }

    const data: UserInterface = {
      fields: {
        ...this.data.fields,
        ...this.formRegister.value
      },
      document_front: this.documents.front,
      document_back: this.documents.back
    };

    const event: GtmEvent = {
      event: 'virtualEvent',
      accion: 'clic_registro2_continuar',
      pantalla: 'Registro2'
    };

    this.gtmService.sendEvent(event);
    this.setData.emit(data);
  }

  goBackStep(): void {
    this.backStep.emit('first');
  }

  excludeLetters(event: KeyboardEvent){
    return !!event.key.match(/^[0-9]+$/)
  }

  get isContinueButtonDisabled():boolean{
    return this.documents.front === null 
    || this.documents.back === null 
    || this.formRegister.invalid 
    || [ErrorImageType.FILE_SIZE, ErrorImageType.FILE_TYPE].includes(this.errorImageTypeFront) 
    || [ErrorImageType.FILE_SIZE, ErrorImageType.FILE_TYPE].includes(this.errorImageTypeBack)
  }

}
