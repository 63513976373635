import { Component, OnInit, inject } from '@angular/core';
import { LoginInterface, UserInterface } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainService } from 'src/app/core/services/main.service';
import { Router } from '@angular/router';
import { ASSETS, AuthStatus, PATHS } from 'src/app/core/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UtilService } from 'src/app/core/services/util.service';
import { LocationApi } from '../../new-mandadito/new-mandadito.data';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { LastPageService } from 'src/app/shared/services/last-page.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  steps = {
    onboarding:true,
    first: false,
  };

  data: UserInterface = null;
  loading = false;
  error = {
    show: false,
    message: null
  };

  showModalAccessTime = false;
  spotAlertGroup: string = ASSETS.SPOT_ALERT_GROUP;
  currentStep = 1;
  locationApi = LocationApi;
  showError: boolean = false;
  subscription: Subscription;

  private authService = inject(AuthService);
  private mainService = inject(MainService);
  private router = inject(Router);
  private localStorageService = inject(LocalStorageService);
  private utilService = inject(UtilService);
  private loaderService = inject(LoaderService);
  private lastPageService = inject(LastPageService);

  constructor() {}

  async ngOnInit() {
    await this.getApiData();
    this.isWithinAccessTime();
  }

  async getApiData() {
    const { goAway, locationApi } = await this.utilService.getApiData();

    if ( goAway ) {
      this.router.navigate([PATHS.OTHER_STATE]);
    }
    this.locationApi = locationApi;
  }

  isWithinAccessTime() {
    this.mainService.isWithinAccessTime().subscribe({
      next: response => {
        this.showModalAccessTime = !response.isWithinAccessTime;
      }
    });
  }

  goToFirstStep() {
    this.steps.onboarding = false;
    this.steps.first = true
    this.currentStep = 1;
    this.scrollToTop()
  }

  processLogin($event: UserInterface): void {
    this.loaderService.requestStarted();
    const { fields: { email, password } } = $event;
    const data: LoginInterface = {
      type: 'autenticate',
      email,
      password
    };
    this.subscription = this.authService.login(data).subscribe({
      next: response => {
        this.authService.status = AuthStatus.authenticated;
        this.localStorageService.setStorage('token', response);
        sessionStorage.setItem('registerData',
          JSON.stringify({ firstValue: email, secondValue: password }));
        this.lastPageService.saveLastPage(PATHS.REGISTER);
        this.localStorageService.removeStorage('referralCode')
        this.loaderService.requestEnded();
        this.router.navigate([PATHS.COMPLETE_REGISTER]);
      },
      error: () => {
        this.loaderService.requestEnded();
        this.showError = true;
      }
    });
  }

  closeErrorModal() {
    this.showError = false;
  }

  goToLanding(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  /**
   * scrolls to top of register component (register view)
   */
  scrollToTop(){
    window.scrollTo(0, 0);
  }
}
