<form class="form-main" [formGroup]="formReceiver" (ngSubmit)="searchByDNI()">
<div class="calculator"> 
    <div class="calculator-dni" [ngClass]="showcomplete ? 'modeenabled' : 'modedisabled'">
        <div *ngIf="showCaptcha" class="recaptcha">
            <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
        </div>
        <div *ngIf="!showCaptcha" class="dni-input">
            <label class="dni-label" for="dni-input">Encuentra tus beneficios ingresando tu DNI</label>
            <div class="dni-input">
                <bcp-input [label]="getError('documentNumber') ? 'Ingresa tu N° de DNI *' : ''" 
                    (ctrlKeyPress)="keyEnter($event)" (ngModelChange)="onChangeInput()"
                    type="text" maxlength="8" required="true" (keypress)="excludeLetters($event)"
                    placeholder="Nº de DNI peruano" bcpCtrlForm [state]="getError('documentNumber')" #dniInput
                    [message]="getError('documentNumber') ? getMessage('documentNumber') : ''" formControlName="documentNumber" ></bcp-input>
                <div class="select-dni">
                    <bcp-button  shape="rectangle" full-width="true" size="lg" type="submit">
                        <bcp-spinner *ngIf="showSpinner" theme="dark" size="md"> </bcp-spinner>
                        <p *ngIf="!showSpinner"> Cotizar</p>
                        </bcp-button>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
