<h2 class="main-title">
    Campaña día de la madre 2024
</h2>

<p>
    Promoción vigente del 5 de abril al 17 de mayo del 2024. Esta Promoción es desarrollada por Mandadito, una
    iniciativa del Banco de Crédito del Perú (“BCP”) habilitada por BCP Miami.
</p>

<p>
    Participan las personas naturales de nacionalidad peruana que, durante la vigencia de la Promoción: (i) se
    encuentren físicamente en el estado de Florida en Estados Unidos de América y (ii) realicen, como mínimo, un (1)
    envío válido en Mandadito de cincuenta y 00/100 Dólares Americanos ($50.00) o más, incluyendo el texto “Día de la
    madre” en el campo opcional de mensaje en el flujo de envío (“Participante”). En adelante, un “Envío Exitoso” es
    aquel que es aprobado por Mandadito y cumple con las especificaciones descritas en este párrafo.
</p>

<p>
    Por cada envío que el Participante realice en el periodo de vigencia de la Promoción, tendrá una oportunidad en el
    Sorteo. El Participante podrá hacer un máximo de cuatro (4) envíos al mes siguiendo los parámetros de Mandadito y;
    por ende, tendrá máximo ocho (8) oportunidades para ganar durante la vigencia de la Promoción. Si previo al periodo
    de vigencia de la Promoción, el usuario ya ha hecho un envío exitoso en Mandadito, este envío no podrá ser
    considerado para el Sorteo.
</p>

<p>
    Premio: un (1) gift card de la agencia COSTAMAR TRAVEL CRUISE & TOURS, en adelante “Costamar Travel”, equivalente a
    quinientos dólares americanos y 00/100 ($500.00) para ser usado exclusivamente por el/la Participante ganador/a para
    un (1) ticket aéreo del trayecto Miami-Lima-Miami. Sorteo: miércoles 22 de mayo del 2024.  El ticket deberá ser
    reservado y usado antes del 15 de diciembre del 2024.
</p>


<p>
    El Premio solo comprende el giftcard por el monto de quinientos dólares americanos y 00/100 ($500.00), por ende, no
    incluye:
</p>

<ul>
    <li>Traslados desde o hacia el aeropuerto en Miami y Perú. </li>
    <li>Estadía en el Perú </li>
    <li>Seguro de viaje </li>
    <li>Visa de viaje u otros permisos de viaje o entrada que puedan ser necesarios para que el ganador del Premio
        disfrute del mismo. </li>
</ul>

<p>Los costos adicionales al valor del Premio, es decir, a los quinientos dólares americanos ($500.00) serán asumidos
    por el/la ganador/a. El Premio no es reembolsable, endosable ni transferible. </p>

<p>Luego del Sorteo, el equipo de Mandadito se comunicará por llamada y por correo electrónico con el/la ganador/a desde
    el buzón <a href="mailto:contacto@mandaditobcp.com.pe">contacto@mandaditobcp.com.pe</a>. La comunicación del Sorteo
    será enviada al correo registrado por el usuario
    al momento de registrarse en Mandadito. El/la ganador/a deberá responder al correo confirmando que acepta el Premio
    y enviar la documentación que acredite que él/ella cumple con los requisitos para el viaje (según lo establecido en
    los presentes términos y condiciones), en un plazo máximo de dos (2) días útiles. De no tener la confirmación del
    ganador/a y/o si no cumple con las condiciones, se procederá con un nuevo Sorteo y el Premio será entregado al nuevo
    ganador/a del mismo, (y así sucesivamente hasta que un ganador/a cumpla con las condiciones antes mencionadas).
</p>

<p>Para ser acreedor del Premio, el/la ganador/a deberá cumplir y enviar como respuesta al correo electrónico inicial,
    la siguiente información y documentación para el viaje.</p>
<ul>
    <li>Confirmación de que acepta el Premio.</li>
    <li>Fechas en las que le gustaría viajar. Estas fechas no son confirmadas. Podrán ser cambiadas según disponibilidad
        y/o coordinaciones con Costamar Travel sin costo adicional. El Premio podrá ser usado hasta el 15 de diciembre
        del 2024.</li>
    <li>Pasaporte vigente escaneado, con una vigencia mínima de seis (6) meses posteriores a la fecha de inicio del
        viaje.</li>
</ul>

<p>
    Si el usuario cumple con las especificaciones del párrafo anterior, se le enviará la Carta Premio en máximo siete
    (7) días útiles. Este documento no es transferible, no es reembolsable, no tiene valor comercial, no es válido para
    canje en efectivo y no permite cambio de nombre. El canje se gestionará directamente con Costamar Travel vía correo
    electrónico. Los detalles del proceso serán brindados junto con la Carta Premio. Una vez emitido el ticket aéreo y/o
    servicio, no se permite anulación o cambios.
</p>

<p>En caso el Participante acreedor del Premio no pueda viajar por no estar habilitado para hacerlo de acuerdo con las
    disposiciones que sean exigibles, por alguna limitación legal y/o de residencia, o por algún otro hecho no imputable
    a Mandadito o al BCP, este perderá su derecho al Premio. En ese caso Mandadito y el BCP reservan el derecho de
    volver a sortearlo o a guardarlo para ser utilizado en otra promoción.</p>

<p>
    Costamar Travel sólo actúa como intermediario entre los proveedores locales e internacionales y es responsable
    únicamente por la organización de los servicios adquiridos. Por lo tanto, el ganador del Premio no puede imputarle
    responsabilidad alguna por causas que estén fuera de su alcance. BCP y Costamar Travel, no son responsables de
    cualquier prejuicio o retraso derivado de circunstancias ajenas a su control (ya sean causas fortuitas, de fuerza
    mayor y a cualquier pérdida, daño, accidente o alguna otra irregularidad que pudiera ocurrirle al usuario final).  
</p>

<p>
    Mandadito y el BCP no se responsabilizan por la idoneidad de los productos o servicios que componen el Premio, ni
    cualquier daño o perjuicio que pudiera derivarse del uso de estos.

    El BCP podrá, previa comunicación a los Usuarios y sin responsabilidad alguna, modificar alguno de los términos
    establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o el
    cambio sea producto de una obligación legal. 
</p>

<p>
    Para más información sobre la Promoción y/o restricciones, puede llamar a +51 945 657 689 o ingresar a <a href="http://www.mandaditobcp.com.pe">www.mandaditobcp.com.pe</a> 
</p>
