import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { API_CODES, COUPON_TYPE, CustomerStatus, MANDADITO_STEPS, ModalTypes, PATHS } from 'src/app/core/constants';
import { CouponData, DataMandadito, GtmEvent, Modal, OPERATIONS, UpdateDataService } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { MainService } from 'src/app/core/services/main.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { Currency } from 'src/app/shared/models/model';
import { environment } from 'src/environments/environment';
import { CouponComponent } from '../coupon/coupon.component';
import { VersionService } from 'src/app/core/services/version.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  urlTermsConditions = environment.urlTermsConditions;
  modal: Modal;

  mainService = inject(MainService);
  authService = inject(AuthService);
  router = inject(Router);
  private gtmService = inject(GtmService);
  private loaderInterceptor = inject(LoaderService);
  private dataService = inject(DataService);
  private versionService = inject(VersionService)
  private storageService = inject(LocalStorageService)

  modals = ModalTypes;
  lengthCharacters = "00/100";

  @Output()
  backStep = new EventEmitter<string>();

  @Output()
  nextStep = new EventEmitter<Object>();

  @Input() data: DataMandadito;
  @Input() userData;
  @Input() currentMaxDailyToSend: number;
  @Input() maxAmountPerMandadito:number;
  @Input() maxAmountPerDay:number;

  @Input()
  step: string;

  @ViewChild(CouponComponent) couponComponent: CouponComponent;

  acceptConditions = false;
  error = {
    show: false,
    message: 'Debe aceptar los términos y condiciones'
  };
  isPromotionalCode = false;

  Currency = Currency

  ngOnInit(): void {
    this.getData();
    window.scrollTo(0, 0); // Esto llevará el scroll al inicio de la página
  }

  getFullName(receiver) {
    if(!receiver) return ""
    let name = `${receiver?.firstName} ${receiver?.firstLastname} ${receiver?.secondLastname}`;
    if (receiver?.secondName && receiver?.secondName.length > 0)
      name = `${receiver?.firstName} ${receiver?.secondName} ${receiver?.firstLastname} ${receiver?.secondLastname}`;

    return name;
  }

  getData() {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data?.operation  === OPERATIONS.COUPON_STATE) {
          if (!data.success) {
            this.coupon = null;
            this.data.coupon = null;
          }
        }
      }
    })
  }

  maskAccountNumber(account): string {
    let maskAccount = '***************';
    if (account) {
      maskAccount = `*** ******* ${account.substring(11,12)} ${account.substring(12,14)}`;
    }

    return maskAccount;
  }

  triggerAccept(event) {
    this.acceptConditions = event.detail === null ? false : true;
  }

  async sendData(): Promise<any> {

    if (this.acceptConditions) {
      this.loaderInterceptor.requestStarted();

      const { code } = await this.getValidationUserOperations();
      const result = this.validateByCode(code);

      if (!result) {
        this.loaderInterceptor.requestEnded();
        return;
      }

      try {

        const customerData = await lastValueFrom(this.mainService.GetCustomer());
        this.loaderInterceptor.requestEnded();

        if(customerData.customerState === CustomerStatus.REJECTED.toString()){
          this.modal = {name:this.modals.REJECTED, userEmail: customerData.email}
          return
        }

        if(customerData.customerState === CustomerStatus.RECOVERY.toString()){
          this.modal = {name:this.modals.RECOVERY, userEmail: customerData.email}
          return
        }

        const event: GtmEvent = {
          event: 'virtualEvent',
          accion: 'clic_Envio3_Continuar',
          pantalla: 'EnvioPaso3',
        };
        this.gtmService.sendEvent(event);
        this.data['versionHash'] = this.versionService.getVersionHash();
        this.mainService.sendLogOperation(this.data).subscribe();
        this.nextStep.emit({step: MANDADITO_STEPS.PAYPAL, data: this.data});

      } catch (error) {
        this.loaderInterceptor.requestEnded();
        this.modal = { name:this.modals.PROBLEM };
        return;
      }


    } else {
      this.error.show = true;
      setTimeout(() => { this.error.show = false; }, 4000);
    }
  }

  private validateByCode(code: string | number): boolean {

    if (code === API_CODES.API_OPER_ERROR_EXPIRED_SESSION) {
      this.modal = { name: this.modals.TIMESESSION };
      return false;
    }

    if (code === API_CODES.API_OPER_ERROR_MAX_QUANTITY_MONTH) {
      this.modal = { name: this.modals.MAX_MONTH };
      return false;
    }

    if (code === API_CODES.API_OPER_ERROR_MAX_AMOUNT_DAY) {
      this.modal = { name: this.modals.MAX_TODAY, maxAmountMandadito: this.maxAmountPerDay, hasOperationsCompleted: this.userData.hasOperationsCompleted };
      return false;
    }

    if (code === API_CODES.API_OPER_ERROR_ACCESS_TIME) {
      this.modal = { name:this.modals.ACCESS_TIME };
      return false;
    }

    if(code === API_CODES.API_OPER_DEPLOY_MAINTENANCE){
      this.router.navigate([PATHS.DEPLOYMENT])
      return false;
    }

    if (code !== API_CODES.OK) {
      this.modal = { name: ModalTypes.PROBLEM };
      return false;
    }

    return true;
  }

  getAmountCurrentDay(operations) {
    let amount = 0;
    operations.forEach(operation => {
      const operationDate = new Date(Date.parse(operation.registerDate));
      const today = new Date();

      if (today.getFullYear() === operationDate.getFullYear() &&
        today.getMonth() === operationDate.getMonth() &&
        today.getDate() === operationDate.getDate()) {
          amount += operation.amount;
      }
    });
    return amount;
  }

  getValidationUserOperations(): Promise<any> {
    return new Promise((resolve, _) => {
      this.mainService.getValidationUserOperations(this.data.operation.amount).subscribe({
        next: () => {
          resolve({ code: API_CODES.OK });
        },
        error: err => {
          resolve({ code: err?.status === API_CODES.CODE_401 ? err.status : err.error?.errors[0]?.code
          });
        }
      });
    });

  }

  goToHistory(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  closeSession(): void {
    this.authService.logout();
    this.router.navigate([PATHS.LANDING]);
  }

  formatPhoneNumber(receiver): string {
    if(!receiver) return ""
    let phoneNumber: string = receiver.phoneNumber?.toString();
    if (receiver.phonePrefix === '+51') {
      phoneNumber =
      `${phoneNumber?.substring(0, 3)} ${phoneNumber?.substring(3, 6)} ${phoneNumber?.substring(6, 9)}`;
    }
    return phoneNumber;
  }

  textareaChange(event) {
    this.lengthCharacters = `${event.detail?.length || 0}/100`;
    this.data.receiver.message = event.detail;
  }

  addFavorite(event) {
    this.data.checkFavorite = event;
  }

  coupon: CouponData = null;
  getCouponData(event): void {
    /*event = cupon code*/
    if (event) {
      this.mainService.getCouponData(event,this.data.operation.currency.toUpperCase()).subscribe({
        next: (data: CouponData ) => {
          this.setCouponData(data);
        },
        error: err => {
          this.coupon = null;
          this.dataService.updateDataWithOperation({ operation: OPERATIONS.COUPON_STATE, data: err, success: false });
        }
      });
    } else {
      this.coupon = null;
      this.data.coupon = null;
      if (this.data.operation.discount?.type === 'COUPON') {
        this.data.operation.discount = null;
        this.data.operation.commission = this.data.operation.realCommission;
        this.data.operation.total = this.data.operation.commission + this.data.operation.amount;
      }
    }
  }

  getExchangeRate(): number {

    return this.coupon?.couponType === COUPON_TYPE.EXCHANGE_RATE ? this.coupon.profitExchangeRate + this.userData.exchangeRate : this.userData.exchangeRate;
  }

  getAdditionalAmount(): number {

    return this.coupon?.couponType === COUPON_TYPE.AMOUNT ? this.coupon.amount :  this.coupon.profitExchangeRate * this.data.operation.amount;
  }

  getReceivedAmount(): number {
    const couponAmount = this.data?.coupon && this.data?.coupon.couponType === COUPON_TYPE.AMOUNT ? this.data?.coupon.amount : 0;
    const amount = ((this.data?.operation?.amount + couponAmount) * (this.data?.operation?.currency === Currency.PEN.code ? this.userData.exchangeRate : 1));
    if (this.coupon?.couponType === COUPON_TYPE.EXCHANGE_RATE) {
      return amount + this.getAdditionalAmount();
    } 

    return amount;
  }

  getInitialRecievedAmount(): number {

    return  this.data?.operation?.amount  * (this.data?.operation?.currency === Currency.PEN.code ? this.userData.exchangeRate : 1);
  }

  setCouponData(data) {
    this.coupon = data || null;
    const maxDailyToSend = parseFloat((this.currentMaxDailyToSend >= this.maxAmountPerMandadito ? this.maxAmountPerMandadito : this.currentMaxDailyToSend).toFixed(2));
    if (this.coupon.couponType === 'Comisión') {
      this.data.operation.commission = this.data.operation.realCommission;
      const commission =  this.data.operation.commission;
      this.data.operation.commission = commission - (commission * this.coupon.discount);
      this.data.operation.total = this.data.operation.commission + this.data.operation.amount;
    }

    if (this.coupon.couponType === COUPON_TYPE.EXCHANGE_RATE) {
      const data = this.storageService.getStoredCustomer();
      data.exchangeRateFinal = this.getExchangeRate();
      this.storageService.deletedStoredCustomer();
      this.storageService.storeCustomer({...data});
    }

    if (this.data.operation.amount > 0 && this.coupon.couponType === 'Monto' && this.userData.totalAmount > 0 
    && this.data.operation.amount + this.coupon.amount > maxDailyToSend) {
      return;
    } else {
      this.data.operation.total = this.data.operation.amount + this.data.operation.commission;
    }

    this.data.operation.discount = {
      show: true, value: this.coupon.discount,
      label: this.coupon.label,
      labelTwo: this.coupon.label,
      url: this.coupon.link,
      type: 'COUPON', id: this.coupon.id, promotionType: null,
    };

    this.data.coupon = data;
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.COUPON_STATE, data, success: true });
  }

  goBack(step: string) {
    this.backStep.emit(step);
    if (this.couponComponent){
      this.couponComponent.clearCoupon()
      this.getCouponData(null)
    }
  }

}
