import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output, inject } from '@angular/core';
import { API_CODES, CustomerStatus, IP_PROVIDER, MANDADITO_STEPS, ModalTypes, PATHS, StatusRegister } from 'src/app/core/constants';
import { CommissionTable, DataMandadito, Favorite, Modal, OPERATIONS, Promotion, UpdateDataService } from 'src/app/core/interfaces';
import { MainService } from 'src/app/core/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationApiService } from 'src/app/core/services/location-api.service';
import { DataService } from 'src/app/core/services/data.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { Data, LocationApi, ModalMaxToday, OperationData, StepElements, StepElementsVariation, Steps } from '../new-mandadito.data';
import { Operation } from 'src/app/core/classes';
import { ICustomerData } from 'src/app/shared/models/model';
import { Subscription, firstValueFrom, lastValueFrom, of, retry, switchMap, tap } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { VersionService } from 'src/app/core/services/version.service';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss']
})
export class SendComponent implements OnInit {

  @Input() disableAbsolute = false;
  @Input() activeHome = false;
  @Input() activeNextStep = false;
  @Input() showSteps = true;
  @Input() initialData: DataMandadito = null;
  @Output() emitFlow = new EventEmitter<boolean>();
  data: DataMandadito;
  @Output() triggerRemittanceHistoryLoad = new EventEmitter<void>();
  @Output() continue = new EventEmitter<{}>();

  private mainService = inject(MainService);
  private dataService = inject(DataService);
  private router = inject(Router);
  private locationApiService = inject(LocationApiService);
  private loaderInterceptor = inject(LoaderService);
  private ngZone = inject(NgZone);
  private activatedRoute = inject(ActivatedRoute)
  private localStorageService = inject(LocalStorageService);
  private versionService = inject(VersionService)
  subscriptionCustomer: Subscription;
  subscriptionLogOperation: Subscription;
  subscriptionSaveOperation: Subscription;
  subscriptionValidation: Subscription;
  subscriptionPromotion: Subscription;

  modalTypes=ModalTypes

  modal: Modal = null;
  modalMaxToday = ModalMaxToday;

  showPanel = true;
  userData: ICustomerData = null;
  maxDailyToSend: number;
  maxAmountPerMandadito:number;
  maxAmountDay:number;
  promotion = null;

  stepElements = StepElements;
  amount = 0;
  steps = Steps;
  step = MANDADITO_STEPS;
  favorite = null;

  loading = true;
  promotionInfo: Promotion = null;
  existsPromotion = false;
  favorites: Favorite[];
  locationApi = LocationApi;
  presetAmount: string
  isRepeatingMandadito:boolean = false
  commissionTable : CommissionTable;

  constructor() {
    this.loaderInterceptor.requestStarted();
  }

  async ngOnInit() {
    this.getQueryParams();
    this.initializeData(this.initialData);
    this.initializeSteps();
    this.getApiData();
    this.getData();
    this.GetCustomer();
  }

  hasPartialRegistration() {
    const { statusRegister } = this.localStorageService.getStorage('token');

    return statusRegister.toLowerCase() === StatusRegister.PARTIAL;
  }

  getData(): void {
    this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        if (data?.operation === OPERATIONS.RESET_MANDADITO) {
          this.setStatusOnHome(true);
          this.presetAmount = null;
          this.isRepeatingMandadito = false;
          this.data.receiver = null;
          this.initializeSteps();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionCustomer?.unsubscribe();
    this.subscriptionLogOperation?.unsubscribe();
    this.subscriptionSaveOperation?.unsubscribe();
    this.subscriptionValidation?.unsubscribe();
    this.subscriptionPromotion?.unsubscribe();
  }

  initializeData(initialData: DataMandadito): void {
    this.data = initialData ? { ...initialData } : { ...Data };
  }

  initializeSteps(): void {
    this.stepElements = JSON.parse(JSON.stringify(this.isRepeatingMandadito ? StepElementsVariation : StepElements));
  }

  async GetCustomer() {
    try {
      let customer = await this.localStorageService.getStorage('customerData');

      if (!customer) {
        customer = await lastValueFrom(this.mainService.GetCustomer());
        this.userData = customer;
        if (this.hasPartialRegistration()) {
          this.userData.totalAmount = 0;
        }
        this.validateUserStatus();
      }
      this.getValidationUserOperations();
    } catch (error) {
      setTimeout(() => {
        this.modal = { name: ModalTypes.RELOAD };
        this.loaderInterceptor.requestEnded();
      }, 0);
    }
  }

  private validateUserAmount(totalAmount): boolean {
    this.maxDailyToSend = this.maxAmountDay - totalAmount;
    if (this.userData.totalAmount >= this.maxAmountDay) {
      this.modalMaxToday.maxAmountMandadito = this.maxAmountDay
      this.modal = this.modalMaxToday;
      this.modal.hasOperationsCompleted = this.userData.hasOperationsCompleted;
      return false;
    }
    return true;
  }

  private validateUserStatus(): boolean {
    const customErrors = [CustomerStatus.RECOVERY.toString(),CustomerStatus.REJECTED.toString()];
    if ( customErrors.includes(this.userData.customerState)) {
      this.modal = { name: CustomerStatus.RECOVERY.toString() 
        ? this.modalTypes.RECOVERY : this.modalTypes.REJECTED,
      userEmail: this.userData.email };
      return false;
    }
    return true;
  }

  getFavorites() {
      const favorites = this.localStorageService.getStorage('favorites');
      if (!favorites) {
        this.callFavoritesApi();
      } else {
        this.loading = false;
        this.favorites = favorites;
      }

      if (this.activeHome && this.activeNextStep) {
        this.goToStep(this.favorites ? MANDADITO_STEPS.FAVORITE : MANDADITO_STEPS.RECEIVER);
      }
  }

  async callFavoritesApi() {
    this.loaderInterceptor.requestStarted();
    try {
      const favorites = await lastValueFrom(this.mainService.getFavorites());
      this.favorites = favorites;
      this.localStorageService.setStorage('favorites', favorites);
    } catch (error) {
      if (error?.status !== 404) this.modal = { name: ModalTypes.RELOAD };
    } finally {
      this.loading = false;
      this.loaderInterceptor.requestEnded();
    }
  }

  resetSteps():void {
    this.steps = {
      AMOUNT: false,
      RECEIVER: false,
      FAVORITE: false,
      RESUME: false,
      PAYPAL: false
    };
  }

  goToStep(step: string): void {
    this.resetSteps();
    this.steps[step] = true;
    if (step === MANDADITO_STEPS.AMOUNT) {
      this.stepElements = this.completeStep(0);
      this.setStatusOnHome(!this.isRepeatingMandadito);
    }
    if (step === MANDADITO_STEPS.RECEIVER || step === MANDADITO_STEPS.FAVORITE) {
      if (step === MANDADITO_STEPS.RECEIVER) {
        this.dataService.updateData({ operation: OPERATIONS.VERIFY_IF_PHONE_EMPTY });
      }
      this.stepElements = this.completeStep(1);
    }
    if (step === MANDADITO_STEPS.RESUME) {
      this.stepElements = this.completeStep(2);
    }
    if (step === MANDADITO_STEPS.PAYPAL) {
      this.stepElements = this.isRepeatingMandadito ? this.completeStep(2) : this.completeStep(3);
    }

  }

  setStatusOnHome(value: boolean) {
    this.activeHome =  value;
    this.emitFlow.emit(value);
  }

  completeStep(step: number) {
    return this.stepElements.map((element, index) => {
      element.status = '';
      if (index < step) element.status = 'completed';
      if (step === index) element.status = 'current';
      return element;
    });
  }

  chooseReceiver(data): void {
    this.continue.emit(data);
    this.resetSteps();
    this.setStatusOnHome(false);

    if (this.isRepeatingMandadito) {
      this.steps.RESUME = true
      this.stepElements = this.completeStep(1);
      if (data.receiver === null && this.data.receiver) {
        data.receiver = this.data.receiver;
      }
      this.data = data;
    } else {
      const hasReceiverWithCurrency = this.favorites?.length > 0 && this.favorites.some(favorite => favorite.accountCurrency === data.operation.currency);
      if (hasReceiverWithCurrency){
        this.steps.FAVORITE = true;
      } else {
        this.steps.RECEIVER = true;
      }

      this.data = data;
      this.stepElements = this.completeStep(1);
    }
  }

  goToResume(eventData) {
    const { step, data } = eventData;
    this.goToStep(step);
    this.data = data;

    this.stepElements = this.completeStep(2);
    this.scrollToTop();
  }

  goToPaypal(eventData) {
    const { step, data } = eventData;
    this.goToStep(step);
    this.data = data;

    this.stepElements =  this.completeStep(this.isRepeatingMandadito ? 2 : 3);
  }

  async registerNewMandadito(event) {
    const { data: paypal, order } = event;

    const versionHash = this.versionService.getVersionHash()

    this.subscriptionLogOperation = this.mainService.sendLogOperation({paypal: event, versionHash}).subscribe();

    const autoRemittance = (this.data.receiver.documentNumber === this.userData.documentNumber)
    ? true : this.data.autoremittance;

    const { receiver, operation, isFavorite, checkFavorite, coupon, extendedSchedule } = this.data;
    const sendData = Operation.fromObject({ receiver, operation, isFavorite,
      checkFavorite, paypal, order, autoRemittance, location: this.locationApi, coupon, extendedSchedule });

    this.saveOperation(sendData, operation, extendedSchedule);
  }

  private saveOperation(sendData, operation, extendedSchedule) {
    this.subscriptionSaveOperation = this.mainService.saveOperation(sendData.operation).subscribe({
      next: ({ operationCode }) => {
        this.clearStorage();
        this.dataService.updateDataWithOperation({ operation: OPERATIONS.MANDADITO_SUCCESS, data: {amount: operation.amount, coupon: this.data.coupon, operationCode, currency: operation.currency, commission: operation.commission }, success: true, extendedSchedule});
        this.ngZone.run(()=>this.router.navigate([PATHS.REQUEST_SUCCESS], sendData.operation.commission > 0 ? {queryParams:{enc:1}} : {}));
      },
      error: _ => {
        this.dataService.updateDataWithOperation({ operation: OPERATIONS.MANDADITO_SUCCESS, data: { 
          amount: operation.amount, operationCode: '', currency:operation.currency, commission: operation.commission
          }, success: true, extendedSchedule });
        this.ngZone.run(()=>this.router.navigate([PATHS.REQUEST_SUCCESS], sendData.operation.commission > 0 ? {queryParams:{enc:1}} : {}));
      },
    });
  }

  private clearStorage(): void {
    this.localStorageService.removeStorage('favorites');
    this.localStorageService.removeStorage('customerData');
  }

  async getApiData() {
    const [ipApi, ipInfo] = await Promise.all([
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_API),
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_STACK)
    ]);

    let goAway = true;
    if (ipApi?.isLocationAllowed || ipInfo?.isLocationAllowed) {
          goAway = false;
          this.locationApi = ipApi?.isLocationAllowed ? ipApi : ipInfo;
    }

    if ( goAway ) {
      this.router.navigate([PATHS.OTHER_STATE]);
    }
  }

  async getValidationUserOperations() {
      this.subscriptionValidation = this.mainService.getValidationUserOperations(0).subscribe({
        next: (res) => {
          this.getPromotionByName();
          this.data.testTransaction = res.testTransaction;
          this.data.extendedSchedule = res.extendedSchedule
          this.maxAmountDay = res.maxAmountDay
          this.maxAmountPerMandadito = res.maxAmountMandadito
          this.commissionTable = res.commission
          this.validateUserAmount(this.userData.totalAmount);
        },
        error: err => {
          this.loading = true;
          this.loaderInterceptor.requestEnded();
          const code = err.error?.errors[0]?.code;
          if(code === API_CODES.API_OPER_ERROR_EXPIRED_SESSION) {
            this.modal = { name: ModalTypes.TIMESESSION };
            return;
          }
          else if (code === API_CODES.API_OPER_ERROR_MAX_QUANTITY_MONTH) {
            this.modal = {
              name: 'showMaxMonthModal',
              gtm: {
                event: 'virtualEvent',
                accion: 'Unhappy_4envios',
                pantalla: 'envioPaso1'
              }
            };
          }

          else if (code === API_CODES.API_OPER_ERROR_MAX_AMOUNT_DAY) {
            this.modal = this.modalMaxToday;
          }

          else if (code === API_CODES.API_OPER_ERROR_ACCESS_TIME) {
            this.modal = { name: this.modalTypes.ACCESS_TIME, hasOperationsValid: this.userData.hasOperationsValid };
            return;
          }
          else {
            this.modal = { name: ModalTypes.RELOAD };
            return;
          }
        }
      });
  }


  getPromotionByName() {
    this.subscriptionPromotion = this.mainService.getAvailablePromotions().subscribe({
      next: (promotions: Promotion[]) => {
        if (promotions && promotions.length > 0) {
          this.promotion = promotions[0];
        }
        this.getFavorites();
        this.triggerRemittanceHistoryLoad.emit();
      },
      error: _ => {
        this.loading = false;
        this.loaderInterceptor.requestEnded();
        this.modal = { name: ModalTypes.RELOAD };
      },
    });
  }

  getQueryParams() {
    this.activatedRoute.queryParams.pipe(
      tap(queryParams => {
        if('amount' in queryParams && 'receiverId' in queryParams) {
          this.isRepeatingMandadito = true
          this.initializeSteps();
          
        }
        if ('amount' in queryParams) {
          this.emitFlow.emit(false);
          if(!this.data) this.data = Data;
          this.presetAmount = queryParams['amount'];
          this.dataService.updateDataWithOperation({ operation: OPERATIONS.RESEND_MANDADITO, data: this.presetAmount });
        }
        
        if ('origin' in queryParams) {
          this.data.operation = OperationData
          this.data.operation.origin = queryParams['origin'] 
        }

      }),
      switchMap(queryParams => 'receiverId' in queryParams ? this.mainService.getReceiver(queryParams['receiverId']) : of(null)),
      retry(1)
    ).subscribe(receiver => {
      if (receiver !== null) {
        this.data.receiver = receiver;
      }
    })
  }

  get getResumeBackStep() {
    if(this.isRepeatingMandadito) return this.step.AMOUNT
    const hasFavoritesWithCurrency = this.favorites?.length > 0 && this.favorites.some(favorite => favorite.accountCurrency === this.data?.operation?.currency);
    return hasFavoritesWithCurrency ? this.step.FAVORITE : this.step.RECEIVER
  }

  @HostListener('window:beforeunload')
  doSomething() {
    return false;
  }

  /**
   * This function is called when the selected currency is changed in the currency-conversion component.
   * If is not repeating a mandadito, once the selected currency changes sets the receiver to null.
   * @remarks
   * **Important** see the currency-conversion component for more information on how it behaves when is repeating a mandadito.
   */
  resetReceiver(){
    if(this.isRepeatingMandadito) return
    this.data.receiver = null;
  }

    /**
   * scrolls to top of register component (register view )
   */
    scrollToTop(){
      window.scrollTo(0, 0);
    }
}
