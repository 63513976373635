<app-referral-banner></app-referral-banner>
<app-menu-panel (toggleFullscreenMenu)="toggleFullscreenMenu()" [isMenuOpened]="isMenuOpened" class="mobile"></app-menu-panel>
<div class="content">
    <div class="app-menu">
        <app-menu (toggleFullscreenMenu)="toggleFullscreenMenu()"></app-menu>
    </div>
    <div class="background"></div>
    <div class="panel">
        <div>
            <img class="mx-auto d-block" src="/assets/images/check-round-valid.svg" width="60" alt="completed">
            <h2 class="primary-700 text-center flexo-regular font-size-20 line-height-28 font-size-24 line-height-32">¡Recibimos tu solicitud!</h2>
            <p class="mb-0 text-center background_800 font-size-12">Monto a enviar</p>
            <h1 class="mb-0 primary-700 text-center flexo-regular font-size-40 line-height-48 mt-1">{{Currency[info.currency]?.symbol}} {{ (info.currency === Currency.PEN.code ?  (toFloat(info.requestedAmount) + toFloat(info.coupon)) * exchangeRate : (toFloat(info.requestedAmount) + toFloat(info.coupon))) | number: '1.2-2'}}</h1>
            <p class="mt-3 mb-2 text-center font-size-14 line-height-143 mx-auto w-100">
                <strong>¡Same day delivery!</strong> <br> para Mandaditos solicitados hasta las 3 pm EST.</p>
            <p class="spam onsurface-800 text-center font-size-13 font-size-md-14">Tiempo máximo de envío 1 dia hábil</p>

            <hr class="line" />
            <ul class="operation mx-auto d-flex justify-content-between align-items-center"
            *ngIf="info.operationCode && info.operationCode.length > 0">
                <li class="flexo font-size-14 line-height-20">Nro. de operación</li>
                <li class="flexo-demi font-size-16 line-height-24">{{ info.operationCode }}</li>
            </ul>

            <ul class="operation mx-auto d-flex justify-content-between align-items-center">
                <li class="flexo font-size-14 line-height-20">Monto solicitado</li>
                <li class="flexo-demi font-size-16 line-height-24">${{ info.requestedAmount }}</li>
            </ul>

            <ul class="operation mx-auto d-flex justify-content-between align-items-center" *ngIf="info.coupon !== '0.00'">
                <li class="flexo font-size-14 line-height-20">Regalo por código promocional</li>
                <li class="flexo-demi font-size-16 line-height-24 color-success">+ ${{info.coupon}}</li>
            </ul>

            <ul class="operation mx-auto d-flex justify-content-between align-items-center" *ngIf="info.currency === Currency.PEN.code">
                <li class="flexo font-size-14 line-height-20">Tipo de cambio</li>
                <li class="flexo-demi font-size-16 line-height-24">1 USD = {{exchangeRate | number:'1.2-2' }} PEN</li>
            </ul>

            <ul class="operation mx-auto d-flex justify-content-between align-items-center">
                <li class="flexo font-size-14 line-height-20">Comisión</li>
                <li class="flexo-demi font-size-16 line-height-24">${{info.commission}}</li>
            </ul>
            <ul class="operation mx-auto d-flex justify-content-between align-items-center">
                <li class="flexo font-size-14 line-height-20">Total pagado</li>
                <li class="flexo-demi font-size-16 line-height-24">${{((toFloat(info.commission)) + toFloat(info.requestedAmount)) | number: '1.2-2'}}</li>
            </ul>

            <br>

            <div class="mx-auto text-center">
                <p class="mb-0 text-center flexo font-size-14 font-size-md-16">Te notificaremos a tu correo:</p>
                <p class="mb-0 text-center flexo-demi font-size-14 font-size-md-16">{{ email }}</p>
            </div>

            <p class="spam onsurface-800 text-center font-size-13 font-size-md-14">
                Revisa tu carpeta de no deseados o Spam</p>

            

            <div class="mt-4 d-flex banner-share-link mx-auto color-white" *ngIf="showBannerShareLink">
                <img src="/assets/images/spot_gift-orange.svg" width="65" alt="Comparte tu enlace" />
                <ul class="m-0 flexo-demi font-size-14 color-white">
                    <li>Refiere y gana ENVÍOS GRATIS</li>
                    <li (click)="showModalShareLink()"><span>Comparte tu enlace</span> <img src="/assets/images/a_ic_angle_down_circle_white.svg" alt="compartir" /></li>
                </ul>
            </div>

            <div class="mt-4 d-flex banner-award-share mx-auto" *ngIf="showBannerReferred">
                <img src="/assets/images/ic-gift.svg" width="20" alt="Comparte tu enlace" />
                <ul class="m-0 font-size-12 font-size-md-14">
                    <li>Cuando tu Mandadito llegue al destino <span class="flexo-demi">¡ganarás un Mandadito gratis!</span></li>
                    <li><a class="font-size-10 font-size-md-12" href="{{urlBlob}}/public-documents/tyc-referidos.pdf" target="_blank">Términos y condiciones de promoción</a></li>
                </ul>
            </div>

            <div class="continue mx-auto d-flex flex-column">
                <bcp-button shape="rectangle" tier="primary" size="lg" (click)="goToNewMandadito()" full-width="true">Hacer otro Mandadito</bcp-button>
            </div>
            <p class="mb-0 text-center">¿Necesitas ayuda? 
                <a href="https://wa.link/ny1jx2" rel="noopener" target="_blank">
                    <bcp-button shape="text" tier="primary" size="lg">
                        Contáctanos
                    </bcp-button>
                </a>
            </p>
        </div>
        
    </div>

    </div>

    <app-referral-modal [showModal]="isModalShareLink" [customerReferralCode]="customer.referralCode" (closeModalEvent)="closeModalShareLink($event)"></app-referral-modal>
