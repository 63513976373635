import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthRecoverType, AuthStatus, PATHS } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

const recoverTypes: string[] = [ AuthRecoverType.justDni.toString(),
  AuthRecoverType.justPersonalData.toString(),
  AuthRecoverType.dniAndPersonalData.toString()];

export const IsRecoverGuard: CanActivateFn = async () => {

  const authService = inject(AuthService);
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);

  if ( !authService.checkAuthStatus() ) {
    let token = localStorageService.getStorage('token');
    authService.status = token?.loginStatus;
    authService.recoverType = token?.recoverType;
  }

  if ( authService.checkAuthStatus() === AuthStatus.Recovering.toString() && recoverTypes.includes(authService.checkAuthRecoveryType()) ) {
    return true;
  }

  router.navigate([PATHS.LOGIN]);
  return false;
  
}
