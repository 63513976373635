import { Component, OnInit, inject } from '@angular/core';
import { TermsAndConditionsBreadcrumb, ActiveCampaingsBreadcrumb, ReferralsCampaignBreadcrumb, MothersDayCampaignBreadcrumb } from '../../breadcrumbData';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-mothers-day-campaing',
  templateUrl: './mothers-day-campaing.component.html',
  styleUrls: ['./mothers-day-campaing.component.scss']
})
export class MothersDayCampaingComponent implements OnInit {

  private breadcrumbService = inject(BreadcrumbService)

  constructor() { }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  setBreadcrumb(){
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      ActiveCampaingsBreadcrumb,
      MothersDayCampaignBreadcrumb
    ])
  }

}
