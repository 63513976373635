<h2 class="main-title">
    Campaña de Referidos
</h2>
<p>
    Promoción vigente del 23 de febrero al 1 de mayo del 2024. Esta Promoción es desarrollada por Mandadito, una
    iniciativa del Banco de Crédito del Perú (“BCP”) habilitada por BCP Miami.
</p>

<p>
    En esta promoción participan dos tipos de usuario: “Referidor” y “Referido”.
</p>

<p>
    Podrán participar como Referidores las personas naturales de nacionalidad peruana que, durante la vigencia de la
    Promoción: (i) se encuentren físicamente en el estado de Florida en Estados Unidos de América y (ii) ya hayan
    enviado por lo menos una solicitud en Mandadito.
</p>

<p>
    Para referir exitosamente a alguien, el Referidor deberá compartir el enlace personal que le brinda Mandadito para
    la campaña de referidos con otro/a peruano/a en Florida, quien a su vez deberá hacer un envío exitoso con Mandadito.
    El Referidor podrá encontrar su enlace en la pantalla de solicitud exitosa al momento de solicitar su primer
    Mandadito y en la parte superior de la pantalla del flujo de envío luego de haber hecho por lo menos un envío
    exitoso en Mandadito. El enlace se ve como el siguiente ejemplo: www.mandaditobcp.com.pe/?codigo=AAAA. El Referido
    deberá ingresar al enlace personal del Referidor para registrarse en Mandadito o sino ninguna de las dos partes
    recibirá los beneficios.
</p>

<p>
    Beneficio para el Referidor: Ganará un (1) envío sin comisión por cada Referido que haga su primer envío exitoso en
    Mandadito. Se considera un envío exitoso aquel que es aprobado por Mandadito. El beneficio se verá disponible en la
    plataforma luego de que el primer envío del Referido sea completado exitosamente. El Referidor podrá visualizar el
    beneficio sin necesidad de agregar algún código. Si el primer envío no fue exitoso, tanto el Referido como el
    Referidor pierden el beneficio. Si el Referido hace su primer envío después de un mes de haberse registrado, tanto
    el Referidor y el Referido pierden el beneficio. Si el Referidor tiene beneficios disponibles, recibirá un correo
    semanal con la información de estos. No hay límite de beneficios para el Referidor.
</p>

<p>
    Beneficio para el Referido: Su segundo envío no tendrá comisión. El Referido podrá visualizar el beneficio luego de
    que su primer envío sea completado exitosamente y sin necesidad de agregar algún código. Si el primer envío no fue
    exitoso, tanto el Referido con el Referidor pierden el beneficio. Si el Referido hace su primer envío después de un
    mes de haberse registrado tanto el Referidor y el Referido pierden el beneficio.
</p>

<p>
    El BCP podrá, previa comunicación a los Usuarios y sin responsabilidad alguna, modificar alguno de los términos
    establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o el
    cambio sea producto de una obligación legal.
</p>

<p>
    Para más información sobre la Promoción y/o restricciones, puede llamar a +51 945 657 689 o ingresar a
    <a href="www.mandaditobcp.com.pe">
        www.mandaditobcp.com.pe
    </a>
</p>
