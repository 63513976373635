import { Pipe, PipeTransform } from '@angular/core';
import { IBreadcrumbItem } from 'src/app/shared/models/model';

@Pipe({
  name: 'shortenBreadcrumb'
})
export class ShortenBreadcrumbPipe implements PipeTransform {

  transform(breadcrumbList: IBreadcrumbItem[], isDesktop: boolean = false): IBreadcrumbItem[] {
    return breadcrumbList.length > 2 && !isDesktop ? breadcrumbList.slice(breadcrumbList.length - 2) : breadcrumbList
  }


}
