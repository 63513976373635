import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  formForgot: FormGroup;
  submitted = false;
  @Output()
  emitData = new EventEmitter<{ step: number, email: string }>();

  private utilService = inject(UtilService);
  private gtmService = inject(GtmService)

  ngOnInit(): void {
    this.basicForm();
  }

  basicForm(): void {
    this.formForgot = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)])
    });
  }

  getMessage(input: string): string {
    const formControl = this.formForgot.get(input);

    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (this.submitted && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }
    return '';
  }

  getError(input: string): string {
    const formControl = this.formForgot.get(input);
    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (this.submitted && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    return '';
  }

  sendData(): void {
    this.submitted = true;
    if (this.formForgot.invalid) return;

    const gtmEvent:GtmEvent = {
      event: 'virtualEvent',
      accion: 'Contraseña_correo',
      pantalla: 'Ingresa correo',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.emitData.emit({ step: 1, email: this.formForgot.get('email').value });
  }

}
