import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IP_PROVIDER, PATHS } from 'src/app/core/constants';
import { UserInterface } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocationApiService } from 'src/app/core/services/location-api.service';
import { MainService } from 'src/app/core/services/main.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-exp',
  templateUrl: './register-exp.component.html',
  styleUrls: ['./register-exp.component.scss']
})
export class RegisterExpComponent implements OnInit {

  stepNumber = 1

  steps = {
    first: true,
    second: false,
    third: false,
    fourth: false,
    completed: false
  };

  stepElements = [
    { value: '1', status: 'current', text: '' },
    { value: '2', status: '', text: '' },
    { value: '3', status: '', text: '' },
    { value: '4', status: '', text: '' }];

  data: UserInterface = null;
  loading = false;
  error = {
    show: false,
    message: null
  };

  showModalAccessTime = false;

  private authService = inject(AuthService);
  private mainService = inject(MainService);
  private locationApiService = inject(LocationApiService);
  private router = inject(Router);

  ngOnInit(): void {
    this.getApiData();
    this.isWithinAccessTime();
  }

  async getApiData() {
    const [ipApi, ipInfo] = await Promise.all([
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_API),
      this.locationApiService.getLocationApi(IP_PROVIDER.IP_STACK)
    ]);

    let goAway = true;
    if (ipApi?.isLocationAllowed || ipInfo?.isLocationAllowed) {
          goAway = false;
    }

    if ( goAway ) {
      this.router.navigate([PATHS.OTHER_STATE]);
    }
  }

  isWithinAccessTime() {
    this.mainService.isWithinAccessTime().subscribe({
      next: response => {
        this.showModalAccessTime = !response.isWithinAccessTime;
      }
    });
  }

  nextStep(event): void {
    this.data = event;
    this.completeStep(this.stepNumber)

    this.stepNumber = this.stepNumber + 1;

    if (this.steps.first) {
      this.steps.first = false;
      this.steps.second = true;
    } else if (this.steps.second) {
      this.steps.second = false;
      this.steps.third = true;
    } else {
      this.steps.third = false
      this.steps.fourth = true
    }
  }

  backStep(step: string): void {
    this.reverseStep(this.stepNumber)
    this.stepNumber = this.stepNumber - 1;
    this.error = {
      show: false,
      message: null
    };
    this.steps = {
      first: false,
      second: false,
      third: false,
      fourth: false,
      completed: false
    }
    this.steps[step] = true;
  }

  sendData(event): void {
    this.data = event;
    this.error = {
      show: false,
      message: null
    };

    this.loading = true;
    this.authService.register(this.data).subscribe({
      next: (res) => {
        this.loading = false;
        this.steps.fourth = false;
        this.steps.completed = true;
      },
      error: (err) => {
        this.loading = false;
        let message = 'Un hubo error en el proceso, vuelva a intentar';
        if (err.error.errors[0].code === "ALREADY_EXIST") message = 'Usuario ya existe';
        this.error = {
          show: true,
          message
        };
        setTimeout(() => {
          this.error = {
            show: false,
            message: null
          };
        }, 3000);
      }, complete: () => {
        console.log('Completed');
      }
    });
  }

  completeStep(step:number){
    if(!step || step < 1) return;
    this.stepElements[step-1].status = 'completed'
    this.stepElements[step].status = 'current'
  }

  reverseStep(step:number){
    if(!step || step < 1) return;
    this.stepElements[step-1].status = ''
    this.stepElements[step-2].status = 'current'
  }
}
