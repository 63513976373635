import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalTypes, PATHS } from 'src/app/core/constants';
import { GtmEvent, Modal } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { ICustomerData } from '../../models/model';

@Component({
  selector: 'app-shared-modals',
  templateUrl: './shared-modals.component.html',
  styleUrls: ['./shared-modals.component.scss']
})
export class SharedModalsComponent implements OnChanges {

  private authService = inject(AuthService);
  private router = inject(Router);
  private gtmService = inject(GtmService);
  @Input() modal: Modal;
  @Input() customer: ICustomerData;
  @Output() closeModalEvent = new EventEmitter<any>()
  modalName = null;
  modals = ModalTypes;
  /*A MODIFICAR*/
  encodedMessage = encodeURIComponent("Hola, necesito ayuda para recuperar mi contraseña. Mi DNI es [Escribe tu DNI]")
  whatsappUrl = `https://wa.me/51945657689?text=${this.encodedMessage}`


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['modal'] && changes['modal'].firstChange === false) {
      this.updateModalData(changes['modal'].currentValue);
    }
  }
  updateModalData(modal: Modal) {
    this.modalName = modal.name;
    this.gtmService.sendEvent(modal.gtm);
    
  }

  goToHistory(): void {
    this.router.navigate([PATHS.HISTORICAL]);
  }

  closeSession(): void {
    this.authService.logout();
    this.router.navigate([PATHS.LANDING]);
  }

  reloadPage() {
    this.router.navigate([PATHS.RELOAD_MANDADITO]);
  }

  closeModal(){
    this.closeModalEvent.emit()
  }

  goToLogin(): void {
    this.router.navigate([PATHS.LOGIN]);
  }

  sendEvent() {
    const event: GtmEvent = {
      event: 'Modal_contraseña50_escribenos​',
      accion: 'whatsapp',
      pantalla: 'Recuperar contraseña',
    };
    this.gtmService.sendEvent(event);
  }
}
