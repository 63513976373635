import { environment } from "src/environments/environment";

const url = environment.production ? 'www.mandaditobcp.com.pe' : 'mandadito-dev.innovacxion.com'

export const message = `*¡Obtén 2 envíos GRATIS a Perú!*

*Usa mi enlace de referido* para registrarte y envía dinero de Florida a Perú sin salir de casa con Mandadito del BCP.
*¡Aprovéchalo ahora!*

*Mi enlace:* ${url}/?referralcode=`
