<div class="header" *ngIf="steps.onboarding">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<app-header-exp [step]="currentStep" class="mobile" [total]="3" [completed]="false"  *ngIf="!steps.onboarding"></app-header-exp>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second" [ngClass]="{'onboarding': steps.onboarding}">
        <div class="d-flex mx-auto justify-content-center flex-column">
            <app-onboarding (goToRegisterForm)="goToFirstStep()" *ngIf="steps.onboarding"></app-onboarding>
            <app-step1 [hidden]="!steps.first" [location]="locationApi" (setData)="processLogin($event)"></app-step1>
        </div>
    </div>
</div>

<app-modal [size]="'sm'" *ngIf="showModalAccessTime">
    <section class="error-modal">
        <div class="d-flex flex-column">
            <img width="147" class="mx-auto" src="/assets/images/sleeping-piggy.svg" alt="pig" />
            <h3 class="mt-4 flexo-demi font-size-md-24 font-size-20 line-height-32 text-center color-primary_700">En este horario no puedes<br />enviar Mandaditos</h3>
            <p class="mt-3 flexo-regular font-size-md-16 font-size-14 line-height-24 text-center mb-2">Nuestro horario de envíos es:</p>
            <ul class="modal-extended-hours-req">
                <li>Lunes a viernes durante todo el día</li>
                <li>Sábados hasta la 1:00 p.m. EST</li>
            </ul>
            <p class="mt-1 mb-3 flexo-demi font-size-md-16 font-size-14 line-height-24 text-center">Puedes registrarte y te avisaremos cuando puedas enviar un Mandadito</p>

            <div class="extra-button-size mx-auto mt-1 mt-md-3">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="showModalAccessTime = false">Registrarme</bcp-button>
            </div>
            <div class="mt-3 text-center">
                <bcp-button shape="text" size="lg" tier="primary" (click)="goToLanding()">Volver a Mandaditobcp.com.pe</bcp-button>
            </div>
        </div>
    </section>
</app-modal>

<app-modal *ngIf="showError">
    <section>
      <div class="d-flex flex-column">
        <img width="170" class="mx-auto" [src]="spotAlertGroup" alt="error">
        <h3 class="title mt-4">Ocurri&oacute; un problema</h3>
        <p class="subtitle">Por favor, intenta nuevamente.</p>   
        <div class="button mt-4">
          <bcp-button
            shape="rectangle"
            tier="primary"
            size="lg"
            full-width="true"
            (ctrlClick)="closeErrorModal()">De acuerdo</bcp-button>
        </div>
      </div>
    </section>
  </app-modal>
