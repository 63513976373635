import { Component, OnInit, inject } from '@angular/core';
import { GtmService } from '../../services/gtm.service';
import { GtmEvent } from '../../interfaces';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private gtmService = inject(GtmService);
  date =  new Date();

  ngOnInit(): void {
  }

  sendEvent(accion: string): void {
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'Landing',
    };
    this.gtmService.sendEvent(event);
  }

}
