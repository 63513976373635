import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { UtilService } from 'src/app/core/services/util.service';
import { atLeastEightCharacters, atLeastOneNumber, atLeastOneSpecialCharacter, atLeastOneUppercase, passwordsMatch } from 'src/app/views/auth/register/custom-validations';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  private authService = inject(AuthService);
  private utilService = inject(UtilService);
  private router = inject(Router);
  private gtmService = inject(GtmService)

  eyeType = {
    password: {
      type: 'password',
      icon: 'eye-b'
    },
    repeatPassword: {
      type: 'password',
      icon: 'eye-b'
    }
  }

  @Input()
  data: {
    email: string;
    question: {text: string; value: string; };
    answer: {text: string; value: string; };
  }

  formPassword: FormGroup;
  submitted = false;
  questionNumber = 0;
  @Output()
  emitData = new EventEmitter<{ step: number }>();

  ngOnInit(): void {
    this.basicForm();
  }

  basicForm(): void {
    this.formPassword = new FormGroup({
      password: new FormControl('', [ Validators.required, atLeastEightCharacters,
        atLeastOneNumber, atLeastOneUppercase, atLeastOneSpecialCharacter]),
      repeatPassword: new FormControl('', [ Validators.required ])
    }, [passwordsMatch('password', 'repeatPassword')]);
  }

  getMessage(input: string): string {
    const formControl = this.formPassword.get(input);

    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "Campo requerido";
    }

    if (this.submitted && !!formControl && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }
  
    return '';
  }

  getError(input: string): string {
    const formControl = this.formPassword.get(input);
    if (this.submitted && !!formControl && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    return '';
  }

  getImageByCondition(condition: boolean) {
    const isDirty = this.formPassword.get('password').dirty;
    if (!condition && isDirty) {
      return 'round-valid';
    } else if (isDirty) {
      return 'round-invalid';
    }
    return 'round';
  }


  sendData() {
    this.submitted = true;
    if (this.formPassword.invalid) return;
    const password = this.formPassword.get('password').value;
    const data = {
      ...this.data,
      password
    }

    const gtmEvent: GtmEvent = {
      event: 'virtualEvent',
      accion: 'Contraseña_creacion',
      pantalla: 'Crea contraseña',
    }

    this.gtmService.sendEvent(gtmEvent)

    this.authService.validateQuestions(data).subscribe({
      next: ({ response }) => {
        this.validateQuestions(response);
      }
    });
  }

  validateQuestions(response: boolean): void {
    if (response) {
      this.router.navigate([PATHS.PASSWOR_UPDATED]);
    }
  }

  eventTriggered(name: string): void {
    this.eyeType[name].icon = this.eyeType[name].icon === 'eye-b' ? 'eye-slash-b' : 'eye-b';
    this.eyeType[name].type = this.eyeType[name].type === 'password' ? 'text' : 'password';
  }

}
