<app-referral-banner></app-referral-banner>
<app-menu-panel (toggleFullscreenMenu)="toggleFullscreenMenu()" [isMenuOpened]="isMenuOpened"
    class="mobile"></app-menu-panel>
<div class="content">
    <div class="app-menu">
        <app-menu (toggleFullscreenMenu)="toggleFullscreenMenu()"></app-menu>
    </div>
    <div class="background"></div>
    <div class="panel">
        <div class="box">
            <bcp-button shape="text" tier="primary" (click)="goToNewMandadito()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r"></bcp-icon>
                Volver
            </bcp-button>
            <div class="clipboard-info" *ngIf="showClipboardInfo">
                <div class="clipboard-info-content">
                    <img src="/assets/images/check-round-valid.svg" alt="Green Check" width="24">
                    <span>Enlace copiado en el portapapeles</span>
                </div>
                <img src="/assets/images/close-green.svg" width="16" class="close-icon" (click)="closeClipboardInfo()">
            </div>
            <h1 class="main-title">
                Refiere y gana
            </h1>
            <p class="subtitle">
                Comparte tu enlace y gana envíos GRATIS
            </p>
            <div class="referral-url">
                <span>{{customerReferralUrl}}</span>
                <img src="/assets/images/clipboard.webp" alt="copy" (click)="copyToClipboard()">
            </div>
            <div class="share-btn">
                <bcp-button tier="primary" size="lg" full-width="true" (click)="sendToWhatsapp('referralCode')">
                    <img src="/assets/images/ic_social_whatsapp.svg" alt="Whatsapp">
                    Compartir enlace
                </bcp-button>
            </div>
            <div class="available-deliveries" [ngClass]="{'gap-12': rewards?.total === 0}">
                <img [src]="rewards?.total !== 0 ? '/assets/images/spot_gift.svg': '/assets/images/piggy-referrals.svg'"
                    [alt]="rewards?.total !== 0 ? 'Referral Gift' : 'Referral Piggy'">
                <div class="text-content">
                    <ng-container *ngIf="rewards?.total !== 0">
                        <p>Tienes disponible</p>
                        <p>{{rewards?.total}} {{rewards?.total > 1 ? 'envíos':'envío'}} gratis</p>
                    </ng-container>
                    <ng-container *ngIf="rewards?.total === 0">
                        <p><span>Comparte tu enlace y empieza a ganar envíos gratis</span></p>
                    </ng-container>
                </div>
            </div>
            <div class="how-it-works">
                <h4>¿Cómo funciona?</h4>
                <ul>
                    <li>Comparte tu enlace con otro/a peruano/a en Florida.</li>
                    <li>Gana un envío sin comisión por cada referido que se registre y haga su primer envío exitoso.
                    </li>
                    <li>Por usar tu enlace, tus referidos ganarán un segundo envío gratis.</li>
                </ul>
                <a class="tyc" href="/terminos-y-condiciones/campanias-activas/campania-referidos" target="_blank" rel="noopener">Ver términos y condiciones</a>
            </div>
            <div class="contact">
                <p>¿Tienes consultas? <span (click)="sendToWhatsapp('contact')">Escríbenos</span></p>
            </div>
        </div>
    </div>
</div>

<app-shared-modals [modal]="modal"></app-shared-modals>
