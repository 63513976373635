export const SELECT_OPTIONS = [
    { value: "+51", name: "+51 Perú", shortName: "+51" },
    { value: "+1", name: "+1 USA", shortName: "+1" },
    { value: "+93", name: "+93 Afganistán", shortName: "+93" },
    { value: "+355", name: "+355 Albania", shortName: "+355" },
    { value: "+49", name: "+49 Alemania", shortName: "+49" },
    { value: "+376", name: "+376 Andorra", shortName: "+376" },
    { value: "+244", name: "+244 Angola", shortName: "+244" },
    { value: "+1 (264)", name: "+1 (264) Anguila", shortName: "+1 (264)" },
    { value: "+1 (268)", name: "+1 (268) Antigua y Barbuda", shortName: "+1 (268)" },
    { value: "+599", name: "+599 Antillas Neerlandesas", shortName: "+599" },
    { value: "+213", name: "+213 Argelia", shortName: "+213" },
    { value: "+54", name: "+54 Argentina", shortName: "+54" },
    { value: "+374", name: "+374 Armenia", shortName: "+374" },
    { value: "+297", name: "+297 Aruba", shortName: "+297" },
    { value: "+247", name: "+247 Ascensión", shortName: "+247" },
    { value: "+61", name: "+61 Australia", shortName: "+61" },
    { value: "+43", name: "+43 Austria", shortName: "+43" },
    { value: "+994", name: "+994 Azerbaiyán", shortName: "+994" },
    { value: "+1 (242)", name: "+1 (242) Bahamas", shortName: "+1 (242)" },
    { value: "+973", name: "+973 Bahrein", shortName: "+973" },
    { value: "+880", name: "+880 Bangladesh", shortName: "+880" },
    { value: "+1 (246)", name: "+1 (246) Barbados", shortName: "+1 (246)" },
    { value: "+32", name: "+32 Bélgica", shortName: "+32" },
    { value: "+501", name: "+501 Belice", shortName: "+501" },
    { value: "+229", name: "+229 Benín", shortName: "+229" },
    { value: "+1 (441)", name: "+1 (441) Bermudas", shortName: "+1 (441)" },
    { value: "+375", name: "+375 Bielorrusia", shortName: "+375" },
    { value: "+95", name: "+95 Birmania (Myanma)", shortName: "+95" },
    { value: "+591", name: "+591 Bolivia", shortName: "+591" },
    { value: "+387", name: "+387 Bosnia y Herzegovina", shortName: "+387" },
    { value: "+267", name: "+267 Botsuana", shortName: "+267" },
    { value: "+55", name: "+55 Brasil", shortName: "+55" },
    { value: "+673", name: "+673 Brunei", shortName: "+673" },
    { value: "+359", name: "+359 Bulgaria", shortName: "+359" },
    { value: "+226", name: "+226 Burkina Faso", shortName: "+226" },
    { value: "+257", name: "+257 Burundi", shortName: "+257" },
    { value: "+975", name: "+975 Bután", shortName: "+975" },
    { value: "+238", name: "+238 Cabo Verde", shortName: "+238" },
    { value: "+855", name: "+855 Camboya", shortName: "+855" },
    { value: "+237", name: "+237 Camerún", shortName: "+237" },
    { value: "+235", name: "+235 Chad", shortName: "+235" },
    { value: "+56", name: "+56 Chile", shortName: "+56" },
    { value: "+86", name: "+86 China", shortName: "+86" },
    { value: "+357", name: "+357 Chipre", shortName: "+357" },
    { value: "+57", name: "+57 Colombia", shortName: "+57" },
    { value: "+269", name: "+269 Comoras", shortName: "+269" },
    { value: "+850", name: "+850 Corea del Norte", shortName: "+850" },
    { value: "+82", name: "+82 Corea del Sur", shortName: "+82" },
    { value: "+225", name: "+225 Costa de Marfil", shortName: "+225" },
    { value: "+506", name: "+506 Costa Rica", shortName: "+506" },
    { value: "+385", name: "+385 Croacia", shortName: "+385" },
    { value: "+53", name: "+53 Cuba", shortName: "+53" },
    { value: "+262", name: "+262 Departamentos y territorios franceses en el Océano Índico", shortName: "+262" },
    { value: "+246", name: "+246 Diego García", shortName: "+246" },
    { value: "+45", name: "+45 Dinamarca", shortName: "+45" },
    { value: "+1 (767)", name: "+1 (767) Dominica", shortName: "+1 (767)" },
    { value: "+593", name: "+593 Ecuador", shortName: "+593" },
    { value: "+20", name: "+20 Egipto", shortName: "+20" },
    { value: "+503", name: "+503 El Salvador", shortName: "+503" },
    { value: "+971", name: "+971 Emiratos Árabes Unidos", shortName: "+971" },
    { value: "+291", name: "+291 Eritrea", shortName: "+291" },
    { value: "+421", name: "+421 Eslovaquia", shortName: "+421" },
    { value: "+386", name: "+386 Eslovenia", shortName: "+386" },
    { value: "+34", name: "+34 España", shortName: "+34" },
    { value: "+691", name: "+691 Estados Federados de Micronesia", shortName: "+691" },
    { value: "+372", name: "+372 Estonia", shortName: "+372" },
    { value: "+251", name: "+251 Etiopía", shortName: "+251" },
    { value: "+63", name: "+63 Filipinas", shortName: "+63" },
    { value: "+358", name: "+358 Finlandia", shortName: "+358" },
    { value: "+679", name: "+679 Fiyi", shortName: "+679" },
    { value: "+33", name: "+33 Francia", shortName: "+33" },
    { value: "+241", name: "+241 Gabón", shortName: "+241" },
    { value: "+220", name: "+220 Gambia", shortName: "+220" },
    { value: "+995", name: "+995 Georgia", shortName: "+995" },
    { value: "+233", name: "+233 Ghana", shortName: "+233" },
    { value: "+350", name: "+350 Gibraltar", shortName: "+350" },
    { value: "+1 (473)", name: "+1 (473) Granada", shortName: "+1 (473)" },
    { value: "+30", name: "+30 Grecia", shortName: "+30" },
    { value: "+299", name: "+299 Groenlandia", shortName: "+299" },
    { value: "+590", name: "+590 Guadalupe", shortName: "+590" },
    { value: "+1 (671)", name: "+1 (671) Guam", shortName: "+1 (671)" },
    { value: "+502", name: "+502 Guatemala", shortName: "+502" },
    { value: "+594", name: "+594 Guayana Francesa", shortName: "+594" },
    { value: "+240", name: "+240 Guinea Ecuatorial", shortName: "+240" },
    { value: "+224", name: "+224 Guinea", shortName: "+224" },
    { value: "+245", name: "+245 Guinea-Bissau", shortName: "+245" },
    { value: "+592", name: "+592 Guyana", shortName: "+592" },
    { value: "+509", name: "+509 Haití", shortName: "+509" },
    { value: "+504", name: "+504 Honduras", shortName: "+504" },
    { value: "+852", name: "+852 Hong Kong", shortName: "+852" },
    { value: "+36", name: "+36 Hungría", shortName: "+36" },
    { value: "+91", name: "+91 India", shortName: "+91" },
    { value: "+62", name: "+62 Indonesia", shortName: "+62" },
    { value: "+964", name: "+964 Irak", shortName: "+964" },
    { value: "+98", name: "+98 Irán", shortName: "+98" },
    { value: "+353", name: "+353 Irlanda", shortName: "+353" },
    { value: "+354", name: "+354 Islandia", shortName: "+354" },
    { value: "+1 (345)", name: "+1 (345) Islas Caimán", shortName: "+1 (345)" },
    { value: "+682", name: "+682 Islas Cook", shortName: "+682" },
    { value: "+298", name: "+298 Islas Feroe", shortName: "+298" },
    { value: "+500", name: "+500 Islas Malvinas (Falkland Island", shortName: "+500" },
    { value: "+692", name: "+692 Islas Marshall", shortName: "+692" },
    { value: "+677", name: "+677 Islas Salomón", shortName: "+677" },
    { value: "+1 (649)", name: "+1 (649) Islas Turcas y Caicos", shortName: "+1 (649)" },
    { value: "+1 (284)", name: "+1 (284) Islas Vírgenes Británicas", shortName: "+1 (284)" },
    { value: "+1 (340)", name: "+1 (340) Islas Vírgenes", shortName: "+1 (340)" },
    { value: "+972", name: "+972 Israel", shortName: "+972" },
    { value: "+39", name: "+39 Italia", shortName: "+39" },
    { value: "+1 (876)", name: "+1 (876) Jamaica", shortName: "+1 (876)" },
    { value: "+81", name: "+81 Japón", shortName: "+81" },
    { value: "+962", name: "+962 Jordania", shortName: "+962" },
    { value: "+7", name: "+7 Kazajstán", shortName: "+7" },
    { value: "+254", name: "+254 Kenia", shortName: "+254" },
    { value: "+996", name: "+996 Kirguistán", shortName: "+996" },
    { value: "+686", name: "+686 Kiribati", shortName: "+686" },
    { value: "+965", name: "+965 Kuwait", shortName: "+965" },
    { value: "+856", name: "+856 Laos", shortName: "+856" },
    { value: "+266", name: "+266 Lesotho", shortName: "+266" },
    { value: "+371", name: "+371 Letonia", shortName: "+371" },
    { value: "+961", name: "+961 Líbano", shortName: "+961" },
    { value: "+231", name: "+231 Liberia", shortName: "+231" },
    { value: "+218", name: "+218 Libia", shortName: "+218" },
    { value: "+423", name: "+423 Liechtenstein", shortName: "+423" },
    { value: "+370", name: "+370 Lituania", shortName: "+370" },
    { value: "+352", name: "+352 Luxemburgo", shortName: "+352" },
    { value: "+853", name: "+853 Macao", shortName: "+853" },
    { value: "+389", name: "+389 Macedonia", shortName: "+389" },
    { value: "+261", name: "+261 Madagascar", shortName: "+261" },
    { value: "+60", name: "+60 Malasia", shortName: "+60" },
    { value: "+265", name: "+265 Malawi", shortName: "+265" },
    { value: "+960", name: "+960 Maldivas", shortName: "+960" },
    { value: "+223", name: "+223 Malí", shortName: "+223" },
    { value: "+356", name: "+356 Malta", shortName: "+356" },
    { value: "+212", name: "+212 Marruecos", shortName: "+212" },
    { value: "+596", name: "+596 Martinica", shortName: "+596" },
    { value: "+230", name: "+230 Mauricio", shortName: "+230" },
    { value: "+222", name: "+222 Mauritania", shortName: "+222" },
    { value: "+269(Duplicar_1)", name: "+269 Mayotte", shortName: "+269(Duplicar_1)" },
    { value: "+52", name: "+52 México", shortName: "+52" },
    { value: "+373", name: "+373 Moldavia", shortName: "+373" },
    { value: "+377", name: "+377 Mónaco", shortName: "+377" },
    { value: "+976", name: "+976 Mongolia", shortName: "+976" },
    { value: "+382", name: "+382 Montenegro", shortName: "+382" },
    { value: "+1 (664)", name: "+1 (664) Montserrat", shortName: "+1 (664)" },
    { value: "+258", name: "+258 Mozambique", shortName: "+258" },
    { value: "+264", name: "+264 Namibia", shortName: "+264" },
    { value: "+674", name: "+674 Nauru", shortName: "+674" },
    { value: "+977", name: "+977 Nepal", shortName: "+977" },
    { value: "+505", name: "+505 Nicaragua", shortName: "+505" },
    { value: "+227", name: "+227 Níger", shortName: "+227" },
    { value: "+234", name: "+234 Nigeria", shortName: "+234" },
    { value: "+683", name: "+683 Niue", shortName: "+683" },
    { value: "+47", name: "+47 Noruega", shortName: "+47" },
    { value: "+687", name: "+687 Nueva Caledonia", shortName: "+687" },
    { value: "+64", name: "+64 Nueva Zelanda", shortName: "+64" },
    { value: "+968", name: "+968 Omán", shortName: "+968" },
    { value: "+31", name: "+31 Países Bajos", shortName: "+31" },
    { value: "+92", name: "+92 Pakistán", shortName: "+92" },
    { value: "+680", name: "+680 Palau", shortName: "+680" },
    { value: "+507", name: "+507 Panamá", shortName: "+507" },
    { value: "+675", name: "+675 Papúa Nueva Guinea", shortName: "+675" },
    { value: "+595", name: "+595 Paraguay", shortName: "+595" },
    { value: "+689", name: "+689 Polinesia Francesa", shortName: "+689" },
    { value: "+48", name: "+48 Polonia", shortName: "+48" },
    { value: "+351", name: "+351 Portugal", shortName: "+351" },
    { value: "+1 (787/939)", name: "+1 (787/939) Puerto Rico", shortName: "+1 (787/939)" },
    { value: "+974", name: "+974 Qatar", shortName: "+974" },
    { value: "+44", name: "+44 Reino Unido", shortName: "+44" },
    { value: "+236", name: "+236 República Centroafricana", shortName: "+236" },
    { value: "+420", name: "+420 República Checa", shortName: "+420" },
    { value: "+242", name: "+242 República del Congo", shortName: "+242" },
    { value: "+243", name: "+243 República Democrática del Congo", shortName: "+243" },
    { value: "+1 (809/829)", name: "+1 (809/829) República Dominicana", shortName: "+1 (809/829)" },
    { value: "+250", name: "+250 Ruanda", shortName: "+250" },
    { value: "+40", name: "+40 Rumanía", shortName: "+40" },
    { value: "+7(Duplicar_1)", name: "+7 Rusia", shortName: "+7(Duplicar_1)" },
    { value: "+212(Duplicar_1)", name: "+212 Sahara Occidental", shortName: "+212(Duplicar_1)" },
    { value: "+1 (684)", name: "+1 (684) Samoa Americana", shortName: "+1 (684)" },
    { value: "+685", name: "+685 Samoa", shortName: "+685" },
    { value: "+1 (869)", name: "+1 (869) San Cristóbal y Nieves", shortName: "+1 (869)" },
    { value: "+378", name: "+378 San Marino", shortName: "+378" },
    { value: "+508", name: "+508 San Pedro y Miquelón", shortName: "+508" },
    { value: "+1 (784)", name: "+1 (784) San Vicente y las Granadinas", shortName: "+1 (784)" },
    { value: "+966", name: "+966 San Vicente y las Granadinas", shortName: "+966" },
    { value: "+290", name: "+290 Santa Helena", shortName: "+290" },
    { value: "+1 (758)", name: "+1 (758) Santa Lucía", shortName: "+1 (758)" },
    { value: "+379", name: "+379 Santa Sede (Ciudad del Vaticano)", shortName: "+379" },
    { value: "+239", name: "+239 Santo Tomé y Príncipe", shortName: "+239" },
    { value: "+221", name: "+221 Senegal", shortName: "+221" },
    { value: "+381", name: "+381 Serbia", shortName: "+381" },
    { value: "+248", name: "+248 Seychelles", shortName: "+248" },
    { value: "+232", name: "+232 Sierra Leona", shortName: "+232" },
    { value: "+65", name: "+65 Singapur", shortName: "+65" },
    { value: "+963", name: "+963 Siria", shortName: "+963" },
    { value: "+252", name: "+252 Somalia", shortName: "+252" },
    { value: "+94", name: "+94 Sri Lanka", shortName: "+94" },
    { value: "+268", name: "+268 Suazilandia", shortName: "+268" },
    { value: "+27", name: "+27 Sudáfrica", shortName: "+27" },
    { value: "+249", name: "+249 Sudán", shortName: "+249" },
    { value: "+46", name: "+46 Suecia", shortName: "+46" },
    { value: "+41", name: "+41 Suiza", shortName: "+41" },
    { value: "+597", name: "+597 Surinam", shortName: "+597" },
    { value: "+66", name: "+66 Tailandia", shortName: "+66" },
    { value: "+886", name: "+886 Taiwán", shortName: "+886" },
    { value: "+255", name: "+255 Tanzania", shortName: "+255" },
    { value: "+992", name: "+992 Tayikistán", shortName: "+992" },
    { value: "+1 (670)", name: "+1 (670) Territorio del Norte", shortName: "+1 (670)" },
    { value: "+670", name: "+670 Timor-Leste", shortName: "+670" },
    { value: "+228", name: "+228 Togo", shortName: "+228" },
    { value: "+690", name: "+690 Tokelau", shortName: "+690" },
    { value: "+676", name: "+676 Tonga", shortName: "+676" },
    { value: "+1 (868)", name: "+1 (868) Trinidad y Tobago", shortName: "+1 (868)" },
    { value: "+216", name: "+216 Túnez", shortName: "+216" },
    { value: "+993", name: "+993 Turkmenistán", shortName: "+993" },
    { value: "+90", name: "+90 Turquía", shortName: "+90" },
    { value: "+688", name: "+688 Tuvalu", shortName: "+688" },
    { value: "+380", name: "+380 Ucrania", shortName: "+380" },
    { value: "+256", name: "+256 Uganda", shortName: "+256" },
    { value: "+598", name: "+598 Uruguay", shortName: "+598" },
    { value: "+998", name: "+998 Uzbekistán", shortName: "+998" },
    { value: "+678", name: "+678 Vanuatu", shortName: "+678" },
    { value: "+13", name: "+5+8 Venezuela", shortName: "+13" },
    { value: "+84", name: "+84 Vietnam", shortName: "+84" },
    { value: "+681", name: "+681 Wallis y Futuna", shortName: "+681" },
    { value: "+967", name: "+967 Yemen", shortName: "+967" },
    { value: "+253", name: "+253 Yibuti", shortName: "+253" },
    { value: "+260", name: "+260 Zambia", shortName: "+260" },
    { value: "+263", name: "+263 Zimbabue", shortName: "+263" },
];