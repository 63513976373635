<h3 class="main-title">
    Sorteo Gift Card
</h3>


<p>
    Promoción vigente del  20 de agosto al 20 de setiembre del 2024. Esta Promoción es desarrollada por Mandadito, una iniciativa del Banco de Crédito del Perú (“BCP”) habilitada por BCP Miami.
</p>

<p>
Participan las personas naturales de nacionalidad peruana que durante la vigencia de la Promoción: (i) se registren en Mandaditos con su DNI y/o licencia de conducir vigente emitida por Estados Unidos de América (“U.S.A.”); y, (ii) se encuentren físicamente en el estado de Florida en U.S.A. (en adelante, el “Participante”).
</p>

<p>
Premio: una (01) gift card virtual de “Amazon”, equivalente a US$200.00 (doscientos con 00/100 dólares americanos) para ser usado exclusivamente por el/la Participante ganador/a en la plataforma de “Amazon”. El Premio no es reembolsable, endosable ni transferible. Stock: un (01) Premio.
</p>

<p>
El Participante que se registre correctamente en Mandaditos durante el periodo de vigencia de la Promoción, tendrá una oportunidad en el Sorteo.
</p>

<p>
El Sorteo se realizará el 25 de setiembre del 2024. El (01) Participante ganador serán anunciado el 27 de setiembre de 2024 en las redes sociales de Mandadito. La entrega del Premio se realizará, vía correo electrónico, entre el 27  y  30 de setiembre del 2024, posterior al contacto y confirmación del Participante ganador.
</p>

<p>
En caso el BCP no obtuviera respuesta del Participante ganador hasta el segundo día calendario contados desde el primer contacto, o si por algún otro hecho imputable al ganador no pudiera realizarse la entrega del Premio, perderá su derecho a recibirlo sin opción a reclamo. En tales casos, el BCP se reserva el derecho de decidir libremente el destino del Premio.
</p>

<p>
El Participante ganador autoriza a el BCP a publicar sus datos en la página web <a href="http://www.mandaditobcp.com.pe">http://www.mandaditobcp.com.pe</a> y/o a través de otros canales oficiales dely Mandadito como redes sociales, con la finalidad de hacer de conocimiento público los resultados del Sorteo. Los datos que se publicarán son los utilizados por el Participante para la Promoción.
</p>

<p>
El BCP no se responsabiliza por la idoneidad de los productos o servicios que componen el Premio, ni por cualquier daño o perjuicio que pudiera derivarse del uso de estos.
</p>

<p>
El BCP podrá, previa comunicación a los Participantes y sin responsabilidad alguna, modificar alguno de los términos establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o sea producto de alguna obligación legal.
</p>

<p>
Para más información sobre la promoción y/o restricciones, puede comunicarse con Mandadidto por Whatsapp al +51 945 657 689 ingresar a <a href="http://www.mandaditobcp.com.pe">http://www.mandaditobcp.com.pe</a>.
</p>