import { Component, OnInit, inject } from '@angular/core';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { TermsAndConditionsBreadcrumb, ActiveCampaingsBreadcrumb, ChristmasCampaingBreadcrumb, PastCampaignsBreadcrumb } from '../breadcrumbData';

@Component({
  selector: 'app-christmas-campaign',
  templateUrl: './christmas-campaign.component.html',
  styleUrls: ['./christmas-campaign.component.scss']
})
export class ChristmasCampaignComponent implements OnInit {


  private breadcrumbService = inject(BreadcrumbService)

  ngOnInit(): void {
    this.setBreadcrumb() 

  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb([
      TermsAndConditionsBreadcrumb,
      PastCampaignsBreadcrumb,
      ChristmasCampaingBreadcrumb
    ])
  }

}
