import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent {

  whatsappUrl = "https://wa.me/51945657689?text=Hola%20Mandadito%2C%20quiero%20saber%20informaci%C3%B3n%20sobre%E2%80%A6"

}
