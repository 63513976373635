import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LastPageService {

  private subject = new BehaviorSubject<string>('');

  saveLastPage(page: string) {
    this.subject.next(page);
  }

  clearLastPage() {
    this.subject.next(null);
  }

  getLastPage(): string {
    return this.subject.getValue();
  }
}
