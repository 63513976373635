import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBreadcrumbItem } from 'src/app/shared/models/model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbData$: BehaviorSubject<IBreadcrumbItem[]> = new BehaviorSubject<IBreadcrumbItem[]>([])

  setBreadcrumb(data: IBreadcrumbItem[]) {
    this.breadcrumbData$.next(data)
  }

  asObservable(): Observable<IBreadcrumbItem[]> {
    return this.breadcrumbData$.asObservable()
  }
}
