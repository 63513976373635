<div class="amount">

        <div *ngIf="!showAmount" class="modal-overlay" (click)="closeModal()">
            <div class="modal-content" (click)="$event.stopPropagation()">
                    <span class="h1modal">Antes de hacer tu primer envío, sube una foto de tu documento</span>
                    <span class="h2modal">Por única vez, toma una foto de tu Driver’s license o DNI peruano por ambos lados</span>
                    <div>
                        <bcp-button class="button" shape="rectangle" tier="primary" size="lg" full-width="true" (click)="clickUploadPhoto()">Subir foto ahora</bcp-button>
                    </div>
            </div>
        </div>

    <h3 class="flexo-demi" *ngIf="presetAmount && !activeHome">Repetir Mandadito</h3>
    <h3 class="flexo-demi paragraph-text" *ngIf="!presetAmount && activeHome">Monto a enviar</h3>

    <div class="receiver-card" *ngIf="presetReceiver && !activeHome">
        <div class="receiver-initial">
            {{presetReceiver?.firstName.substring(0,1).toUpperCase()}}
        </div>
        <div>
            <div class="receiver-data-name">
                {{presetReceiver?.firstName | titlecase }} {{presetReceiver?.firstLastname | titlecase}}
            </div>
            <div class="receiver-data-account">
                {{maskAccountNumber(presetReceiver?.accountNumber)}}
            </div>
        </div>
    </div>

    <p class="amount-subtitle" *ngIf="presetAmount && !activeHome">Monto a enviar</p>

    <form [formGroup]="amountForm" (ngSubmit)="sendData()" *ngIf="amountForm">

        <div class="customAmount">
                <div>
                    <span translate="no" [ngClass]="{'error': isAmountExceeded || customError}">$</span>
                    <app-input-amount (inputDecimalEvent)="getInputAmount('DECIMAL', $event)" (inputAmountEvent)="getInputAmount('INTEGER', $event)" [presetAmount]="data?.operation?.amount ? data?.operation?.amount?.toString() : presetAmount" [activeHome]="activeHome"
                    [error]="isAmountExceeded|| customError"></app-input-amount>
                </div>
            <p [ngClass]="{'error': isAmountExceeded|| customError}" [innerHTML]="getTextInputAmount"></p>
        </div>

        <app-currency-conversion [amount]="amount" (onCurrencyChange)="setSelectedCurrency($event)" [isAmountExceeded]="isAmountExceeded" [isRepeatingMandadito]="isRepeatingMandadito" [presetReceiver]="presetReceiver"></app-currency-conversion>
        
        <app-promotion [promotion]="promotion" *ngIf="promotion && promotion.id.length > 0"></app-promotion>
        
        <div class="commission" [ngClass]="{'promotion': promotion && commission !== null }">
            <ul>
                <li class="position-relative">
                    Comisión
                    <span class="discount" *ngIf="promotion?.discount === 1"><span class="flexo-demi"> {{ promotion.label }}</span></span>
                </li>
                <li><p class="flexo-demi" (click)="showModalTariff()">Ver tarifario</p></li>
            </ul>
            <ul>
                <li class="flexo-demi">
                    <span>{{ normalCommission > 0 ? '$'+(commission | number:'1.2-2') : 'Por calcular' }}</span></li>
                <li class="with-discount text-right" *ngIf="promotion && commission !== null && amount > 0"><span>${{ normalCommission | number:'1.2-2' }}</span></li>
            </ul>
        </div>

        <div class="buttons">
            <div class="continue" *ngIf="presetAmount && !activeHome">
                <bcp-button type="submit" shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goBack()">
                    <bcp-icon name="arrow-left-r" slot="start"></bcp-icon>
                    Volver
                </bcp-button>
            </div>
            <div class="continue">
                <bcp-button type="submit" shape="rectangle" tier="primary" size="lg" full-width="true" [disabled]="amount < 1 || maxDailyToSend < amount || customError || isAmountExceeded">Continuar</bcp-button>
            </div>
        </div>
    </form>

</div>

<app-modal [size]="'lg'" *ngIf="modalTariff">
    <section>
        <div class="close" (click)="modalTariff = false;">
            <img src="/assets/images/close.svg" width="20" alt="close" />
        </div>
        <div class="tariff">
            <h3 class="text-center">¿Cuánto cuesta Mandadito?</h3>
            <p class="text-center">Cobramos una comisión de acuerdo al monto que envíes</p>
            <div class="table">
                <ul class="head">
                        <li>Monto</li>
                        <li>Comisión</li>
                </ul>
                <div class="body">
                    <ul class="even">
                        <li>Hasta 99.99 USD</li>
                        <li>3.99 USD</li>
                    </ul>
                    <ul>
                        <li>100 - 199.99 USD</li>
                        <li>5.50 USD</li>
                    </ul>
                    <ul class="even">
                        <li>200 - 500.99 USD</li>
                        <li>7.50 USD</li>
                    </ul>
                    <ul>
                        <li>501 - 970 USD</li>
                        <li>9.00 USD</li>
                    </ul>
                </div>
            </div>
            <div class="button">
                <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" (click)="modalTariff = false">De acuerdo</bcp-button>
            </div>
        </div>
    </section>
</app-modal>