import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS, UPDATE_DATA_STORAGE } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-reviewing-data',
  templateUrl: './reviewing-data.component.html',
  styleUrls: ['./reviewing-data.component.scss']
})
export class ReviewingDataComponent implements OnInit {

  email: string;
  authService = inject(AuthService);
  localStorageService = inject(LocalStorageService);
  router = inject(Router);

  constructor() { }

  ngOnInit(): void {
    this.goToLoginIfNotStorage();
    this.email = this.authService.email;
  }

  private goToLoginIfNotStorage(): void {
    const updateData = JSON.parse(JSON.stringify(this.localStorageService.getStorage(UPDATE_DATA_STORAGE)));
    this.localStorageService.removeStorage(UPDATE_DATA_STORAGE);
    if (!updateData) this.router.navigate([PATHS.LOGIN]);
  }

  goToLanding(): void {
    this.authService.logout()
    this.router.navigate([PATHS.LANDING]);
  }

}
