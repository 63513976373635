<div class="header w-100">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<div class="exceeded">

    <section>
        <img src="/assets/images/group-21.svg" alt="otro estado">
        <article>
            <img src="/assets/images/logo-1-logo-light.svg" class="d-none d-md-block" alt="Superaste los intentos de inicio de sesión">
            <h2 class="main-title font-size-md-32 line-height-md-40 flexo text-center text-md-left">Excediste el número de intentos</h2>
            <p class="sub-title d-flex align-items-center text-md-left">
                <span class="pr-2 font-size-14 line-height-20 font-size-md-20 line-height-md-28 text-center text-md-left">Por tu seguridad <strong>envíanos un correo</strong> para poder ayudarte a restablecer tu contraseña</span>
            </p>
            <a class="d-flex align-items-center text-center text-md-left" href="mailto:contacto@mandaditobcp.com.pe" rel="noopener" target="_blank" (click)="sendGtmEvent()">
                <bcp-button shape="text" tier="primary" size="lg" full-width="true">
                    <bcp-icon accessible-name="" accessible-title="" name="mail-r" slot="start"></bcp-icon>
                    contacto&#64;mandaditobcp.com.pe
                </bcp-button>
            </a>

            <p class="register pt-4 mb-0 sub-title d-flex align-items-center mx-auto font-size-14 font-size-md-16">
                <span class="pr-2">¿No tienes un usuario? </span>
                <bcp-button shape="text" tier="primary" (click)="goToRegister()">Regístrate</bcp-button>
            </p>
        </article>
    </section>
</div>
