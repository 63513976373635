<div class="dsc-carousel" [ngStyle]="{'min-height': finalHeight}">
    <div class="dsc-carousel__btns" *ngIf="activeArrows">
        <button (click)="setBack()"><img src="/assets/images/left-arrow.svg" alt="Left arrow" width="18" height="12"> </button>
        <button (click)="setNext()"><img src="/assets/images/left-arrow.svg" class="right" alt="Right arrow" width="18" height="12"></button>
    </div>
    <div class="dsc-carousel__content">
        <ng-container *ngFor="let slide of items;">
            <div class="w-100 dsc-carousel__content-item" [ngStyle]="{'margin-left': slide.marginLeft + '%' }">
                <picture>
                    <source 
                       media="(min-width: 650px)"
                       [srcset]="slide.images.desktop">
                    <img [src]="slide.images.responsive" 
                    alt="Slide illustration" width="280" height="210.77" loading="lazy">
                 </picture>
                <div>
                    <h4 class="flexo-demi mt-3 color-primary font-size-16 font-size-md-20 text-center text-md-left">{{ slide.title }}</h4>
                    <p class="font-size-14 font-size-md-16 text-md-left" [innerHTML]="slide.text"></p>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="dsc-carousel__steps">
        <div class="arrow cursor-pointer">
            <button (click)="setBack()"><img src="/assets/images/left-arrow.svg" alt="Left arrow" width="10.5" height="7"></button>
        </div>
        <div 
            class="dsc-carousel__steps-step 
            dsc-carousel__steps-step-{{currentPosition === item.id ? 'active': ''}}" 
            *ngFor="let item of items" (click)="setCurrentPosition(item.id)">
        </div>
        <div class="arrow cursor-pointer">
            <button (click)="setNext()"><img src="/assets/images/left-arrow.svg" class="right" alt="Right arrow" width="10.5" height="7"></button>
        </div>
    </div>
</div>

    