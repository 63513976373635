<div class="dsc-carousel__content">
    <ng-container *ngFor="let slide of ItemsCarouselHead;">
        <div class="w-100 dsc-carousel__content-item" [ngStyle]="{'margin-left': slide.marginLeft + '%' }">
          <section (click)="executeEvent(slide.hasModal)" class="inline-container" [style.background-color]="slide.id == 1 ? '#fff6e6' :'#ebf4ff'" >
                <img [src]="slide.imageLeft" width="44" height="44" class="my-auto" alt="Headband image">
                <p [style.color]="slide.id == 1 ? 'black' :'#0a47f0'" class="flexo-demi font-size-14 font-size-md-16 line-height-18 line-height-md-18" [innerHTML]="slide.title"></p>
                <img [src]="slide.imageRight" width="20" height="20" class="my-auto" alt="Headband right arrow">
            </section>
        </div>
    </ng-container>
  </div>
