import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Coupon, CouponData, OPERATIONS, UpdateDataService } from 'src/app/core/interfaces';
import { DataService } from 'src/app/core/services/data.service';
import { UtilService } from 'src/app/core/services/util.service';
import { validateCouponError } from 'src/app/views/auth/register/custom-validations';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit, OnDestroy {

  couponForm: FormGroup;
  submitted = false;
  @Output() emitCoupon = new EventEmitter<string>();
  isValidCoupon = false;
  steps = {
    promotionalCode: true,
    modalInput: false,
    coupon: false,
  }
  coupon: CouponData = null;
  // error = {
  //   show: false,
  //   message: ''
  // };
  couponError = '';
  subscriptionDataService: Subscription;

  private utilService = inject(UtilService);
  private dataService = inject(DataService);

  ngOnInit(): void {
    this.initializeForm();
    this.initializeData();
  }

  ngOnDestroy(): void {
    this.subscriptionDataService?.unsubscribe();
  }

  initializeForm(): void {
    this.couponForm = new FormGroup({
      couponCode: new FormControl(null, [ Validators.required, Validators.pattern(/^[\w]*$/), validateCouponError(this.couponError) ]),
    });
  }

  valideCharacters(event): boolean {
    const found = event.key.match(/^[a-zA-Z0-9]*$/);
    if (found === null) {
      return false;
    }

    return true;
  }

  removeSpecialCharacter(event) {
    const input = this.couponForm.controls['couponCode'];
    if (event.key && input?.value) {
      input?.setValue(input?.value.replace(/[¨*áéíóúÁÉÍÓÚàèìòùÀÈÌÒÙÇ^]/gi, ''));
    }
    input?.setValue(input?.value.toUpperCase());
  }

  initializeData(): void {
    this.subscriptionDataService = this.dataService.data.subscribe({
      next: (data: UpdateDataService) => {
        this.couponError = '';
        if (data && data.operation && data.operation === OPERATIONS.COUPON_STATE) {
          if (data.success) {
            this.coupon = data.data;
            this.isValidCoupon = true;
            this.steps.modalInput =  false;
            this.steps.coupon = true;
          } else {
            this.isValidCoupon = false;
            if (!!data.clear === false) {
              this.couponForm.get('couponCode')?.setErrors({'couponError': true});
              this.couponError = data.data.error.errors[0].message;
              // this.error.message = data.data.error.errors[0].message;
            }
          }
        }
      },
      error: err => {},
    });
  }

  getMessage(input: string): string {
    const formControl = this.couponForm?.get(input);

    if (this.submitted && !!formControl && formControl.errors) {
      return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
    }

    return '';
  }

  getError(input: string): string {
    const formControl = this.couponForm?.get(input);

    if (this.submitted && !!formControl && formControl.errors) {
      return "error";
    }

    return '';
  }

  sendCouponCode() {
    const couponCode = this.couponForm?.get('couponCode')?.value;
    if (couponCode && couponCode.length > 2) {
      this.couponError = null;
      // this.couponForm.get('couponCode').setErrors({'couponError': null});
      this.submitted = true;
      this.emitCoupon.emit(couponCode);
    }
  }

  clearCoupon() {
    this.coupon = null;
    // this.isValidCoupon = false;
    this.couponForm.reset()
    this.steps.promotionalCode = true;
    this.steps.coupon = false;
    this.resetForm();
    this.dataService.updateDataWithOperation({ operation: OPERATIONS.COUPON_STATE, data: null, success: false, clear: true });
    this.emitCoupon.emit(null);
  }

  resetCoupon() {

    this.resetForm();
    this.submitted = false;
    this.steps.modalInput = false;
    this.steps.promotionalCode = true;
    
  }

  resetForm() {
    this.couponForm.get('couponCode').setErrors({'couponError': null});
    this.couponForm.get('couponCode').setErrors({'invalid': null});
    this.couponForm.get('couponCode').setErrors({'required': null});
    this.couponForm.get('couponCode').reset();
    this.couponForm.updateValueAndValidity();
    this.submitted = false;
  }

}
