<div class="register">
    <h2 class="main-title">Crea tu cuenta</h2>
    <p class="sub-title">Tu correo electrónico sera tu usuario para ingresar a Mandadito</p>
    <form class="form-main" [formGroup]="formRegister" (ngSubmit)="sendData()">
         <div class="inputElement">
            <bcp-input type="email" label="Correo electrónico *" required="true" bcpCtrlForm placeholder="ejemplo@correo.com"
            [message]="getMessage('email')" [state]="getError('email')" formControlName="email"></bcp-input>
        </div>

        <div class="inputElement">
            <bcp-input type="email" label="Repite tu correo electrónico *" required="true" bcpCtrlForm placeholder="ejemplo@correo.com"
            [message]="!formRegister.errors ? 'Los correos deben coincidir' : ' Los correos no coinciden'" [state]="getError('repeatEmail')" formControlName="repeatEmail"></bcp-input>
        </div>
        <div class="inputElement">
            <bcp-input [type]="inputType" label="Contraseña" required="true" bcpCtrlForm placeholder="Ingresa una contraseña segura"
            [message]="getMessage('password')" [state]="getError('password')" formControlName="password" icon-position="right">
                <bcp-icon [name]="eyeType" class="cursor-pointer" slot="icon" (click)="eventTriggered($event)"></bcp-icon>
            </bcp-input>

            <ul class="password-validators">
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastEightCharacters'])}}.svg" alt=""> <span>Mínimo 8 caracteres</span></li>
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneNumber'])}}.svg" alt=""> <span>Por lo menos un número</span></li>
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneUppercase'])}}.svg" alt=""> <span>Por lo menos una mayúscula</span></li>
                <li><img src="/assets/images/check-{{getImageByCondition(formRegister.get('password').errors?.['atLeastOneSpecialCharacter'])}}.svg" alt=""> <span>Por lo menos un caracter especial #S/ %-.!</span></li>
            </ul>
        </div>

        <div class="button">
            <bcp-button id="button-customer-register" shape="rectangle" tier="primary" size="lg" full-width="true" type="submit" [disabled]="formRegister.invalid">Registrarme</bcp-button>
        </div>

        <div class="button">
            <bcp-button shape="rectangle" tier="secondary" size="lg" full-width="true" (click)="goBackStep()">
                <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
                Volver
            </bcp-button>
        </div>

    </form>
</div>
