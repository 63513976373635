import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss']
})
export class ImgComponent {

  @Input()
  src: string;
  @Input()
  alt: string;
  @Input()
  sizes: number[];

}
