import { Component, OnInit, inject } from '@angular/core';
import { GtmEvent, UpdateRegisterRequest, UpdateRegisterResponse, UserInterface } from 'src/app/core/interfaces';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ASSETS, LOGIN_NEXT_STEP, PATHS } from 'src/app/core/constants';
import { UtilService } from 'src/app/core/services/util.service';
import { LocationApi } from '../../new-mandadito/new-mandadito.data';
import { LastPageService } from 'src/app/shared/services/last-page.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-complete-registration-photo',
  templateUrl: './complete-registration-photo.component.html',
  styleUrls: ['./complete-registration-photo.component.scss']
})
export class CompleteRegistrationPhotoComponent implements OnInit {

  steps = {
    second: false,
    third: false,
    completed: false
  };

  data: UserInterface = null;
  loading = false;
  error = {
    show: false,
    message: null
  };

  showModalAccessTime = false;
  currentStep: number = 1;
  totalSteps: number = 3;
  locationApi = LocationApi;
  hasIncompleteRegister: boolean = false;
  spotAlertGroup: string = ASSETS.SPOT_ALERT_GROUP;
  spotAlertHand: string = ASSETS.SPOT_ALERT_HAND;
  showModalError: boolean = false;
  showModalSkipPhoto: boolean = false;
  imgLogo: string = ASSETS.IMG_LOGO_CHIP;
  isMenuOpened = false;
  activeHome = true;

  private authService = inject(AuthService);
  private router = inject(Router);
  private utilService = inject(UtilService);
  private lastPageService = inject(LastPageService);
  private gtmService = inject(GtmService);
  private localStorageService = inject(LocalStorageService);

  private getMetaData() {
    return {
      ip: this.locationApi?.ip,
      city: this.locationApi?.city,
      browser: this.utilService.getBrowserByUserAgent(),
      operationSystem: this.utilService.getOperationSystemByUserAgent(),
      device: this.utilService.getDeviceByUserAgent(),
    };
  }

  async ngOnInit() {
    await this.getApiData();
    this.setInitialPage();
  }

  async getApiData() {
    const { goAway, locationApi } = await this.utilService.getApiData();

    if ( goAway ) {
      this.router.navigate([PATHS.OTHER_STATE]);
    }
    this.locationApi = locationApi;
  }

  setInitialPage() {
      this.setInitialStep();
  }

  setInitialStep() {
    this.steps.third = true;
  }


  setStatusRegister(response: UpdateRegisterResponse) {
    const token = this.localStorageService.getStorage('token');
    token.statusRegister = response.statusRegister;
    this.localStorageService.setStorage('token', token);
  }

  nextStep(event: UpdateRegisterRequest): void {
    this.loading = true;
    this.data = { fields: {...event} } as UserInterface;
    this.authService.updateUserRegister(event).subscribe({
      next: (response: UpdateRegisterResponse) => {
        this.setStatusRegister(response);
        this.steps.second = false;
        this.steps.third = true;
        this.currentStep+=1;
        this.scrollToTop();
      },
      error: () => this.showModalError = true
    });
  }

  closeModal(modal: string) {
    this[modal] = false;
  }

  skipStep(): void {
    this.showModalSkipPhoto = true;
  }

  sendData(event): void {
    this.data = event;
    this.data.fields = {...this.data.fields, ...this.getMetaData()};
    this.loading = true;

    this.authService.register(this.data).subscribe({
      next: (response) => {
        this.loading = false;
        this.steps.third = false;
        this.steps.completed = true;
        this.setStatusRegister(response);
        const event: GtmEvent = {
          event: 'Reg_Registrarme​',
          accion: 'Termina registro​​',
          pantalla: 'Registro3​',
        };
        this.gtmService.sendEvent(event);
        this.completedRegister();
      },
      error: (err) => {
        this.loading = false;
        let message = 'Un hubo error en el proceso, vuelva a intentar';
        if (err.error.errors[0].code === "ALREADY_EXIST") message = 'Usuario ya existe';
        this.error = {
          show: true,
          message
        };
        setTimeout(() => {
          this.error = {
            show: false,
            message: null
          };
        }, 3000);
      }
    });
  }

  completedRegister(): void {
    this.lastPageService.saveLastPage(PATHS.COMPLETE_REGISTER_PHOTO);
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  goToLanding(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  /**
   * scrolls to top of register component (register view)
   */
  scrollToTop(){
    window.scrollTo(0, 0);
  }

  toggleFullscreenMenu() {
    this.isMenuOpened = !this.isMenuOpened
  }
  get hideContent(){
    return this.isMenuOpened && window.innerWidth < 768
  }

  goBack(): void {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }
}
