
<ul *ngIf="elements.length > 0">
    <li class="line"  [ngStyle]="{'width.%': 100}"></li>
    <li class="line green" [ngStyle]="{'width.%': lineWith}"></li>
    <ng-container *ngFor="let element of elements; let i = index">
        <li class="step" [ngClass]="{'completed': element.status === 'completed', 'current': element.status === 'current'}">
            <span *ngIf="element.status !== 'completed'">{{ element.value }}</span>
            <span *ngIf="element.status === 'completed'"></span>
            <p>{{ element.text }}</p>
        </li>
    </ng-container>
</ul>