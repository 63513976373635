import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';
import { MandaditoQueryParams, OPERATIONS, Operation } from 'src/app/core/interfaces';
import { DataService } from 'src/app/core/services/data.service';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent {

  @Input() historyElements: Operation[] = [];
  @Input() showTutorial = false;
  @Input() currentStep = 1;
  
  private mainService = inject(MainService);
  private router = inject(Router);

  async validateOperation(operation: Operation) {

    this.mainService.getOperationDetail(operation.id).subscribe({
      next: async(data) => {
        const operationDetail = data.data;

        const mandaditoQueryParams: MandaditoQueryParams = {
          receiverId: operationDetail.receiverId,
          amount: operationDetail.requestedAmount,
          origin: 'Home'
        }
        
        this.router.navigate([PATHS.NEW_MANDADITO], { queryParams: mandaditoQueryParams });
      }
    });

  }

}
