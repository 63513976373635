import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/core/services/util.service';
import { Router } from '@angular/router';
import { GtmEvent, IRegisterUserResponse, UserInterface } from 'src/app/core/interfaces';
import { atLeastEightCharacters, atLeastOneNumber, atLeastOneSpecialCharacter, atLeastOneUppercase, emailsMatch } from '../../custom-validations';
import { ASSETS, FORM_MESSAGE, PATHS, TEXT } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainService } from 'src/app/core/services/main.service';
import { GtmService } from 'src/app/core/services/gtm.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { LocationApi } from 'src/app/views/new-mandadito/new-mandadito.data';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, OnDestroy {

  @Input() location = LocationApi;
  
  @Output()
  setData = new EventEmitter<UserInterface>();

  private authService = inject(AuthService);
  private mainService = inject(MainService);
  private gtmService = inject(GtmService);
  private localStorageService = inject(LocalStorageService);

  urlTermsConditionsPrivacyPolicy = environment.urlTermsConditionsPrivacyPolicy;

  formRegister: FormGroup;
  submitted = false;
  terms = false;
  data = false;
  user: UserInterface;
  previousDocumentNumber = '';
  inputType = 'password';
  showModalUserExists = false;
  showModalRegisterInProcess: boolean = false;
  showError: boolean = false;
  showAlert: boolean = false;
  spotAlertHand: string = ASSETS.SPOT_ALERT_HAND;
  spotAlertGroup: string = ASSETS.SPOT_ALERT_GROUP;
  msgInvalidForm: string = TEXT.MSG_INCOMPLETE_FORM;
  hasDniError: boolean = false;
  dniSelected!: string;
  eyeType = 'eye-b';
  dniError = null;

  dniSubscription$: Subscription;

  selectOptions = [{
    value: '+1',
    name: '+1 USA',
    shortName: '+1'
  },
  {
    value: '+51',
    name: '+51 Perú',
    shortName: '+51'
  }]

  recaptchaSiteKey = environment.recaptcha.siteKey;
  isInvalidCaptcha = true;
  captchaResponse: string;

  constructor(private router: Router, private utilService: UtilService) { }


  ngOnInit(): void {
    this.initializeForm();
    this.detectFormChanges();
  }

  initializeForm(): void {
    this.formRegister = new FormGroup({
      dni: new FormControl(null, [Validators.required,
      Validators.pattern(/^[0-9]{8,8}$/), Validators.minLength(8), Validators.maxLength(8)]),
      firstName: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-zA-ZáéíóúÁÉÍÓÚñÑ])[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$/)]),
      secondName: new FormControl('', [Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/)]),
      firstSurname: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-zA-ZáéíóúÁÉÍÓÚñÑ])[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$/)]),
      secondSurname: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-zA-ZáéíóúÁÉÍÓÚñÑ])[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$/)]),
      address: new FormControl('', [ Validators.required ]),
      combinedPhone: new FormControl(''),
      phonePrefix: new FormControl('+1', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.minLength(8), Validators.pattern(/^[0-9]+$/), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^[+\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      repeatEmail: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, atLeastEightCharacters,
        atLeastOneNumber, atLeastOneUppercase, atLeastOneSpecialCharacter]),
    }, [emailsMatch('email', 'repeatEmail')]);
  }

  goBack(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  goToLogin(screen?: string, eventName?: string): void {
    if (screen) {
      const event: GtmEvent = {
        event: eventName,
        accion: 'Iniciar sesión​',
        pantalla: screen
      };
      this.gtmService.sendEvent(event);
    }
    this.router.navigate([PATHS.LOGIN]);
  }

  getMessage(input: string): string {
    if (!input) return ''

    const formControl = this.formRegister.get(input);
    if (formControl && !formControl.pristine && formControl.errors && formControl.errors['required']) {
      if (input === 'terms' || input === 'clauses') {
        return this.utilService.getMessageErrorByErrorType(input, Object.keys(formControl.errors)[0]);
      }
      return "Campo requerido";
    }

    if (formControl && !formControl.pristine && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return this.utilService.getMessageErrorByErrorType(input, input === 'password' ? 'invalid' : Object.keys(formControl.errors)[0]);
    }

    if (input === 'phoneNumber' && formControl && formControl.errors) {
      if (formControl.errors['pattern']) return this.utilService.getMessageErrorByErrorType(input, 'pattern');
      if (formControl.errors['minlength']) return this.utilService.getMessageErrorByErrorType(input, 'minlength');
    }

    return '';
  }

  getError(input: string): string {
    const formControl = this.formRegister.get(input);
    if (!!formControl && !formControl.pristine && formControl.errors && formControl.errors['required']) {
      return "error";
    }

    if (input === 'repeatEmail' && formControl && !formControl.pristine && this.formRegister.errors?.['emailMatchError']) {
      return "error";
    }

    if (input === 'email' && this.formRegister.errors?.['emailMatchError']) {
      return "error";
    }

    if (!!formControl && !formControl.pristine && formControl.errors && !formControl.errors.hasOwnProperty('required')) {
      return "error";
    }

    if (input === 'phoneNumber' && formControl && formControl.dirty) {
      return this.formRegister.get('phonePrefix').invalid && this.formRegister.get('phoneNumber').invalid ? 'error' : ''
    }

    return '';
  }

  acceptTerms(event) {
    this.terms = event.detail === null ? false : true;
    if (this.terms && this.showAlert) {
      this.showAlert = !this.formRegister.valid || this.isInvalidCaptcha;
    } else if (this.showAlert) {
      this.msgInvalidForm = TEXT.MSG_INCOMPLETE_FORM;
    }
  }

  acceptClauses(event) {
    this.data = event.detail !== null;
  }

  private getMetaData() {
    return {
      ip: this.location?.ip,
      city: this.location?.city,
      browser: this.utilService.getBrowserByUserAgent(),
      operationSystem: this.utilService.getOperationSystemByUserAgent(),
      device: this.utilService.getDeviceByUserAgent(),
    };
  }

  async sendData() {
    this.submitted = true;
    if (this.formRegister.invalid || this.isInvalidCaptcha || !this.terms) {
      this.showAlert = true;
      Object.keys(this.formRegister.controls).forEach(control => {
        this.formRegister.controls[control].markAsDirty();
      })
      this.msgInvalidForm = this.formRegister.invalid || !this.terms ? TEXT.MSG_INCOMPLETE_FORM : TEXT.MSG_INCOMPLETE_CAPTCHA;
      return;
    }

    const dni = this.formRegister.get('dni').value;
    const email = this.formRegister.get('email').value; 
    const { existsUser, hasImcompleteRegister } = await this.userExists(dni, email) as IRegisterUserResponse;

    if (existsUser) {
      this.showModalUserExists = true;
      const event: GtmEvent = {
        event: 'Modal_correoregistrado​',
        accion: '-',
        pantalla: 'Correo registrado​'
      };
      this.gtmService.sendEvent(event);
      return;
    } else if (hasImcompleteRegister) {
      this.showModalRegisterInProcess = true;
      const event: GtmEvent = {
        event: 'Modal_DNI_registro_incompleto​',
        accion: '-',
        pantalla: 'DNI registro incompleto​'
      };
      this.gtmService.sendEvent(event);
      return;
    }
    
    this.user = {
      fields: {
        ...this.formRegister.value,
        terms: this.terms,
        data: this.data,
        country: null,
        state: null,
        combinedPhone: null,
        captchaResponse: this.captchaResponse,
        metaData: this.getMetaData()
      },
      document_front: null,
      document_back: null,
    };

    const referralcode = this.localStorageService.getStorage('referralCode')

    if(referralcode) this.user.fields['referralCodeUsed'] = referralcode

    this.mainService.saveUserLog(this.user.fields).subscribe({
      next: () => {
        const event: GtmEvent = {
          event: 'virtualEvent',
          accion: 'clic_Registro1_Continuar',
          pantalla: 'Registro1',
          email: this.formRegister.get('email').value
        };
        this.gtmService.sendEvent(event);
        this.setData.emit(this.user);
      },
      error: () => this.showError = true
    });
  }

  async userExists(dni: string, email: string) {
    return new Promise((resolve, reject) => {
      this.authService.validateUserExists(dni, email).subscribe({
        next: (res: IRegisterUserResponse) => {
          resolve(res);
        },
        error: (err) => {
          this.showError = true;
          reject(err);
        }
      });
    })
  }

  getImageByCondition(condition: boolean) {
    const isDirty = this.formRegister.get('password').dirty;
    if (!condition && isDirty) {
      return 'round-valid';
    } else if (isDirty) {
      return 'round-invalid';
    }
    return 'round';
  }

  eventTriggered(_) {
    if (this.inputType === 'password') {
      this.inputType = 'text';
      this.eyeType = 'eye-slash-b';
    } else {
      this.inputType = 'password';
      this.eyeType = 'eye-b';
    }
  }

  handleValueChange(event) {
    const { selectValue, inputValue } = event.detail
    const phonePrefixCtrl = this.formRegister.get('phonePrefix')
    const phoneNumberCtrl = this.formRegister.get('phoneNumber')

    phonePrefixCtrl.setValue(selectValue)
    phoneNumberCtrl.setValue(inputValue)

    if (phonePrefixCtrl.pristine) {
      phoneNumberCtrl.markAsDirty()
    }

    phonePrefixCtrl.updateValueAndValidity()
    phoneNumberCtrl.updateValueAndValidity()
  }

  resolved(captchaResponse: any) {
    this.isInvalidCaptcha = true;
    if (captchaResponse && captchaResponse.length > 10) {
      this.captchaResponse = captchaResponse;
      this.isInvalidCaptcha = false;
      if (this.showAlert) {
        this.showAlert = !this.formRegister.valid || !this.terms;
      }
    }
  }

  getDniErrorMsg() {
    const errorList = this.formRegister.get('dni').errors;
    if (errorList?.['userExists']) {
      return FORM_MESSAGE.DNI_REGISTER;
    } else if (errorList?.['registerIncomplete']) {
      return FORM_MESSAGE.DNI_REGISTER_INCOMPLETE
    } else {
      return FORM_MESSAGE.DNI_INVALID
    }
  }

  detectFormChanges() {
    this.formRegister.valueChanges.subscribe(value => {
      if (this.formRegister.valid && !this.isInvalidCaptcha && this.terms) {
        this.showAlert = false;
      } else if (this.showAlert) {
        this.msgInvalidForm = TEXT.MSG_INCOMPLETE_FORM;
      }
    });
  }

  excludeLetters(event: KeyboardEvent) {

    return !!event.key.match(/^[0-9]+$/)
  }

  closeErrorModal() {
    if (this.hasDniError) {
      this.formRegister.get('dni').setValue('');
      this.hasDniError = false;
    }
    this.showError = false;
  }

  closeModal() {
    this.showModalRegisterInProcess = false;
    const event: GtmEvent = {
      event: 'Modal_DNI_incorrecto​',
      accion: 'Iniciar sesión​',
      pantalla: 'DNI registro incompleto​'
    };
    this.gtmService.sendEvent(event);
  }

  ngOnDestroy(): void {
    this.dniSubscription$?.unsubscribe();
  }
}
