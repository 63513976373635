<div class="main-content">
    <h2 class="main-title">
        Necesitamos verificar tu identidad
    </h2>
    <p class="sub-title" *ngIf="!isActiveBoth">
        Las fotos de tu documento no eran legibles o estaban incompletas.
    </p>

    <p class="sub-title" *ngIf="isActiveBoth">
        Las fotos de tu documento no eran legibles y los datos que ingresaste no coincidían con tu {{documentType ===
        documentTypes.DNI ? 'DNI' : "Driver's License"}}
    </p>

    <h2 class="documents-title">
        Sube las fotos de tu DNI o Driver's license
    </h2>

    <div class="radio-group">
        <label class="radio-card">
            <input type="radio" [value]="documentTypes.DNI" class="radio" [(ngModel)]="selectedDocumentType">
            <div class="radio-content">
                <img src="/assets/images/illust_DNI.svg" alt="DNI">
                <span> Usar DNI Peruano </span>
            </div>
        </label>
        <label class="radio-card">
            <input type="radio" [value]="documentTypes.DriverLicense" class="radio" [(ngModel)]="selectedDocumentType">
            <div class="radio-content">
                <img src="/assets/images/illust_Drivers.svg" alt="Drivers's license">
                <span> Usar Driver's license</span>
            </div>
        </label>
    </div>

    <ng-container *ngIf="selectedDocumentType">
        <div class="info-box">
            <img src="/assets/images/e_ic_exclamation_triangle.svg" alt="Exclamation">
            <span>Recuerda que tu documento debe estar vigente</span>
        </div>
        <div class="d-flex mx-auto justify-content-center flex-column">
            <app-upload-dni (documentFront)="setDocumentByType('front', $event)"
                (documentBack)="setDocumentByType('back', $event)" [selectedDocument]="selectedDocumentType"></app-upload-dni>
        </div>
    </ng-container>
        <div class="button">
            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="button" (click)="sendData()"
                [disabled]="canContinue">Continuar</bcp-button>
        </div>
        <p class="error mx-auto mt-2 text-center" *ngIf="showGeneralError">
            Todas las fotos son obligatorias
        </p>

    <p class="sub-title d-flex align-items-center justify-content-center mx-auto mt-4 mb-0"><span class="pr-2">¿Tienes
            dudas? </span>
        <a href="https://wa.link/ny1jx2" rel="noopener" target="_blank">
            <bcp-button shape="text" tier="primary" size="lg">
                Escríbenos
            </bcp-button>
        </a>
    </p>
</div>
