import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';;
import { PATHS } from 'src/app/core/constants';
import { GtmEvent } from 'src/app/core/interfaces';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  private router = inject(Router)

  @Output()
  private goToRegisterForm = new EventEmitter<any>()

  step = 1


  private gtmService = inject(GtmService);

  ngOnInit(): void {
  }

  goToLanding(): void {
    this.router.navigate([PATHS.LANDING]);
  }

  nextStep() {
    this.step = this.step + 1
    const dotsComponent = document.querySelector('#dots') as any
    dotsComponent.activeDot(2)
    this.sendGtmEvent('OnboardingRegistro_Continuar')
  }

  goToStep1() {
    this.sendGtmEvent('OnboardingRegistro_Registrate​')
    this.goToRegisterForm.emit()
  }

  sendGtmEvent(accion:string){
    const event: GtmEvent = {
      event: 'virtualEvent',
      accion,
      pantalla: 'Registro_Onboarding',
    };

    this.gtmService.sendEvent(event)
  }

  goToLogin(){
    this.router.navigate([PATHS.LOGIN]);
  }

}
