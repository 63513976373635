import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, concatMap, of, switchMap, take } from 'rxjs';
import { PATHS } from 'src/app/core/constants';
import { Operation, OperationDetail } from 'src/app/core/interfaces';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.scss']
})
export class HistoricalComponent implements OnInit {


  showError = false;
  operation: OperationDetail = null;
  hadSendMandadito: boolean
  isMenuOpened = false

  private mainService = inject(MainService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  operations: Operation[] = null;
  steps = {
    list: true,
    detail: false
  };

  ngOnInit(): void {
    this.getRouteParams();
  }

  getRouteParams(): void {
    this.route.queryParams
      .subscribe(params => {
        const error = params['error'];
        this.getOperations(error);
      }
      );
  }

  getOperations(error: boolean) {
    if (error) {
      this.showError = true
      return;
    }
    const size = 1
    this.mainService.getOperations(size, '').pipe(take(1), switchMap(res => {
      const mandaditoList = res.data

      // If hasn't sent mandaditos
      if (mandaditoList.length <= 0) {
        return of(res)
      }

      //If has send mandaditos
      if (mandaditoList.length > 0) {
        const mandaditoDate = new Date(mandaditoList[0].createdAt)
        const now = new Date()
        const monthDiff = this.getMonthDifference(mandaditoDate, now)

        //If the latest mandadito they sent was more thant six months ago
        if (monthDiff > 6) {
          console.log(monthDiff)
          return of({ data: [] })
        } else {
          // If they had send mandadito not more that six months ago
          this.hadSendMandadito = true
          const dateSplit = mandaditoList[0].createdAt.split('T')[0].split('-')
          const period = `${dateSplit[0]}${dateSplit[1]}`
          return this.mainService.getOperations(100, '', period)
        }

      } else {
        this.hadSendMandadito = false
        return of(res)
      }
    }), catchError(err => err)).subscribe({
      next: (res) => {
        if (res.data.length > 0) {
          this.operations = res.data.filter(mandadito => mandadito.status !== 'Confirmado')
        } else {
          this.operations = []
        }
      },
      error: (err) => {
        this.showError = true
      }
    })
  }

  getOperationsForPeriod(period: string) {
    const size = 100
    this.mainService.getOperations(size, '', period).subscribe({
      next: (res) => {
        if (res.data.length > 0) {
          this.operations = res.data.filter(mandadito => mandadito.status !== 'Confirmado')
        } else {
          this.operations = []
        }
      },
      error: (err) => {
        this.showError = true
      }
    })
  }

  showDetail(operation: Operation) {
    this.mainService.getOperationDetail(operation.id).subscribe({
      next: (data) => {
        this.operation = data.data;
        this.steps = {
          list: false,
          detail: true
        };
      }
    });
  }

  showList() {
    this.steps = {
      list: true,
      detail: false
    };
    this.operation = null;
  }

  goToNewMandadito(): void {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  onDateSelected(event: string) {
    this.getOperationsForPeriod(event)
  }

  private getMonthDifference(startDate: Date, endDate: Date) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  toggleFullscreenMenu() {
    this.isMenuOpened = !this.isMenuOpened
  }

  get hideContent(){
    return this.isMenuOpened && window.innerWidth < 768
  }
}
