import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent implements OnInit {

  private router = inject(Router);

  ngOnInit(): void {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

}
