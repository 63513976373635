<h2>Campaña de fiestas patrias 2023</h2>
<p>
    Promoción vigente del 17 de julio al 4 de agosto del 2023 o hasta agotar stock. Esta Promoción es desarrollada por
    Mandadito, una iniciativa del Banco de Crédito del Perú (“BCP”) habilitada por BCP Miami.
</p>

<p>
    Participan las personas naturales de nacionalidad peruana que, durante la vigencia de la Promoción: (i) se
    encuentren
    físicamente en el estado de Florida en Estados Unidos de América y (ii) soliciten su primer envío, en adelante, los
    “Usuarios”.

    Beneficio: Descuento del 50% de la comisión a los cien (100) primeros Usuarios que cumplan con todos los requisitos
    del
    segundo párrafo. Stock: cien (100) Beneficios. Máximo un (1) Beneficio por Usuario.
</p>


<p>
    Si el Usuario ya tiene una operación pendiente, aprobada o rechazada en la plataforma de Mandadito, previo a la
    vigencia
    de la Promoción, no se considerará como usuario nuevo para efectos de la Promoción y no podrá ser acreedor del
    Beneficio.

    El Beneficio se aplicará directamente al momento de usar la plataforma y se reflejará en las pantallas de resumen
    antes
    de hacer el pago.
</p>


<p>
    El BCP podrá, previa comunicación a los Usuarios y sin responsabilidad alguna, modificar alguno de los términos
    establecidos en el presente texto, únicamente cuando dicho cambio no afecte la naturaleza de la Promoción o el
    cambio
    sea producto de una obligación legal.
</p>


<p>
    Para más información sobre la Promoción y/o restricciones, puede llamar a +51 945 657 689 o ingresar a
    www.mandaditobcp.com.pe
</p>
