import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  show = false;

  private loaderService = inject(LoaderService);
  private cdRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.init();
  }

  init() {

    this.loaderService.getSpinnerObserver().subscribe((status) => {
      this.show = (status === 'start');
      this.cdRef.detectChanges();
    });
  }

}
