import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/core/constants';

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent {

  isMenuOpened = false

  private router = inject(Router);

  goToNewMandadito(): void {
    this.router.navigate([PATHS.NEW_MANDADITO]);
  }

  toggleFullscreenMenu() {
    this.isMenuOpened = !this.isMenuOpened
  }
}
