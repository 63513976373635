import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterContentInit, OnDestroy {

  @ContentChildren(AccordionItemComponent) accordions: QueryList<AccordionItemComponent>;
  @Input() activeFirst = false;
  subscriptions = [];
  temporalAccordions: any = [];

  ngAfterContentInit() {
    this.temporalAccordions = this.accordions;
    this.removeSubscriptions();
    this.addSubscriptions();

    this.accordions.changes.subscribe(rex => {
      this.temporalAccordions = rex;
      this.removeSubscriptions();
      this.addSubscriptions();
    });
    this.openDefaultToggle();
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }

  addSubscriptions() {
    this.temporalAccordions.forEach(a => {
      if (a.toggleAccordion) {
        const subscription = a.toggleAccordion.subscribe(() => {
          this.toogleAccordion(a);
        });
        this.subscriptions.push(subscription);
      }
    });
  }

  removeSubscriptions() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  toogleAccordion(accordion) {
    accordion.active = !accordion.active;
  }

  openDefaultToggle() {
    if (this.activeFirst) {
      setTimeout(() => {
        this.toogleAccordion(this.accordions.first);
      }, 0);
    }
  }

}
