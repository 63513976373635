<div class="header">
    <img src="/assets/images/logo-header.webp" alt="">
</div>
<div class="content">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="second">
        
        <div class="d-flex mx-auto justify-content-center flex-column">
            <div class="text-center">
                <div class="position-absolute">
                    <bcp-button shape="text" tier="primary" (click)="goBack()">
                        <bcp-icon accessible-name="" accessible-title="" name="arrow-left-r" slot="start"></bcp-icon>
                        Volver
                    </bcp-button>
                </div>
                <img src="/assets/images/map-usa-florida.svg" width="220" alt="Florida">
                <h1 class="subtitle flexo mt-3 font-size-20 font-size-md-24">Por ahora Mandadito solo<br />está en Florida</h1>
                <p class="font-size-16 font-size-md-16">Estamos trabajando para expandirnos<br />a más estados</p>
                <div class="message-let-me-know" *ngIf="isLetMeKnow">
                    <img src="/assets/images/check-round-valid.svg" width="22" alt="check" />
                    <p class="m-0 font-size-14 text-left"><span class="flexo-demi">¡Listo!</span> Te notificaremos cuando Mandadito esté disponible en más estados</p>
                </div>
                <div class="form-container" [ngClass]="{'d-none': isLetMeKnow}">
                    <hr>
                    <p class="text-left mt-2 mb-2 font-size-12 font-size-md-14"><span class="flexo-demi color-primary_700">¿Quieres que te avisemos?</span></p>
                    <form class="form-main justify-content-between" [formGroup]="formRegister" (ngSubmit)="sendData()">
                        <div class="input-email text-left">
                            <bcp-input type="email" label="Ingresa tu correo" required="true" bcpCtrlForm
                            [message]="getMessage('email')" [state]="getError('email')" message="Ej. micorreo@mail.com" formControlName="email"></bcp-input>
                        </div>
                        <div class="mt-2">
                            <bcp-button shape="rectangle" tier="primary" size="lg" full-width="true" type="submit">Avíseme</bcp-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


